import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { deleteCriterios } from "../../api/transferencias";
import { ButtonsContainer } from "./elements";

const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 370,
	height: 200,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 3,
};
const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
};

interface Props {
    open: boolean, 
    setOpen: (param:boolean) => void, 
    id: string, 
}

export default function ModalEliminarCriterio({open, setOpen, id} : Props) {
	
    const { width, height } = useWindowDimensions();

	const handleCerrar = () => {
		setOpen(false);
	};

    const handleEliminar = async() => {
        try {
            const res = await deleteCriterios(id);
            if(res?.status === 200){
                setOpen(false)
                window.location.reload();
            }
        } catch (error) {
            console.log(error)
        }
    }


	return (
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 300,
                            margin: "0em 0 0 0",
                        }}
                    >
                        ¿QUERES ELIMINAR ESTE CRITERIO?
                    </Typography>
                        <ButtonsContainer>
                            <Button
                                style={{
                                    ...styleButtons,
                                    marginRight: "16px",
                                    backgroundColor: "#004B6F",
                                    fontSize: "0.8em",
                                }}
                                onClick={handleEliminar}
                                variant="contained"
                                size="large"
                            >
                                ELIMINAR
                            </Button>

                            <Button
                                style={{
                                    ...styleButtons,
                                    marginRight: "16px",
                                    backgroundColor: "#C9C9C9",
                                    fontSize: "0.8em",
                                }}
                                onClick={handleCerrar}
                                variant="contained"
                                size="large"
                            >
                                CANCELAR
                            </Button>
                        </ButtonsContainer>
                </Box>
            </Modal>
    );
}
