import styled from "styled-components";

export const Container = styled.div`
    display: flex;
	flex-direction: column;
	height: calc(100vh - 3em);
	position: absolute;
	top: 3em;
	left: 12%;
	width: 88%;
`;

export const ContenedorTextoBusqueda = styled.div`
    margin-left: 32px;
	margin-right: 32px;
	display: flex;
	justify-content: space-between;
`;

export const TituloSeccion = styled.h2`
    color: #004b6fbf;
	font-size: 24px;
	font-family: "Montserrat";
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const ContenedorBlanco = styled.div`
    display: flex;
	flex-direction: column;
	background-color: #fff;
	height: 85%;
	margin-right: 32px;
	margin-left: 32px;
	margin-bottom: 32px;
	overflow-y: scroll;
`;

export const ContenedorElementos = styled.div`
    display: flex;
	flex-direction: column;
	margin-right: 24px;
	margin-left: 40px;
`;

export const Titulo = styled.h3`
    color: #004b6fbf;
	font-size: 24px;
	font-family: "Montserrat";
	margin-top: 32px;
	margin-bottom: 16px;
`;

export const ContenedorMotivos = styled.div`
    display: flex;
	flex-direction: column;
`;

export const ContenedorAgregarMotivo = styled.div`
    display: flex;
`;

export const ContenedorSelects = styled.div`
    display: flex;
	flex-direction: column;
	width: 50%;
`;

export const TituloMotivo = styled.span`
    font-weight: bold;
	font-size: 18px;
	margin-bottom: 8px;
`;

export const ContenedorTexto = styled.div`
    display: flex;
	flex-direction: column;
	margin-top: 8px;
`;

export const ContenedorMotivosArray = styled.div`
    width: 50%;
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	margin-top: 8px;
`;

export const Textos = styled.div`
    display: flex;
	flex-direction: column;
`;

export const OptionContainer = styled.div`
    align-items: stretch;
	margin-top: 12px;
`;

export const Label = styled.label`
    margin-left: 8px;
`;

export const TextoTextos = styled.span`
    display: flex;
	color: gray;
	margin-top: 8px;
`;

export const Botones = styled.div`
    display: flex;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 16px;
	width: 25%;
	margin: 0 auto;
	margin-bottom: 16px;
`;