import { TableColumn } from 'react-data-table-component';
import { RowDolarTable } from '../../interfaces/interfacesRows/rows';
import { formatCurrency } from '../formatCurrency';

const errorColor = "#FFBABA";

function extraerNumero(string: string): string {
    if (string) {
        const numero = string.match(/^\d+/); // Busca el número al inicio del string
        // console.log("numero " + numero ? numero[0] : null);
        return numero ? numero[0] : ""; // Retorna el número encontrado o "" si no se encontró ninguno
    } else {
        // console.log("numero ");
        return "";
    }
}

const columnsDolaresTable: TableColumn<RowDolarTable>[]  = [
    {
        selector: (row: RowDolarTable) =>
            extraerNumero(row["'Cliente Nombre del Cliente'"]),
        name: "Comitente",
        center: true,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDolarTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDolarTable) => row["Sistema de pago"],
        name: "Moneda",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDolarTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDolarTable) => formatCurrency(row["Saldo disponible"]),
        name: "Saldo Caja",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDolarTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },

    {
        selector: (row: RowDolarTable) => formatCurrency(row["'Saldo Caja Val'"]),
        name: "Saldo Gallo",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDolarTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },

    {
        selector: (row: RowDolarTable) =>
            formatCurrency(
                row["Saldo disponible"] - row["'Saldo Caja Val'"]
            ),
        name: "Diferencia",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDolarTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
];

export {
    columnsDolaresTable,
};
