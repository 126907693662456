import { Dialog, DialogTitle } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react'
import { Content, Text } from './elements';

interface Props {
    open: boolean,
    success: boolean | null,
    handleClose: () => void,
} 

const ModalAgregarAutorizado = ( {open, success, handleClose} : Props ) => {

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '20px',
                    backgroundColor: 'white',
                },
            }}
        >
        <DialogTitle sx={{ backgroundColor:  'white', padding: '1em' }}>
                {
                    success ? (
                        <Content>
                            <CheckCircleIcon
                                style={{ color: "#4CC70A", fontSize: 40 }}
                            />
                            <Text>Agregado con éxito</Text>
                        </Content>
                    ) : (
                        <Content>
                            <CancelIcon
                                style={{ color: "#FF0000", fontSize: 40 }}
                            />
                            <Text>Ocurrió un error</Text>
                        </Content>
                    )
                }
            </DialogTitle>
        </Dialog >
    )
}

export default ModalAgregarAutorizado