import styled from "styled-components";


export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    width: 100%; 
    border-radius: 10px;
    padding: 0.5em;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
`

export const TituloMercado = styled.p`
    font-size: 14px;
    margin: 0px;
    letter-spacing: 1.835px;
`

export const DineroEnCuenta = styled.p`
    font-size: 22px;
    font-weight: 700;
    margin-top: 0.7em;
`

export const DolaresLocal = styled.p`
    font-size: 22px;
    font-weight: 700;
    margin-top: 0.2em;
    color: gray;
`

export const ContainerPorcentajes = styled.div`
    display: flex; 
    align-items: center;
    justify-content: end;
`

export const Porcentaje = styled.p`
    font-size: 22px;
    font-weight: bold;
    margin: 0px;
    line-height: 1.5;
    text-align: end;
    margin-right: 1em;
`

export const PorcentajeCero = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
    text-align: center;
`

export const ContainerInfoPorcentaje = styled.div`
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    gap: 1em; 
    margin-top: 2em;
`

export const ImgBandera = styled.img`
    align-self: end; 
    width: 2em;
`