import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import MotivosSubsanar from "../../components/MotivosSubsanar/MotivosSubsanar";
import { subsanar } from "../../api/subsanados";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { BotonesContainer, Container, ContenedorBlanco, ContenedorElementos, ContenedorTextoBusqueda, MotivoContainer, Titulo, TituloSeccion } from "./elements";

const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
	color: "#FFFFFF",
};

const botonAgregar = {
	display: "flex !important",
	justifyContent: "flex-start !important",
	marginLeft: "36px !important",
	color: "#004B6F",
};

const Subsanar = () => {

	
    const { width } = useWindowDimensions();
	const navigate = useNavigate();
	let [motivosTextos, setMotivosTextos] = React.useState([]);
	const [motivos, setMotivos] = React.useState([
		<MotivosSubsanar numMotivo={1} motivosTextos={motivosTextos} />,
	]);

	const id = () => {
		let url = window.location.href;
		return url.substring(url.lastIndexOf("/") + 1);
	};

	const agregarMotivo = () => {
		setMotivos([
			...motivos,
			<MotivosSubsanar numMotivo={motivos.length + 1} motivosTextos={motivosTextos} />,
		]);
	};
	const enviarMotivos = async () => {
		try {
			const resp = await subsanar(id(), motivosTextos);
			navigate("/pendientes");
			
		} catch (error) {
			console.log(error);
		}
	};
	
	const toPendientes = () => {
		navigate("/pendientes");
	};

	return (
		<Container style={{
			left: width * 0.15,
			width: width * 0.85,
		}}>
			<ContenedorTextoBusqueda>
				<TituloSeccion>SUBSANAR</TituloSeccion>
			</ContenedorTextoBusqueda>
			<ContenedorBlanco>
				<ContenedorElementos>
					<Titulo> ¿CUALES SON LOS MOTIVOS?</Titulo>
					{motivos.map((motivo, index) => (
						<MotivoContainer key={index}>
							{motivo}
						</MotivoContainer>
					))}
				</ContenedorElementos>
				<Button
					style={botonAgregar}
					variant="text"
					onClick={agregarMotivo}
				>
					+ Agregar más motivos
				</Button>
				<BotonesContainer>
					<Button
						style={{
							...styleButtons,
							background: "#004B6F",
							marginRight: "16px",
						}}
						onClick={enviarMotivos}
						variant="contained"
						size="small"
					>
						ACEPTAR
					</Button>
					<Button
						style={{ ...styleButtons, background: "#C9C9C9" }}
						onClick={toPendientes}
						variant="contained"
						size="small"
					>
						CANCELAR
					</Button>
				</BotonesContainer>
			</ContenedorBlanco>
		</Container>
	);
};

export default Subsanar;
