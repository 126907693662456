import * as React from "react";
import { useState, useContext } from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { Icon } from "@iconify/react";
import ManagerModal from "../ModalManager/ModalManager";
import { getManagerById } from "../../../api/pendientes";
import { roles } from "../../../helper/roles";
import { UserContext } from "../../../context/userContext/UserContexts";

//Select para mostrar los manager disponibles y asignar y/o cambiar el manager a una ficha

interface Props {
	manager: string,
	id: string,
	pendiente: boolean,
	usuarioViejo: boolean,
	cotitular: boolean,
}

export interface Manager {
	Codigo: string,
	Email: string,
	Nombre: string,
	_id: string,
}

export default function ManagerSelect({
	manager,
	id,
	pendiente,
	usuarioViejo,
	cotitular,
} : Props) {

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const open = Boolean(anchorEl);

	const { user, managers } = useContext(UserContext);
	const managersList:Manager[] = managers;

	const [managerName, setManagerName] = useState("");

	// const [managerActual, setManagerActual] = useState({});

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		manager !== "ASIGNAR" ? getManagerActual() : setManagerName(manager);
	}, [managersList]);

	async function getManagerActual() {
		// const resp = await getManagerById(manager);
		// let managerResp = resp.data.body.manager;
		// setManagerActual(managerResp);
		// setManagerName(managerResp.name + " " + managerResp.lastname);
		let id = manager.toString();
		if (id.includes("Operador") || id.includes("ASIGNAR")) {
			setManagerName(id);
		} else {
			const managerFound = managersList.find(
				(element:Manager) => element._id === id
			);
			if (managerFound !== null && managerFound !== undefined) {
				setManagerName(getManagerNameAndCode(managerFound));
			} else {
				setManagerName("No hallado");
			}
		}
	}

	function getManagerNameAndCode(managerFound : Manager) {
		return (
			managerFound.Codigo +
			" - " +
			capitalizarPalabras(managerFound.Nombre)
		);
	}

	function capitalizarPalabras(cadena : string) {
		// Dividir la cadena en palabras
		let palabras = cadena.split(" ");

		// Iterar sobre cada palabra
		for (let i = 0; i < palabras.length; i++) {
			// Convertir la primera letra a mayúscula y el resto a minúscula
			palabras[i] =
				palabras[i].charAt(0).toUpperCase() +
				palabras[i].slice(1).toLowerCase();
		}

		// Unir las palabras en una cadena nuevamente
		let resultado = palabras.join(" ");

		return resultado;
	}

	return (
		<div>
			{user.rol === roles.compliance ||
			user.rol === roles.operador ||
			!pendiente ||
			usuarioViejo ||
			cotitular ? (
				<p
					style={{
						fontSize: "1.1em",
						textTransform: "capitalize",
						height: 25,
					}}
				>
					{managerName}
				</p>
			) : (
				<>
					<Button
						id="fade-button"
						aria-controls={open ? "fade-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						onClick={handleClick}
						sx={{
							color: "#004B6F",
							textTransform: "capitalize",
							fontSize: "1.1em",
							height: 25,
						}}
					>
						{managerName === "ASIGNAR"
							? managerName.toLowerCase()
							: managerName}
						<div style={{ marginLeft: "1em" }}>
							<Icon icon="ep:arrow-down-bold" />
						</div>
					</Button>
					<Menu
						id="fade-menu"
						MenuListProps={{
							"aria-labelledby": "fade-button",
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						TransitionComponent={Fade}
					>
						{managersList.map((manager, index) => (
							<MenuItem key={index}>
								<ManagerModal
									handleClose={handleClose}
									texto={
										getManagerNameAndCode(manager)
									}
									id={id}
									idManager={manager._id}
									setManagerName={setManagerName}
									managerName={managerName}
								/>
							</MenuItem>
						))}
					</Menu>
				</>
			)}
		</div>
	);
}
