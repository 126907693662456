import apiInstance from "../utils/Axios.js";
import { AprobadasResponse } from "../interfaces/interfacesAprobadas/aprobadasInterface.js";
import { AprobarFichaResponse } from "../interfaces/interfacesAprobadas/aprobarFichaInterface.js";
import { CheckAprobarResponse } from "../interfaces/interfacesAprobadas/checkAprobarInterface.js";
import { NumeroDeCuentaResponse } from "../interfaces/interfacesAprobadas/numeroDeCuentaInterface.js";
import { CheckJuridicasResponse } from "../interfaces/interfacesAprobadas/checkJuridicasInterface.js";
import { AddNuevaJuridicaResponse } from "../interfaces/interfacesAprobadas/addNuevaJuridicaInterface.js";

export async function getAprobadas() {
	return await apiInstance.get<AprobadasResponse>("/aprobadas");
}
export async function aprobarFicha(id: string) {
	return await apiInstance.put<AprobarFichaResponse>(`/aprobar/${id}`);
}

export async function checkAprobar(cuit: number) {
	return await apiInstance.post<CheckAprobarResponse>(`/checkterrorist`, {cuit});
}

export async function asignarNumCuenta(idClient: string) {
	return await apiInstance.post<any>(`/addtitulares`, {idClient});
}

export async function agregarNumCuenta(id: string, accountNumber: string) {
	return await apiInstance.put<any>(`/numcuenta/${id}`, {num: accountNumber});
}

export async function checkJuridicas(razonSocial: string, cuit: string, numCuenta: number | string) {
	return await apiInstance.post<CheckJuridicasResponse>(`/checkJuridicas`, {razonSocial, cuit, numCuenta});
}

export async function addNewJuridica(razonSocial: string, cuit: string, numCuenta: number | string) {
	return await apiInstance.post<AddNuevaJuridicaResponse>(`/addNewJuridica`, {razonSocial, cuit, numCuenta});
}
