import styled from "styled-components";

export const Button = styled.button`
    display: flex;
    cursor: pointer;
    border: none;
    align-items: center;
    background-color: white;
`;

export const Logo = styled.img`
    width: 30%;
    @media screen and (min-width: 992px) {
        width: 12%;
    }
`;

export const LogOutText = styled.p`
    color: #004b6f;
    font-size: 16px;
    font-weight: bold;
`;
