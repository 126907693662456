import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
	justify-content: center;
	margin-top: 32px;
	margin-bottom: 16px;
`;

export const Desc = styled.span`
    color: black;
	font-size: 14px;
	margin-top: 4px;
`;

export const ContainerMotivos = styled.div`
    display: flex;
	flex-direction: column;
	width: 85%;
	margin: 0 auto;
	/* overflow: scroll; */
	max-height: 350px;
`;

export const SpanEtapa = styled.span`
    margin-bottom: 8px;
`;

export const ContainerMotivo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const TituloEdit = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SpanMotivoTitulo = styled.span`
    display: flex;
	text-align: center;
	align-items: center;
	font-weight: bold;
`;

