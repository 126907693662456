import { ChangeEvent, useContext, useState } from "react";
import { DolaresContext } from "../context/dolaresContext/dolaresContext";
import { useNavigate } from "react-router-dom";
import { procesarDolares } from "../api/dolares";

export const useDolares = () => {
	const [cajaArchive, setCajaArchive] = useState<File | null>(null);
	const [gallo7000Archive, setGallo7000Archive] = useState<File | null>(null);
	const [gallo10000Archive, setGallo10000Archive] = useState<File | null>(null);
	const { actualizarDolares } = useContext(DolaresContext);

	const navigate = useNavigate();

	const handleSubmit = async () => {
		try {
			const resp = await procesarDolares(
				cajaArchive,
				gallo7000Archive,
				gallo10000Archive
			);
			actualizarDolares(resp.data.body);
			navigate("/tabla-dolares");
		} catch (error) {
			console.log(error);
		}
	};

	return {
		handleSubmit,
		cajaArchive,
		setCajaArchive,
		gallo10000Archive,
		gallo7000Archive,
		setGallo7000Archive,
		setGallo10000Archive,
	};
};
