import { TableColumn } from 'react-data-table-component';
import { RowReinversionTable } from '../../interfaces/interfacesRows/rows';

const columnsReinversionTable: TableColumn<RowReinversionTable>[] = [
    {
        selector: (row: RowReinversionTable) => row.especie,
        name: "ESPECIE",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowReinversionTable) => row.cantidad,
        name: "CANTIDAD",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowReinversionTable) => row.saldo,
        name: "SALDO",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowReinversionTable) => row.fechaLiq,
        name: "FECHA LIQUIDACIÓN",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowReinversionTable) => row.fechaLim,
        name: "DÍAS PARA OPERAR",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
];

export default columnsReinversionTable;
