import styled from "styled-components";

export const SeccionsContainer = styled.div`
    display: flex;
	flex-direction: row;
	align-items: stretch;
	height: 100%;
`;

export const Seccion = styled.div`
    width: 49.5%;
	height: 100%;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
`;

export const SeccionBox = styled.div`
    display: flex; 
	flex-direction: row;
	align-items: center;
	justify-content: center;
    margin-bottom: 2em;
`;

export const LineaVertical = styled.div`
    background-color: #949494;
	height: 90%;
	width: 0.1em;
	padding: 1em 0 1em 0;
`;