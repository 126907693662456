import { useState, useEffect, ChangeEvent, FormEvent } from "react"
import { sendNotification } from "../api/notificaciones_app";

// Hook que manipula el formulario para crear y porgramar nuevas notificaciones en la aplicación

export interface Form {
    date: string;
    autor: string;
    title: string;
    description: string;
    img: string;
    duration: string;
    setDate: string;
    setTime: string;
}

export interface Errors {
    autor?: string;
    title?: string;
    duration?: string;
}

export interface ValidationResult {
    [key: string]: string;
}

export const useFormAgregar = (initialForm: Form, validateForm: (form: Form) => ValidationResult) => {

    const [form, setForm] = useState<Form>(initialForm);

    const [img, setImg] = useState({});

    const [errors, setErrors] = useState<Errors>({});

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }, [form, Object.keys(errors).length]);

    const handleChange = (e : ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value,
        });


        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }
    
    const handleProgramar = ({handleClose, handleOpen} : any) => {
        
        let errorsVariableEstatica = validateForm(form);
        setErrors(validateForm(form));
        
        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorsVariableEstatica).length === 0 
            ) {
            handleOpen(true);
        } else {
            handleClose();
        }

    }

    const handleClick = async (e:ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setImg(e.target.files[0]);
        }
    };

    const handleSubmit = async(e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        let errorsVariableEstatica = validateForm(form);
        setErrors(validateForm(form));

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorsVariableEstatica).length === 0 
            ) {
                try {
                    
                    await sendNotification(img, form).then(
                        function (value) {
                            // Success!
                            window.location.reload();
                        },
                        function (error) {
                            console.log(error.response);
                        }

                    );
                } catch (error) {
                    console.log(error)
                }
        } 
    }

    return {
        form,
        errors,
        handleChange,
        handleProgramar,
        handleSubmit,
        //handleSubmitProgramar,
        handleClick,
        img
    }
}