import React, { ReactNode, createContext, useState } from "react";
import { Dividendo } from "../../interfaces/interfaceDividendos/getDividendosInterface";

interface DividendosContextProps {
	dividendos: Dividendo[];
	actualizarDividendos: (newValue: Dividendo[]) => void;
}

export const DividendosContext = createContext<DividendosContextProps>({} as DividendosContextProps);

interface DividendosProviderProps {
	children: ReactNode;
}

export const DividendosProvider = ({ children }: DividendosProviderProps) => {
	const [dividendos, setDividendos] = useState<Dividendo[]>([]);

	const actualizarDividendos = (newValue: Dividendo[]) => {
		newValue.sort((a, b) => {
			// Si 'a.diferencias' es true y 'b.diferencias' es false,
			// colocamos 'a' antes que 'b'
			if (a.diferencias && !b.diferencias) {
				return -1;
			}
			// Si 'a.diferencias' es false y 'b.diferencias' es true,
			// colocamos 'b' antes que 'a'
			if (!a.diferencias && b.diferencias) {
				return 1;
			}
			// Si ambos tienen el mismo valor de 'diferencias', no cambia su orden
			return 0;
		});
		setDividendos(newValue);
	};

	return (
		<DividendosContext.Provider
			value={{
				dividendos,
				actualizarDividendos,
			}}
		>
			{children}
		</DividendosContext.Provider>
	);
};
