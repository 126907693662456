import styled from "styled-components";

export const Container = styled.div`
	height: calc(100vh - 3em);
    position: absolute;
    top: 2em;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
`;

