import { GetMotivosResponse } from "../interfaces/interfaceSubsanadas/getMotivosInterface.js";
import { SubsanadasResponse } from "../interfaces/interfaceSubsanadas/getSubsanadasInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getSubsanadas() {
	return await apiInstance.get<SubsanadasResponse>("/subsanadas");
}
export async function getInformeById(id: string) {
	return await apiInstance.get("/informes/" + id);
}
export async function subsanar(id: string, motivos: any) {
	return await apiInstance.put("/subsanar/" + id, motivos);
}

export async function getMotivos() {
	return await apiInstance.get<GetMotivosResponse>("/motivo/");
}
export async function postMotivos(etapa: string, error: any, texto: string) {
	return await apiInstance.post("/motivo/", { etapa, error, texto });
}
export async function deleteMotivos(array: any[]) {
	const promesas = [];
	let error;

	for (let index = 0; index < array.length; index++) {
		if (array[index][1] === false) {
			error = array[index][0];
			promesas.push(
				apiInstance.delete("/motivo", {
					data: {
						error: error,
					},
				})
			);
		}
	}
	const resp = await Promise.all(promesas);
	return resp;
}
