import { GetCriteriosInterface } from "../interfaces/interfaceTransferencias/getCriteriosInterface.js";
import { GetRentaOperableResponse } from "../interfaces/interfaceTransferencias/getRentaOperableInterface.js";
import { GetTransferenciasResponse } from "../interfaces/interfaceTransferencias/getTransferenciasInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getTranferencias() {
    return await apiInstance.get<GetTransferenciasResponse>('/transferencias');
}

export async function getCriterios() {
    return await apiInstance.get<GetCriteriosInterface>('/criteriosTransferencias');
}

export async function postCriterios( cuenta: string, monto: string, porcTenencia: string, tipo: string ) {
    return await apiInstance.post('/criteriosTransferencias', { cuenta, monto, porcTenencia, tipo });
}

export async function putCriterios( id: string, cuenta: string, monto: string, porcTenencia: string, tipo: string ) {
    return await apiInstance.put('/criteriosTransferencias',{ id, cuenta, monto, porcTenencia, tipo });
}

export async function deleteCriterios( id: string ) {
    return await apiInstance.delete(`/criteriosTransferencias?id=${id}`);
}

export async function getRentaOperable( accountNumber: string ) {
    return await apiInstance.get<GetRentaOperableResponse>(`/rentaOperable?accountNumber=${accountNumber}`);
}



