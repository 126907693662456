import Table from '../../components/Table/Table';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { columnsSubsanados } from '../../helper/Colums/columsSubsanados';
import { Container } from './elements';

const subtitulo = "SUBSANADOS"

export const Subsanados = () => {

    const { width } = useWindowDimensions();

    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <Table
                subtitulo={subtitulo}
                columns={columnsSubsanados}
            />
        </Container>
    )
}
