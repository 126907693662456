import React from "react";
import { Button } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useDolares } from "../../hooks/useDolares";
import { Box, Form, Input, Label, LogoAdjuntar, MiniBox, SeccionAdjuntos, SeccionButtons } from "./elements";

interface FileData {
    id: string;
    label: string;
    accept: string;
    state: File | null;
    setState: React.Dispatch<React.SetStateAction<File | null>>;
    alt: string;
}

export const Dolares = () => {
	const {
		handleSubmit,
		cajaArchive,
		setCajaArchive,
		gallo10000Archive,
		gallo7000Archive,
		setGallo7000Archive,
		setGallo10000Archive,
	} = useDolares();

	const data: FileData[] =  [
		{
			id: "caja",
			label: "CAJA (CSV)",
			accept: ".csv",
			state: cajaArchive,
			setState: setCajaArchive,
			alt: "adjuntar-dorado.png",
		},
		{
			id: "gallo7000",
			label: "GALLO 7000 (XLS)",
			accept: ".xls,.xlsx",
			state: gallo7000Archive,
			setState: setGallo7000Archive,
			alt: "adjuntar-dorado.png",
		},
		{
			id: "gallo10000",
			label: "GALLO 10000 (XLS)",
			accept: ".xls,.xlsx",
			state: gallo10000Archive,
			setState: setGallo10000Archive,
			alt: "adjuntar-dorado.png",
		},
	];

	const { width, height } = useWindowDimensions();

	return (
        <Form
            style={{
                width: width * 0.84,
                height: height * 0.15,
            }}
            onSubmit={async (event) => {
                event.preventDefault(); // Evitar que el formulario se envíe automáticamente
                await handleSubmit(); // Esperar a que se complete la función handleSubmit
            }}
        >
            <Box>
                <>
                    <SeccionAdjuntos>
                        {data.map(
                            ({ id, label, accept, state, setState, alt }) => (
                                <MiniBox key={id}>
                                    {state ? (
                                        <>
                                            <Label htmlFor={id}>
                                                <LogoAdjuntar
                                                    src={require(`../../images/documentos/${alt}`)}
                                                    alt="logo"
                                                />
                                                {label}
                                            </Label>
                                            <Input
                                                type="file"
                                                accept={accept}
                                                id={id}
                                                onChange={(e) =>
                                                    setState(e.target.files?.[0] || null)
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Label htmlFor={id}>
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar.png")}
                                                    alt="logo"
                                                />
                                                {label}
                                            </Label>
                                            <Input
                                                type="file"
                                                accept={accept}
                                                id={id}
                                                onChange={(e) =>
                                                    setState(e.target.files?.[0] || null)
                                                }
                                            />
                                        </>
                                    )}
                                </MiniBox>
                            )
                        )}
                        <SeccionButtons>
                            <Button
                                style={{
                                    marginRight: "16px",
                                    backgroundColor: "#004B6F",
                                }}
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                PROCESAR
                            </Button>
                        </SeccionButtons>
                    </SeccionAdjuntos>
                </>
            </Box>
        </Form>
    );
};
