import { MovimientosResponse } from "../interfaces/interfacesMovimientos/getMovimientosInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getMovimientos () {
	return await apiInstance.get<MovimientosResponse>(`/movimientos`);
};

export async function getNombreOperador(id: string) {
	return await apiInstance.get(`/operador/${id}`);
};

