import React from "react";
import Table from "../../components/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { columnsRechazados } from "../../helper/Colums/columsRechazados";
import { Container } from "./elements";

const subtitulo = "RECHAZADOS"

export const Rechazados = () => {

    const { width } = useWindowDimensions();

    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <Table 
                subtitulo={subtitulo}
                columns={columnsRechazados}
            />
        </Container>
    );
}