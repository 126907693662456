import { useContext, useEffect, useState, ChangeEvent, MouseEvent, FormEvent } from "react";
import usePendientesHook from "./usePendientesHook";
import useAprobadasHook from "./useAprobadasHook";
import useInformadasHook from "./useInformadasHook";
import useRechazadasHook from "./useRechazadasHook";
import useSubsanadasHook from "./useSubsanadasHook";
import useMovimientosHook from "./useMovimientosHook";
import useCotitularesHook from "./useCotitularesHook";
import { useTranferenciasHook } from "./useTransferenciasHook";
import { useCriteriosHook } from "./useCriteriosHook";
import { useDividendosTableHook } from "./useDividendosTable";
import { useEstadoRentasTable } from "./useEstadoRentasTable";
import { useDolaresTableHook } from "./useDolaresTable";
import { UserContext } from "../context/userContext/UserContexts";
import useOrdenesExteriorHook from "./useOrdenesExteriorHook";
import usePedidosDolaresHook from "./usePedidosDolares";
import { useCBUsTableHook } from "./useCBUsTable";
import { useConciliacionesTableHook } from "./useConciliacionesTable";

interface Props {
	subtitulo: string,
	titularId: string,
	filtroTenencia: string | undefined
	reloadTable?: boolean
	setReloadTable?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function useTableDolarRenta({ subtitulo, titularId, filtroTenencia, reloadTable, setReloadTable }: Props) {
	const { user } = useContext(UserContext);
	const userRol = user ? user.rol : "";

	const [loading, setLoading] = useState(true);

	const {
		getPedidosDolares,
		filtPedidosTransferencias,
		pedidosDolaresFilt,
		showAprobadasRechazadas,
		setShowAprobadasRechazadas,
		pedidosDolares,
		setPedidosDolaresFilt,
		pedidos,
	} = usePedidosDolaresHook({ setLoading, filtroTenencia, reloadTable, setReloadTable, subtitulo });


	//se llama a las funciones que hacen las peticiones al montarse el componente

	useEffect(() => {
		getPedidosDolares();
	}, []);

	const [name, setName] = useState("");

	useEffect(() => {
		if (subtitulo === "PEDIDOS DOLARES") {
			const interval = setInterval(() => {
				getPedidosDolares().then(() => {
					// Reaplicar el filtro actual después de la actualización
					filtPedidosTransferencias(name);
				});
			}, 2 * 60 * 1000); // 2 minutos en milisegundos

			// Limpieza del intervalo al desmontar el componente
			return () => clearInterval(interval);
		}
	}, [name, getPedidosDolares, filtPedidosTransferencias]);

	useEffect(() => {
		if (showAprobadasRechazadas) {
			setPedidosDolaresFilt(pedidosDolares);
			setName("");
		} else {
			const withOutAprobadas = pedidosDolares.filter(e => e.estadoActual !== "APROBADA" && e.estadoActual !== "RECHAZADA")
			setPedidosDolaresFilt(withOutAprobadas);
			setName("");
		}
	}, [showAprobadasRechazadas])

	let data = pedidosDolaresFilt;
	let filtFunction: (name: string) => void = filtPedidosTransferencias;

	const paginationOpciones = {
		rowsPerPageText: "Filas por página",
		rangeSeparatorText: "de",
		selectAllRowsItem: true,
		selectAllRowsItemText: "Todos",
	};

	function handleInput(e: ChangeEvent<HTMLInputElement>) {
		setName(e.target.value);
		filtFunction(e.target.value);
	}

	function handleSubmit(e: ChangeEvent<HTMLFormElement>) {
		e.preventDefault();

		setName(e.target.value);
		filtFunction(e.target.value);
	}

	return {
		handleInput,
		handleSubmit,
		data,
		paginationOpciones,
		loading,
		userRol,
		showAprobadasRechazadas,
		setShowAprobadasRechazadas,
		name,
		pedidos,
	};
}
