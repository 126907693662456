import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Icon } from '@iconify/react';
import { useEffect } from "react";
import { getEmailCode, getSmsCode } from "../../../api/datos";
import { roles } from "../../../helper/roles";
import { LineaVertical, Seccion, SeccionBox, SeccionsContainer } from "./elements";


const styleTel = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 360,
	height: 220,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 4,
};

const styleDatos = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 480,
	height: 260,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 2,
    paddingTop: 1.5,
};

//Muestra datos del usuario

interface Props {
    id: string, 
    telefono: number, 
    nombre: string, 
    rol: string, 
    handleClose: () => void, 
    setOpen: (param: boolean) => void, 
    open: boolean,
}

export default function ModalDatos({ id, telefono, nombre, rol, handleClose, setOpen, open} : Props) {
    const [smsCode, setSmsCode] = React.useState('');
    const [emailCode, setEmailCode] = React.useState('');

	const handleCerrar = () => {
		setOpen(false);
		handleClose();
	};

    useEffect(() => {
        if (rol === roles.administrador || rol === roles.comercial) {
            getCodes()
        }
    }, [])

    const getCodes = async() => {
        const sms = await getSmsCode(id);
        const email = await getEmailCode(id);
        setEmailCode(email.data.body);
        if(sms.data.body === "No hay codigo"){
            setSmsCode(sms.data.body);
        } else {
            setSmsCode(sms.data.body.code);
        }
        
    }
    
    const render = () => {
        if(rol === roles.administrador || rol === roles.comercial){
            return (
                <Box sx={styleDatos}>
                    <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={handleCerrar}
                        style={{
                            color: "#004B6F",
                            height: "1em",
                            marginLeft: "17.8em",
                        }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                    <SeccionsContainer>
                        <Seccion>
                            <div>
                                <Typography
                                    id="keep-mounted-modal-title"
                                    variant="h6"
                                    component="h2"
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        lineHeight: 1.2,
                                        width: 200,
                                        margin: "0 auto",
                                        marginTop: "0.5em",
                                    }}
                                >
                                    {nombre.toUpperCase()}
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        lineHeight: 1.2,
                                        width: 200,
                                        margin: "0 auto",
                                        marginTop: "0.8em",
                                        marginBottom: "2em",
                                    }}
                                >
                                    {telefono}
                                </Typography>
                            </div>
                        </Seccion>
                        <LineaVertical/>
                        <Seccion>
                            <div>
                                <SeccionBox>
                                    <Icon
                                        icon="mdi:email-outline"
                                        style={styles.icon}
                                    />
                                    <Typography
                                        id="keep-mounted-modal-title"
                                        variant="h6"
                                        component="h2"
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            lineHeight: 1.2,
                                            width: 110,
                                            margin: "0 0.5em",
                                            marginTop: "0.5em",
                                            textAlign: "start",
                                        }}
                                    >
                                        CÓDIGO DE EMAIL:
                                        <br />
                                        <p style={{ fontSize: 18 }}>
                                            {emailCode}
                                        </p>
                                    </Typography>
                                </SeccionBox>
                                {smsCode !== "No hay codigo" && (
                                    <SeccionBox>
                                        <Icon
                                            icon="mdi:cellphone"
                                            style={styles.icon}
                                        />
                                        <Typography
                                            id="keep-mounted-modal-title"
                                            variant="h6"
                                            component="h2"
                                            style={{
                                                fontFamily: "Montserrat",
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                lineHeight: 1.2,
                                                width: 110,
                                                margin: "0 0.5em",
                                                marginTop: "0.5em",
                                                textAlign: "start",
                                            }}
                                        >
                                            CÓDIGO DE SMS:
                                            <br />
                                            <p style={{ fontSize: 18 }}>
                                                {smsCode}
                                            </p>
                                        </Typography>
                                    </SeccionBox>
                                )}
                            </div>
                        </Seccion>
                    </SeccionsContainer>
                </Box>
            );
        } else {
            return (
                <Box sx={styleTel}>
                        <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={handleCerrar}
                            style={{
                                color: "#004B6F",
                                marginLeft: "12.2em",
                                
                            }}
                        >
                            <CloseOutlinedIcon />
                        </IconButton>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 300,
                            margin: "0 auto",
                            marginTop: "0.5em",
                        }}
                    >
                        {nombre.toUpperCase()}
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 30,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 300,
                            margin: "0 auto",
                            marginTop: "0.8em",
                            marginBottom: "2em",
                        }}
                    >
                        {telefono}
                    </Typography>
                </Box>
            )
        }
    }
	
	return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                {render()}
            </Modal>
        </div>
    );
}

const styles: Record<string, React.CSSProperties> = {
    icon: {
        fontSize: "2.2em",
        alignSelf: "center",
    },
};

