import { RechazadasResponse } from "../interfaces/interfaceRechazadas/getRechazadasInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getRechazadas() {
	return await apiInstance.get<RechazadasResponse>("/rechazadas");
}

export async function rechazarFicha(id: string, checked: boolean, motivo: any) {
	return await apiInstance.put(`/rechazar/${id}`, { enviar: checked, motivo });
}

export async function getMotivoRechazo(id: string) {
	return await apiInstance.put(`/motivo-rechazo`, { id });
}

