import { Errors, Form, ValidationResult } from "../hooks/useFormAgregar";

export const validacionesAgregar = (form : Form): ValidationResult =>{
    let errors : ValidationResult = {};

    if(!form.autor){
        errors.autor = "Este campo es requerido"
    }
    if(!form.title){
        errors.title = "Este campo es requerido"
    }
    if(!form.duration){
        errors.duration = "Este campo es requerido"
    }

    return errors;
}
