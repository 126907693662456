import { GetSentNotificationsResponse } from "../interfaces/interfaceNotificacionesApp/getSentNotifications.js";
import { GetSettedNotificationsResponse } from "../interfaces/interfaceNotificacionesApp/getSettedNotifications.js";
import apiInstance from "../utils/Axios.js";

export async function getSettedNotifications() {
	return await apiInstance.get<GetSettedNotificationsResponse>("/settedNotifications");
}

export async function getSentNotifications() {
	return await apiInstance.get<GetSentNotificationsResponse>("/sentNotifications");
}

export async function sendNotification(archivo: any, form: any){
	let formData = new FormData();
	
		formData.append("attachments", archivo);
		formData.append("date", form.date);
		formData.append("autor", form.autor);
		formData.append("title", form.title);
		formData.append("description", form.description);
		formData.append("img", form.img);
		formData.append("duration", form.duration);
		formData.append("setDate", form.setDate);
		formData.append("setTime", form.setTime);

		
	
	return await apiInstance.post("/sendNotification", formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});
};


export async function setNotification(archivo: any, form: any) {
    let formData = new FormData();

    formData.append("attachments", archivo);
    formData.append("date", form.date);
    formData.append("autor", form.autor);
    formData.append("title", form.title);
    formData.append("description", form.description);
    formData.append("img", form.img);
    formData.append("duration", form.duration);
    formData.append("setDate", form.setDate);
    formData.append("setTime", form.setTime);

    return await apiInstance.post("/setNotification", formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}
