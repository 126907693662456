import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container } from "./elements";
import { columnsTransferenciasDolares } from "../../helper/Colums/columsTransferenciasDolares";
import TableDolarRenta from "../../components/TableDolarRenta/TableDolarRenta";

const subtitulo = "PEDIDOS DOLARES";

export const PedidosDolares = () => {

    const { width } = useWindowDimensions();

    return (
		<>
			<Container style={{ width: width * 0.85, left: width * 0.15,  } }>
				<TableDolarRenta subtitulo={subtitulo} columns={columnsTransferenciasDolares} />
			</Container>
		</>
	);
};
