import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ButtonsContainer, Select, SelectContainer } from "./elements";
import { CambiarEstadoTransferenciaDolar } from "../../../api/pedidos_dolares";
import { Pedido } from "../../../hooks/usePedidosDolares";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 370,
    height: 250,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 3,
};
const styleButtons = {
    width: "130px",
    height: "35px",
    fontWeight: "bold",
};

interface Props {
    open: boolean,
    setOpen: (param: boolean) => void,
    pedidos: Pedido[],
}

const estadoOpciones: string[] = ['EN PROCESO', 'RECHAZAR', 'APROBAR'];

export default function ModalEstadoPedidoDolar({ open, setOpen, pedidos }: Props) {

    const { width, height } = useWindowDimensions();

    const [action, setAction] = useState<string>('');

    const handleCerrar = () => {
        setOpen(false);
    };

    const handleConfirmar = async () => {
        try {
            if (action !== "") {
                const promises = pedidos.map(e => 
                    CambiarEstadoTransferenciaDolar(e.id, action, e.origen)
                );
    
                const results = await Promise.all(promises);
    
                const allSuccessful = results.every(res => res?.status === 200);
    
                if (allSuccessful) {
                    setOpen(false);
                    window.location.reload();
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleCerrar}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="keep-mounted-modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                        lineHeight: 1.2,
                        width: 300,
                        margin: "0em 0 0 0",
                    }}
                >
                    ¿QUERES CAMBIAR DE ESTADO?
                </Typography>
                <SelectContainer>
                    <Select
                        onChange={(e) => setAction(e.target.value)}
                        name="cuitVerificado"
                        value={action}
                    >
                        <option value="" disabled hidden>
                            Selecciona
                        </option>

                        {estadoOpciones.map((e) => (
                            <option key={e} value={e}>
                                {e}
                            </option>
                        ))}
                    </Select>
                </SelectContainer>
                <ButtonsContainer>
                    <Button
                        style={{
                            ...styleButtons,
                            backgroundColor: "#004B6F",
                            fontSize: "0.8em",
                        }}
                        onClick={handleConfirmar}
                        variant="contained"
                        size="large"
                    >
                        CONFIRMAR
                    </Button>

                    <Button
                        style={{
                            ...styleButtons,
                            backgroundColor: "#C9C9C9",
                            fontSize: "0.8em",
                        }}
                        onClick={handleCerrar}
                        variant="contained"
                        size="large"
                    >
                        CANCELAR
                    </Button>
                </ButtonsContainer>
            </Box>
        </Modal>
    );
}
