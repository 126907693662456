import React, { useState } from 'react'
import { formatCurrency } from '../../../helper/formatCurrency';
import { Cantidad, Container, ContainerPrecios, ContainerRight, Especie, Importe, Precio, VariacionPorcentaje } from './elements';
import ModalConfirmacion from '../ModalConfirmacion/ModalConfirmacion';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CachedIcon from '@mui/icons-material/Cached';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import { PedidosDolarRenta } from '../../../interfaces/interfaceTenecia/getTenenciaInterface';
import usePedidosDolaresHook from '../../../hooks/usePedidosDolares';

interface Props {
    especie: string;
    precio: number;
    cantidad: number;
    importe: string | number;
    porcentaje: number;
    costo: number;
    variacionCosto: number;
    pintar: boolean;
    tipoMercado: string;
    tipoOpcion: string;
    nombreEspecie: string
    dolarRenta?: PedidosDolarRenta[];
    setTipoDolarImporte?: React.Dispatch<React.SetStateAction<{
        cantidad: number;
        especie: string;
    }>>;
    transferirDolares?: () => Promise<void>;
    setReloadTable: React.Dispatch<React.SetStateAction<boolean>>
}

const CardEspecieTenencia = ({
    especie,
    precio,
    cantidad,
    importe,
    porcentaje,
    costo,
    variacionCosto,
    pintar,
    tipoMercado,
    tipoOpcion,
    nombreEspecie,
    dolarRenta,
    setTipoDolarImporte,
    transferirDolares,
    setReloadTable
}: Props) => {

    const formatName = (): string => {
        if (especie === "DOLA") {
            return nombreEspecie.includes("7000") ? "DOLA 7000" : nombreEspecie.includes("10000") ? "DOLA 10000" : especie
        } else {
            return especie
        }
    }

    const esDolarDiezMil = (dolar: string, esModal?: boolean) => {
        return esModal
            ? dolar.includes('10000') ? 'DOLA 10000' : 'DOLA 7000'
            : dolar.includes('10000') ? 'DOLAR 10.000' : 'DOLAR 7.000';
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAceptarModal = async () => {
        transferirDolares && transferirDolares()
        setReloadTable(true)
        setOpen(false)
    }

    const tienePedidoRenta = (dolar: string) => {
        let tienePedido = false
        dolarRenta?.forEach(d => {
            if (d.especie === esDolarDiezMil(dolar, true)) {
                tienePedido = true;
            }
        })
        return tienePedido;
    }

    const textoTooltip = (
        <>
            Pedido de conversión de <strong>dólar renta</strong> pendiente.
        </>
    )



    return (
        <Container
            style={{
                backgroundColor: pintar
                    ? 'white'
                    : 'rgba(242, 245, 248, 1)'
            }}
        >
            <div style={{ marginLeft: '0em' }}>
                {
                    especie === "DOLA"
                        ?
                        <div style={{ display: 'flex' }}>
                            <Especie>
                                {esDolarDiezMil(nombreEspecie)}
                            </Especie>
                            {
                                !tienePedidoRenta(nombreEspecie)
                                    ? <div
                                        style={{ display: 'flex' }}
                                        onClick={() => {
                                            setTipoDolarImporte && setTipoDolarImporte((prev) => ({
                                                ...prev,
                                                especie: esDolarDiezMil(nombreEspecie, true),
                                                cantidad: Number(cantidad),
                                            }))
                                            handleOpen();
                                        }}
                                    >
                                        <CachedIcon
                                            style={{
                                                // color: primaryColor,
                                                fontSize: '1.2em',
                                                // backgroundColor: secondaryBackgroundColor,
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                    :
                                    <TooltipComponent
                                        text={textoTooltip}
                                    >
                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <ErrorOutlineIcon
                                                style={{
                                                    // color: primaryColor,
                                                    fontSize: '1.2em',
                                                    // backgroundColor: secondaryBackgroundColor,
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </div>
                                    </TooltipComponent>
                            }
                            <ModalConfirmacion
                                open={open}
                                handleClose={handleClose}
                                handleConfirmarModal={handleAceptarModal}
                                tituloModal={'¿Querés convertir a dólar billete?'}
                                textoModal={'El proceso puede tardar hasta 96hs. hábiles.'}
                                textoConfirmarModal={'Aceptar'}
                            />
                        </div>
                        : <Especie>{formatName()}</Especie>
                }

                <VariacionPorcentaje
                    style={{
                        color: tipoOpcion === 'ACTUAL'
                            ? porcentaje > 0 ? '#03DAC5'
                                : porcentaje < 0 ? '#B00020'
                                    : undefined
                            : variacionCosto > 0 ? '#03DAC5'
                                : variacionCosto < 0 ? '#B00020' : undefined
                    }}
                >
                    {tipoOpcion === 'ACTUAL'
                        ? porcentaje !== null
                            ? formatCurrency(porcentaje)
                            : '-'
                        : variacionCosto !== null
                            ? formatCurrency(variacionCosto)
                            : '-'
                    }%
                </VariacionPorcentaje>
            </div>
            <ContainerRight>
                <Cantidad style={{ alignSelf: tipoOpcion === 'HISTORICO' ? 'center' : undefined }}>{cantidad}</Cantidad>
                <ContainerPrecios>
                    {tipoOpcion === 'ACTUAL' ?
                        <Precio>
                            {
                                tipoMercado === 'LOCAL'
                                    ? `$${formatCurrency(precio)}`
                                    : `USD ${formatCurrency(precio)}`
                            }
                        </Precio>
                        :
                        <Precio>
                            {
                                tipoMercado === 'LOCAL'
                                    ? `$${formatCurrency(costo)}`
                                    : `USD ${formatCurrency(costo)}`
                            }
                        </Precio>
                    }
                    {
                        tipoOpcion === 'ACTUAL'
                            ?
                            <Importe>
                                {
                                    tipoMercado === 'LOCAL'
                                        ? `$${formatCurrency(importe)}`
                                        : `USD ${formatCurrency(importe)}`
                                }
                            </Importe>
                            : null
                    }
                </ContainerPrecios>
            </ContainerRight>
        </Container>
    )
}

export default CardEspecieTenencia