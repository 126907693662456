import styled from "styled-components";

export const Container = styled.div`
	position: absolute;
    top: 4em;
    height: 90%;
    padding-left: 1em;
    padding-top: 1em;
`;

export const Subtitulo = styled.h3`
	color: rgba(0, 75, 111, 0.75);
    font-size: 1.5em;
`;

export const Box = styled.div`
	background-color: white;
    margin-top: 0.5em;
    width: 98%;
    height: 93%;
    padding: 1em;
    border-radius: 1em;
`;

export const Sub = styled.h5`
	color: #757474;
    font-size: 1.3em;
    margin-bottom: 1em;
`;

export const Notificacion = styled.div`
	display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 0.5em 0 0.1em;
`;

export const NotificacionTexto = styled.p`
	align-self: center;
    margin-left: 1em;
    font-size: 1em;
`;

export const Linea = styled.div`
	border-top: 1px solid #A0A0A0;
    height: 2px;
    padding: 0;
    margin: 0.5em auto 0.5em auto;
`;


