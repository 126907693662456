import styled from "styled-components";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Label = styled.label`
    display: flex;
    padding: 1.8em;
    background: white;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.877), inset 4px 4px 14px #EBEBED;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    cursor: pointer;
    color: #00386E;	
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    width: fit-content;
    height: 2em;
`;

export const Input = styled.input`
    display: none;
`;

export const FileText = styled.p`
    color: #00386E;
    font-weight: bold;
    font-size: 0.8em;
    margin-left: 1.8em;
`;

