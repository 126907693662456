import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { procesarCbus } from "../api/cbu";
import { CBUsContext } from "../context/cbusContext/cbusContext";
import { CuentasCBUs } from "../components/Table/CuentasCBUs/CuentasCBUs";

export const useCBUs = () => {
	const [archive, setArchive] = useState<File | null>(null);
	const { actualizarCBUs } = useContext(CBUsContext);
	const navigate = useNavigate();
	const handleSubmit = async () => {
		try {
			const resp = await procesarCbus(archive);
			const ultCBUs = resp?.data?.body.map((e) => {
				return {
					_id: '-',
					Fecha: e.Fecha,
					Hora: e.Hora,
					Beneficiario: e.Beneficiario,
					Banco: e.Banco,
					Importe: e.Importe,
					Cuentas: <CuentasCBUs cuentas= { e.Cuentas } />,
					CuentasFilt: e.Cuentas,
				}
			});
			actualizarCBUs(ultCBUs);
			navigate("/tabla-cbu");
		} catch (error) {
			console.log(error);
		}
	};

	return {
		handleSubmit,
		archive,
		setArchive,
	};
};
