import styled from 'styled-components';

export const SeccionButtons = styled.div`
    display: flex;
    justify-content: center;
`;

export const DatosContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 2em 0 3em 0;
    padding: 0 0.5em 0 1.5em;
`;

export const DatosText = styled.div`
    color: #00386E;
    font-size: 1em;
    margin-bottom: 0.3em;
    margin-top: 0.3em;
`;

export const RazonText = styled.p`
    color: #00386E;
    font-size: 1em;
    margin-bottom: 0.3em;
    font-weight: bold;
`;
