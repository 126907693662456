const LightTheme = {
	background: "white",
	fontColor: "#004b6f",
	fontAperturaColor: "#282B30",
	fontEspecialColor: "#004b6f",
	backgroundResultadoTest: "#EBEBED",
	inputShadow: "#ffffff99",
	inputShadow2: "#EBEBED",
	disabledButton: "#CECECE",
	disabledFont: "#EBEBED",
	errorColor: "#E3342F",
	linkColor: "013d58",
	errorFondo: "#FDEDED",
    buttonColor:"#004B6F",
	fontButtonColor: "white",
};

// const DarkTheme = {
// 	background: "#282B30",
// 	fontColor: "white",
// 	fontAperturaColor: "white",
// 	fontEspecialColor: "#FFC74F",
// 	backgroundResultadoTest: "#404041",
// 	inputShadow: "#282B30",
// 	inputShadow2: "#161723",
// 	disabledButton: "#35373b",
// 	disabledFont: "#292929",
// 	linkColor: "#FFC74F",
// 	errorColor: "#FFF",
// 	errorFondo: "#9B1B19",
// };

export const Themes = {
	light: LightTheme,
	// dark: DarkTheme,
};

