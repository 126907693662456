import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ButtonsContainer, Container, Text, TextContainer } from "./elements";
import { Button } from "@mui/material";

const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 370,
	height: 200,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 3,
};

interface Props {
    open: boolean, 
    setOpen: (param:boolean) => void, 
    id: string, 
    cliente: string,
    especie: string,
    operacion: string,
    cantidad: string,
    precio: string,
    importe: string,
}

export default function ModalCambiarEstadoOrden({open, setOpen, id, cliente, especie, operacion, cantidad, precio, importe} : Props) {
	
    const { width, height } = useWindowDimensions();

	const handleClose = () => {
		setOpen(false);
	};

	return (
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Container>
                        <TextContainer>
                            <Text>Operación: {operacion}</Text>
                            <Text>Cliente: {cliente}</Text>
                            <Text>Especie: {especie}</Text>
                            <Text>Cantidad: {cantidad}</Text>
                            <Text>Precio: {precio}</Text>
                            <Text>Importe: {importe}</Text>
                        </TextContainer>
                        <ButtonsContainer>
                            <Button 
                                variant="contained"
                                sx={ButtonStyles}
                            >
                                Operar
                            </Button>
                            <Button
                                variant="contained"
                                sx={ButtonStyles}
                            >
                                Anula
                            </Button>
                            <Button
                                variant="contained"
                                sx={ButtonStyles}
                            >
                                Cambia
                            </Button>
                            <Button
                                variant="contained"
                                sx={ButtonStyles}
                            >
                                Continua
                            </Button>
                        </ButtonsContainer>
                    </Container>
                </Box>
            </Modal>
    );
}

const ButtonStyles = {
    background: "#00386E",
}
