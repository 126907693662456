import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useModalEstado } from "../../hooks/useModalEstado";
import { roles } from "../../helper/roles";
import { s, ButtonsContainer, EstadoAprobado, EstadoRechazado, Estados, ContainerCheck, InputMotivos, TextMotivos } from "./elements";
import { WarningBox } from "../WarningBox/WarningBox";

const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 'fit-content',
	height: 'fit-content',
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	padding: '2em 4em'
};
const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
};

// Permite modificar el estado de la ficha de acuerdo al rol


interface Props {
    handleClose: () => void,
    texto: string,
    desde: string,
	id: string,
    phaseLength: number,
    subsanado: boolean | string,
    rol: string,
}


export default function ModalEstado({handleClose, texto, desde, id, phaseLength, subsanado, rol} : Props) {

	const { handleOpen, open, handleCerrar, warning, submit, loading, handleChange, handleCheck, checked, documentos} = useModalEstado({id, phaseLength, texto, handleClose, rol});

	const hastaRender = () => {
		if (texto === "Rechazar") {
			return <EstadoRechazado>Rechazada</EstadoRechazado>;
		} else if (texto === "Aprobar") {
			return <EstadoAprobado>Aprobada</EstadoAprobado>;
		}
	};

	return (
        <div>
            <Button
                onClick={handleOpen}
                variant="text"
                style={{
                    color:
                        texto === "Aprobar" && phaseLength && phaseLength < 6
                            ? "#c0c0c0"
                            : "#004B6FBF",
                    textTransform: "capitalize",
                }}
                // disabled={props.texto === "Aprobar" && props.phaseLength != 6}
            >
                {texto}
            </Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 300,
                            margin: "0 auto",
                        }}
                    >
                        ¿QUERÉS CAMBIAR DE ESTADO LA FICHA?
                    </Typography>

                    {loading ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "0.5em",
                            }}
                        >
                            <CircularProgress style={{ color: "#004B6F" }} />
                        </Box>
                    ) : warning.length > 0 ? (
                        <WarningBox warning={warning} documentos={documentos}/>
                    ) : (
                        <Estados>
                            <div
                                style={
                                    desde === "Informado"
                                        ? s.estadoInformado
                                        : !subsanado
                                        ? s.estadoPendiente
                                        : s.estadoPendienteSubsanado
                                }
                            >
                                {desde}
                            </div>
                            <ArrowRightAltIcon fontSize="large" />
                            {hastaRender()}
                        </Estados>
                    )}

                    {texto === "Rechazar" && (
                        <>
                        {
                            rol !== roles.compliance &&
                            <ContainerCheck>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            onChange={handleCheck}
                                            checked={checked}
                                            sx={{
                                                color: '#004B6F',
                                                margin: 0,
                                                "&.Mui-checked": {
                                                    color: '#004B6F',
                                                },
                                            }}
                                        />
                                    }
                                    label="Enviar mail"
                                />
                            </ContainerCheck>
                        }
                            <div style={{margin: 0}}>
                                <TextMotivos>Motivos</TextMotivos>
                                <InputMotivos 
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    )}

                    <ButtonsContainer>
                        <Button
                            style={{
                                ...styleButtons,
                                background: "#004B6F",
                                marginRight: "16px",
                            }}
                            onClick={submit}
                            variant="contained"
                            size="small"
                        >
                            ACEPTAR
                        </Button>
                        <Button
                            style={{ ...styleButtons, background: "#C9C9C9" }}
                            onClick={handleCerrar}
                            variant="contained"
                            size="small"
                        >
                            CANCELAR
                        </Button>
                    </ButtonsContainer>
                </Box>
            </Modal>
        </div>
    );
}
