export interface GetOrdenesBymaResponse {
    error:  boolean;
    status: number;
    body:   Body;
}

export interface Body {
    result: ResultGetOrdenesByma[];
}

export interface ResultGetOrdenesByma {
    _id:           string;
    MsgType?:      string;
    MsgSeqNum?:    number;
    SendingTime?:  string;
    ClOrdID:      string;
    ExecID?:       string;
    OrderID:      string;
    ExecType?:     string;
    Symbol:       Symbol;
    SecurityID:   string;
    Side:         string;
    TransactTime?: string;
    OrdStatus:    string;
    OrdType?:      string;
    Price:        number;
    OrderQty:     number;
    LeavesQty?:    number;
    CumQty?:       number;
    Text?:         Text;
    DisplayQty?:   number;
    Account:      string;
    createdAt:     string;
    updatedAt:     string;
    __v:           number;
}

export enum OrdStatus {
    Nuevo = "NUEVO",
    ParcialmenteTerminada = "PARCIALMENTE TERMINADA",
    Terminada = "TERMINADA",
    Cancelada = "CANCELADA",
    Rechazado = "RECHAZADO",
    Suspendido = "SUSPENDIDO",
    PendingNew = "pending new",
    Expirado = "EXPIRADO",
    RemplazoPendiente = "REEMPLAZO PENDIENTE",
}

// Nuevo => EN PROCESO (opción cancelar)
// ParcialmenteTerminada => EN PROCESO (opción cancelar)
// Terminada => TERMINADA
// Cancelada => CANCELADA
// Rechazado => RECHAZADA
// Suspendido => RECHAZADA
// PendingNew => EN PROCESO (opción cancelar)
// Expirado => RECHAZADA O CANCELADA no estoy segura
// RemplazoPendiente => EN PROCESO (opción cancelar)