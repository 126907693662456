import { GetCBUResponse } from "../interfaces/interfaceCBU/getCBUInterface.js";
import { ProcesarCBUResponse } from "../interfaces/interfaceCBU/procesarCBUInterface.js";
import apiInstance from "../utils/Axios.js";
import FormData from "form-data";

export async function procesarCbus(csv: any) {
	let data = new FormData();
	data.append("archivoBanco", csv);
	return await apiInstance.post<ProcesarCBUResponse>("/procesarCbusTransferencias", data);
}

export async function getCBUs() {
	return await apiInstance.get<GetCBUResponse>("/cbusCompare");
}
