import React from "react";
import Table from "../../components/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container } from "./elements";
import { columnsEstadoRentas } from "../../helper/Colums/columsEstadoRentas";

const subtitulo = "ESTADO DE RENTAS";

export const EstadoRentasTable = () => {
	const { width } = useWindowDimensions();

	return (
		<>
			<Container
				style={{
					width: width * 0.85,
					left: width * 0.15,
				}}
			>
				<Table subtitulo={subtitulo} columns={columnsEstadoRentas} />
			</Container>
		</>
	);
};
