import { useContext, useState } from "react";
import { procesarConciliacionesTitulos } from "../api/conciliaciones";
import { ConciliacionesContext } from "../context/conciliacionesContext/conciliacionesContext";

export const useConciliacionesTitulos = () => {
	const [cajaArchive, setCajaArchive] = useState<File | null>(null);
	const [galloArchive, setGalloArchive] = useState<File | null>(null);
	const { actualizarConciliacionesTitulos } = useContext(ConciliacionesContext);
	
	const handleSubmit = async () => {
		try {
			const resp = await procesarConciliacionesTitulos(cajaArchive, galloArchive);
			actualizarConciliacionesTitulos(resp.data.body.resultado);
		} catch (error) {
			console.log(error);
		}
	};

	return {
		handleSubmit,
		cajaArchive,
		setCajaArchive,
		galloArchive,
		setGalloArchive,
	};
};
