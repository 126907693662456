import React from "react";
import DataTable from "react-data-table-component";
import { Button } from "@mui/material";
import { ButtonContainer, Container, TableContainer } from "./elements";
import columnsOperarFinal from "../../../helper/columsOperarFinal";
import { Resultado } from "../../../hooks/useOperarCarteras";

interface Props {
    results: Resultado[], 
    handleContinuar: () => Promise<void>, 
}

export const Final = ({ results, handleContinuar }: Props) => {

    return (
        <Container>
            <TableContainer>
                <DataTable
                    columns={columnsOperarFinal}
                    data={results}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    // minHeight="600px"
                    customStyles={customStyles}
                    responsive
                    keyField="id"
                />
            </TableContainer>
            <ButtonContainer> 
                <Button
                    style={{
                        marginTop: "24px",
                        backgroundColor: "#004B6F",
                        fontSize: "0.8em",
                        padding: "0.7em 2em",
                    }}
                    onClick={handleContinuar}
                    variant="contained"
                    size="medium"
                >
                    CONTINUAR
                </Button>
            </ButtonContainer>
        </Container>
    );
};


const customStyles = {
    tableWrapper: {
        style: {
            display: "table",
            minHeight: "50%",
            maxHeight: "50%",
            backgroundColor: "white",
            color: "#004B6F",
            padding: "0 1.2em",
        },
    },

    header: {
        style: {
            minHeight: "56px",
        },
    },
    rows: {
        style: {
            minHeight: "58px", // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",

            backgroundColor: "rgba(142, 178, 221, 0.5)",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
        },
    },
};
