import React, { ChangeEvent } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getMotivos } from "../api/subsanados";
import { AxiosResponse } from "axios";
import { GetMotivosResponse, Motivo } from "../interfaces/interfaceSubsanadas/getMotivosInterface";
import { MotivoTexto } from "../components/MotivosSubsanar/MotivosSubsanar";
import { SelectChangeEvent } from "@mui/material";

// Hook que manipula el formulario para crear y porgramar nuevas notificaciones en la aplicación

interface Props {
	numMotivo: number,
	motivosTextos: MotivoTexto[];
}

export const useMotivosSubsanar = ({numMotivo, motivosTextos}: Props) => {
	const [etapas, setEtapas] = useState<string[]>([]);
	const [errores, setErrores] = useState<string[][]>([]);
	const [textos, setTextos] = useState<string[][]>([]);

	const [etapa, setEtapa] = useState<number | undefined>();
	const [error, setError] = useState<number | undefined>();
	const [texto, setTexto] = useState<string | undefined>();

	const [motivosApi, setMotivosApi] = useState<Motivo[]>([]);

	const [cargando, setCargando] = useState<boolean>(true);

	useEffect(() => {
		callApi();
	}, []);

	useEffect(() => {
		filterEtapa();
	}, [motivosApi]);

	function filterEtapa() {
		let erroresArr: string[][] = [];
		let etapasArr: string[] = [];
		let textosArr: string[][] = [];
		motivosApi.forEach((motivo) => {
			let indexEtapa = etapasArr.indexOf(motivo.etapa);
			if (indexEtapa === -1) {
				indexEtapa = etapasArr.length;
				etapasArr.push(motivo.etapa);
				erroresArr[erroresArr.length] = [];
				textosArr[textosArr.length] = [];
			}
			erroresArr[indexEtapa].push(motivo.error);
			textosArr[indexEtapa].push(motivo.texto);
		});
		setEtapas(etapasArr);
		setErrores(erroresArr);
		setTextos(textosArr);

		if (textosArr.length !== 0) {
			setEtapa(0);
			setError(0);
			setTexto(textosArr[0][0]);
			motivosTextos[numMotivo - 1] = {
				...motivosTextos[numMotivo - 1],
				motivo: numMotivo,
			};
			motivosTextos[numMotivo - 1] = {
				...motivosTextos[numMotivo - 1],
				etapa: etapasArr[0],
				error: erroresArr[0][0],
			};
			setCargando(false);
		}
	}

	const callApi = async () => {
		let resp: AxiosResponse<GetMotivosResponse> = await getMotivos();
		setMotivosApi(resp.data.body.motivos);
	};

	useEffect(() => {
		if (etapa && error && textos) {
			setTexto(textos[etapa][error]);
			motivosTextos[numMotivo - 1] = {
				...motivosTextos[numMotivo - 1],
				texto: textos[etapa][error],
			};
		}
	}, [etapa, error]);

	useEffect(() => {
			motivosTextos[numMotivo - 1] = {
				...motivosTextos[numMotivo - 1],
				texto: texto,
			};
	}, [texto])
	

	const handleChangeEtapa = (event: SelectChangeEvent<number>) => {
		const newEtapa = parseInt(event.target.value as string);
		setEtapa(newEtapa);
		reiniciarError(newEtapa);
		motivosTextos[numMotivo - 1] = {
			...motivosTextos[numMotivo - 1],
			etapa: etapas[newEtapa],
		};
	};
	const reiniciarError = (nuevaEtapa: number) => {
		setError(0);
		motivosTextos[numMotivo - 1] = {
			...motivosTextos[numMotivo - 1],
			error: errores[nuevaEtapa][0],
		};
	};
	const handleChangeError = (event: SelectChangeEvent<number>) => {
		const newError = parseInt(event.target.value as string);
		setError(newError);
		motivosTextos[numMotivo - 1] = {
			...motivosTextos[numMotivo - 1],
			error: errores[etapa!][newError],
		};
	};

	return {
		handleChangeEtapa,
		handleChangeError,
		etapa,
		etapas,
		error,
		errores,
		texto,
		cargando,
		setTexto
	};
};
