import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Container, EstadoContainer, EstadoText } from "./elements";

interface Props {
	cancelarOrden: (orden: OrdenCancel) => Promise<void>,
	estado: string,
	orderCancel: OrdenCancel,
}

export interface OrdenCancel {
	OrigClOrdID: string,
	OrderID: string,
	Symbol: Symbol,
	SecurityID: string,
	Side: string,
}

export const EstadoCarteraButton = ({
	cancelarOrden,
	estado,
	orderCancel,
} : Props) => {

	let orden : OrdenCancel = {} as OrdenCancel;

	// console.log('ORDEN', orden)

	return (
		<Container>
			<EstadoContainer
				style={{
					backgroundColor:
						estado === "EN PROCESO"
							? "#F6B40E"
							: estado === "TERMINADA"
							? "#03DAC5"
							: estado === "CANCELADA"
							? "#47AAFA"
							: estado === "RECHAZADA"
							? "#B00020"
							: "rgba(176, 0, 32, 0.38)",
				}}
			>
				<EstadoText
					style={{
						color: 
						estado === "RECHAZADA" ? "white" : "#000000",
					}}
				>
					{estado}
				</EstadoText>
			</EstadoContainer>
			{estado === 'EN PROCESO' ? (
				<Button
					onClick={() => cancelarOrden(orderCancel)}
				>
					<p>CANCELAR</p>
				</Button>
			) : null}
		</Container>
	);
};

// EN PROCESO - CANCELADA - TERMINADA - RECHAZADA
// MsgType !== 8 => TERMINADA
// 0 New => EN PROCESO (opción cancelar)
// 1 Partially Filled => EN PROCESO (opción cancelar)
// 2 Filled => TERMINADA
// 4 Cancelled => CANCELADA
// 8 Rejected => RECHAZADA
// 9 Suspended => RECHAZADA
// A Pending New => EN PROCESO (opción cancelar)
// C Expired => RECHAZADA
// E Pending Replace => EN PROCESO (opción cancelar)
