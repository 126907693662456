import React from "react";
import { ContainerText, Text } from "./elements";

interface Props {
    cuentas: string,
}

export const CuentasCBUs = ({ cuentas }: any) => {

    return (
        <div>
            <p>{cuentas}</p>
            {
                cuentas.length > 20 &&
                <ContainerText>
                    <Text>{cuentas}</Text>
                    
                </ContainerText>
            }
        </div>
    );
}