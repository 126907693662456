import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { SideCalendar } from "../../../components/Aplicacion/Calendar/Calendar";
import { HistorialItem } from "../../../components/Aplicacion/Historial/HistorialItem";
import { getSentNotifications } from "../../../api/notificaciones_app";
import { Container, InputSearch, SearchButton, Icon, Subtitulo, SearchContainer } from "./elements";

export const Historial = () => {
	const { width, height } = useWindowDimensions();

	const [notificacionesApp, setNotificationesApp] = useState([]);
	const [notificacionesFilt, setNotificacionesFilt] = useState([]);

	const [name, setName] = useState("");

	const filtNotificaciones = (name) => {
		name = name.toLowerCase();

		let filtered = notificacionesApp?.filter(
			(e) =>
				e?.title?.includes(name) 
				||
				e?.date?.replaceAll("-", "/").includes(name) 
		);

		setNotificacionesFilt(filtered);
	};

	function handleInput(e) {
		e.preventDefault();
		setName(e.target.value);
		filtNotificaciones(name);
	}

	function handleSubmit(e) {
		e.preventDefault();

		filtNotificaciones(name);

		setName(e.target.value);
	}

	
    useEffect(() => {
        getNotifications();
    }, [notificacionesApp.length > 0])

	const getNotifications = async() => {
        try {
            await getSentNotifications().then(
                function (value) {
                    // Success!
					let res = value.data.body.sent.reverse();
                    setNotificationesApp(res)
                    setNotificacionesFilt(res)
                },
                function (error) {
                    console.log(error.response);
                }

            );
        } catch (error) {
            console.log(error)
        }
    }

	return (
		<>
			<div
				style={{
					width: width * 0.85,
					height: height * 0.92,
					left: width * 0.15,
					top: height * 0.062,
					position: "absolute",
				}}
			>
				<Container
					style={{
						height: height * 0.9,
						width: "65%",
						// width: width * 0.5,
						paddingLeft: width * 0.04,
						// backgroundColor: "aqua",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							paddingTop: 24,
						}}
					>
						<Subtitulo>Historial</Subtitulo>
						<SearchContainer>
							<InputSearch
								type="text"
								placeholder="Buscar..."
								onChange={(e) => handleInput(e)}
							/>
							<SearchButton
								type="submit"
								onClick={(e) => handleSubmit(e)}
							>
								<Icon
									// src={require("../../images/tabla/search-icon.png")}
									src={require("../../../images/tabla/search-icon.png")}
									alt="logo"
								/>
							</SearchButton>
						</SearchContainer>
					</div>
					<div style={{ paddingTop: "32px", height: height * 0.75, overflowY: "scroll", marginTop: "16px" }}>

						{
							notificacionesFilt.map((e) => 
								<div key={e._id}>
									<HistorialItem titulo={e.title} descripcion={e.description} fecha={e.date}/>
								</div>
							)
						}
					</div>
				</Container>
				<div
					style={{
						// width: width * 0.35,
						// width: "25%",
						height: height * 0.935,
						//  backgroundColor: "orange",
						alignSelf: "flex-end",
						position: "absolute",
						right: 0,
						boxShadow: "0px 4px 4px rgba(206, 204, 204, 0.44)",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "center",
							alignContent: "center",
						}}
					>
						<SideCalendar />
					</div>
				</div>
			</div>
		</>
	);
};
