import React, { useCallback } from "react";
import { Select, MenuItem, Button, TextField, Checkbox, SelectChangeEvent } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteMotivos, getMotivos, postMotivos } from "../../api/subsanados";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Botones, Container, ContenedorAgregarMotivo, ContenedorBlanco, ContenedorElementos, ContenedorMotivos, ContenedorMotivosArray, ContenedorSelects, ContenedorTexto, ContenedorTextoBusqueda, Label, OptionContainer, TextoTextos, Textos, Titulo, TituloMotivo, TituloSeccion } from "./elements";
import { AxiosResponse } from "axios";
import { GetMotivosResponse, Motivo } from "../../interfaces/interfaceSubsanadas/getMotivosInterface";

const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
	color: "#FFFFFF",
};

const etapas: string[] = ["Datos personales", "Adjuntos", "Declaraciones"];

export const EditarSubsanar = () => {
	const [motivosApi, setMotivosApi] = useState<Motivo[]>([]);
	const [input, setInput] = useState<Record<string, boolean>>({});
	const [etapa, setEtapa] = React.useState<any>("");
	const [texto, setTexto] = React.useState<string>("");
	const [textoError, setTextoError] = React.useState<string>("");
	const [erroresApi, setErroresApi] = useState<string[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		callApi();
	}, []);

	useEffect(() => {
		setErrores();
		setInputs();
	}, [motivosApi]);

	const callApi = async () => {
		let resp: AxiosResponse<GetMotivosResponse> = await getMotivos();
		setMotivosApi(resp.data.body.motivos);
	};

	function setErrores() {
		let arr: string[] = [];
		motivosApi.forEach((element) => {
			arr.push(element.error);
		});

		setErroresApi(arr);
	}

	const setInputs = useCallback(() => {
		const obj: Record<string, boolean> = {};
		erroresApi.forEach((element) => {
			obj[element] = true;
		});
		setInput(obj);
	}, [erroresApi]);

	const handleChangeEtapa = (event: SelectChangeEvent<any>) => {
		setEtapa(event.target.value);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInput({ ...input, [e.target.name]: !input[e.target.name] });
	};

	const deleteMotivo = useCallback(async () => {
		const asArray = Object.entries(input);
		const resp = await deleteMotivos(asArray);
		navigate("/pendientes");
	}, [input, navigate]);

	async function agregarError() {
		let resp = await postMotivos(etapas[etapa], textoError, texto);
		/* 		let arr = motivosApi;
		arr.push({ etapa: etapas[etapa], error: textoError, texto: texto });
		setMotivosApi(arr); */
		/* 		setErrores();
		setInputs(); */
		setMotivosApi((motivosApi) => [...motivosApi, resp.data.body.motivo]);
		setEtapa("");
		setTexto("");
		setTextoError("");
	}

	function checked(condicion: boolean | undefined) {
		if (condicion === undefined || condicion) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<Container>
			<ContenedorTextoBusqueda>
				<TituloSeccion>SUBSANAR</TituloSeccion>
			</ContenedorTextoBusqueda>
			<ContenedorBlanco>
				<ContenedorElementos>
					<Titulo> AGREGAR MOTIVOS</Titulo>
					<ContenedorMotivos>
						<ContenedorAgregarMotivo>
							<ContenedorSelects>
								<TituloMotivo>Etapa</TituloMotivo>
								<Select
									displayEmpty
									style={{ width: "80%" }}
									value={etapa}
									onChange={handleChangeEtapa}
									renderValue={(selected) => {
										if (etapa === "") {
											return (
												<span>
													Selecciona
												</span>
											);
										} else {
											return (
												<span>{etapas[selected]}</span>
											);
										}
									}}
								>
									{etapas.map((etapa, index) => (
										<MenuItem key={index} value={index}>
											{etapa}
										</MenuItem>
									))}
								</Select>

								<ContenedorTexto>
									<TituloMotivo>
										Error
									</TituloMotivo>
									<TextField
										multiline
										rows={1}
										style={{ width: "80%" }}
										value={textoError}
										onChange={(e) =>
											setTextoError(e.target.value)
										}
									/>
								</ContenedorTexto>
								<Button
									style={{
										background: "#ECF5FF",
										color: "#004B6F",
										width: "40%",
										marginTop: 8,
										fontWeight: "bold",
									}}
									onClick={agregarError}
								>
									AGREGAR
								</Button>
							</ContenedorSelects>
							<ContenedorTexto
								style={{ width: "50%" }}
							>
								<TituloMotivo>Texto</TituloMotivo>
								<TextField
									multiline
									rows={4}
									style={{ width: "80%" }}
									value={texto}
									onChange={(e) => setTexto(e.target.value)}
								/>
							</ContenedorTexto>
						</ContenedorAgregarMotivo>
						<Titulo> EDITAR MOTIVOS</Titulo>
						<TituloMotivo>Error</TituloMotivo>

						<ContenedorMotivosArray>
							{motivosApi.map((motivos, index) => (
								<Textos key={index} >
									<OptionContainer>
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Checkbox
												name={erroresApi[index]}
												style={{ padding: 0 }}
												icon={
													<CircleUnchecked
														style={{
															color: "#004B6F",
														}}
													/>
												}
												checkedIcon={
													<CircleChecked
														style={{
															color: "#004B6F",
														}}
													/>
												}
												checked={checked(
													input[erroresApi[index]]
												)}
												onChange={(e) =>
													handleChange(e)
												}
												inputProps={{
													"aria-label": "controlled",
												}}
											/>
											<Label>
												{motivos.error}
											</Label>
										</div>
										<TextoTextos>
											"{motivos.texto}"
										</TextoTextos>
										<TextoTextos
											style={{
												fontStyle: "italic",
											}}
										>
											{motivos.etapa}
										</TextoTextos>
									</OptionContainer>
								</Textos>
							))}
						</ContenedorMotivosArray>
						<Botones>
							<Button
								style={{
									...styleButtons,
									background: "#004B6F",
									marginRight: "16px",
								}}
								type="submit"
								onClick={deleteMotivo}
								variant="contained"
								size="small"
							>
								ACEPTAR
							</Button>
							<Button
								style={{
									...styleButtons,
									background: "#C9C9C9",
								}}
								onClick={() => navigate("/pendientes")}
								variant="contained"
								size="small"
							>
								CANCELAR
							</Button>
						</Botones>
					</ContenedorMotivos>
				</ContenedorElementos>
			</ContenedorBlanco>
		</Container>
	);
};
