import React, { useContext } from "react";
import Table from "../../components/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";
import { Container } from "./elements";
import { columnsOrdenesExterior } from "../../helper/Colums/columsOrdenesExterior";

const subtitulo = "ÓRDENES A OPERAR";

export const OrdenesExterior = () => {

	const { width } = useWindowDimensions();
	const { user } = useContext(UserContext);

	return (
		<>
			<Container style={{ width: width * 0.85,  left: width * 0.15 }} >
				<Table subtitulo={subtitulo} columns={columnsOrdenesExterior} />
			</Container>
		</>
	);
};



