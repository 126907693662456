import React from 'react'
import { Button } from "@mui/material";
import { Box, Container, Form, Input, LabelForm, SeccionButton, Sub, TextError } from './elements'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FormAgregarAutorizado, useFormAgregarAutorizado } from '../../hooks/useFormAgregarAutorizado';
import ModalAgregarAutorizado from '../../components/ModalAgregarAutorizado/ModalAgregarAutorizado';

export const AgregarAutorizado = () => {

    const { width, height } = useWindowDimensions();

    const initialForm: FormAgregarAutorizado = {
        name: "",
        lastName: "",
        DNI: "",
        accountNumber: "",
        email: "",
    }

    const { form, errors, handleChange, handleSubmit, open, success, handleClose } = useFormAgregarAutorizado(initialForm);


    return (
        <Container style={{
            left: width * 0.165,
            width: width * 0.82,
            height: height * 0.91,
        }}>
            <Sub>AGREGAR AUTORIZADO</Sub>
            <Box>
                <Form onSubmit={handleSubmit}>
                    <LabelForm>Número de cuenta</LabelForm>
                    <Input
                        type='text'
                        onChange={(e) => handleChange(e)}
                        name="accountNumber"
                        value={form.accountNumber}
                    />
                    <LabelForm>Nombre/s</LabelForm>
                    <Input 
                        type='text'
                        onChange={(e) => handleChange(e)}
                        name="name"
                        value={form.name}
                    />
                    <LabelForm>Apellido/s</LabelForm>
                    <Input 
                        type='text'
                        onChange={(e) => handleChange(e)}
                        name="lastName"
                        value={form.lastName}
                    />
                    <LabelForm>Número de documento</LabelForm>
                    <Input 
                        type='text'
                        onChange={(e) => handleChange(e)}
                        name="DNI"
                        value={form.DNI}
                    />
                    <LabelForm>Email</LabelForm>
                    <Input 
                        type='text'
                        onChange={(e) => handleChange(e)}
                        name="email"
                        value={form.email}
                    />
                    {errors.incompletos && <TextError>{errors.incompletos}</TextError>}
                    <SeccionButton>
                        <Button
                                style={{
                                    backgroundColor: "#004B6F",
                                    fontSize: "0.9em",
                                    fontWeight: "bold",
                                }}
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                CONFIRMAR
                            </Button>
                    </SeccionButton>
                    <ModalAgregarAutorizado
                        open={open}
                        success={success}
                        handleClose={handleClose}
                    />
                </Form>
            </Box>
        </Container>
    )
}
