import React from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, Container, Form, Input, Sub, TextError } from './elements';
import { Button } from '@mui/material';
import { useFormReinversionRenta } from '../../hooks/useReinversionRenta';
import { ReinversionTable } from '../../components/ReinversionTable/ReinversionTable';

export const ReinversionRenta = () => {

    const { width, height } = useWindowDimensions();

    const { 
        accountNumber,
        error,
        handleChange,
        handleSubmit, 
        results,
        resultAccountNumber,
    } = useFormReinversionRenta();

    return (
        <Container style={{
            left: width * 0.165,
            width: width * 0.82,
            height: height * 0.91,
        }}>
            <Sub>Reinversion renta</Sub>
            <Box>
                <Form onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        placeholder='N° de cuenta'
                        onChange={(e) => handleChange(e)}
                        value={accountNumber}
                        name="accountNumber"
                    />
                    <Button
                        style={{
                            backgroundColor: "#004B6F",
                            fontSize: "0.9em",
                        }}
                        variant="contained"
                        type='submit'
                    >
                        BUSCAR
                    </Button>
                    {
                        error && <TextError>{error}</TextError>
                    }
                </Form>
                {
                    results.length !== 0 &&
                    <ReinversionTable results={results} resultAccountNumber={resultAccountNumber}/>
                }
            </Box>
        </Container>
    )
}
