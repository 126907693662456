import React, { useContext } from "react";
import { useEffect } from "react";
import LoginForm from "../../components/LoginForm/LoginForm";
import Appbar from "../../components/Appbar/Appbar";
import { useNavigate } from "react-router-dom";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";
import { Container } from "./elements";

const Login = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token");
	const { user, restartStore } = useContext(UserContext);
	// console.log(user)

	useEffect(() => {
		if (token && user?.rol) {
			if (token !== "" && user?.rol !== "") {
				if (user?.rol === roles.comunicacion) {
					navigate("/app/historial");
				} else if (user?.rol === roles.operadorDeCarteras) {
					navigate("/operar-carteras");
				} else if (user?.rol === roles.tesoreria) {
					navigate("/transferencias-pendientes");
				} else {
					navigate("/pendientes");
				}
			} else {
				restartStore()
			}
		} else {
			restartStore();
		}
	}, []);

	return (
		<>
			{/* <Appbar /> */}
			<Container>
				<>
					<LoginForm />
				</>
			</Container>
		</>
	);
};

export default Login;
