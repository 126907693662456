import { TableColumn } from 'react-data-table-component';
import { RowOrdenesCartera } from '../../interfaces/interfacesRows/rows';

export const columnsOrdenesCartera: TableColumn<RowOrdenesCartera>[] = [
    {
        selector: (row: RowOrdenesCartera) => row.fecha,
        name: "FECHA",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOrdenesCartera) => row.cliente,
        name: "CLIENTE",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOrdenesCartera) => row.operacion,
        name: "OPERACIÓN",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOrdenesCartera) => row.especie,
        name: "ESPECIE",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOrdenesCartera) => row.cantidad,
        name: "CANTIDAD",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowOrdenesCartera) => row.precio,
        name: "PRECIO",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        cell: (row: RowOrdenesCartera) => row.estado, 
        name: "ESTADO",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
];
