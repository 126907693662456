import { ThemeProvider } from "styled-components";
import { createGlobalStyle } from "styled-components";
import "./App.css";
import { Themes } from "./css/Themes";
import { AppRouter } from "./router/AppRouter";
import { NotificacionesProvider } from "./context/notificacionesContext/notificacionesContext";
import { DividendosProvider } from "./context/dividendosContext/dividendosContext";
import { DolaresProvider } from "./context/dolaresContext/dolaresContext";
import { UserProvider } from "./context/userContext/UserContexts";
import { CBUsProvider } from "./context/cbusContext/cbusContext";
import { ConciliacionesProvider } from "./context/conciliacionesContext/conciliacionesContext";

const GlobalStyle = createGlobalStyle`
	body{
		background-color: ${({ theme }) => theme.background};
		color: ${({ theme }) => theme.fontColor};
	}
	.MuiButton-contained{
		color: ${({ theme }) => theme.fontButtonColor};
		background-color: ${({ theme }) => theme.buttonColor};
	}
	.MuiButton-contained:focus {
		background-color: #DCDCDC;
		color: #878787;
	}
	.MuiButton-contained:active {
		background-color: #DCDCDC;
		color: #878787;
	}
	.MuiButton-contained:hover {
		background-color: #DCDCDC;
		color: #878787;
	}
	.MuiButton-root {
		font-family: "Open Sans", "Roboto", "Helvetia", "Arial", sans-serif;
		font-weight: bold;
		font-size: 12px;
	}
	.MuiTableCell-root{
		font-family: "Open Sans", "Roboto", "Helvetia", "Arial", sans-serif;
		color: ${({ theme }) => theme.fontColor};
		font-size: 1em;
		border-bottom: 1px solid #C2DEFF;
		text-align: center;
		width: fit-content;
	}
	.css-1keqkc0-MuiPaper-root{
		border-radius: 1em;
	}
	.MuiTableCell-head{
		font-weight: bold;
	}
	.MuiTableRow-head{
		background-color: "#8EB2DD";
		font-weight: "bold";
	}
	.css-1q1u3t4-MuiTableRow-root{
		background-color: "#8EB2DD";
		font-weight: "bold";
	}
	.css-14xnjt8-MuiTableCell-root{
		padding: 0.5em 0;
	}
	@media (min-width: 600px){
		.css-1im6ja8-MuiToolbar-root {
			min-height: 45px;
		}
	}
	::-webkit-scrollbar {
		width: 15px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		background-color: white;
	}
	::-webkit-scrollbar-thumb {
		background-color: lightgray;
		border-radius: 6px; 
	}
`;

const AppState = ({ children }) => {
	return (
		<UserProvider>
			<DividendosProvider>
				<ConciliacionesProvider>
					<DolaresProvider>
						<CBUsProvider>
							<NotificacionesProvider>
								{children}
							</NotificacionesProvider>
						</CBUsProvider>
					</DolaresProvider>
				</ConciliacionesProvider>
			</DividendosProvider>
		</UserProvider>
	);
};

function App() {
	const theme = "light";

	return (
		<div className="App">
			<ThemeProvider theme={Themes[theme]}>
				<AppState>
					<GlobalStyle />
					<AppRouter className="AppRouter" />
				</AppState>
			</ThemeProvider>
		</div>
	);
}

export default App;
