import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { roles } from "../../../helper/roles";
import { ItemsAdministrador } from "./MenuItemsRoles/ItemsAdministrador";
import { ItemsComercial } from "./MenuItemsRoles/ItemsComercial";
import { ItemsCompliance } from "./MenuItemsRoles/ItemsCompliance";
import { ItemsTesoreria } from "./MenuItemsRoles/ItemsTesoreria";
import { useMenuHook } from "../../../hooks/useHookMenu";

// Menu de opciones

interface Props {
    id: string, 
    rechazada?: boolean, 
    telefono?: number | undefined, 
    nombre?: string, 
    cotitular?: boolean, 
    cuitVerificado?: null | string, 
    refreshFunc?: () => void, 
    cotitularesView?: boolean, 
    email?: string, 
    fichaSinIniciar?: boolean, 
    idTitular?: string,  //id del titular (para las pantallas de cotitulares de...) 
    criterios?: boolean, //indica si estoy en la pantalla criterios
}

export interface Texts {
    datosCopiados: string,
    emailReenviado: string,
}

export const texts: Texts = {
    datosCopiados : "¡Datos copiados!",
    emailReenviado : "¡Email reenviado!",
}

export default function FadeMenu({ id, rechazada, telefono, nombre, cotitular, cuitVerificado, refreshFunc, cotitularesView, email, fichaSinIniciar, idTitular, criterios } : Props) {

    const {
        open,
        handleClick,
        anchorEl,
        handleClose,
        rol,
        handleOpenDatos,
        handleOpenDatosCotitular,
        handleOpenSirena,
        handleOpenEliminarCriterio,
        openDatosCotitular,
        setOpenDatosCotitular,
        openDatos,
        setOpenDatos,
        openEliminarCriterio,
        setOpenEliminarCriterio,
        handleOpenAlerta,
        handleOpenSubsanar,
        openAlerta,
        text,
        openSubsanar,
        setOpenSubsanar,
        handleOpenCUIT,
        openCUIT,
        setOpenCUIT,
        openSirena,
        setOpenSirena,
        documentos,
    } = useMenuHook({id, texts, email, idTitular});

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                    color: "#004B6F",
                    textTransform: "capitalize",
                    fontSize: "1.1em",
                    height: 25,
                }}
            >
                <img
                    src={require("../../../images/tabla/puntos.png")}
                    alt="logo"
                />
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {rol === roles.administrador && (
                    <ItemsAdministrador
                        id={id}
                        fichaSinIniciar={fichaSinIniciar ? fichaSinIniciar : false}
                        cotitular={cotitular ? cotitular : false}
                        handleOpenDatosCotitular={handleOpenDatosCotitular}
                        handleOpenDatos={handleOpenDatos}
                        handleOpenSirena={handleOpenSirena}
                        handleOpenEliminarCriterio={handleOpenEliminarCriterio}
                        handleClose={handleClose}
                        telefono={telefono!}
                        nombre={nombre ? nombre : ""}
                        rol={rol}
                        openDatosCotitular={openDatosCotitular}
                        setOpenDatosCotitular={setOpenDatosCotitular}
                        openDatos={openDatos}
                        setOpenDatos={setOpenDatos}
                        openSirena={openSirena}
                        setOpenSirena={setOpenSirena}
                        openEliminarCriterio={openEliminarCriterio}
                        setOpenEliminarCriterio={setOpenEliminarCriterio}
                        cotitularesView={cotitularesView ? cotitularesView : false}
                        handleOpenAlerta={handleOpenAlerta}
                        texts={texts}
                        handleOpenSubsanar={handleOpenSubsanar}
                        openAlerta={openAlerta}
                        text={text}
                        email={email ? email : ""}
                        openSubsanar={openSubsanar}
                        setOpenSubsanar={setOpenSubsanar}
                        idTitular={idTitular ? idTitular : ""}
                        criterios={criterios ? criterios : false}
                    />
                )}
                {rol === roles.comercial && (
                    <ItemsComercial
                        id={id}
                        refreshFunc={refreshFunc}
                        fichaSinIniciar={fichaSinIniciar ? fichaSinIniciar : false}
                        handleOpenCUIT={handleOpenCUIT}
                        handleClose={handleClose}
                        openCUIT={openCUIT}
                        setOpenCUIT={setOpenCUIT}
                        cuitVerificado={cuitVerificado}
                        handleOpenDatosCotitular={handleOpenDatosCotitular}
                        handleOpenDatos={handleOpenDatos}
                        handleOpenSirena={handleOpenSirena}
                        telefono={telefono!}
                        nombre={nombre ? nombre : ""}
                        rol={rol}
                        openDatosCotitular={openDatosCotitular}
                        setOpenDatosCotitular={setOpenDatosCotitular}
                        openDatos={openDatos}
                        setOpenDatos={setOpenDatos}
                        openSirena={openSirena}
                        setOpenSirena={setOpenSirena}
                        handleOpenAlerta={handleOpenAlerta}
                        texts={texts}
                        handleOpenSubsanar={handleOpenSubsanar}
                        openAlerta={openAlerta}
                        text={text}
                        email={email ? email : ""}
                        openSubsanar={openSubsanar}
                        setOpenSubsanar={setOpenSubsanar}
                        idTitular={idTitular ? idTitular : ""}
                        cotitular={cotitular ? cotitular : false}
                        cotitularesView={cotitularesView ? cotitularesView : false}
                    />
                )}
                {rol === roles.compliance && (
                    <ItemsCompliance
                        id={id}
                        fichaSinIniciar={fichaSinIniciar ? fichaSinIniciar : false}
                        rechazada={rechazada ? rechazada : false}
                        documentos={documentos}
                        refreshFunc={refreshFunc}
                        handleOpenCUIT={handleOpenCUIT}
                        handleClose={handleClose}
                        openCUIT={openCUIT}
                        setOpenCUIT={setOpenCUIT}
                        cuitVerificado={cuitVerificado}
                        rol={rol}
                    />
                )}
                {rol === roles.tesoreria && (
                    <ItemsTesoreria
                        id={id}
                        handleOpenEliminarCriterio={handleOpenEliminarCriterio}
                        openEliminarCriterio={openEliminarCriterio}
                        setOpenEliminarCriterio={setOpenEliminarCriterio}
                    />
                )}
            </Menu>
        </div>
    );
}
