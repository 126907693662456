
import { TableColumn } from 'react-data-table-component';
import { RowCBUs } from '../../interfaces/interfacesRows/rows';
import { Cuenta } from '../../interfaces/interfaceCBU/getCBUInterface';

const formatCuentas = (cuentas: Cuenta[]) => {
    if (cuentas.length === 0) {
        return "-";
    }
    return cuentas.map(obj => obj.cuenta).join("-");
}

const columnsCBUsTable: TableColumn<RowCBUs>[] = [
    {
        selector: (row: RowCBUs) => row.Fecha,
        name: "Fecha",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCBUs) => row.Hora,
        name: "Hora",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCBUs) => row.Beneficiario,
        name: "Beneficiario",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCBUs) => row.Banco ? row.Banco : '-',
        name: "Banco",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCBUs) => row.Importe,
        name: "Importe",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: any) => row.Cuentas,
        name: "Cuentas",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
            whiteSpace: 'normal', // Permite que el texto se envuelva
            wordWrap: 'break-word', // Asegura que las palabras largas se envuelvan
            maxWidth: '600px', // Ajusta esto según el tamaño deseado
        },
    },
];


export {
    columnsCBUsTable,
};
