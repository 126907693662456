import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { CircularProgress } from "@mui/material";
import { setCuitVerificado } from "../../api/datos";
import { Select, Text, TextContainer } from "./elements";
import { roles } from "../../helper/roles";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 440,
    height: 280,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};

const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
};

const styleContainerButtons = {
    display: "flex",
	justifyContent: "center",
    marginTop: "16px",
};

// Permite setear el cuit como valido o invalido y una vez seteado ver el dato
interface Props {
    id: string, 
    rol: string,
    refreshFunc: () => void,
    handleClose: () => void,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    cuitVerificado: null | string, 
}

const cuitOpciones = [ 'BLOQUEADO', 'TEMPORALMENTE INHABILITADO', 'INACTIVO', 'HABILITADO' ];

export default function ModalValidarCUIT({ handleClose, id, open, setOpen, cuitVerificado, refreshFunc, rol }: Props) {
    const [verificado, setVerificado] = useState<string>("");
    const [input, setInput] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    
    useEffect(() => {
        if(cuitVerificado !== null){
            setVerificado(cuitVerificado);
            setInput(cuitVerificado)
        }
    }, []);

    const handleCerrar = () => {
        setOpen(false);
    };

    const getEstadoCUIT = async () => {
        // ver y setear si el cuit ya fue verificado por un comercial
        setLoading(true);
        await refreshFunc();
        handleClose();
    };

    const handleClick = async () => {
        if (rol === roles.comercial) {
            setLoading(true);
            const res = await setCuitVerificado(input, id)
            //console.log(res)
            res && getEstadoCUIT();
        }
    }

    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    {loading ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress
                                style={{
                                    color: "#004B6F",
                                    marginBottom: "4em",
                                    marginTop: "4em",
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                            <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={handleCerrar}
                                style={{
                                    color: "#004B6F",
                                    marginLeft: "16.5em",
                                }}
                            >
                                <CloseOutlinedIcon />
                            </IconButton>
                            <Typography
                                id="keep-mounted-modal-title"
                                variant="h6"
                                component="h2"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: 1.2,
                                    width: 300,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    marginTop: verificado ? "0.1em" : "0.8em",
                                    marginBottom: "1.2em",
                                }}
                            >
                                EL CUIT DEL USUARIO EN AFIP
                            </Typography>
                            <Select
                                onChange={(e) => setInput(e.target.value)}
                                name="cuitVerificado"
                                value={input}
                                disabled={rol === roles.compliance}
                            >
                                <option value="" disabled hidden>
                                    Selecciona
                                </option>

                                {cuitOpciones.map((e) => (
                                    <option key={e} value={e}>
                                        {e}
                                    </option>
                                ))}
                            </Select>
                            <div
                                style={{
                                    ...styleContainerButtons,
                                    marginBottom: verificado ? "1.8em" : "2em",
                                }}
                            >
                                <Button
                                    style={{
                                        ...styleButtons,
                                        background: "#004B6F",
                                        marginRight: "16px",
                                    }}
                                    onClick={() => handleClick()}
                                    variant="contained"
                                    size="small"
                                >
                                    ACEPTAR
                                </Button>
                                <Button
                                    style={{
                                        ...styleButtons,
                                        background: "#C9C9C9",
                                    }}
                                    onClick={() => handleCerrar()}
                                    variant="contained"
                                    size="small"
                                >
                                    CENCELAR
                                </Button>
                            </div>
                            {verificado && (
                                <TextContainer>
                                    <Text>{`EL USUARIO TIENE EL CUIT ${cuitVerificado}`}</Text>
                                </TextContainer>
                            )}
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
