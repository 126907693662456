
export const date = () => {
    let date = new Date();
    let correctMonth;
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = String(date.getFullYear()).slice(2, 4);

    if (month < 10) {
        correctMonth = `0${month}`;
    } else {
        correctMonth = `${month}`;
    }
    if (day < 10) {
        return `0${day}/${correctMonth}/${year}`;
    } else {
        return `${day}/${correctMonth}/${year}`;
    }
};