import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
`;

export const SubtituloContainer = styled.div`
    white-space: nowrap;
    margin-left: 2em;
`;

export const Subtitulo = styled.h1`
    font-size: 1.5em;
    color: rgba(0, 75, 111, 0.75);
`;

export const SearchContainer = styled.input`
    display: flex;
    align-items: center;
    height: 2.3em;
    width: 85%;
    margin-left: 1em;
    border-radius: 0.5em;
    background: white;
    border: none;
    outline: none;
    font-size: 0.9em;
`;
export const SearchContainerSinInput = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 2.3em;
    width: 85%;
    margin-left: 4em;
    border-radius: 0.5em;
    background: white;
    border: none;
    outline: none;
    font-size: 0.9em;
`;

export const SearchButton = styled.button`
    border-radius: 2em;
    background: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding-right: 1em;
`;

export const ContainerLoading = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 100%;
`;

export const SecondContainer = styled.div`
    height: 100%;
    display: flex; 
    flex-direction: column;
`;

export const CardsInfoUserContainer = styled.div`
    margin: 1em 0em 0em 3.8em;
    width: 85%;
`;

export const CardsInfoCuentaontainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 1em 0em 0em 3.8em;
    width: 85%;
`;

export const TenenciaContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0.8em 0em 0em 3.8em;
    gap: 20px;
    width: 85%;
    height: 6em;
    flex-grow: 1;
`;

export const ContainerEspecies = styled.div`
    background-color: white;
    overflow-y: scroll;
    border-radius: 10px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
`;

export const ContainerError = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center; 
    height: 80%;
`;

export const TextoError = styled.p`
    font-size: 1.5em;
    font-weight: bold;
`;
