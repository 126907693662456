import React from 'react';
// import s from "./CardInfoCCuenta.module.css";
import { formatCurrency } from "../../../helper/formatCurrency";
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import { Container, ContainerInfoPorcentaje, ContainerPorcentajes, DineroEnCuenta, DolaresLocal, ImgBandera, Porcentaje, PorcentajeCero, TituloMercado } from './elements';

interface Props {
    tipoMercado: string;
    pesosEnCuenta?: number;
    dolaresEnCuenta?: number;
    dolaresMercadoLocal?: number;
    img: string;
    variacion: number;
}

export const CardInfoCuenta = ({
    tipoMercado,
    pesosEnCuenta,
    dolaresEnCuenta,
    dolaresMercadoLocal,
    img,
    variacion,
}: Props) => {


    return (
        <Container>
            <div>
                <TituloMercado>
                    MERCADO {tipoMercado}
                </TituloMercado>
                {
                    tipoMercado === 'LOCAL'
                        ?
                        <div>
                            <DineroEnCuenta>
                                ${formatCurrency(pesosEnCuenta)}
                            </DineroEnCuenta>
                            <DolaresLocal>
                                USD{formatCurrency(dolaresMercadoLocal)}
                            </DolaresLocal>
                        </div>
                        :
                        <div>
                            <DineroEnCuenta>
                                USD{formatCurrency(dolaresEnCuenta)}
                            </DineroEnCuenta>
                        </div>
                }
            </div>
            <ContainerInfoPorcentaje>
                {variacion > 0
                    ? <ContainerPorcentajes>
                        <ArrowDropUpSharpIcon style={{ fontSize: '2em', color: '#03DAC5' }} />
                        <Porcentaje style={{ color: '#03DAC5' }}>
                            {formatCurrency(variacion)}
                        </Porcentaje>
                    </ContainerPorcentajes>
                    : variacion < 0
                        ? <ContainerPorcentajes>
                            <ArrowDropDownSharpIcon style={{ fontSize: '2em', color: '#B00020' }} />
                            <Porcentaje style={{ color: '#B00020' }}>
                                {formatCurrency(variacion)}
                            </Porcentaje>
                        </ContainerPorcentajes>
                        : <PorcentajeCero> -% </PorcentajeCero>
                }
                {/* <ImgBandera
                    alt='bandera'
                    src={img}
                /> */}
            </ContainerInfoPorcentaje>
        </Container>
    )
}
