import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container } from "./elements";
import { columnsCBUsTable } from "../../helper/Colums/columsCBUsTable";
import TableTitulos from "../../components/TableTitulos/TableTitulos";

const subtitulo = "CBUs";

export const CBUsTable = () => {
	const { width } = useWindowDimensions();
	
	return (
		<>
			<Container
				style={{
					width: width * 0.85,
					left: width * 0.15,
				}}
			>
				<TableTitulos subtitulo={subtitulo} columns={columnsCBUsTable} />
			</Container>
		</>
	);
};
