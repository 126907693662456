import React, { useContext } from "react";
import Toolbar from "@mui/material/Toolbar";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Icon } from "@iconify/react";
import { Link, NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { roles } from "../../helper/roles";
import { UserContext, initialState } from "../../context/userContext/UserContexts";
import { Container, Item, Lista, Row, Sub, TextContainer, TextIcon, UserActionsContainer } from "./elements";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `none`,
    backgroundColor: "transparent",
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: "row",
    padding: 0,
    "& .MuiAccordionSummary-expandIconWrapper": {
        color: "white",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
        color: "white",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const AsideAppbar = () => {
    const { user } = useContext(UserContext);

    const { width, height } = useWindowDimensions();

    const [expanded, setExpanded] = React.useState<string | boolean>(
        (user.rol === roles.comercial) || (user.rol === roles.compliance)
            ? "panel1"
            : user.rol === roles.tesoreria
                ? "panel3"
                : false
    );

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container
            style={{
                height: height * 0.99999,
                width: width * 0.15,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "75%",
                }}
            >
                <img
                    src={require("../../images/logo-rava-azul.png")}
                    alt="logo"
                    style={{ marginTop: "1.5em" }}
                />

                {user.rol === roles.comunicacion ? (
                    <TextContainer>
                        <Sub>NOTIFICACIONES</Sub>
                        <Lista>
                            <Item>
                                <Link to={"/app/historial"} style={styles.links}>
                                    Historial
                                </Link>
                            </Item>
                            <Item>
                                <Link to={"/app/agregar"} style={styles.links}>
                                    Agregar
                                </Link>
                            </Item>
                        </Lista>
                    </TextContainer>
                ) : user.rol === roles.operadorDeCarteras ? (
                    <TextContainer>
                        <Lista>
                            <Item>
                                <Link
                                    to={"/operar-carteras"}
                                    style={styles.links}
                                >
                                    Subir Archivo
                                </Link>
                            </Item>
                            <Item>
                                <Link
                                    to={"/ordenes-carteras"}
                                    style={styles.links}
                                >
                                    Órdenes
                                </Link>
                            </Item>
                        </Lista>
                    </TextContainer>
                ) : (
                    <TextContainer>
                        {user.rol === roles.administrador ||
                            user.rol === roles.comercial ||
                            user.rol === roles.compliance ||
                            user.rol === roles.operador ? (
                            <Accordion
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Sub>
                                        APERTURA <br></br>
                                        DE CUENTA
                                    </Sub>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Lista>
                                        <Item>
                                            <Link
                                                to={"/pendientes"}
                                                style={styles.links}
                                            >
                                                Pendientes
                                            </Link>
                                        </Item>
                                        <Item>
                                            <Link
                                                to={"/aprobados"}
                                                style={styles.links}
                                            >
                                                Aprobados
                                            </Link>
                                        </Item>
                                        {user.rol === roles.compliance && (
                                            <Item>
                                                <Link
                                                    to={"/subsanados"}
                                                    style={styles.links}
                                                >
                                                    Subsanados
                                                </Link>
                                            </Item>
                                        )}
                                        <Item>
                                            <Link
                                                to={"/informados"}
                                                style={styles.links}
                                            >
                                                Informados
                                            </Link>
                                        </Item>
                                        <Item>
                                            <Link
                                                to={"/rechazados"}
                                                style={styles.links}
                                            >
                                                Rechazados
                                            </Link>
                                        </Item>
                                        {user.rol === roles.administrador && (
                                            <Item>
                                                <Link
                                                    to={"/historial"}
                                                    style={styles.links}
                                                >
                                                    Historial de movimientos
                                                </Link>
                                            </Item>
                                        )}
                                    </Lista>
                                </AccordionDetails>
                            </Accordion>
                        ) : null}
                        {user.rol === roles.administrador ? (
                            <>
                                <Accordion
                                    expanded={expanded === "panel2"}
                                    onChange={handleChange("panel2")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Sub>OPERADOR DE CARTERAS</Sub>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Lista>
                                            <Item>
                                                <Link
                                                    to={"/operar-carteras"}
                                                    style={styles.links}
                                                >
                                                    Subir Archivo
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={"/ordenes-carteras"}
                                                    style={styles.links}
                                                >
                                                    Órdenes
                                                </Link>
                                            </Item>
                                        </Lista>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ) : null}
                        {user.rol === roles.administrador ||
                            user.rol === roles.tesoreria ? (
                            <div>
                                {/* <Accordion
                                    expanded={expanded === "panel4"}
                                    onChange={handleChange("panel4")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Sub>TESORERÍA</Sub>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Lista>
                                            <Item>
                                                <Link
                                                    to={
                                                        "/transferencias-criterios"
                                                    }
                                                    style={styles.links}
                                                >
                                                    Criterios de aceptación de
                                                    transferencias
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={
                                                        "/transferencias-pendientes"
                                                    }
                                                    style={styles.links}
                                                >
                                                    Pendientes
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={
                                                        "/transferencias-anuladas"
                                                    }
                                                    style={styles.links}
                                                >
                                                    Anuladas
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={
                                                        "/transferencias-aprobadas"
                                                    }
                                                    style={styles.links}
                                                >
                                                    Aprobadas
                                                </Link>
                                            </Item>
                                        </Lista>
                                    </AccordionDetails>
                                </Accordion> */}
                                <Accordion
                                    expanded={expanded === "panel5"}
                                    onChange={handleChange("panel5")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Sub>TÍTULOS</Sub>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Lista>
                                            <Item>
                                                <Link
                                                    to={"/procesar-dolares"}
                                                    style={styles.links}
                                                >
                                                    Procesar dolares
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={"/procesar-dividendos"}
                                                    style={styles.links}
                                                >
                                                    Procesar dividendos
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={"/procesar-conciliacionesTitulos"}
                                                    style={styles.links}
                                                >
                                                    Procesar conciliaciones titulos
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={"/procesar-conciliaciones"}
                                                    style={styles.links}
                                                >
                                                    Procesar conciliaciones
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={
                                                        "/procesar-cbu"
                                                    }
                                                    style={styles.links}
                                                >
                                                    Procesar CBUs
                                                </Link>
                                            </Item>
                                            <Item>
                                                <Link
                                                    to={"/procesar-gara"}
                                                    style={styles.links}
                                                >
                                                    Procesar GARA
                                                </Link>
                                            </Item>
                                        </Lista>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel10"}
                                    onChange={handleChange("panel10")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Sub>DOLAR RENTA</Sub>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Lista>
                                            {/* <Item>
                                                <Link
                                                    to={"/estado-rentas"}
                                                    style={styles.links}
                                                >
                                                    Estado de rentas
                                                </Link>
                                            </Item> */}
                                            {/* <Item>
                                                <Link
                                                    to={"/reinversion-renta"}
                                                    style={styles.links}
                                                >
                                                    Reinversion renta
                                                </Link>
                                            </Item> */}
                                            <Item>
                                                <Link
                                                    to={
                                                        "/pedidos-dolares"
                                                    }
                                                    style={styles.links}
                                                >
                                                    Pedidos dolar renta
                                                </Link>
                                            </Item>
                                        </Lista>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ) : null}
                        {user.rol === roles.comercial ? (
                            <>
                                <Accordion
                                    expanded={expanded === "panel5"}
                                    onChange={handleChange("panel5")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                    >
                                        <Sub>AUTORIZADOS</Sub>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Lista>
                                            <Item>
                                                <Link
                                                    to={"/agregar-autorizado"}
                                                    style={styles.links}
                                                >
                                                    Agregar
                                                </Link>
                                            </Item>
                                        </Lista>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel6"}
                                    onChange={handleChange("panel6")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6a-content"
                                        id="panel6a-header"
                                    >
                                        <Sub>ÓRDENES EXTERIOR</Sub>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Lista>
                                            <Item>
                                                <Link
                                                    to={"/consulta-ordenes"}
                                                    style={styles.links}
                                                >
                                                    A operar
                                                </Link>
                                            </Item>
                                        </Lista>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel8"}
                                    onChange={handleChange("panel8")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8a-content"
                                        id="panel8a-header"
                                    >
                                        <Sub>ESTADO PIN</Sub>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Lista>
                                            <Item>
                                                <Link
                                                    to={"/consulta-pin"}
                                                    style={styles.links}
                                                >
                                                    Consultar
                                                </Link>
                                            </Item>
                                        </Lista>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ) : null}
                        {(user.rol !== roles.comunicacion && user.rol !== roles.administrador && user.rol !== roles.tesoreria) &&
                            <Accordion
                                expanded={expanded === "panel9"}
                                onChange={handleChange("panel9")}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel9a-content"
                                    id="panel9a-header"
                                >
                                    <Sub>DOLAR RENTA</Sub>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Lista>
                                        <Item>
                                            <Link
                                                to={"/pedidos-dolares"}
                                                style={styles.links}
                                            >
                                                Consulta de pedidos
                                            </Link>
                                        </Item>
                                        {/* <Item>
                                            <Link
                                                to={"/reinversion-renta"}
                                                style={styles.links}
                                            >
                                                Reinversion renta
                                            </Link>
                                        </Item> */}
                                    </Lista>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {user.rol !== roles.comunicacion &&
                            <Accordion
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Sub>CLIENTES</Sub>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Lista>
                                        <Item>
                                            <Link
                                                to={"/tenencia"}
                                                style={styles.links}
                                            >
                                                Tenencia
                                            </Link>
                                        </Item>
                                        <Item>
                                            <Link
                                                to={"/liquidez-blanqueo"}
                                                style={styles.links}
                                            >
                                                Tabla liquidez blanqueo
                                            </Link>
                                        </Item>
                                    </Lista>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </TextContainer>
                )}
            </div>
        </Container>
    );
};

const styles = {
    links: {
        textDecoration: "none",
        color: "rgba(236, 245, 255, 0.75)",
        cursor: "pointer",
    },
    icon: {
        alignItems: "center",
        color: "white",
        marginRight: "8px",
    },
}