import { useContext, useEffect, useState } from "react";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { ConciliacionesContext } from "../context/conciliacionesContext/conciliacionesContext";
import { Conciliacion, GetConciliacionesResp } from "../interfaces/interfaceConciliaciones/getConciliacionesInterface";
import { getConciliaciones } from "../api/conciliaciones";

interface Props {
	userRol: string,
	setLoading: (param: boolean) => void,
}

export const useConciliacionesTableHook = ({ userRol, setLoading }: Props) => {
	const { conciliaciones, actualizarConciliaciones } = useContext(ConciliacionesContext);
	const [conciliacionesFilt, setConciliacionesFilt] = useState<Conciliacion[]>([]);

	useEffect(() => {
		if (userRol === roles.administrador || userRol === roles.tesoreria) {
			console.log(conciliaciones)
			if (conciliaciones.length !== 0) {
				setConciliacionesFilt(conciliaciones);
			} else {
				getconciliacionesApi();
			}
		}
		conciliaciones.length === 0 && setLoading(false);
	}, []);

	useEffect(() => {

		setConciliacionesFilt(conciliaciones)
	}, [conciliaciones])


	const getconciliacionesApi = async () => {
		try {
			const res: AxiosResponse<GetConciliacionesResp> = await getConciliaciones();
			if (res?.status === 200) {
				actualizarConciliaciones(res.data.body);
				setConciliacionesFilt(res.data.body);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const filtconciliaciones = (name: string) => {
		if (name.length === 0) {
			setConciliacionesFilt(conciliaciones);
		} else {
			let filtered = conciliaciones.filter((e) =>
				e["accountNumber_Gallo"].toString().startsWith(name)
			);

			setConciliacionesFilt(filtered);
			filtered.length === 0 && setLoading(false);
		}
	};

	return {
		conciliaciones,
		conciliacionesFilt,
		filtconciliaciones,
	};
};

const styles = {
	button: {
		backgroundColor: "transparent",
		border: "none",
		cursor: "pointer",
		color: "#004B6F",
	},
};
