import { Dialog, DialogTitle } from '@mui/material';
import React from 'react'

interface Props {
    open: boolean,
    text: string,
} 

const ModalAlert = ( {open, text} : Props ) => {

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '20px',
                    backgroundColor: 'white',
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor:  'white', color: 'gray', padding: '1.2em' }}>
                {text}
            </DialogTitle>
        </Dialog >
    )
}

export default ModalAlert