import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { CircularProgress, TextField } from "@mui/material";
import { useMotivosSubsanar } from "../../hooks/useMotivosSubsanar";
import { ElementosContainer, FormContainer, FormSelectsContainer, TextContainer, TextSelecciona, TitleError, TitleEtapa, TitleForm } from "./elements";

interface Props {
	numMotivo: number,
	motivosTextos: MotivoTexto[],
}

export interface MotivoTexto {
	motivo?: number;
	etapa?: string;
	error?: string;
	texto?: string;
}

const MotivosSubsanar = ({ numMotivo, motivosTextos }: Props) => {
	const {
		handleChangeEtapa,
		handleChangeError,
		etapa,
		etapas,
		error,
		errores,
		texto,
		cargando,
		setTexto,
	} = useMotivosSubsanar({ numMotivo, motivosTextos });

	if (!cargando) {
		return (
			<ElementosContainer>
				<TitleForm>Motivo {numMotivo}</TitleForm>
				<FormContainer>
					<FormSelectsContainer>
						<TitleEtapa>Etapa / elemento</TitleEtapa>
						<Select
							displayEmpty
							style={{ width: "85%" }}
							value={etapa}
							onChange={handleChangeEtapa}
							renderValue={(selected) => {
								// if (!selected) {
								// 	return (
								// 		<TextSelecciona>
								// 			Selecciona
								// 		</TextSelecciona>
								// 	);
								// }
								return <span>{etapas[selected]}</span>;
							}}
						>
							{etapas.map((etapa, index) => (
								<MenuItem key={index} value={index}>
									{etapa}
								</MenuItem>
							))}
						</Select>
						<TitleError>Error</TitleError>

						{
							etapa !== undefined &&
							<Select
								displayEmpty
								style={{ width: "85%" }}
								value={error}
								onChange={handleChangeError}
								renderValue={(selected) => {
									// if (!selected) {
									// 	return (
									// 		<TextSelecciona>
									// 			Selecciona
									// 		</TextSelecciona>
									// 	);
									// }
									return <span>{errores[etapa][selected]}</span>;
								}}
							>
								{errores[etapa].map((error, index) => (
									<MenuItem key={index} value={index}>
										{error}
									</MenuItem>
								))}
							</Select>
						}

					</FormSelectsContainer>
					<TextContainer>
						<TitleEtapa>Texto</TitleEtapa>
						<TextField
							multiline
							rows={6}
							style={{ width: "75%" }}
							value={texto}
							onChange={(t) => setTexto(t.target.value)}
							// disabled
						/>
					</TextContainer>
				</FormContainer>
			</ElementosContainer>
		);
	} else {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	}
};

MotivosSubsanar.prototype = {
	numMotivo: PropTypes.number.isRequired,
};
export default MotivosSubsanar;
