import {
	SIGNIN,
	RESTART_STORE,
	CAMBIAR_APPBAR,
	GET_ID_FICHA,
	GET_MANAGERS,
} from "./constantes";

export interface UserState {
    user: {
        email: string,
        rol:  string,
    },
    aprobadas: [],
	rechazadas: [],
	informadas: [],
	pendientes: [],
	appBarAside: boolean,
	idFicha: string,
	managers: [],
	notificaciones: [],
	notificaconesAnteriores: [],
}

type UserAction =
    { type: "SIGNIN" ; payload: { email: string, rol: string }}
    | { type: "RESTART_STORE"}
    | { type: "CAMBIAR_APPBAR" ; payload: boolean}
    | { type: "GET_ID_FICHA" ; payload: string}
    | { type: "GET_MANAGERS" ; payload: []}
    ;


export const userReducer = (state: UserState, action: UserAction): UserState => {

    switch (action.type) {

        case SIGNIN:
			return {
				...state,
				user: action.payload,
			};
		case CAMBIAR_APPBAR:
			return {
				...state,
				appBarAside: action.payload,
			};
		case GET_MANAGERS:
			return {
				...state,
				managers: action.payload,
			};
		case GET_ID_FICHA:
			return {
				...state,
				idFicha: action.payload,
			};
		case RESTART_STORE:
			return {
				user: {
					email: "",
					rol: "",
				},
				aprobadas: [],
				rechazadas: [],
				informadas: [],
				pendientes: [],
				appBarAside: false,
				idFicha: "",
				managers: [],
				notificaciones: [],
				notificaconesAnteriores: [],
			};
            
        default:
            return state
    }
}