import { useState } from "react";
import { getPendientes } from "../api/pendientes";
import { getSubsanadas } from "../api/subsanados";
import {
	getPendientesOperador,
	getSubsanadosOperador,
} from "../api/operador";
import { Time } from "../components/Table/Time/Time";
import { Avance } from "../components/Table/Avance/Avance";
import { Ficha } from "../components/Table/Ficha/Ficha";
import ManagerSelect from "../components/Table/ManagerSelect/ManagerSelect";
import { CotitularesLink } from "../components/Table/CotitularesLink/CotitularesLink";
import EstadoSelect from "../components/Table/EstadoSelect/EstadoSelect";
import FadeMenu from "../components/Table/TableButton/Menu";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { PendientesResponse } from "../interfaces/interfacesPendientes/getPendientesInterface";
import { SubsanadasResponse } from "../interfaces/interfaceSubsanadas/getSubsanadasInterface";

// Hook que manipula la data de las fichas pendientes

interface PendienteInterface {
    id: string;
    fecha: string;
    hora: string;
    nombre: string;
    icon: JSX.Element;
    avance: JSX.Element;
    ficha: JSX.Element;
    manager: JSX.Element;
    email: string;
    nCuenta: string;
    estado: JSX.Element;
    puntos: JSX.Element;
    usuarioViejo: boolean;
    cotitular: boolean;
    cuitVerificado: string | null;
    numCuenta: string;
}

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export default function usePendientesHook({userRol, setLoading} : Props) {

	const [pendiente, setPendiente] = useState<PendienteInterface[]>([]);
	const [pendienteFilt, setPendienteFilt] = useState<PendienteInterface[]>([]);

	// Petición a la api

	const pending = async () => {
		let pendientesData = [];
		try {
			if (userRol === roles.administrador || userRol === roles.comercial) {
				const resPendientes : AxiosResponse<PendientesResponse> = await getPendientes();

				pendientesData = resPendientes.data.body.pendingFile;
				pendientesData = pendientesData.reverse();
				const resSubsanadas : AxiosResponse<SubsanadasResponse> = await getSubsanadas();

				const pendientesSubsanadasData =
					resSubsanadas.data.body.subsanadas;
				const union = [...pendientesSubsanadasData, ...pendientesData];

				const array = union.map((e) => ({
                    id: e._id,
                    fecha: `${e.creation.slice(0, 5)}`,
                    hora: e.creation
                        .substring(e.creation.lastIndexOf(","))
                        .slice(1),
                    nombre: `${e.name} ${e.last_name}`,
                    icon: <Time updatedAt={e.updatedAt} />,
                    avance: (
                        <Avance
                            phase={e.phase}
                            name={e.name}
                            status={e.status}
                        />
                    ),
                    ficha: <Ficha id={e._id} />,
                    manager: (
                        <ManagerSelect
                            manager={e.manager}
                            id={e._id}
                            pendiente={true}
                            usuarioViejo={
                                e.newclient === "false" ? true : false
                            }
                            cotitular={e.cotitular}
                        />
                    ),
                    email: e.email,
                    nCuenta: e.numCuenta ? e.numCuenta.toString() : "-",
                    estado:
						e.emailCotitulares?.flat().length  !== 0 ? (
                            <div>
                                <CotitularesLink
                                    id={e._id}
                                    nombre={`${e.name} ${e.last_name}`}
                                    texto="Cotitulares"
                                />
                            </div>
                        ) : (
                            <div>
                                <EstadoSelect
                                    id={e._id}
                                    manager={e.manager}
                                    status={e.status_field}
                                    phaseLength={e.phase.length}
                                    rol={userRol}
                                    subsanado={e.subsanado}
                                />
                            </div>
                        ),
                    puntos: (
                        <FadeMenu
                            id={e._id}
                            nombre={`${e.name} ${e.last_name}`}
                            telefono={e.phone}
                            cotitular={e.cotitular}
							cuitVerificado={e.cuitVerificado}
							refreshFunc={pending}
                        />
                    ),
                    usuarioViejo: e.newclient === "false" ? true : false,
                    cotitular: e.cotitular,
					cuitVerificado: e.cuitVerificado, 
					numCuenta: e.numCuenta ? e.numCuenta.toString() : "",
                }));

				setPendiente(array);

				setPendienteFilt(array);
			} 
			else if (userRol === roles.operador) {
				const resPendientes = await getPendientesOperador();
				pendientesData = resPendientes.data.body;
				const resSubsanadas = await getSubsanadosOperador();
				const pendientesSubsanadasData = resSubsanadas.data.body;

				const union = [...pendientesSubsanadasData, ...pendientesData];

				const array = union.map((e) => ({
                    id: e._id,
                    fecha: `${e.creation.slice(0, 5)}`,
                    hora: e.creation
                        .substring(e.creation.lastIndexOf(","))
                        .slice(1),
                    nombre: `${e.name} ${e.last_name}`,
                    icon: <Time updatedAt={e.updatedAt} />,
                    avance: (
                        <Avance
                            phase={e.phase}
                            name={e.name}
                            status={e.status}
                        />
                    ),
                    ficha: <Ficha id={e._id} />,
                    manager: (
                        <ManagerSelect
                            manager={e.manager}
                            id={e._id}
                            pendiente={true}
                            usuarioViejo={
                                e.newclient === "false" ? true : false
                            }
                            cotitular={e.cotitular}
                        />
                    ),
                    email: e.email,
                    nCuenta: e.numCuenta ? e.numCuenta.toString() : "-",
                    estado:
						e.emailCotitulares?.flat().length  !== 0 ? (
                            <div>
                                <CotitularesLink
                                    id={e._id}
                                    nombre={`${e.name} ${e.last_name}`}
                                    texto="Cotitulares"
                                />
                            </div>
                        ) : (
                            <div>
                                <EstadoSelect
                                    id={e._id}
                                    manager={e.manager}
                                    status={e.status_field}
                                    phaseLength={e.phase.length}
                                    rol={userRol}
                                    subsanado={e.subsanado}
                                />
                            </div>
                        ),
                    puntos: (
                        <FadeMenu
                            id={e._id}
                            nombre={`${e.name} ${e.last_name}`}
                            telefono={e.phone}
                            cotitular={e.cotitular}
							cuitVerificado={e.cuitVerificado}
							refreshFunc={pending}
                        />
                    ),
                    usuarioViejo: e.newclient === "false" ? true : false,
                    cotitular: e.cotitular,
					cuitVerificado: e.cuitVerificado, 
					numCuenta: e.numCuenta ? e.numCuenta.toString() : "",
                }));

				setPendiente(array);

				setPendienteFilt(array);
			} 
			else if (userRol === roles.compliance) {
				const resPendientes : AxiosResponse<PendientesResponse> = await getPendientes();
				pendientesData = resPendientes.data.body.pendingFile;

				pendientesData = pendientesData.filter(
					(e) => e.manager !== "ASIGNAR" && e.phase.length >= 6
				);

				pendientesData = pendientesData.reverse();

				pendientesData = pendientesData.map((e) => ({
					id: e._id,
					fecha: `${e.creation.slice(0, 5)}`,
					hora: e.creation
						.substring(e.creation.lastIndexOf(",")).slice(1)
					,
					nombre: `${e.name} ${e.last_name}`,
					icon: <Time updatedAt={e.updatedAt} />,
					avance: <Avance phase={e.phase} name={e.name} status={e.status} />,
					ficha: <Ficha id={e._id} />,
					manager: (
						<ManagerSelect
							manager={e.manager}
							id={e._id}
							pendiente={true}
							usuarioViejo={e.newclient === "false" ? true : false}
							cotitular={e.cotitular}
						/>
					),
					email: e.email,
					nCuenta: e.numCuenta ? e.numCuenta.toString() : "-",
					estado: (
						e.emailCotitulares.flat().length  !== 0 ?
						<div>
							<CotitularesLink id={e._id} nombre={`${e.name} ${e.last_name}`}  texto='Cotitulares'/>
						</div>
						:
						<div>
							<EstadoSelect
								id={e._id}
								manager={e.manager}
								status={e.status_field}
								phaseLength={e.phase.length}
								rol={userRol}
								subsanado={e.subsanado}
							/>
						</div>
					),
					puntos: (
						<FadeMenu
							id={e._id}
							nombre={`${e.name} ${e.last_name}`}
							telefono={e.phone}
							cotitular={e.cotitular}
							cuitVerificado={e.cuitVerificado}
							refreshFunc={pending}
						/>
					),
					usuarioViejo: e.newclient === "false" ? true : false,
					cotitular: e.cotitular,
					cuitVerificado: e.cuitVerificado, 
					numCuenta: e.numCuenta ? e.numCuenta.toString() : "",
            	}));

				setPendiente(pendientesData);
				setPendienteFilt(pendientesData);
			}
			!(pendientesData?.length > 0) && setLoading(false);

			// return pendientesData;
		} catch (error) {
			console.log(error);
		}
	};

    // Funcion de filtro

	const filtPendiente = (name: string) => {
        name = name.toLowerCase();

        let filtered = pendiente.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.hora.toLowerCase().includes(name) ||
                e.nombre.toLowerCase().includes(name) ||
                e.email.toLowerCase().includes(name) ||
                (e.numCuenta && e.numCuenta.toLowerCase().includes(name)) 
        );

        setPendienteFilt(filtered);
		filtered.length === 0 && setLoading(false);
    };

    const filtPorEstadoCuit = (estado: string) => {
        // 	setActualMenuEstado(estado);
        estado = estado.toLowerCase();

        if (estado === "todas") {
            setPendienteFilt(pendiente);
        } else {
            let filtered = pendiente.filter(
                (e) =>
                    e.cuitVerificado &&
                    e.cuitVerificado.toLowerCase() === estado
            );

			setPendienteFilt(filtered)

			filtered.length === 0 && setLoading(false);
        }
    };

	return {
        pending,
		filtPendiente,
        pendienteFilt,
		filtPorEstadoCuit,
	};
}
