import React, { useState } from 'react'
import { TooltipContainer, TooltipText } from './elements';


interface Props {
    children: any;
    text: JSX.Element;
}

const TooltipComponent = ({ children, text }: Props) => {

    const [visible, setVisible] = useState(false);

    return (
        <TooltipContainer
            onMouseEnter={() => {
                setVisible(true)
            }}
            onMouseLeave={() => {
                setVisible(false)
            }}
        >
            {children}
            <TooltipText
                style={{
                    visibility: visible ? "visible" : "hidden",
                    opacity: visible ? 1 : 0,
                }}
            >{text}</TooltipText>
        </TooltipContainer>
    )
}

export default TooltipComponent