import { TableColumn } from 'react-data-table-component';
import { RowCotitular } from '../../interfaces/interfacesRows/rows';

const columnsCotitulares : TableColumn<RowCotitular>[] = [
    {
        selector: (row: RowCotitular) => row.fecha,
        name: "Fecha",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.hora,
        name: "Hora",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.nombre,
        name: "Nombre y Apellido",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        cell: (row: RowCotitular) => row.icon,
        name: (
            <img src={require("../../images/tabla/reloj.png")} alt="logo" />
        ),
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.avance,
        name: "Nivel de avance",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.ficha,
        name: "Ficha",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.manager,
        name: "Manager",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.email,
        name: "Email",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.nCuenta,
        name: "Nº",
        center: true,
        grow: 1,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.estado,
        name: "Estado",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
    {
        selector: (row: RowCotitular) => row.puntos,
        name: "",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowCotitular) => row.usuarioViejo,
                style: {
                    backgroundColor: 'rgba(76, 199, 10, 0.14)',
                },
            },
            {
                when: (row: RowCotitular) => row.cotitular,
                style: {
                    backgroundColor: 'rgba(255, 153, 0, 0.31)',
                },
            },
        ],
    },
];

export {
    columnsCotitulares,
}