import styled from "styled-components";

export const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 85%;
	margin-top: 0.5em;
	@media (min-width: 1600px) {
		margin-top: 1em;
	}
`;

export const OptionContainerMini = styled.div`
	display: flex;
	flex-direction: column;
	width: 30%;
	margin-top: 0.5em;
	@media (min-width: 1600px) {
		margin-top: 1em;
	}
`;

export const Input = styled.input`
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
	border-radius: 5px;
	padding: 0.8em;
	color: #00386E;	
    border: none;
	@media (max-width: 992px) {
		font-size: 14px;
	}
	@media (min-width: 1600px) {
		font-size: 0.9em;
	}
`;

export const Subtitulo = styled.h4`
    font-size: 2em;
    color: rgba(0, 75, 111, 0.75);
`;

export const WhiteBox = styled.div`
    background-color: white;
    margin-top: 0.3em;
    height: 90%;
    width: 100%;
    border-radius: 0.8em;
    margin-bottom: 0;
`;

export const FormContainer = styled.div`
    margin-left: 2.5em;
    margin-top: 2em;
	@media (min-width: 1600px) {
		margin-top: 3em;
	}
`;

export const Sub = styled.p`
    font-size: 1.2em;
    color: rgba(0, 75, 111, 0.75);
    font-weight: bold;
`;

export const Form = styled.form`
    margin-top: 1em;
	@media (min-width: 1600px) {
		margin-top: 2em;
	}
`;

export const LabelForm = styled.label`
    font-weight: bold;
    margin-top: 1em;
`;

export const TextForm = styled.p`
    margin-top: 0.5em;
    margin-bottom: 0.5em;
`;

export const SeccionButtons = styled.div`
    position: absolute;
    bottom: 5em;
    left: 40%;
    display: flex;
`;

export const ErrorsContainer = styled.div`
    margin-top: 2em;
`;

export const TextError = styled.p`
    margin-top: 0.1em;
    color: #E3342F;
    font-weight: bold;
`;

