import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1.5em;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Text = styled.p`
    color: #00386E;
    font-size: 1em;
    margin-top: 0.5em;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
