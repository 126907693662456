import React, { useEffect, useState } from "react";
import { getCriterios } from "../api/transferencias";
import FadeMenu from "../components/Table/TableButton/Menu";
import { useNavigate } from "react-router-dom";
import { tiposDeCuentas } from "../views/Transferencias/AgregarCriterio/AgregarCriterio";
import { GetCriteriosInterface } from "../interfaces/interfaceTransferencias/getCriteriosInterface";
import { AxiosResponse } from "axios";

interface Props {
	subtitulo: string,
	setLoading: (param:boolean) => void,
}

interface Criterio {
    id: string;
    fecha: string;
    hora: string;
    monto: number;
    porcTenencia: number;
    cuenta: string;
    modificación: JSX.Element;
    puntos: JSX.Element;
}

export const useCriteriosHook = ({subtitulo, setLoading}: Props) => {
    const [allCriterios, setAllCriterios] = useState<Criterio[]>([]);
    const [criteriosFilt, setCriteriosFilt] = useState<Criterio[]>([]);
    const navigate = useNavigate();

    const handleNavigate = (monto: number, porcTenencia:number, tipo: string, cuenta: string[], id: string) => {
        navigate("/transferencias/criterio", {
            state: {
                id: id,
                editarCriterio: true,
                montoAnterior: monto,
                porcTenenciaAnterior: porcTenencia,
                tipoAnterior: tipo === "TODAS" ? tiposDeCuentas.todas : tiposDeCuentas.especificas,
                cuentaAnterior: cuenta.join("-"),
            }
        })
    };

    const getAllCriterios = async () => {
        let criteriosData: Criterio[] = [];
        try {
            const res: AxiosResponse<GetCriteriosInterface> = await getCriterios();

            if (res?.status === 200) {
                // console.log(res?.data?.body);
                const resp = res?.data?.body;
                criteriosData = resp.map( (e) => ({
                    id: e._id,
                    fecha: e.fecha,
                    hora: e.hora,
                    monto: e.monto,
                    porcTenencia: e.porcTenencia,
                    cuenta: e.tipo === "TODAS" ? e.tipo : e.cuenta.join("-"),
                    modificación: <button style={styles.button} onClick={() => handleNavigate(e.monto, e.porcTenencia, e.tipo, e.cuenta, e._id)}>Modificar</button>,
                    puntos: (
						<FadeMenu
							id={e._id}
							refreshFunc={getAllCriterios}
                            criterios={true}
						/>
					),
                }));

                setAllCriterios(criteriosData);
                setCriteriosFilt(criteriosData);
                setLoading(false);
            }

            !(criteriosData?.length > 0) && setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    // Funcion de filtro

    const filtCriterios = (name: string) => {
        name = name.toLowerCase();

        let filtered = allCriterios.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.hora.toLowerCase().includes(name) ||
                e.monto.toString().includes(name) ||
                e.porcTenencia.toString().includes(name) ||
                e.cuenta.toLowerCase().includes(name)
        );

        setCriteriosFilt(filtered);

        filtered.length === 0 && setLoading(false);
    };

    return {
        getAllCriterios,
        criteriosFilt,
        filtCriterios,
    };
};


const styles = {
    button : {
        backgroundColor: 'transparent', 
        border: 'none', 
        cursor: 'pointer',
        color: '#004B6F',
    }
}