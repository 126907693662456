import styled from "styled-components";

export const Container = styled.div`
	text-align: center;
    height: calc(100vh - 50px);
    background-color: #faf5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
