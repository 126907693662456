import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { ModalDatosCoTitulares } from "../../ModalDatos/ModalDatosCoTitulares";
import ModalDatos from "../../ModalDatos/ModalDatos";
import ModalSirena from "../../../ModalSirena/ModalSirena";

interface Props {
    id: string, 
    fichaSinIniciar: boolean, 
    cotitular: boolean, 
    handleOpenDatosCotitular: () => void,
    handleOpenDatos: () => void,
    handleOpenSirena: () => void,
    handleClose: () => void,
    telefono: number, 
    nombre: string, 
    rol: string,
    openDatosCotitular: boolean,
    setOpenDatosCotitular: React.Dispatch<React.SetStateAction<boolean>>,
    openDatos: boolean,
    setOpenDatos: React.Dispatch<React.SetStateAction<boolean>>,
    openSirena: boolean,
    setOpenSirena: React.Dispatch<React.SetStateAction<boolean>>,
}


export const ItemsDetalle = (props : Props) => {

    const {
        id,
        fichaSinIniciar,
        cotitular,
        handleOpenDatosCotitular,
        handleOpenDatos,
        handleOpenSirena,
        handleClose,
        telefono,
        nombre,
        rol,
        openDatosCotitular,
        setOpenDatosCotitular,
        openDatos,
        setOpenDatos,
        openSirena,
        setOpenSirena,
    } = props;

    return (
        <div>
            {!fichaSinIniciar && (
                <div>
                    <MenuItem
                        onClick={
                            cotitular
                                ? handleOpenDatosCotitular
                                : handleOpenDatos
                        }
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        Detalle
                    </MenuItem>
                    <MenuItem
                        onClick={handleOpenSirena}
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        En Sirena
                    </MenuItem>
                    <ModalDatosCoTitulares
                        handleClose={handleClose}
                        id={id}
                        telefono={telefono}
                        nombre={nombre}
                        rol={rol}
                        open={openDatosCotitular}
                        setOpen={setOpenDatosCotitular}
                    />
                    <ModalDatos
                        handleClose={handleClose}
                        id={id}
                        telefono={telefono}
                        nombre={nombre}
                        rol={rol}
                        open={openDatos}
                        setOpen={setOpenDatos}
                    />
                    <ModalSirena
                    handleClose={handleClose} 
                    telefono={telefono} 
                    open={openSirena} 
                    setOpen={setOpenSirena}
                    />
                </div>
            )}
        </div>
    );
};
