import { useState } from "react";
import { AxiosResponse } from "axios";
import { getOrdenesExterior } from "../api/ordenesExterior";
import { GetOrdenesExteriorResponse, Orden } from "../interfaces/interfaceOrdenesExterior/getOrdenesExteriorInterface";
import MenuOrdenesExterior from "../components/Table/MenuOrdenesExterior/MenuOrdenesExterior";

// Hook que manipula la data de las órdenes exterior 

interface OrdenExterior {
    id: string;
    fecha: string;
    cliente: string;
    recibio: string;
    especie: string;
    operacion: string;
    cantidad: string;
    precio: string;
    importe: string;
    puntos: JSX.Element | string;
}

interface Props {
    setLoading: (param: boolean) => void,
}

export default function useOrdenesExteriorHook({ setLoading }: Props) {

    const [ordenesExterior, setOrdenesExterior] = useState<OrdenExterior[]>([]);
    const [ordenesExteriorFilt, setOrdenesExteriorFilt] = useState<OrdenExterior[]>([]);

    // Petición a la api

    const getOrdenesExteriorData = async () => {
        try {
            const res: AxiosResponse<GetOrdenesExteriorResponse> = await getOrdenesExterior();

            let ordenesExteriorData = res?.data?.body?.ordenes;
            ordenesExteriorData = ordenesExteriorData?.reverse();

            const mappedOrdenes: OrdenExterior[] = ordenesExteriorData?.map((e) => ({
                id: e._id,
                fecha: `${e.createdAt.slice(8, 10)}/${e.createdAt.slice(5, 7)}/${e.createdAt.slice(0, 4)}`,
                cliente: e.comitente.toString(),
                recibio: e.estado,
                especie: e.ticker,
                operacion: e.tipoOperacion === "compra" ? "C" : "V",
                cantidad: e.cantidad.toString(),
                precio: e.precio.toString(),
                importe: `${e.cantidad * e.precio}`,
                puntos: (
                        <MenuOrdenesExterior
                            id={e._id}
                            cliente= {e.comitente.toString()}
                            especie= {e.ticker}
                            operacion= {e.tipoOperacion === "compra" ? "C" : "V"}
                            cantidad= {e.cantidad.toString()}
                            precio= {e.precio.toString()}
                            importe= {`${e.cantidad * e.precio}`}
                        />
                ),
            }));

            setOrdenesExterior(mappedOrdenes);
            setOrdenesExteriorFilt(mappedOrdenes);
            !(mappedOrdenes?.length > 0) && setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    // Funcion de filtro

	const filtOrdenesExterior = (name : string) => {
		name = name.toLowerCase();

		let filtered = ordenesExterior.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.cliente.toLowerCase().includes(name) ||
                e.especie.toLowerCase().includes(name) ||
                e.operacion.toLowerCase().includes(name)
        );

		setOrdenesExteriorFilt(filtered);
        filtered.length === 0 && setLoading(false);
	};

	return {
        getOrdenesExteriorData,
		ordenesExteriorFilt,
        filtOrdenesExterior,
	};
}
