import { Icon } from "@iconify/react";
import React from "react";
import moment from "moment";
import 'moment/locale/es';
moment.locale('es');

interface Props {
	titulo: string,
	descripcion: string | null,
	fecha: string;
}

export const HistorialItem = ({titulo, descripcion, fecha} : Props) => {

	let diff = moment(fecha).fromNow();
	
	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					paddingRight: 16,
					paddingLeft: 16,
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginBottom: 8,
						}}
					>
						{/* <img
							src={require("../../../images/icono-historial.png")}
							alt="icon"
						/> */}
						<div
							style={{
								border: "1.5px solid #4CC70A",
								borderRadius: "50%",
								height: 22,
								width: 22,
								alignItems: "center",
								display: "flex",
							}}
						>
							<Icon
								icon="typcn:tick-outline"
								style={{ color: "#4CC70A" }}
								fontSize={25}
							/>
						</div>
						<span style={{ marginLeft: 8 }}>{titulo.length > 70 ? `${titulo.slice(0, 70)}...` : titulo}</span>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<span>{fecha.slice(0, 10).replaceAll("-", "/")}</span>
						<span style={{ marginLeft: 8 }}>{fecha.slice(11, 16)}</span>
					</div>
				</div>

				<span style={{ width: "80%" }}>
					{descripcion && descripcion.length > 300 ? `${descripcion.slice(0, 300)}...` : descripcion}
				</span>

				<span style={{ display: "flex", justifyContent: "flex-end" }}>
					{diff[0].toUpperCase() + diff.slice(1)}
				</span>
			</div>
			<div
				style={{
					height: 1,
					backgroundColor: "#A0A0A0",
					marginTop: 8,
					marginBottom: 16,
				}}
			/>
			{/* <hr /> */}
		</div>
	);
};
