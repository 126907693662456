import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.4em 0.3em;
`

export const ContainerRight = styled.div` 
    display: grid; 
    grid-template-columns: 1fr 1.5fr;
`

export const Cantidad = styled.p` 
    text-align: end;
    margin-right: 0.5em;
    font-size: 0.9em;
`

export const ContainerPrecios = styled.div` 
    display: flex;
    flex-direction: column;
    border-left: 1px solid ;
    text-align: end;
    margin-right: 1em;
    justify-content: center;
`

export const Especie = styled.p` 
    font-weight: bold;
     font-size: 0.9em;
`

export const VariacionPorcentaje = styled.p` 
     font-size: 0.9em;
`

export const Precio = styled.p` 
    font-size: 0.9em;
`


export const Importe = styled.p` 
 font-size: 0.9em;
    font-weight: bold;
`