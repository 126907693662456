import styled from "styled-components";

export const Container = styled.div`
    /* height: 3em; */
	display: flex;
    align-items: center;
    background-color: white;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    left: 0px;
    top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
    font: inherit;
    font-weight: bold;
    padding-right: 16px;
`;

export const Button = styled.button`
    cursor: pointer;
    border: none;
    background-color: inherit;
`;

export const LogoContainer = styled.div`
	margin: 0 0.3em;
`;

export const LogoContainerUser = styled.div`
	background-color: #C2DEFF;
    border-radius: 2em;
    margin: 0 0.8em;
    width: 2em;
    display: flex;
    align-content: center;
    justify-content: center;
`;

export const LogoUser = styled.img`
	font-size: 1.5em;
    margin: 0.2em;
`;

