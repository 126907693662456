import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { userReducer, UserState } from './UserReducer';
import {
    SIGNIN,
    RESTART_STORE,
    CAMBIAR_APPBAR,
    GET_ID_FICHA,
    GET_MANAGERS,
} from "./constantes";
import { getManagers } from "../../api/pendientes";

interface UserData {
    user: {
        email: string,
        rol: string,
    },
    aprobadas: [],
    rechazadas: [],
    informadas: [],
    pendientes: [],
    appBarAside: boolean,
    idFicha: string,
    managers: [],
    notificaciones: [],
    notificaconesAnteriores: [],
    signinAction: (email: string, rol: string) => void,
    restartStore: () => void,
    cambiarAppbar: (payload: boolean) => void,
    getIdFicha: (id: string) => void,
    getAllManagers: () => void,
}

export const initialState: UserState = {
    user: {
        email: "",
        rol: "",
    },
    aprobadas: [],
    rechazadas: [],
    informadas: [],
    pendientes: [],
    appBarAside: false,
    idFicha: "",
    managers: [],
    notificaciones: [],
    notificaconesAnteriores: [],
};

export const UserContext = createContext({} as UserData);

export const UserProvider = ({ children }: any) => {

    const storedUser = JSON.parse(localStorage.getItem('myContext') || '{}');

    const [state, dispatch] = useReducer(userReducer, storedUser || initialState);

    const signinAction = (email: string, rol: string) => {
        return dispatch({
            type: SIGNIN,
            payload: { email, rol },
        });
    }

    const restartStore = () => {
        return dispatch({
            type: RESTART_STORE,
        });
    }

    const cambiarAppbar = (payload: boolean) => {
        return dispatch({
            type: CAMBIAR_APPBAR,
            payload,
        });
    }

    const getIdFicha = (id: string) => {
        return dispatch({
            type: GET_ID_FICHA,
            payload: id,
        });
    }

    const getAllManagers = async () => {
        try {
            const resp = await getManagers();
            let managers = Array.isArray(resp.data.body.combinedInfo)
                ? resp.data.body.combinedInfo
                : [resp.data.body.combinedInfo];

            return dispatch({
                type: GET_MANAGERS,
                payload: managers,
            });

        } catch (error) {
            console.log(error)
        }

    }

    // Guardar los datos del contexto en el localStorage cada vez que cambien
    useEffect(() => {
        localStorage.setItem("myContext", JSON.stringify(state));
    }, [state.user]);


    return (
        <UserContext.Provider value={{
            signinAction,
            restartStore,
            cambiarAppbar,
            getIdFicha,
            getAllManagers,
            ...state
        }}>
            {children}
        </UserContext.Provider>
    )
}