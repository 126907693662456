import { TableColumn } from 'react-data-table-component';
import { RowOrdenesExterior } from '../../interfaces/interfacesRows/rows';

const columnsOrdenesExterior: TableColumn<RowOrdenesExterior>[] = [
    {
        selector: (row: RowOrdenesExterior) => row.fecha,
        name: "Fecha",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.cliente,
        name: "Cliente",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.recibio,
        name: "Recibió",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.especie,
        name: "Especie",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.operacion,
        name: "Operación",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.cantidad,
        name: "Cantidad",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.precio,
        name: "Precio",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.importe,
        name: "Importe",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExterior) => row.puntos,
        name: "",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
];

export {
    columnsOrdenesExterior,
};
