import React, { useState, useEffect, useContext } from 'react';
import { NotificacionesContext } from '../../context/notificacionesContext/notificacionesContext';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import moment from "moment";
import { Box, Container, Linea, Notificacion, NotificacionTexto, Sub, Subtitulo } from './elements';


const hoy = moment().format().slice(5,10);
const ayer = moment().subtract( 1, "days").format().slice(5,10);    
const dosDespuesDeHoy = moment().subtract( 2, "days").format().slice(5,10);
const tresDespuesDeHoy = moment().subtract( 3, "days").format().slice(5,10);
const cuatroDespuesDeHoy = moment().subtract( 4, "days").format().slice(5,10);



export const Notificaciones = () => {

    const { width, height } = useWindowDimensions();

    //helper al que le mande el array y la fecha y que filtre teniendo en cuenta el mes si es 28 en febrero o 30/31, etc.
	
    const [loading, setLoading] = useState(true);
    const [notificacionesHoy, setNotificacionesHoy] = useState([]);
    const [notificacionesAyer, setNotificacionesAyer] = useState([]);
    const [dosDiasDespues, setDosDiasDespues] = useState([]);
    const [tresDiasDespues, setTresDiasDespues] = useState([]);
    const [cuatroDiasDespues, setCuatroDiasDespues] = useState([]);


    const context = useContext(NotificacionesContext);

    useEffect(() => {
        filtNotificacionesHoy();
	}, [context]);

    const notificacionRender = (notificacion) => {
        let mensaje
        let imagen

        switch (notificacion) {
            case 'ingreso nueva ficha':
                mensaje = '¡Ha ingresado una nueva ficha! Acordate de asignar un operador'
                imagen = 'nueva'
                break;
            case 'ficha subsanada':
                mensaje = 'Una ficha cambio de estado. Fue subsanada por el cliente'
                imagen = 'cambio-de-estado'
                break;
            case 'ficha aprobada':
                mensaje = 'Una ficha cambio de estado. Se aprobó'
                imagen = 'aprobada'
                break;
            case 'ficha informada':
                mensaje = 'Una ficha cambio de estado. Ya podes enviar los motivos'
                imagen = 'cambio-de-estado'
                break;
            case 'ficha rechazada':
                mensaje = 'Una ficha cambio de estado. Se rechazo'
                imagen = 'rechazada'
                break;
        
            default:
                break;
        }
        return {mensaje, imagen}
    }

    const filtNotificacionesHoy = () => {
        let filtHoy = context.notificaciones?.filter((e) => e.createdAt.slice(5,10) === hoy)
        let filtAyer = context.notificaciones?.filter((e) => e.createdAt.slice(5,10) === ayer)
        let filtDos = context.notificaciones?.filter((e) => e.createdAt.slice(5,10) === dosDespuesDeHoy)
        let filtTres = context.notificaciones?.filter((e) => e.createdAt.slice(5,10) === tresDespuesDeHoy)
        let filtCuatro = context.notificaciones?.filter((e) => e.createdAt.slice(5,10) === cuatroDespuesDeHoy)
        setNotificacionesHoy(filtHoy);
        setNotificacionesAyer(filtAyer);
        setDosDiasDespues(filtDos);
        setTresDiasDespues(filtTres);
        setCuatroDiasDespues(filtCuatro);

        setLoading(false)
    }
    


    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <Subtitulo>NOTIFICACIONES</Subtitulo>
            <Box>
                {loading ? (
                    <>
                        <p>Cargando</p>
                    </>
                ) : (
                    <>
                    {
                        notificacionesHoy.length > 0 &&
                        (
                            <div style={{marginBottom: "2em"}}>
                            <Sub>HOY</Sub>
                            {notificacionesHoy?.map((e) => (
                                <div key={e._id}>
                                    <Notificacion>
                                        <Notificacion>
                                            <img
                                                src={require(`../../images/notificaciones/${
                                                    notificacionRender(e.text)
                                                        .imagen
                                                }.png`)}
                                                alt="icon"
                                                style={{alignSelf: "center"}}
                                            />
                                            <NotificacionTexto>
                                                {
                                                    notificacionRender(e.text)
                                                        .mensaje
                                                }
                                            </NotificacionTexto>
                                        </Notificacion>
                                        <p style={{alignSelf: "center"}}>
                                            {e.createdAt.slice(11, 16)}
                                        </p>
                                    </Notificacion>
                                    <Linea />
                                </div>
                            ))}
                        </div>
                        )
                    }
                    {
                        notificacionesAyer.length > 0 &&
                        (
                            <div style={{marginBottom: "2em"}}>
                            <Sub>AYER</Sub>
                            {notificacionesAyer?.map((e) => (
                                <div key={e._id}>
                                    <Notificacion>
                                        <Notificacion>
                                            <img
                                                src={require(`../../images/notificaciones/${
                                                    notificacionRender(e.text)
                                                        .imagen
                                                }.png`)}
                                                alt="icon"
                                                style={{alignSelf: "center"}}
                                            />
                                            <NotificacionTexto>
                                                {
                                                    notificacionRender(e.text)
                                                        .mensaje
                                                }
                                            </NotificacionTexto>
                                        </Notificacion>
                                        <p style={{alignSelf: "center"}}>
                                            {e.createdAt.slice(11, 16)}
                                        </p>
                                    </Notificacion>
                                    <Linea />
                                </div>
                            ))}
                        </div>
                        )
                    }
                    {
                        dosDiasDespues.length > 0 &&
                        (
                            <div style={{marginBottom: "2em"}}>
                            <Sub>{dosDespuesDeHoy}</Sub>
                            {dosDiasDespues?.map((e) => (
                                <div key={e._id}>
                                    <Notificacion>
                                        <Notificacion>
                                            <img
                                                src={require(`../../images/notificaciones/${
                                                    notificacionRender(e.text)
                                                        .imagen
                                                }.png`)}
                                                alt="icon"
                                                style={{alignSelf: "center"}}
                                            />
                                            <NotificacionTexto>
                                                {
                                                    notificacionRender(e.text)
                                                        .mensaje
                                                }
                                            </NotificacionTexto>
                                        </Notificacion>
                                        <p style={{alignSelf: "center"}}>
                                            {e.createdAt.slice(11, 16)}
                                        </p>
                                    </Notificacion>
                                    <Linea />
                                </div>
                            ))}
                        </div>
                        )
                    }
                    {
                        tresDiasDespues.length > 0 &&
                        (
                            <div style={{marginBottom: "2em"}}>
                            <Sub>{tresDespuesDeHoy}</Sub>
                            {tresDiasDespues?.map((e) => (
                                <div key={e._id}>
                                    <Notificacion>
                                        <Notificacion>
                                            <img
                                                src={require(`../../images/notificaciones/${
                                                    notificacionRender(e.text)
                                                        .imagen
                                                }.png`)}
                                                alt="icon"
                                                style={{alignSelf: "center"}}
                                            />
                                            <NotificacionTexto>
                                                {
                                                    notificacionRender(e.text)
                                                        .mensaje
                                                }
                                            </NotificacionTexto>
                                        </Notificacion>
                                        <p style={{alignSelf: "center"}}>
                                            {e.createdAt.slice(11, 16)}
                                        </p>
                                    </Notificacion>
                                    <Linea />
                                </div>
                            ))}
                        </div>
                        )
                    }
                    {
                        cuatroDiasDespues.length > 0 &&
                        (
                            <div style={{marginBottom: "2em"}}>
                            <Sub>{cuatroDespuesDeHoy}</Sub>
                            {cuatroDiasDespues?.map((e) => (
                                <div key={e._id}>
                                    <Notificacion>
                                        <Notificacion>
                                            <img
                                                src={require(`../../images/notificaciones/${
                                                    notificacionRender(e.text)
                                                        .imagen
                                                }.png`)}
                                                alt="icon"
                                                style={{alignSelf: "center"}}
                                            />
                                            <NotificacionTexto>
                                                {
                                                    notificacionRender(e.text)
                                                        .mensaje
                                                }
                                            </NotificacionTexto>
                                        </Notificacion>
                                        <p style={{alignSelf: "center"}}>
                                            {e.createdAt.slice(11, 16)}
                                        </p>
                                    </Notificacion>
                                    <Linea />
                                </div>
                            ))}
                        </div>
                        )
                    }
                    </>
                )}
            </Box>
        </Container>
    );
}
