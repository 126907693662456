import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ModalValidarCUIT from "../../../ModalValidarCUIT/ModalValidarCUIT";
import { ModalDatosCoTitulares } from "../../ModalDatos/ModalDatosCoTitulares";
import ModalDatos from "../../ModalDatos/ModalDatos";
import ModalAlert from "../../../ModalAlert/ModalAlert";
import ModalSubsanarMail from "../../../ModalSubsanarMail/ModalSubsanarMail";
import ModalSirena from "../../../ModalSirena/ModalSirena";
import { ItemsCotitulares } from "./ItemsCotitulares";
import { ItemsDetalle } from "./itemsDetalles";
import { Texts } from "../Menu";

interface Props {
    id: string, 
    refreshFunc?: () => void,
    fichaSinIniciar: boolean, 
    handleOpenCUIT: () => void,
    handleClose: () => void,
    openCUIT: boolean,
    setOpenCUIT: React.Dispatch<React.SetStateAction<boolean>>,
    cuitVerificado?: null | string, 
    handleOpenDatosCotitular: () => void,
    handleOpenDatos: () => void,
    handleOpenSirena: () => void,
    telefono: number, 
    nombre: string, 
    rol: string,
    openDatosCotitular: boolean,
    setOpenDatosCotitular: React.Dispatch<React.SetStateAction<boolean>>,
    openDatos: boolean,
    setOpenDatos: React.Dispatch<React.SetStateAction<boolean>>,
    openSirena: boolean,
    setOpenSirena: React.Dispatch<React.SetStateAction<boolean>>,
    handleOpenAlerta: (param: string) => void,
    texts: Texts,
    handleOpenSubsanar: () => void,
    openAlerta: boolean,
    text: string,
    email: string, 
    openSubsanar: boolean,
    setOpenSubsanar: React.Dispatch<React.SetStateAction<boolean>>,
    idTitular: string,
    cotitular: boolean, 
    cotitularesView: boolean, 
}


export const ItemsComercial = (props : Props) => {

    const {
        id,
        refreshFunc,
        fichaSinIniciar,
        handleOpenCUIT,
        handleClose,
        openCUIT,
        setOpenCUIT,
        cuitVerificado,
        handleOpenDatosCotitular,
        handleOpenDatos,
        handleOpenSirena,
        telefono,
        nombre,
        rol,
        openDatosCotitular,
        setOpenDatosCotitular,
        openDatos,
        setOpenDatos,
        openSirena,
        setOpenSirena,
        handleOpenAlerta,
        texts,
        handleOpenSubsanar,
        openAlerta, 
        text,
        email,
        openSubsanar,
        setOpenSubsanar,
        idTitular,
        cotitular,
        cotitularesView,
    } = props;
    
    return (
        <div>
            {refreshFunc && !fichaSinIniciar && (
                <div>
                    <MenuItem
                        onClick={handleOpenCUIT}
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        Afip
                    </MenuItem>
                    <ModalValidarCUIT
                        handleClose={handleClose}
                        id={id}
                        open={openCUIT}
                        setOpen={setOpenCUIT}
                        cuitVerificado={cuitVerificado ? cuitVerificado : null}
                        refreshFunc={refreshFunc}
                        rol={rol}
                    />
                </div>
            )}

            {!fichaSinIniciar && (
                <ItemsDetalle
                    id={id}
                    fichaSinIniciar={fichaSinIniciar}
                    cotitular={cotitular}
                    handleOpenDatosCotitular={handleOpenDatosCotitular}
                    handleOpenDatos={handleOpenDatos}
                    handleOpenSirena={handleOpenSirena}
                    handleClose={handleClose}
                    telefono={telefono}
                    nombre={nombre}
                    rol={rol}
                    openDatosCotitular={openDatosCotitular}
                    setOpenDatosCotitular={setOpenDatosCotitular}
                    openDatos={openDatos}
                    setOpenDatos={setOpenDatos}
                    openSirena={openSirena}
                    setOpenSirena={setOpenSirena}
                />
            )}
            {cotitularesView && fichaSinIniciar && (
                <ItemsCotitulares
                    id={id}
                    fichaSinIniciar={fichaSinIniciar}
                    handleClose={handleClose}
                    cotitularesView={cotitularesView}
                    handleOpenAlerta={handleOpenAlerta}
                    texts={texts}
                    handleOpenSubsanar={handleOpenSubsanar}
                    openAlerta={openAlerta}
                    text={text}
                    email={email}
                    openSubsanar={openSubsanar}
                    setOpenSubsanar={setOpenSubsanar}
                    idTitular={idTitular}
                />
            )}
        </div>
    );
};
