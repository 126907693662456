import styled from "styled-components";

export const RedBox = styled.div`
    background: rgba(255, 163, 163, 0.75);
	width: 100%;
	padding: 0.5em 0;
	border-radius: 0.5em;
	margin-top: 0.5em;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const WarningText= styled.p`
    padding: 0.1em;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #FF0000;
	font-weight: bold;
`;

export const DocsBox= styled.div`
    margin-top: 1em;
	display: flex;
    flex-direction: column;
	text-align: center;
`;

export const DocsText= styled.p`
	font-weight: bold;
    margin-top: 0.3em;
`;
