import { Box, Modal } from '@mui/material'
import { Title, Text, ButtonsContainer, ButtonCancelar, ButtonConfirmarAccion, TextsContainer } from './elements'

interface Props {
    open: boolean,
    handleClose: () => void,
    handleConfirmarModal: () => void,
    tituloModal?: string,
    textoModal?: string,
    textoConfirmarModal: string,
}

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: 'center',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    minHeight: 130,
    boxShadow: 24,
    borderRadius: 2,
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
};

const ModalConfirmacion = ({
    open,
    handleClose,
    handleConfirmarModal,
    tituloModal,
    textoModal,
    textoConfirmarModal,
}: Props) => {



    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, }}>
                    <TextsContainer>
                        <Title>{tituloModal}</Title>
                        <Text>{textoModal}</Text>
                    </TextsContainer>


                    <ButtonsContainer>
                        <ButtonCancelar
                            onClick={handleClose}
                        >
                            Cancelar
                        </ButtonCancelar>
                        <ButtonConfirmarAccion
                            onClick={handleConfirmarModal}
                        >
                            {textoConfirmarModal}
                        </ButtonConfirmarAccion>
                    </ButtonsContainer>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalConfirmacion
