import { useState, useEffect, ChangeEvent, FormEvent } from "react"
import { AxiosResponse } from "axios";
import { getRentaOperable } from "../api/transferencias";
import { GetRentaOperableResponse } from "../interfaces/interfaceTransferencias/getRentaOperableInterface";

export interface Result {
    especie: string,
    cantidad: string,
    saldo: string,
    fechaLiq: string,
    fechaLim: number,
}

export const useFormReinversionRenta = () => {

    const [accountNumber, setAccountNumber] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [results, setResults] = useState<Result[]>([]);
    const [resultAccountNumber, setResultAccountNumber] = useState<string>('');


    const handleChange = (e : ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setAccountNumber(value)
    }
    

    const handleSubmit = async(e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (accountNumber !== "") {
            try {
                results.length !== 0 && setResults([]);
                error && setError('')
                const res: AxiosResponse<GetRentaOperableResponse> = await getRentaOperable(accountNumber);
                if (res.status === 200) {
                    console.log(res)
                    //setResults()
                    if(res.data.body.length === 0 ){
                        setError(`No hay datos de la cuenta ${accountNumber}`)
                    } else {
                        const resultado: Result[] = res.data.body.map((e) => {
                            return {
                                especie: e.especie,
                                cantidad: e.cantidad,
                                saldo: e.saldo,
                                fechaLiq: e.fechaLiq,
                                fechaLim: e.diasParaOperar,
                            }
                        })
                        setResults(resultado);
                        setResultAccountNumber(accountNumber);
                    }
                    setAccountNumber('');
                }
            } catch (error) {
                setAccountNumber('');
                setError('Ocurrió un error')
                console.log(error)
            }
        }
    }

    return {
        accountNumber,
        error,
        handleChange,
        handleSubmit,
        results,
        resultAccountNumber,
    }
}