import React from "react";
import { Text } from "./elements";

// Calcula el porcentaje completado de la ficha

interface Props {
    phase: object[],
    name: string,
    status: string,
}

export const Avance = ({phase, name, status} : Props) => {

    let reference = ''

    let porcentaje = "0%"

    if(phase.length >= 6){
        porcentaje = "100%";
        reference = 'Número validado'
    }  else if(phase.length === 5){
        porcentaje = "90%";
        reference = 'Términos'
    } else if(phase.length === 4){
        porcentaje = "75%";
        reference = 'DDJJ'
    } else if(phase.length === 3){
        porcentaje = "60%";
        reference = 'Test inversor'
    } else if(phase.length === 2){
        porcentaje = "50%";
        reference = 'Adjuntos'
    }
    else if(phase.length === 1){
        porcentaje = "40%"
        reference = 'Datos personales'
    } else if(status === "enable"){
        porcentaje = "25%";
        reference = 'Mail validado'
    } else if(name){
        porcentaje = "10%";
        reference = 'Ficha iniciada'
    }

    // Si solo inicio la apertura pero no valido el mail => 10%
    // Si valido el mail => 25%
    // Si completo los datos personales => 40%
    // Si adjunto las fotos dni => 50%
    // Si completo el test de inversor => 60%
    // Si completo las declaraciones juradas => 75%
    // Si acepto los terminos => 90%
    // Si valido el sms => 100%
    
    return (
        <div>
            <img
                src={require(`../../../images/tabla/avance/${porcentaje}.png`)}
                alt="logo"
            />
            <Text>{reference}</Text>
        </div>
    );
}