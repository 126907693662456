import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Container, Text, s } from "./elements";

//Componente que muestra el link para acceder a la pantalla de Cotitulares

interface Props {
    id: string,
    nombre: string,
    texto: string,
}

export const CotitularesLink = ({ id, nombre, texto } : Props) => {
    const styles =
        texto === "Aprobada"
            ? {
                  backgroundColor: "#C2DEFFBF",
                  color: "#00386E",
              }
            : texto === "Rechazada"
            ? {
                  backgroundColor: "#FFA3A3BF",
                  color: "#E3342F",
              }
            : texto === "Subsanada"
            ? {
                  backgroundColor: "#FBC8E0",
                  color: "#FF3093",
              }
            : {
                  backgroundColor: "rgba(0, 75, 111, 0.25)",
                  color: "#00386E",
              };

    return (
        <Container style={styles}>
            <NavLink
                to={`/cotitulares/${id}`}
                state={nombre}
                style={s.link}
            >
                <Text style={{ color: styles.color }}>
                    {texto}
                </Text>
                <Icon
                    icon="ep:arrow-right"
                    // icon="ep:arrow-right-bold"
                    style={{ ...s.icon, color: styles.color }}
                />
            </NavLink>
        </Container>
    );
};
