import { GetDividendosResponse } from "../interfaces/interfaceDividendos/getDividendosInterface.js";
import apiInstance from "../utils/Axios.js";
import FormData from "form-data";

export async function procesarDividendos(csv: any, xls: any) {
	let data = new FormData();
	data.append("cajaDeValores", csv);
	data.append("dataGallo", xls);
	return await apiInstance.post("/procesarDividendos", data);
}

export async function getDividendos() {
	return await apiInstance.get<GetDividendosResponse>("/dividendos");
}

export async function cambiarEstadoRenta(id: string, check: any) {
	return await apiInstance.put("/cambiarEstadoRenta", {
		id,
		check,
	});
}
