import styled from "styled-components";

export const Container = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 380px;
	height: 250px;
	background-color: white;
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
`;

export const TitleContainer = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #e0d7d7;
	margin-bottom: 8px;
	width: 100%;
	height: 50px;
	/*rounded in top but not in bottom*/
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
`;

export const Title = styled.p`
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #004b6f;
`;

export const Form = styled.form`
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background-color: white; */
	width: 70%;
	height: 100%;
`;

export const InputContainer = styled.div`
    margin-bottom: 8px;
	width: 100%;
`;

export const Button = styled.button`
    background-color: #004B6F;
    color: #FFFFFF;
    width: 100%;
    padding: 0.5em 0;
    border-radius: 0.3em;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: rgb(220, 220, 220);
        color: rgb(135, 135, 135);
    }
`;

export const TextButton = styled.p`
    font-size: 12px;
    font-weight: bold;
`;



