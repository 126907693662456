import styled from "styled-components";

export const Container = styled.div`
    background-color: #00386E; 
    border-radius: 10px; 
    padding: 1.5em;
`

export const CuentaInfo = styled.p`
    color: white; 
    font-weight: bold;
    font-size: 1.2em;
`

export const Name = styled.p`
    color: white;
    margin-left: 2em;
    align-self: center;
    font-size: 0.9em;
`

export const FechaTenencia = styled.p`
    color: white; 
    margin-top: 1em;
    font-size: 0.8em;
`