import React, { useEffect } from 'react'
import { baseURL } from '../../../utils/Axios'

//Enlace que te abre los datos de la ficha completos en formato pdf

interface Props {
    id: string,
}
export const Ficha = ({id} : Props) => {

    let url

    url = `${baseURL}/ficha-pdf/${id}`

    return (
        
        <a
            href = {url}
            style={{cursor: "pointer"}} 
            target="_blank"
            rel="noreferrer"
        >
            <img
                src={require("../../../images/tabla/ficha.png")}
                alt="logo"
                style={{width: "1.6em"}}
            />
        </a>
    )
}
