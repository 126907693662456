import React, { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useState } from 'react'
import { getTenencia, TransferirDolares } from '../api/tenencia'
import { AxiosResponse } from 'axios';
import { BodyGetTenencia, GetTenenciaResponse, TenenciaMercado } from '../interfaces/interfaceTenecia/getTenenciaInterface';

export const useTenenciaHook = () => {

    const [nroCuenta, setNroCuenta] = useState<string>('');
    const [infoCuenta, setInfoCuenta] = useState<BodyGetTenencia | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [nroCuentaCliente, setNroCuentaCliente] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [actualOption, setActualOption] = useState<string>('ACTUAL');
    const [tipoDolarImporte, setTipoDolarImporte] = useState({
        cantidad: 0,
        especie: "",
    });


    useEffect(() => {
        setInfoCuenta(null)
        setError('')
    }, [])


    function handleInput(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setNroCuenta(e.target.value);
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        setInfoCuenta(null);
        setError('');
        setLoading(true);
        try {
            const data: AxiosResponse<GetTenenciaResponse> = await getTenencia(nroCuenta);
            setInfoCuenta(data.data.body);
            setNroCuentaCliente(nroCuenta);
        } catch (error) {
            setError('Error al buscar la cuenta.')
            setLoading(false);
        }
        setLoading(false);
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents the default behavior of the Enter key (e.g., form submission)
            handleSubmit(event);
        }
    };

    const changeColor = (num: number) => {
        if ((num % 2) === 0) {
            return true
        }
        return false
    }

    const sortArray = (array: TenenciaMercado[]) => {
        const sortedArray = array.sort((a, b) => {
            if (a.ticker > b.ticker) return 1
            if (a.ticker < b.ticker) return -1
            return 0
        })
        return sortedArray
    }

    const transferirDolares = async () => {
        try {
            const resp = await TransferirDolares({
                accountNumber: nroCuenta,
                ...tipoDolarImporte,
            });
            const tenencia = await getTenencia(nroCuenta);
            setInfoCuenta(tenencia.data.body);
            if (resp.data.error === false) {
            }
        } catch (error) {
            console.log(error);
        }
    };


    return {
        handleInput,
        handleSubmit,
        changeColor,
        loading,
        infoCuenta,
        nroCuentaCliente,
        handleKeyDown,
        error,
        actualOption,
        setActualOption,
        sortArray,
        setTipoDolarImporte,
        transferirDolares,
    }
}
