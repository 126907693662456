import React from "react";
import Table from "../../components/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { columnsHistorial } from "../../helper/Colums/columsHistorial";
import { Container } from "./elements";


const subtitulo = "HISTORIAL DE MOVIMIENTOS"

export const HistorialDeMovimientos = () => {

    const { width } = useWindowDimensions();

    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <Table subtitulo={subtitulo} columns={columnsHistorial} />
        </Container>
    );
}