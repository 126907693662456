import React from 'react'
import MenuItem from "@mui/material/MenuItem";
import ModalValidarCUIT from '../../../ModalValidarCUIT/ModalValidarCUIT';

interface Props {
    id: string, 
    rol: string,
    fichaSinIniciar: boolean,  
    rechazada: boolean, 
    documentos: () => void,
    refreshFunc?: () => void,
    handleOpenCUIT: () => void,
    handleClose: () => void,
    openCUIT: boolean,
    setOpenCUIT: React.Dispatch<React.SetStateAction<boolean>>,
    cuitVerificado?: null | string, 
}

export const ItemsCompliance = (props : Props) => {
    const { id, rol, fichaSinIniciar, rechazada, documentos, refreshFunc, handleOpenCUIT, handleClose, openCUIT, setOpenCUIT, cuitVerificado } = props;
    return (
        <div>
            {refreshFunc && !fichaSinIniciar && (
                <div>
                    <MenuItem
                        onClick={handleOpenCUIT}
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        Afip
                    </MenuItem>
                    <ModalValidarCUIT
                        handleClose={handleClose}
                        id={id}
                        open={openCUIT}
                        setOpen={setOpenCUIT}
                        cuitVerificado={cuitVerificado ? cuitVerificado : null}
                        refreshFunc={refreshFunc}
                        rol={rol}
                    />
                </div>
            )}
            {!rechazada && !fichaSinIniciar && (
                <MenuItem
                    onClick={documentos}
                    sx={{
                        color: "#004B6F",
                        justifyContent: "center",
                    }}
                >
                    Documentos
                </MenuItem>
            )}
        </div>
    );
};
