import { useState } from "react";
import { getMovimientos } from "../api/movimientos";
import { AxiosResponse } from "axios";
import { MovimientosResponse } from "../interfaces/interfacesMovimientos/getMovimientosInterface";

// Hook que manipula la data de los movimientos de las fichas

interface Movimiento {
	fecha: string;
	hora: string;
	accion: string;
	cliente: string;
	usuario: string;
	nombreCliente: string;
	nombreOperador: string;
}

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export default function useMovimientosHook({userRol, setLoading} : Props) {

	const [movimientos, setMovimientos] = useState<Movimiento[]>([]);
	const [movimientosFilt, setMovimientosFilt] = useState<Movimiento[]>([]);

	// Petición a la api

	const allMovimientos = async () => {
		let movimientosData;
		try {
			setLoading(true);
			const res : AxiosResponse<MovimientosResponse> = await getMovimientos();

			movimientosData = res.data.body.fichaMovimiento;

			movimientosData = movimientosData.map((e) => ({
				fecha: e.fecha.slice(0, 5),
				hora: e.fecha.slice(12, 20),
				accion: e.accion,
				cliente: e.ficha ? e.ficha : "-",
				usuario: e.autor ? e.autor : "-",
				nombreCliente:
					e.fichaName && e.fichaLastname
						? e.fichaName + " " + e.fichaLastname
						: "Cliente no disponible",
				nombreOperador:
					e.name && e.lastname
						? e.name + " " + e.lastname
						: "Operador no disponible",
			}));

			movimientosData = movimientosData.reverse();

			setMovimientos(movimientosData);
			setMovimientosFilt(movimientosData);
			!(movimientosData?.length > 0) && setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

    // Funcion de filtro
	
	const filtMovimientos = (name: string) => {
		name = name.toLowerCase();

		let filtered = movimientos.filter(
			(e) =>
				e?.fecha?.includes(name) ||
				e?.accion?.toLowerCase().includes(name) ||
				e?.hora?.includes(name) ||
				e.nombreOperador.toLowerCase().includes(name) ||
				e.nombreCliente.toLowerCase().includes(name)
		);

		setMovimientosFilt(filtered);
	};

	return {
        allMovimientos,
		movimientosFilt,
        filtMovimientos,
	};
}
