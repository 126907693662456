import styled from "styled-components";

export const Container = styled.div`
	position: absolute;
	padding-left: 1em;
`
export const Subtitulo = styled.h3`
	color: #00386e;
	font-size: 1.5em;
	padding: 0px;
    margin: 0px;
`

export const SearchCont = styled.div`
	display: flex;
	align-items: stretch;
	height: 2.3em;
	float: right;
	border-radius: 0.5em;
	background: white;
	border: none;
	outline: none;
	font-size: 0.9em;
	margin-left: 40.3em;
`

export const SearchContainer= styled.div`
	display: flex;
	align-items: stretch;
	height: 2.3em;
	float: right;
	border-radius: 0.5em;
	background: white;
	border: none;
	outline: none;
	font-size: 0.9em;
`
export const InputSearch = styled.input`
	border-radius: 0.5em;
	background: white;
	border: none;
	outline: none;
	font-size: 1em;
	font-weight: bold;
	font-family: inherit;
	color: rgb(102, 102, 102);
	padding-left: 1em;
`

export const SearchButton = styled.button`
	border-radius: 2em;
	background: white;
	border: none;
	outline: none;
	cursor: pointer;
	padding-right: 1em;
`

export const Icon = styled.img`
	width: 1em;
`
