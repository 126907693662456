import React from 'react'
import { DocsBox, DocsText, RedBox, WarningText } from './elements'
import { FileUserNew } from '../../interfaces/interfacesDocumentos/getDocumentosInterface'

interface Props {
    warning: string[],
    documentos: FileUserNew[] | [],
}

export const WarningBox = ({warning, documentos}: Props) => {
    console.log(documentos)

    const formatDocumentos = () => {
        let docs: string[] = [];
        if(documentos.length === 0){
            docs = ["CUIT", "CUIL", "SO", "LT", "ROL"]
        } else {
            documentos.map( (e) => {
                e.order === 1 && docs.push("CUIT");
                e.order === 2 && docs.push("CUIL");
                e.order === 3 && docs.push("SO");
                e.order === 4 && docs.push("LT");
                e.order === 5 && docs.push("ROL");
            })
        }
        return docs
    }

    const docs = formatDocumentos();
    console.log("DOCS", docs)

    return (
        <div>
            {
                (warning.includes("El cliente es una persona terrorista") || warning.includes("El cliente es una persona estadounidense")) ?
                    <RedBox>
                        <WarningText>NO SE PUEDE APROBAR</WarningText>
                        {warning?.map((e: string, i: number) => (
                            <WarningText key={i}>
                                {e.toLocaleUpperCase()}
                            </WarningText>
                        ))}
                    </RedBox>
                    :
                    (warning.includes("El cliente presenta inconsistencias en 'Sujeto obligado'") || warning.includes("El cliente presenta inconsistencias en 'PEP'")) ?
                    <RedBox>
                        <WarningText>HAY INCONSISTENCIA</WarningText>
                        {warning?.map((e: string, i: number) => (
                            <WarningText key={i}>
                                {e.toLocaleUpperCase()}
                            </WarningText>
                        ))}
                    </RedBox>
                    :
                    <DocsBox>
                    <p>FALTAN LOS SIGUIENTES DOCUMENTOS:</p>
                    {docs?.map((e: string, i: number) => (
                        <DocsText key={i}>
                            {e.toLocaleUpperCase()}
                        </DocsText>
                    ))}
                    </DocsBox>
            }
        </div>
    )
}


