import styled from "styled-components";

export const Content = styled.div`
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const Text = styled.p`
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #004b6f;
	margin-left: 0.5em;
`;