import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { addNewJuridica, checkJuridicas } from "../api/aprobadas";

// Hook que manipula el formulario para agregar una persona jurídica


interface Props {
    razonSocial: string, 
    cuit: string, 
    numCuenta: string,
}

interface Errors {
    incompletos?: string,
    numCuenta?: string,
    razonSocial?: string, 
    cuit?: string, 
} 

interface HandleAgregarProps {
    handleClose: () => void, 
    handleOpen: (param : boolean) => void, 
}

const validateForm = async ({razonSocial, cuit, numCuenta} : Props) => {
    let errors : Errors = {};

    if(!razonSocial || !cuit || !numCuenta){
        errors.incompletos = "Campos incompletos"
    } else {
        const err = await checkJuridicas(razonSocial, cuit, numCuenta);

        if(err?.data?.body?.numeroDeCuenta){
            errors.numCuenta = err.data.body.numeroDeCuenta;
        }
    }
    
    return errors;
}


export const useFormJuridica = (initialForm : Props) => {

    const navigate = useNavigate();

    const [form, setForm] = useState(initialForm);

    const [errors, setErrors] = useState<Errors>({});

    useEffect(() => {
        const validateAndSetErrors = async () => {
            const validatedErrors = await validateForm(form);
            setErrors(validatedErrors);
        };
        if (Object.keys(errors).length > 0) {
            validateAndSetErrors()
        }
    }, [form, errors]);

    const handleChange = async(e : React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target as HTMLButtonElement;

        setForm({
            ...form,
            [name]: value,
        });


        if (Object.keys(errors).length > 0) {
            setErrors( await validateForm(form));
        }
    }

    const handleSubmit = async({razonSocial, cuit, numCuenta} : Props) => {
        
        let errorVariableEstatica = await validateForm(form);
        setErrors(await validateForm(form));

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorVariableEstatica).length === 0 
            ) {
                try {
                    const res = await addNewJuridica(razonSocial, cuit, numCuenta);
                    if(res){
                        navigate("/aprobados");
                    }
                } catch (error) {
                    console.log(error)
                }
        } 
    }

    const handleAgregar = async ({handleClose, handleOpen} : HandleAgregarProps) => {
        let errorVariableEstatica = await validateForm(form);
        setErrors(await validateForm(form));

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorVariableEstatica).length === 0 
            ) {
                try {
                    handleOpen(true)
                } catch (error) {
                    console.log(error)
                }
        } 
    }

    const handleCancelar = () => {
        //volver a pantalla de aprobados
        navigate("/aprobados");
    }

    return {
        form,
        errors,
        handleChange,
        handleAgregar,
        handleCancelar,
        handleSubmit,
    }
}