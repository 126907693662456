import { useState, useEffect, ChangeEvent } from "react"
import { useNavigate } from "react-router-dom";
import { updatepassword } from "../api/auth";

// Hook que manipula el formulario para agregar una persona jurídica

interface Props {
    mail: string, 
    contraseñaAnterior: string, 
    nuevaContraseña: string, 
    nuevaContraseñaCheck: string,
}

interface Errors {
    incompletos?: string,
    contraseña?: string,
    updateError?: string,
}

const validateForm = ({mail, contraseñaAnterior, nuevaContraseña, nuevaContraseñaCheck} : Props) => {
    let errors:Errors = {};

    if(!mail || !contraseñaAnterior || !nuevaContraseña || !nuevaContraseñaCheck){
        errors.incompletos = "Campos incompletos"
    } else if (nuevaContraseña !== nuevaContraseñaCheck) {
        errors.contraseña = "Las contraseñas deben coincidir"
    }
    
    return errors;
}


export const useFormCambiarContraseña = (initialForm : Props) => {

    const navigate = useNavigate();

    const [form, setForm] = useState(initialForm);

    const [errors, setErrors] = useState<Errors>({});

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }, [form, Object.keys(errors).length > 0]);

    const handleChange = async(e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value,
        });


        if (Object.keys(errors).length > 0) {
            setErrors( validateForm(form));
        }
    }

    const handleSubmit = async() => {
        let errorVariableEstatica = validateForm(form);
        setErrors( validateForm(form));

        const { mail, contraseñaAnterior, nuevaContraseña } = form;

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorVariableEstatica).length === 0 
            ) {
                try {
                    console.log(mail, contraseñaAnterior, nuevaContraseña)
                    const res = await updatepassword(mail, contraseñaAnterior, nuevaContraseña)

                    if(res.status === 200){
                        localStorage.setItem("token", "");
                        window.location.assign("/");
                    } else {
                        setErrors({updateError : "Ocurrió un error, revisar los datos ingresados"})
                    }
                } catch (error) {
                    console.log(error)
                    setErrors({updateError : "Ocurrió un error, revisar los datos ingresados"})
                }
        } 
    }

    return {
        form,
        errors,
        handleChange,
        handleSubmit,
    }
}