import React from "react";
import Table from "../../components/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { columnsAprobados } from "../../helper/Colums/columsAprobados";
import { Container } from "./elements";
const subtitulo = "APROBADOS"

export const Aprobados = () => {

    const { width } = useWindowDimensions();
    
    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <Table 
                subtitulo={subtitulo}
                columns= {columnsAprobados}
            />
        </Container>
    );
}