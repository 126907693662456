import { TableColumn } from 'react-data-table-component';
import { RowTransferencia } from '../../interfaces/interfacesRows/rows';

const columnsTransferencias: TableColumn<RowTransferencia>[] = [
    {
        selector: (row: RowTransferencia) => row.fecha,
        name: "Fecha",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowTransferencia) => row.cliente,
        name: "Cliente",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowTransferencia) => row.ticker,
        name: "Ticker",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowTransferencia) => row.importe,
        name: "Importe",
        center: true,
        grow: 1.5,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowTransferencia) => row.numOrden,
        name: "N° de orden",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowTransferencia) => row.estado,
        name: "Estado",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
];

export {
    columnsTransferencias,
}
