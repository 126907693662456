import styled from "styled-components";

export const Input = styled.input`
    background: #ecf5ff;
	/* box-shadow: 4px 4px 10px 0px #c2deffac inset; */
    box-shadow: inset 4px 4px 10px 0px rgba(177, 214, 255, 0.4), inset 4px 4px 14px rgba(177, 214, 255, 0.3);
	border-radius: 5px;
	padding: 0.75em;
	color: #00386E;	
	border:  0.1px solid rgba(177, 214, 255, 0.3);
	font-size: 0.9em;
	@media (min-width: 1600px) {
		padding: 1em;
	}
`;

export const Select = styled.select`
	background: #ecf5ff;
	box-shadow: inset 4px 4px 10px 0px rgba(177, 214, 255, 0.4), inset 4px 4px 14px rgba(177, 214, 255, 0.3);
	border-radius: 5px;
	border:  0.1px solid rgba(177, 214, 255, 0.3);
	padding: 0.75em;
	color: #00386E;
	overflow: hidden;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	/* background-image: url(${({ theme }) => theme.urlIconSelect}); */
	background-image: url("https://api.iconify.design/ep/arrow-down-bold.svg?color=#00386E");
	background-repeat: no-repeat, repeat;
	background-position: right 0.9em top 50%, 0 0;
	background-size: 0.85em auto, 100%;
	font-size: 0.9em;
	@media (min-width: 1600px) {
		padding: 1em;
	}
`;

export const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 85%;
	margin-top: 0.5em;
	@media (min-width: 1600px) {
		margin-top: 1em;
	}
`;


//#C2DEFF
//#ecf5ff
