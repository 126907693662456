import styled from "styled-components";

export const Button = styled.button`
    background: none;
    border: none;
`;

export const Title = styled.p`
    text-align: center;
    font-weight: 800;
    font-size: 1.2em;
    line-height: 21px;
    margin: 0.7em 0 0 0;
    padding: 0.2em;
`;

export const Text = styled.p`
    text-align: center;
    font-size: 0.9em;
    line-height: 15px;
    letter-spacing: -0.024em;
    margin: 0;
    padding: 0.2em;
    @media(min-width: 992px) {
        font-size: 1em
    }
`;

export const TextsContainer = styled.div`
    width: 90%;
    height: 100%;
`

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    border-top: 0.1em solid #EBEBEB;
    margin: 1.2em 0 0 0;
    padding: 0;
    height: 3.2em;
`;

export const ButtonCancelar = styled.button`
    background: none;
    border: none;
    width: 50%;
    font-size: 1em;
    font-weight: 400;
    padding-top: 0.1em;
    border-right: 0.1em solid #EBEBEB;
    cursor: pointer;
    padding: 10px;
    color: #00386E;
`;

export const ButtonConfirmarAccion = styled.button`
    background: none;
    border: none;
    width: 50%;
    font-size: 1em;
    font-weight: 400;
    padding: 10px;
    cursor: pointer;
    color: #00386E;

`;
