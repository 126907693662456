import React from "react";
import DataTable from "react-data-table-component";
import { Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { ButtonContainer, Container, TableContainer, TextError } from "./elements";
import columnsOperarConfirmacion from "../../../helper/Colums/columsOperarConfirmacion";


export interface FiltData {
    cantidad: number;
    cliente: string;
    especie: string;
    operacion: string;
    precio: number;
    tipo: string;
}

interface Props {
    filtData: FiltData[], 
    handleContinuar: () => Promise<void>, 
    loading: boolean, 
    error: string,
}

export const Confirmacion = ({ filtData, handleContinuar, loading, error }: Props) => {
    return (
        <Container>
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress
                        style={{
                            color: "#004B6F",
                            marginBottom: "4em",
                            marginTop: "4em",
                        }}
                    />
                </Box>
            ) : error ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <TextError>{error}</TextError>
                </Box>
            ) : (
                <>
                    <TableContainer>
                        <DataTable
                            columns={columnsOperarConfirmacion}
                            data={filtData}
                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            // minHeight="600px"
                            customStyles={customStyles}
                            responsive
                            keyField="id"
                        />
                    </TableContainer>
                    <ButtonContainer>
                        <Button
                            style={{
                                marginTop: "24px",
                                backgroundColor: "#004B6F",
                                fontSize: "0.8em",
                                padding: "0.7em 2em",
                            }}
                            onClick={handleContinuar}
                            variant="contained"
                            size="medium"
                            disabled={loading}
                        >
                            CONFIRMAR
                        </Button>
                    </ButtonContainer>
                </>
            )}
        </Container>
    );
};

const customStyles = {
    tableWrapper: {
        style: {
            display: "table",
            minHeight: "50%",
            maxHeight: "50%",
            maxWidth: "100%",
            backgroundColor: "white",
            color: "#004B6F",
            padding: "0 1.2em",
        },
    },

    header: {
        style: {
            minHeight: "56px",
        },
    },
    rows: {
        style: {
            minHeight: "58px", // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",

            backgroundColor: "rgba(142, 178, 221, 0.5)",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
        },
    },
};
