import styled from "styled-components";

export const Container = styled.div`
    height: calc(100vh - 3em);
    position: absolute;
    top: 3em;
    display: flex;
    flex-direction: column;
`;

export const Subtitulo = styled.p`
    font-size: 2em;
    color: rgba(0, 75, 111, 0.75);
`;

export const WhiteBox = styled.div`
    background-color: white;
    margin-top: 0.3em;
    height: 90%;
    width: 100%;
    border-radius: 0.8em;
    margin-bottom: 0;
`;

export const FormContainer = styled.div`
	width: 50%;
    margin-left: 1.5em;
    margin-top: 1em;
	@media (min-width: 1600px) {
		margin-top: 3em;
	}
`;

export const Form = styled.form`
    margin-top: 1em;
	@media (min-width: 1600px) {
		margin-top: 2em;
	}
`;

export const LabelForm = styled.label`
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
`;

export const ErrorsContainer = styled.div`
    margin-top: 2em;
`;

export const TextError = styled.p`
    margin-top: 0.1em;
    color: #E3342F;
    font-weight: bold;
`;

export const SeccionButtons = styled.div`
    position: absolute;
    bottom: 5em;
    left: 40%;
    display: flex;
`;
