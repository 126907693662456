import { GetDolaresResponse } from "../interfaces/interfaceDolares/getDolaresInterface.js";
import apiInstance from "../utils/Axios.js";
import FormData from "form-data";

export async function procesarDolares(csv: any, dola7000: any, dola10000: any) {
	let data = new FormData();
	data.append("cajaDeValores", csv);
	data.append("dataDolar7000", dola7000);
	data.append("dataDolar10000", dola10000);
	return await apiInstance.post("/procesarDolares", data);
}

export async function getDolares() {
	return await apiInstance.get<GetDolaresResponse>("/dolares");
}
