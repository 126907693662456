import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 98%;
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    height: 90%;
    width: 100%;
`;

export const TextAccount = styled.p`
    margin-top: 0.8em;
    margin-left: 1.5em;
    font-size: 1em;
    font-weight: bold;
`;

