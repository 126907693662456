import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { FileText, Input, Label, Row } from "./elements";
import { Data } from "../../../hooks/useOperarCarteras";

interface Props {
    name: string, 
    data: Data[], 
    handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void, 
    handleContinuar: () => Promise<void>,
}

export const Inicial = ( {name, data, handleUpload, handleContinuar}: Props) => {
    return (
        <>
            <Row>
                <Label htmlFor="upload">
                    <Icon icon="material-symbols:download" style={styles.logo} />
                    Seleccionar archivo
                </Label>
                <Input
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={(e) => handleUpload(e)}
                />
                {name ? (
                    <FileText>
                        {name?.substring(name.lastIndexOf("\\"))}
                    </FileText>
                ) : (
                    <FileText>Ningún archivo selec.</FileText>
                )}
            </Row>
            {data.length !== 0 ? (
                <Button
                    style={{
                        marginTop: "24px",
                        backgroundColor: "#004B6F",
                        fontSize: "0.7em",
                        padding: "0.7em 2em",
                    }}
                    onClick={handleContinuar}
                    variant="contained"
                    size="medium"
                >
                    CONTINUAR
                </Button>
            ) : (
                <Button
                    style={{
                        marginTop: "24px",
                        backgroundColor: "#EBEBED",
                        fontSize: "0.7em",
                        padding: "0.7em 2em",
                    }}
                    variant="contained"
                    size="medium"
                    disabled
                >
                    CONTINUAR
                </Button>
            )}
        </>
    );
};

const styles = {
    logo: {
        fontSize: "2em",
        marginRight: "0.5em",
    }
}