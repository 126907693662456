import styled from "styled-components";

export const NumContainer = styled.button`
    border: 1px solid #d8e9fe;
    border-radius: 4px;
    background-color:#dfeeff;
    padding: 0.5em;
    text-align: center;
`;

export const Boton = styled.button`
    display: flex;
    border: 1px solid #d8e9fe;
    border-radius: 4px;
    background-color:#dfeeff;
    color: #154F14;
    padding: 0.6em;
    -moz-appearance: none;
	-webkit-appearance: none;
    appearance: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
`;