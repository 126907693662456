import React, { useState } from "react";
import { useOperarCartera } from "../../hooks/useOperarCarteras";
import { Inicial } from "../../components/OperarCarteras/Inicial/Inicial";
import { Confirmacion } from "../../components/OperarCarteras/Confirmacion/Confirmacion";
import { Final } from "../../components/OperarCarteras/Final/Final";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Box, Container, Subtitulo, SubtituloContainer } from "./elements";


export const OperarCarteras = () => {

    const { width, height } = useWindowDimensions();
    
    const {
        name,
        data,
        filtData,
        results,
        etapa,
        etapas,
        handleUpload,
        loading,
        handleContinuar,
        error,
    } = useOperarCartera();

    return (
        <div
            style={{
                width: width * 0.85,
                height: height * 0.92,
                left: width * 0.15,
                top: height * 0.062,
                position: "absolute",
            }}
        >
            <Container>
                <SubtituloContainer>
                    <Subtitulo>SUBIR ARCHIVO</Subtitulo>
                </SubtituloContainer>
                <Box>
                    {etapa === etapas.Confirmacion ? (
                        <Confirmacion
                            filtData={filtData}
                            handleContinuar={handleContinuar}
                            loading={loading}
                            error={error}
                        />
                    ) : etapa === etapas.Final ? (
                        <Final
                            results={results}
                            handleContinuar={handleContinuar}
                        />
                    ) : (
                        <Inicial
                            name={name}
                            data={data}
                            handleUpload={handleUpload}
                            handleContinuar={handleContinuar}
                        />
                    )}
                </Box>
            </Container>
        </div>
    );
};
