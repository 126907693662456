import { useState, useEffect, useContext } from "react";
import { io } from "socket.io-client";
import { Icon } from "@iconify/react";
import { getNotificaciones } from "../../api/notificaciones";
import { NotificacionesContext } from "../../context/notificacionesContext/notificacionesContext";
import { baseURL } from "../../utils/Axios";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";
import { Button, Container, LogoContainer, LogoContainerUser } from "./elements";
import UserMenu from "../UserMenu/UserMenu";

const Appbar = () => {
  const [notifica, setNotifica] = useState([]);
  const [notificaAper, setNotificaAper] = useState([]);
  const { width, height } = useWindowDimensions();

  const { user } = useContext(UserContext);
  const context = useContext(NotificacionesContext);


    // useEffect(() => {
    //     if (user.rol === roles.comercial || user.rol === roles.compliance || user.rol === roles.operador) {
    //         if (
    //             context.notificaciones?.length === 0 ||
    //             context.notificaciones === undefined
    //         ) {
    //             getNotificacionesAnteriores();
    //         }

    //         initiateAperturaSocketConnection();
    //         initiateSocketConnection();

    //         return () => {
    //             socket.off();
    //             socketApertura.off();
    //         };
    //     }
    // }, [notificaAper, notifica]);

  let socket;
  let socketApertura;
  const url = `${baseURL}/exportar`;

  const initiateSocketConnection = () => {
    socket = io.connect(process.env.REACT_APP_SOCKET_ENDPOINT, {
      //transports: ["websocket"],
      path: "/socket.io",
    });

    socket.on("notificacionCrm", (notificacion, usuario) => {
      if (user.rol === usuario) {
        setNotifica((prevNotifica) => [
          ...prevNotifica,
          { notificacion, usuario },
        ]);
        user.rol === usuario && getNotificacionesAnteriores();
      }
    });
  };

  const initiateAperturaSocketConnection = () => {
    socketApertura = io.connect(
      process.env.REACT_APP_SOCKET_ENDPOINT_APERTURA,
      {
        transports: ["websocket"],
      }
    );

    socketApertura.on("notificacionApertura", (notificacion, usuario) => {
      if (user.rol === usuario) {
        setNotificaAper((prevNotificaAper) => [
          ...prevNotificaAper,
          { notificacion, usuario },
        ]);
        user.rol === usuario && getNotificacionesAnteriores();
      }
    });
  };

  const getNotificacionesAnteriores = async () => {
    let res = await getNotificaciones();
    res = res?.data?.body;

    if (res?.find((element) => element.user === user.rol)) {
      setNotifica(["anteriores"]);
    }
    res?.length > 0 && context.setNotificaciones(res);
  };

  const handleClick = () => {
    setNotifica([]);
    setNotificaAper([]);
    window.location.assign("/notificaciones");
  };

  return (
    <Container style={{ height: height * 0.06 }}>
      {(user.rol === roles.comercial || user.rol === roles.compliance || user.rol === roles.operador || user.rol === roles.administrador )&& (
        <div style={{ marginRight: "0.3em" }}>
          <Button>
            <a href={url} target="_blank" rel="noreferrer">
              <Icon
                icon="lucide:download"
                style={{...styles.logo, color: "#004B6F" }}
              />
            </a>
          </Button>
        </div>
      )}

      {/* {(user.rol === roles.comercial || user.rol === roles.compliance || user.rol === roles.operador) && (
        <LogoContainer>
          {notifica?.length > 0 || notificaAper?.length > 0 ? (
            <Button onClick={handleClick} >
              <Icon
                icon="clarity:notification-outline-badged"
                style={styles.logoActivado}
              />
            </Button>
          ) : (
            <Button onClick={handleClick} >
              <Icon icon="clarity:notification-line" style={styles.logo} />
            </Button>
          )}
        </LogoContainer>
      )} */}

      <p>{user?.email.split("@", 1)}</p>

      <LogoContainerUser>
        <UserMenu />
      </LogoContainerUser>
    </Container>
  );
};

export default Appbar;

const styles = {
  logoUser: {
    fontSize: "1.5em",
    margin: "0.2em",
  },
  logo: {
    fontSize: "1.7em",
    color: "rgba(85, 86, 88, 0.75)",
    margin: "0.2em",
  },
  logoActivado: {
    fontSize: "1.7em",
    color: "rgba(195, 28, 28, 0.75)",
    margin: "0.2em",
  },
}

