import { SingInResponse } from "../interfaces/interfacesAuth/singInIntreface.js";
import { UpdatePasswordResponse } from "../interfaces/interfacesAuth/updatePasswordInterface.js";
import apiInstance from "../utils/Axios.js";

export async function signin(email: string, password: string) {
	return await apiInstance.post<SingInResponse>("/auth/signin", {
		email,
		password,
	});
}

export async function updatepassword(email:string, oldPasssword: string, newPassword: string) {
	return await apiInstance.put<UpdatePasswordResponse>("/auth/updatepassword", {
		email,
		oldPasssword,
		newPassword,
	});
}