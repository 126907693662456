import React from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Input, OptionContainer, Select } from "../aplicacionElements";
// import s from "./Agregar.module.css";
import { SideCalendar } from "../../../components/Aplicacion/Calendar/Calendar";
import { Button } from "@mui/material";
import { useFormAgregar } from "../../../hooks/useFormAgregar";
import { validacionesAgregar } from "../../../helper/validacionesAgregar";
import { Container, Subtitulos, LabelForm, Error, Label, FileText, SeccionButtons } from "./elements";
import ModalProgramar from "../../../components/Aplicacion/ModalProgramar/ModalProgramar";

let date = moment().format()

export const Agregar = () => {
    const { width, height } = useWindowDimensions();


    const initialForm = {
        date,
        autor: "",
        title: "",
        description: "",
        img: "",
        duration: "",
        setDate: "",
        setTime: ""
    };

    const { form, errors, handleChange, handleSubmit, handleProgramar, handleClick, img } = useFormAgregar(
        initialForm,
        validacionesAgregar
    );

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>
            <div
                style={{
                    width: width * 0.85,
                    height: height * 0.92,
                    left: width * 0.15,
                    top: height * 0.062,
                    position: "absolute",
                }}
            >
                <Container
                    style={{
                        height: height * 0.9,
                        width: width * 0.6,
                        paddingLeft: width * 0.04,
                    }}
                >
                    <Subtitulos>Agregar notificación</Subtitulos>

                    <form onSubmit={handleSubmit}>
                        <OptionContainer>
                            <LabelForm>Fecha</LabelForm>

                            <Input
                                type="text"
                                name="date"
                                value={form.date.slice(0, 10).replaceAll("-", "/")}
                                readOnly
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>Autor *</LabelForm>

                            <Select
                                defaultValue={"DEFAULT"}
                                onChange={(e) => handleChange(e)}
                                name="autor"
                                value={form.autor}
                            >
                                <option
                                    value="DEFAULT"
                                    disabled
                                >
                                    Selecciona
                                </option>

                                <option>Juan Ignacio Rava</option>
                                <option>Yamila Char</option>
                                <option>Marina Tirinato</option>
                                <option>Agostina Linares</option>
                            </Select>
                            {errors.autor && (
                                <Error>{errors.autor}</Error>
                            )}
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>Titulo *</LabelForm>

                            <Input
                                type="text"
                                name="title"
                                value={form.title}
                                onChange={(e) => handleChange(e)}
                            />

                            {errors.title && (
                                <Error>{errors.title}</Error>
                            )}
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>Texto</LabelForm>

                            <Input
                                type="text"
                                name="description"
                                value={form.description}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainer>

                        <OptionContainer>
                            <LabelForm>Imagen</LabelForm>

                            <div
                                style={{ display: "flex", }}
                            >
                                <Label htmlFor="imagen">
                                    <Icon
                                        icon="ei:image"
                                        style={{
                                            color: '#00386E',
                                            fontSize: '2.5em',
                                            marginRight: '0.3em',
                                            marginLeft: '0.3em'
                                        }}
                                    />
                                    Seleccionar archivo
                                </Label>
                                <Input
                                    type="file"
                                    accept="image/*"
                                    id="imagen"
                                    name="img"
                                    value={form.img}
                                    onChange={(e) => { handleClick(e); handleChange(e) }}
                                />
                                {
                                    form.img ? <FileText>{form.img?.substring(form.img.lastIndexOf("\\")).slice(1)}</FileText> :
                                        <FileText>Ningun archivo selec.</FileText>
                                }
                            </div>
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>
                                ¿Cuánto tiempo se va a ver? *
                            </LabelForm>

                            <Select
                                defaultValue={"DEFAULT"}
                                name="duration"
                                value={form.duration}
                                onChange={(e) => handleChange(e)}
                            >
                                <option
                                    value="DEFAULT"
                                    disabled
                                >
                                    Selecciona
                                </option>

                                <option>24 hs</option>
                                <option>7 días</option>
                                <option>14 días</option>
                                <option>28 días</option>
                            </Select>

                            {errors.duration && (
                                <Error>{errors.duration}</Error>
                            )}
                        </OptionContainer>
                        <SeccionButtons>
                            <Button
                                style={{
                                    marginRight: "16px",
                                    backgroundColor: "#004B6F",
                                    fontSize: width > 1600 ? "0.75em" : "0.7em",
                                    padding: width > 1600 ? "0.8em 3em" : "0.7em 2em",
                                }}
                                // className={s.button}
                                type="submit"
                                variant="contained"
                                size="medium"
                            >
                                GUARDAR
                            </Button>


                            <ModalProgramar handleClose={handleClose} handleProgramar={handleProgramar} formAgregar={form} img={img} />



                        </SeccionButtons>
                    </form>
                </Container>
                <div
                    style={{
                        // width: width * 0.20,
                        height: height * 0.935,
                        //  backgroundColor: "orange",
                        alignSelf: "flex-end",
                        position: "absolute",
                        right: 0,
                        boxShadow: "0px 4px 4px rgba(206, 204, 204, 0.44)",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            alignContent: "center",
                        }}
                    >
                        <SideCalendar />
                    </div>
                </div>
            </div>
        </>
    );
};
