import styled from "styled-components";
export const Container = styled.div`
    border-radius: 0.3em;
	min-width: 85px;
`;

export const Text = styled.p`
    font-weight: 300;
`;

export const s = {
    link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    justifyContent: "center",
    height: "25px",
    width: "90px",
    },
    icon: {
        fontSize: "10px", 
        marginTop: "2px",
        marginLeft: "2px",
    }
}
