import React from "react";
import { Button } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useDocumentos } from "../../hooks/useDocumentos";
import { Box, SeccionDatos, MiniBox, Label, Sub, ColumnaDatos, Datos, ColumnaError, WarningBox, WarningText, Logo, LogoAdjuntar, Input, Subtitulo, SeccionSubidos, SeccionAdjuntos, SeccionButtons, Icon, Lista, Item, LinkBox, ViewBox } from "./elements";

export const Documentos = () => {
    let idFicha;
    let url = window.location.href;
    idFicha = url.substring(url.lastIndexOf("/"));
    idFicha = idFicha.slice(1);

    const {
        handleCuit,
        handleCuil,
        handleSo,
        handleLt,
        handleRol,
        handleChange,
        handleSubmit,
        handleClick,
        show,
        cuitUrl,
        cuilUrl,
        soUrl,
        ltUrl,
        rolUrl,
        isCuitPicked,
        isCuilPicked,
        isSoPicked,
        isLtPicked,
        isRolPicked,
        tipoIdFiscal,
        idFiscal,
        nombre,
        fechaDeNacimiento,
        warning,
    } = useDocumentos(idFicha);
    console.log(idFicha)

    const { width, height } = useWindowDimensions();

    return (
        <div>
            <form
                style={{
                    left: width * 0.165,
                    width: width * 0.82,
                    height: height * 0.91,
                    position: "absolute",
                    top: "3em",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1em",
                    marginBottom: 0,
                    padding: 0,
                    overflow: "hidden",
                }}
                onSubmit={handleSubmit}
            >
                <Subtitulo>DOCUMENTOS</Subtitulo>
                <Box>
                    <SeccionDatos>
                        <ColumnaDatos>
                            <Datos>
                                NOMBRE: {nombre.toLocaleUpperCase()}
                            </Datos>
                            <Datos>
                                {tipoIdFiscal
                                    ? `${tipoIdFiscal} : ${idFiscal}`
                                    : ""}
                            </Datos>
                        </ColumnaDatos>
                        <ColumnaDatos>
                            <Datos>
                                {fechaDeNacimiento
                                    ? `NACIMIENTO: ${fechaDeNacimiento}`
                                    : ""}
                            </Datos>
                            {warning ? (
                                <Datos>{warning}</Datos>
                            ) : null}
                        </ColumnaDatos>
                        {tipoIdFiscal && tipoIdFiscal !== "CUIT/CUIL" ? (
                            <ColumnaError>
                                <WarningBox>
                                    <WarningText>
                                        NO SE PUEDEN REALIZAR LAS VALIDACIONES
                                        DE DATOS
                                    </WarningText>
                                </WarningBox>
                            </ColumnaError>
                        ) : null}
                    </SeccionDatos>
                    {show ? (
                        <>
                            <SeccionSubidos>
                                <Button
                                    onClick={handleClick}
                                    style={{width: "1.3em",
                                        float: "right",
                                        marginRight: "1.5em",}}
                                >
                                    <Icon
                                        src={require("../../images/documentos/editar-icon.png")}
                                        alt="icon"
                                    />
                                </Button>
                                <Sub>
                                    Se adjuntaron estos documentos:
                                </Sub>
                                <Lista>
                                    {isCuilPicked && (
                                        <Item>
                                            <Logo
                                                src={require("../../images/documentos/check-icon.png")}
                                                alt="logo"
                                            />
                                            CUIL
                                        </Item>
                                    )}
                                    {isCuitPicked && (
                                        <Item>
                                            <Logo
                                                src={require("../../images/documentos/check-icon.png")}
                                                alt="logo"
                                            />
                                            CUIT
                                        </Item>
                                    )}
                                    {isSoPicked && (
                                        <Item>
                                            <Logo
                                                src={require("../../images/documentos/check-icon.png")}
                                                alt="logo"
                                            />
                                            SO
                                        </Item>
                                    )}
                                    {isLtPicked && (
                                        <Item>
                                            <Logo
                                                src={require("../../images/documentos/check-icon.png")}
                                                alt="logo"
                                            />
                                            LT
                                        </Item>
                                    )}
                                    {isRolPicked && (
                                        <Item>
                                            <Logo
                                                src={require("../../images/documentos/check-icon.png")}
                                                alt="logo"
                                            />
                                            ROL
                                        </Item>
                                    )}
                                </Lista>
                            </SeccionSubidos>
                        </>
                    ) : (
                        <>
                            <SeccionAdjuntos>
                                <MiniBox>
                                    <a
                                        href="https://seti.afip.gob.ar/padron-puc-constancia-internet/ConsultaConstanciaAction.do"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkBox>
                                            <Logo
                                                src={require("../../images/documentos/link-icon.png")}
                                                alt="logo"
                                            />
                                        </LinkBox>
                                    </a>
                                    {isCuitPicked ? (
                                        <>
                                            <Label
                                                htmlFor="cuit"
                                            >
                                                <LogoAdjuntar 
                                                    src={require("../../images/documentos/adjuntar-dorado.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR CUIT
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="cuit"
                                                onChange={handleCuit}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Label
                                                htmlFor="cuit"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR CUIT
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="cuit"
                                                onChange={handleCuit}
                                            />
                                        </>
                                    )}
                                    {cuitUrl && (
                                        <ViewBox>
                                            <a
                                                href={cuitUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                }}
                                            >
                                                VER
                                            </a>
                                        </ViewBox>
                                    )}
                                </MiniBox>
                                <MiniBox>
                                    <a
                                        href="https://www.anses.gob.ar/consulta/constancia-de-cuil"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkBox>
                                            <Logo
                                                src={require("../../images/documentos/link-icon.png")}
                                                alt="logo"
                                            />
                                        </LinkBox>
                                    </a>
                                    {isCuilPicked ? (
                                        <>
                                            <Label
                                                htmlFor="cuil"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar-dorado.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR CUIL
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="cuil"
                                                onChange={handleCuil}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Label
                                                htmlFor="cuil"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR CUIL
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="cuil"
                                                onChange={handleCuil}
                                            />
                                        </>
                                    )}
                                    {cuilUrl && (
                                        <ViewBox>
                                            <a
                                                href={cuilUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                }}
                                            >
                                                VER
                                            </a>
                                        </ViewBox>
                                    )}
                                </MiniBox>
                                <MiniBox>
                                    <a
                                        href="https://sro.uif.gob.ar/SROAPP/#/consultaRegistracion"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkBox>
                                            <Logo
                                                src={require("../../images/documentos/link-icon.png")}
                                                alt="logo"
                                            />
                                        </LinkBox>
                                    </a>
                                    {isSoPicked ? (
                                        <>
                                            <Label
                                                htmlFor="so"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar-dorado.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR SO
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="so"
                                                onChange={handleSo}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Label
                                                htmlFor="so"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR SO
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="so"
                                                onChange={handleSo}
                                            />
                                        </>
                                    )}
                                    {soUrl && (
                                        <ViewBox>
                                            <a
                                                href={soUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                }}
                                            >
                                                VER
                                            </a>
                                        </ViewBox>
                                    )}
                                </MiniBox>
                                <MiniBox>
                                    <a
                                        href="https://repet.jus.gob.ar/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkBox>
                                            <Logo
                                                src={require("../../images/documentos/link-icon.png")}
                                                alt="logo"
                                            />
                                        </LinkBox>
                                    </a>
                                    {isLtPicked ? (
                                        <>
                                            <Label
                                                htmlFor="lt"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar-dorado.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR LT
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="lt"
                                                onChange={handleLt}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Label
                                                htmlFor="lt"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR LT
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="lt"
                                                onChange={handleLt}
                                            />
                                        </>
                                    )}
                                    {ltUrl && (
                                        <ViewBox>
                                            <a
                                                href={ltUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                }}
                                            >
                                                VER
                                            </a>
                                        </ViewBox>
                                    )}
                                </MiniBox>
                                <MiniBox>
                                    <a
                                        href="https://clasico.rava.com/admin/rol/pedir.php?cuit="
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkBox>
                                            <Logo
                                                src={require("../../images/documentos/link-icon.png")}
                                                alt="logo"
                                            />
                                        </LinkBox>
                                    </a>
                                    {isRolPicked ? (
                                        <>
                                            <Label
                                                htmlFor="rol"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar-dorado.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR ROL
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="rol"
                                                onChange={handleRol}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Label
                                                htmlFor="rol"
                                            >
                                                <LogoAdjuntar
                                                    src={require("../../images/documentos/adjuntar.png")}
                                                    alt="logo"
                                                />
                                                ADJUNTAR ROL
                                            </Label>
                                            <Input
                                                type="file"
                                                accept="application/pdf, image/*"
                                                id="rol"
                                                onChange={handleRol}
                                            />
                                        </>
                                    )}
                                    {rolUrl && (
                                        <ViewBox>
                                            <a
                                                href={rolUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                }}
                                            >
                                                VER
                                            </a>
                                        </ViewBox>
                                    )}
                                </MiniBox>
                            </SeccionAdjuntos>
                            <SeccionButtons>
                                <Button
                                    style={{
                                        marginRight: "16px",
                                        backgroundColor: "#004B6F",
                                    }}
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                >
                                    ARCHIVAR
                                </Button>
                                <Button
                                    style={{
                                        marginRight: "16px",
                                        backgroundColor: "#C9C9C9",
                                    }}
                                    onClick={handleChange}
                                    variant="contained"
                                    size="large"
                                >
                                    CANCELAR
                                </Button>
                            </SeccionButtons>
                        </>
                    )}
                </Box>
            </form>
        </div>
    );
};
