import React, { useState } from "react";
import { read, utils, writeFile } from "xlsx";
import { operarCarteras, operarCarterasByma } from "../api/operar_cartera";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { OperarCarteraBymaResponse } from "../interfaces/interfaceOperarCarteras/OperarCarterasByma";

export interface Data {
    CANTIDAD: number;
    CLIENTE: string;
    ESPECIE: string;
    OPERACIÓN: string;
    PRECIO: number;
    TIPO: string;
}

export interface Resultado {
    cantidad: number;
    comi: string;
    espe: string;
    estado: JSX.Element;
    oper: string;
    precio: number;
    ticker: string;
    tipo: string;
}

export interface Operaciones {
    OrderQty: number;
    Account: string;
    SecurityID: string;
    Symbol: string;
    Side: string;
    Price: number;
    SecurityType: string;
    SettlType: string;
}

interface Etapas {
    Inicial: string;
    Confirmacion: string;
    Final: string;
}

const etapas: Etapas = {
    Inicial: "Inicial",
    Confirmacion: "Confirmacion",
    Final: "Final",
};

export const useOperarCartera = () => {
	const [name, setName] = useState("");
	const [data, setData] = useState<Data[]>([]);
	const [etapa, setEtapa] = useState<string>(etapas.Inicial);
	const [results, setResults] = useState<Resultado[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const navigate = useNavigate();

	const filtData = data.map((e) => ({
		cantidad: e.CANTIDAD,
		cliente: e.CLIENTE,
		especie: e.ESPECIE,
		operacion: e.OPERACIÓN,
		precio: e.PRECIO,
		tipo: e.TIPO,
	}));

	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files) {
			setName(e.target.files[0].name);
			const reader = new FileReader();
			reader.onload = (e) => {
				if(e.target){
                    const data = e.target.result;
                    const workbook = read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
					const json: Data[] = utils.sheet_to_json(worksheet);
                    setData(json);
                }
			};
			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};

	const handleContinuar = async () => {
		setLoading(true);
		if (etapa === etapas.Inicial) {
			setEtapa(etapas.Confirmacion);
		}
		if (etapa === etapas.Confirmacion) {
			await realizarOperacion();
		}
		if (etapa === etapas.Final) {
			setData([]);
			setName("");
			setEtapa(etapas.Inicial);
			navigate("/ordenes-carteras");
		}
		setLoading(false);
	};

	const getTicker = (esp: string): string => {
		return esp.split("-")[0];
	};

	const getSettlType = (esp: string): string => {
		const plazo = esp.split("-")[1];
		return plazo[plazo.length - 1];
	};

	const realizarOperacion = async () => {
		try {
			const operaciones: Operaciones[] = data.map((e) => ({
				OrderQty: e.CANTIDAD,
				Account: e.CLIENTE,
				SecurityID: e.ESPECIE,
				Symbol: getTicker(e.ESPECIE),
				Side: e.OPERACIÓN,
				Price: e.PRECIO,
				SecurityType: e.TIPO,
				SettlType: getSettlType(e.ESPECIE),
			}));
	
			// const operaciones = data.map((e) => ({
			// 	comitente: e.CLIENTE,
			// 	tipoOperacion: e.OPERACIÓN === "C" ? "compra" : "venta",
			// 	especie: e.ESPECIE,
			// 	ticker: getTicker(e.ESPECIE),
			// 	cantidad: e.CANTIDAD,
			// 	precio: e.PRECIO,
			// 	// SecurityType: e.TIPO,
			// 	// SettlType: getSettlType(e.ESPECIE),
			// }));
	
	
			const res: AxiosResponse<OperarCarteraBymaResponse> =  await operarCarterasByma(operaciones)
	
			const result = res.data.body.operaciones.map((e) => ({
				cantidad: e.orden.OrderQty,
				comi: e.orden.Account,
				espe: e.orden.SecurityID,
				estado: e.estado === 0 ? <p style={{color: 'red'}}>No realizada</p> : <p style={{color: 'green'}}>Realizada</p>,
				oper: e.orden.Side === '1' ? 'C' : 'V',
				precio: e.orden.Price,
				ticker: e.orden.Symbol,
				tipo: e.orden.SecurityType,
			}));
	
			// const res = await operarCarteras(operaciones);
	
			console.log(res.data.body.operaciones);
	
			// const result = res.data.body.operaciones.map((e) => ({
			// 	// cantidad: e.cantidad,
			// 	// comi: e.comi,
			// 	// espe: e.espe,
			// 	// estado:
			// 	// 	e.estado === "No realizada" ? (
			// 	// 		<p style={{ color: "red" }}>{e.estado}</p>
			// 	// 	) : (
			// 	// 		<p style={{ color: "green" }}>{e.estado}</p>
			// 	// 	),
			// 	// oper: e.oper === "compra" ? "C" : "V",
			// 	// precio: e.precio,
			// 	// ticker: e.ticker,
			// 	// detalle: e.detalle,
			// 	cantidad: e.cantidad,
			// 	comi: e.comi,
			// 	espe: e.espe,
			// 	estado:
			// 		e.estado === "No realizada" ? (
			// 			<p style={{ color: "red" }}>{e.estado}</p>
			// 		) : (
			// 			<p style={{ color: "green" }}>{e.estado}</p>
			// 		),
			// 	oper: e.oper === "compra" ? "C" : "V",
			// 	precio: e.precio,
			// 	ticker: e.ticker,
			// 	detalle: e.detalle,
			// }));
			setResults(result);
			setEtapa(etapas.Final);
		} catch (error) {
			console.log(error)
			setError('Ocurrió un error')
		}
	};

	return {
		name,
		data,
		filtData,
		etapa,
		etapas,
		handleUpload,
		handleContinuar,
		results,
        loading,
		error,
	};
};
