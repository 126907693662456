import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Input, OptionContainer } from "../../../views/Aplicacion/aplicacionElements";
import { setNotification } from "../../../api/notificaciones_app";
import { Form } from "../../../hooks/useFormAgregar";
import { Label, InputsContainer } from "./elements";

interface FormProgramar {
    setDate: string,
    setTime: string,
}

interface Errors {
    setDate?: string,
    setTime?: string,
}

export const validacionesProgramar = (form : FormProgramar) => {
    let errors: Errors = {};

    if (!form.setDate) {
        errors.setDate = "Este campo es requerido";
    }
    if (!form.setTime) {
        errors.setTime = "Este campo es requerido";
    }

    return errors;
};



const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	height: 260,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 4,
};

interface Props {
    handleClose: () => void, 
    handleProgramar: ({ handleClose, handleOpen }: any) => void, 
    formAgregar: Form, 
    img: any,
}

export default function ModalProgramar({handleClose, handleProgramar, formAgregar, img} : Props) {
	
    const { width, height } = useWindowDimensions();

    const [errors, setErrors] = useState<Errors>({});

    const initialForm : FormProgramar = {
        setDate: "",
        setTime: ""
    }

    const [form, setForm] = useState(initialForm)

    const [open, setOpen] = React.useState(false);

	const handleOpen = () => setOpen(true);
	const handleCerrar = () => {
		setOpen(false);
		handleClose();
	};

    const handleSubmitProgramar = async () => {

        let errorsVariableEstatica = validacionesProgramar(form);
        setErrors(validacionesProgramar(form));

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorsVariableEstatica).length === 0 
            ){
                try {
                    await setNotification(img, {
                        title: formAgregar.title,
                        autor: formAgregar.autor,
                        date: formAgregar.date,
                        description: formAgregar.description,
                        duration: formAgregar.duration,
                        img: formAgregar.img,
                        setDate: form.setDate,
                        setTime: form.setTime,
                    }).then(
                        function (value) {
                            // Success!
                            window.location.reload();
                        },
                        function (error) {
                            console.log(error.response);
                        }

                    );
                } catch (error) {
                    console.log(error)
                }
            }
        else{ 
            console.log("errores", errors)
        }
        
    }

    const handleChange = async (e : React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value,
        })
    }


    
    const handleClick = async () => {
        
        try {
            handleProgramar({handleClose, handleOpen});
        } catch (error) {
            console.log(error);
        }
    }

	return (
        <div
            style={{
                display: "flex",
            }}
        >
            <Button
                onClick={handleClick}
                variant="contained"
                size="medium"
                style={{
                    marginRight: "16px",
                    backgroundColor: "#004B6F",
                    fontSize: width > 1600 ? "0.75em" : "0.7em",
                    padding: width > 1600 ? "0.8em 3em" : "0.7em 2em",
                }}
            >
                PROGRAMAR
            </Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            margin: "0.5em 0 0 0",
                        }}
                    >
                        PROGRAMAR NOTIFICACIÓN
                    </Typography>
                    <form>
                        <InputsContainer>
                            <div style={{ width: "90%" }}>
                                <OptionContainer>
                                    <Label>Fecha</Label>
                                    <Input
                                        type="date"
                                        name="setDate"
                                        min={formAgregar.date.slice(0, 10)}
                                        value={form.setDate}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </OptionContainer>
                            </div>
                            <div style={{ width: "90%" }}>
                                <OptionContainer>
                                    <Label>Hora</Label>
                                    <Input
                                        type="time"
                                        name="setTime"
                                        value={form.setTime}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </OptionContainer>
                            </div>
                        </InputsContainer>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                variant="contained"
                                size="medium"
                                style={{
                                    marginRight: "16px",
                                    backgroundColor: "#004B6F",
                                    fontSize: width > 1600 ? "0.75em" : "0.7em",
                                    padding:
                                        width > 1600
                                            ? "0.8em 3em"
                                            : "0.7em 2em",
                                }}
                                onClick={handleSubmitProgramar}
                            >
                                PROGRAMAR
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
