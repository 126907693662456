import React, { createContext, useState } from 'react'

export const NotificacionesContext = createContext({});

export const NotificacionesProvider = ({ children }) => {
    const [notificaciones, setNotificaciones] = useState([]);

    return (
        <NotificacionesContext.Provider
            value={{
                notificaciones, 
                setNotificaciones
            }}
        >
            {children}
        </NotificacionesContext.Provider>
    );
};
