import React from 'react'
import { date } from '../../../helper/calcularDateTenencia';
import { formatName } from '../../../helper/formatearNombreTenencia';
import { Container, CuentaInfo, FechaTenencia, Name } from './elements';
// import s from "./CardInfoUser.module.css";

interface Props {
    nroCuenta: string;
    userName: string;
}

export const CardInfoUser = ({ nroCuenta, userName }: Props) => {


    return (
        <Container>
            <div style={{ display: 'flex' }}>
                <CuentaInfo>
                    Cuenta N° {nroCuenta}
                </CuentaInfo>
                <Name>
                    {formatName(userName)}
                </Name>
            </div>
            <FechaTenencia>
                Tenencia hasta {date()}
            </FechaTenencia>
        </Container>
    )
}
