import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { TableColumn } from 'react-data-table-component';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { StyledMenu } from "../../views/OrdenesCartera/OrdenesCartera";
import { roles } from "../../helper/roles";
import { baseURL } from "../../utils/Axios";
import { Checkbox } from "@material-ui/core";
import useTableTitulos from "../../hooks/useTableTitulos";
import { Button, CheckContainer, CheckText, CriterioContainer, DataBox, InputSearch, PlusIconContainer, PlusTexto, SearchButton, SearchContainer, Sub, SubContainer, TableContainer } from "./elements";
import { Dolares } from "../Dolares/Dolares";
import { Dividendos } from "../Dividendos/Dividendos";
import { CBU } from "../CBU/CBU";
import { Conciliaciones } from "../Conciliaciones/Conciliaciones";

// Tabla que se renderiza en las diferentes screens

interface Props {
    subtitulo: string,
    titularId?: string,
    columns: TableColumn<any>[],
}

export default function TableTitulos({ subtitulo, columns, titularId }: Props) {
    const { width, height } = useWindowDimensions();
    const {
        handleInput,
        data,
        paginationOpciones,
        loading,
        showAprobadasRechazadas,
        setShowAprobadasRechazadas,
        name,
    } = useTableTitulos({ subtitulo, titularId: titularId || '' });

    const customStyles = {
        tableWrapper: {
            style: {
                display: "table",
                minHeight: height * 0.60,
                maxHeight: "33em",
                // minWidh: "30em",
                maxWidth: "100%",
                backgroundColor: "white",
                color: "#004B6F",
                // borderRadius: "20px"
            },
        },

        header: {
            style: {
                minHeight: "56px",
            },
        },
        rows: {
            style: {
                minHeight: "38px", // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",

                backgroundColor: "rgba(142, 178, 221, 0.5)",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                whiteSpace: 'normal', // Permite que el texto se envuelva
                //wordWrap: 'break-word', // Asegura que las palabras largas se envuelvan
            },
        },
    };

    let altura = `${height * 0.85}px`;

    const urlPedidosDola = `${baseURL}/exportarDola`;
    const urlDolares = `${baseURL}/exportarDolares`;

    return (
        <div style={{ width: width * 0.84, height: height * 0.85, }}>
            <DataBox>
                <SubContainer>
                    <Sub>{subtitulo}</Sub>
                    {
                        (subtitulo === "PEDIDOS DOLARES" || subtitulo === "DOLARES") &&
                        <div style={{ marginRight: "0.3em" }}>
                            <Button>
                                <a href={subtitulo === "PEDIDOS DOLARES" ? urlPedidosDola : urlDolares} target="_blank" rel="noreferrer">
                                    <Icon
                                        icon="lucide:download"
                                        style={{ ...styles.logo, color: "#004B6F" }}
                                    />
                                </a>
                            </Button>
                        </div>
                    }
                </SubContainer>
                {
                    subtitulo === "PEDIDOS DOLARES" &&
                    <CheckContainer>
                        <Checkbox
                            inputProps={{
                                "aria-label": "controlled",
                            }}
                            onChange={() => setShowAprobadasRechazadas(!showAprobadasRechazadas)}
                            checked={showAprobadasRechazadas}
                            style={{
                                color: "#004B6F",
                                margin: 0,
                            }}
                            classes={{
                                checked: "Mui-checked",
                            }}
                        />
                        <CheckText>Mostrar aprobadas</CheckText>
                    </CheckContainer>
                }

                <SearchContainer>
                    <InputSearch
                        type="text"
                        placeholder="Buscar..."
                        onChange={(e) => handleInput(e)}
                        value={name}
                    />
                    <SearchButton
                        type="submit"
                    >
                        <img
                            src={require("../../images/tabla/search-icon.png")}
                            alt="logo"
                            style={{ width: "1em" }}
                        />
                    </SearchButton>
                </SearchContainer>
            </DataBox >

            {subtitulo === "CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS" && (
                <CriterioContainer>
                    <Link to={"/transferencias/criterio"} style={{...styles.links, marginRight: "0.8em",}}>
                        <PlusIconContainer>
                            <Icon
                                icon="ph:plus-bold"
                                style={styles.plusIcon}
                            />
                            <PlusTexto>
                                Agregar nuevo criterio
                            </PlusTexto>
                        </PlusIconContainer>
                    </Link>
                </CriterioContainer>
            )}


            <TableContainer style={{height: height * 0.82, paddingTop: height * 0.15,}}>
                {
                    subtitulo === "DOLARES" &&
                    <Dolares />
                }
                {
                    subtitulo === "DIVIDENDOS" &&
                    <Dividendos />
                }
                {
                    subtitulo === "CBUs" &&
                    <CBU />
                }
                {
                    subtitulo === "CONCILIACIONES" &&
                    <Conciliaciones />
                }
                <DataTable
                    columns={columns}
                    data={data!}
                    pagination
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
                    paginationComponentOptions={paginationOpciones}
                    fixedHeader
                    fixedHeaderScrollHeight={altura}
                    // minHeight={altura}
                    customStyles={customStyles}
                    noDataComponent={
                        loading ? (
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <CircularProgress style={{ color: "#004B6F" }} />
                            </Box>
                        ) : (
                            <>No se encontró ningún elemento</>
                        )
                    }
                    responsive
                    keyField="id"
                />

            </TableContainer>
        </div>
    );
}


const styles = {
    links: {
        textDecoration: "none",
        cursor: "pointer",
        marginRight: "2em",
        marginTop: "0.25em",
    },
    menu: {
        display: "flex",
        backgroundColor: "white",
        height: "2.2em",
        borderRadius: "14px",
        padding: 0,
        margin: 0,
    },
    listItem: {
        display: "flex",
        padding: "0.1em 0.5em 0.1em 0.5em",
        margin: 0,
        fontSize: "0.9em",
        cursor: "pointer",
    },
    menuItem: {
        fontSize: "0.9em",
        backgroundColor: "white", 
        color: "rgba(0, 75, 111, 0.75)",  
    },
    plusIcon: {
        width: "1.5em",
        height: "1.5em",
        marginRight: "0.3em",
        color: "#004B6F",
    },
    logo: {
        fontSize: "1.7em",
        color: "rgba(85, 86, 88, 0.75)",
        margin: "0.2em",
    },
}