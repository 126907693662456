import { useState } from "react";
import Button from "@mui/material/Button";
import ModalRenta from "../../ModalRenta/ModalRenta";

interface Props {
    ticker: string,
    cliente: string,
    id: string,
    cantidadPedido: string | number
    origen: string | undefined
    idWebVieja?: string;
    comentariosWebVieja?: string;
    cbuTransferencia?: string;
}

export const DetallePedidosDolares = ({ ticker, cliente, id, cantidadPedido, origen, idWebVieja, comentariosWebVieja, cbuTransferencia }: Props) => {

    // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    // const open = Boolean(anchorEl);

    const [openModalPedidoDolar, setOpenModalPedidoDolar] = useState<boolean>(false);

    const handleOpenModalPedidoDolar = () => {
        setOpenModalPedidoDolar(true);
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                // aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                onClick={() => handleOpenModalPedidoDolar()}
                variant="contained"
                size="small"
                style={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    height: "25px",
                    width: "110px",
                    background: "transparent",
                    color: "#004B6FBF"
                }}
            >
                Ver
            </Button>

            <ModalRenta
                open={openModalPedidoDolar}
                setOpen={setOpenModalPedidoDolar}
                id={id}
                cliente={cliente}
                ticker={ticker}
                origen={origen}
                cantidadPedido={cantidadPedido}
                idWebVieja={idWebVieja}
                comentariosWebVieja={comentariosWebVieja}
                cbuTransferencia={cbuTransferencia}
            />
        </div>
    )
}
