import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getInformeById } from "../../api/subsanados";
import { editarFichaInformada } from "../../api/informados";
import { informar } from "../../api/informados";
import { ButtonsContainer, ContainerMotivo, ContainerMotivos, Desc, SpanEtapa, SpanMotivoTitulo, TituloEdit } from "./elements";

const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 520,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 4,
};
const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
};

//Mostrar los motivos y permitir editarlos según el rol

interface Props {
	id: string,
	handleClose: () => void,
	_motivos?: boolean,
	informados?: boolean,
	subsanados?: boolean,
}

interface Motivo {
    texto: string,
    _id?: string,
	error?: string,
	etapa?: string,
}

export default function ModalMotivos({id, handleClose, _motivos, informados, subsanados} : Props) {
	const [open, setOpen] = React.useState<boolean>(false);
	const [motivos, setMotivos] = React.useState<Motivo[]>([]);
	const [textoMotivosEdit, setTextoMotivosEdit] = React.useState<string[]>([]);
	const [editTextOn, setEditTextOn] = React.useState<boolean[]>([]);

	useEffect(() => {
		getMotivos();
	}, []);

	useEffect(() => {
		setTextoMotivos();
		setEditText();
	}, [motivos]);

	const handleOpen = () => setOpen(true);
	const handleCerrar = () => {
		setOpen(false);
		handleClose();
	};
	const submit = async () => {
		//llamado a api
		let reqCambioTexto : Motivo;
		try {
			for (let i = 0; i < textoMotivosEdit.length; i++) {
				if (editTextOn[i]) {
					//change motivos[i].text
					reqCambioTexto = motivos[i];
					reqCambioTexto.texto = textoMotivosEdit[i];
					delete reqCambioTexto._id;
					let resp = await editarFichaInformada(
						id,
						reqCambioTexto
					);
				}
			}
			let resp = await informar(id);
			setOpen(false);
			handleClose();
			window.location.reload();
		} catch (error) {
			console.log(error);
		}
	};
	async function getMotivos() {
		let resp = await getInformeById(id);
		if (resp.data.body.informe !== null) {
			setMotivos(resp.data.body.informe.info);
		}
	}
	function setTextoMotivos() {
		let textoMotivos: string[] = []
		motivos.forEach((motivo) => {
			textoMotivos.push(motivo.texto);
		});
		setTextoMotivosEdit(textoMotivos);
	}
	function setEditText() {
		let editText = [];
		let idx = 0;
		while (idx < motivos.length) {
			idx++;
			editText.push(false);
		}
		setEditTextOn(editText);
	}
	function editText( index: number) {
		let arr = editTextOn;
		arr[index]  = true;
		setEditTextOn([...arr]);
	}

	const renderEditText = (motivo : Motivo, index : number) => {
		if (editTextOn[index]) {
			return (
				<TextField
					id="filled-basic"
					variant="filled"
					defaultValue={motivo.texto}
					multiline
					onChange={(e) => {
						let arr = textoMotivosEdit;
						arr[index] = e.target.value;
						setTextoMotivosEdit(arr);
					}}
				/>
			);
		} else {
			return <Desc>{motivo.texto}</Desc>;
		}
	};

	return (
        <div>
            <Button
                onClick={handleOpen}
				{...(!_motivos 
					&& { variant: "contained",  size:"small"})}
                style={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    height: "25px",
					width: "90px",
                    background: _motivos ? "inherit" : informados ? "#C4FFC2" : subsanados ? "#FBC8E0" : "#FAC6B5",
                    color: _motivos ? "#004c6fd3" : informados ? "#369F34" : subsanados ? "#FF3093" : "#F6460E",
                }}
            >
                {_motivos ? "Motivos" : informados ? "Informado" : subsanados ? "Subsanada" : "Informar"}
            </Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 300,
                            margin: "0 auto",
                            marginBottom: "8px",
                        }}
                    >
                        {informados || _motivos || subsanados ? "MOTIVOS" : "INFORMAR MOTIVOS"}
                    </Typography>
                    <ContainerMotivos
                        style={
                            motivos.length > 2 ? { overflow: "scroll" } : undefined
                        }
                    >
                        {motivos.map((motivo, index) => (
                            <ContainerMotivo key={index}>
                                <TituloEdit>
                                    <SpanMotivoTitulo>
                                        {"Motivo " + (index + 1)}
                                    </SpanMotivoTitulo>
                                    {!informados && !_motivos && !subsanados && (
                                        <IconButton
                                            style={{
                                                color: "#00386E",
												fontSize: "small",
                                            }}
                                            aria-label="add to shopping cart"
                                            onClick={() => editText(index)}
                                        >
                                            <EditOutlinedIcon />
                                        </IconButton>
                                    )}
                                </TituloEdit>
                                <SpanEtapa>
                                    {motivo.etapa}
                                </SpanEtapa>
                                <SpanEtapa>
                                    {motivo.error}
                                </SpanEtapa>
                                {renderEditText(motivo, index)}
                                {/* <span className={s.descripcion}>
									{motivo.texto}
								</span> */}

                                {/* <TextField
									id="filled-basic"
									variant="filled"
									defaultValue={motivo.texto}
									multiline
									onChange={(e) => {
										let arr = textoMotivosEdit;
										arr[index] = e.target.value;
										setTextoMotivosEdit(arr);
									}}
								/> */}
                            </ContainerMotivo>
                        ))}
                    </ContainerMotivos>
                    {(!informados && !_motivos && !subsanados) && (
                        <ButtonsContainer>
                            <Button
                                style={{
                                    ...styleButtons,
                                    background: "#004B6F",
                                    marginRight: "16px",
                                }}
                                onClick={submit}
                                variant="contained"
                                size="small"
                            >
                                ENVIAR MAIL
                            </Button>
                            <Button
                                style={{
                                    ...styleButtons,
                                    background: "#C9C9C9",
                                }}
                                onClick={handleCerrar}
                                variant="contained"
                                size="small"
                            >
                                CANCELAR
                            </Button>
                        </ButtonsContainer>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
