import { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { getDatos, getNombre, getDeclaraciones } from "../api/datos";
import { adjuntarArchivo, getArchivos, putArchivo } from "../api/documentos";
import { useNavigate } from "react-router-dom";
import { checkAprobar } from "../api/aprobadas";
import { GetDatosResponse } from "../interfaces/interfacesDatos/getDatosInterface";
import { AxiosResponse } from "axios";
import { DatosPersonales } from "../interfaces/interfacesDatos/getNombreInterface";
import { DeclaracionesResponse } from "../interfaces/interfacesDatos/getDeclaracionesInterface";
import { GetArchivos } from "../interfaces/interfacesDocumentos/getDocumentosInterface";

// Hook de la pantalla Documentos

export const useDocumentos = (idFicha : string) => {
    const navigate = useNavigate();

    const [isCuitPicked, setIsCuitPicked] = useState<boolean>(false);
    const [isCuilPicked, setIsCuilPicked] = useState<boolean>(false);
    const [isSoPicked, setIsSoPicked] = useState<boolean>(false);
    const [isLtPicked, setIsLtPicked] = useState<boolean>(false);
    const [isRolPicked, setIsRolPicked] = useState<boolean>(false);
    const [cuitId, setCuitId] = useState<string>("");
    const [ciulId, setCuilId] = useState<string>("");
    const [soId, setSoId] = useState<string>("");
    const [ltId, setLtId] = useState<string>("");
    const [rolId, setRolId] = useState<string>("");
    const [cuitUrl, setCuitUrl] = useState<string>("");
    const [cuilUrl, setCuilUrl] = useState<string>("");
    const [soUrl, setSoUrl] = useState<string>("");
    const [ltUrl, setLtUrl] = useState<string>("");
    const [rolUrl, setRolUrl] = useState<string>("");

    const [show, setShow] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [warning, setWarning] = useState<string>('');

    const handleCuit = (e:ChangeEvent<HTMLInputElement>) => {
        setIsCuitPicked(true);
        let position : number = 1;
        if (isCuitPicked) {
            putArchivo(e.target.files![0], cuitId, idFicha, position);
        } else {
            adjuntarArchivo(e.target.files![0], position, idFicha);
        }
    };

    const handleCuil = (e:ChangeEvent<HTMLInputElement>) => {
        setIsCuilPicked(true);
        let position = 2;

        if (isCuilPicked) {
            putArchivo(e.target.files![0], ciulId, idFicha, position);
        } else {
            adjuntarArchivo(e.target.files![0], position, idFicha);
        }
    };

    const handleSo = (e:ChangeEvent<HTMLInputElement>) => {
        setIsSoPicked(true);
        let position = 3;

        if (isSoPicked) {
            putArchivo(e.target.files![0], soId, idFicha, position);
        } else {
            adjuntarArchivo(e.target.files![0], position, idFicha);
        }
    };

    const handleLt = (e:ChangeEvent<HTMLInputElement>) => {
        setIsLtPicked(true);
        let position = 4;

        if (isLtPicked) {
            putArchivo(e.target.files![0], ltId, idFicha, position);
        } else {
            adjuntarArchivo(e.target.files![0], position, idFicha);
        }
    };

    const handleRol = (e:ChangeEvent<HTMLInputElement>) => {
        setIsRolPicked(true);
        let position = 5;

        if (isRolPicked) {
            putArchivo(e.target.files![0], rolId, idFicha, position);
        } else {
            adjuntarArchivo(e.target.files![0], position, idFicha);
        }
    };

    const handleSubmit = (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setEdit(false);
        setShow(true);
        archivosPrevios(idFicha);
    };

    const handleClick = () => {
        setEdit(true);
    };

    const handleChange = () => {
        navigate("/aprobados");
    };

    useEffect(() => {
        datos(idFicha);

        if (edit) {
            setShow(false);
        } else {
            archivosPrevios(idFicha);
        }
    }, [edit]);

    const [tipoIdFiscal, setTipoIdFiscal] = useState("");
    const [idFiscal, setIdFiscal] = useState<string | number>("");
    const [nombre, setNombre] = useState("");
    const [fechaDeNacimiento, setFechaDeNacimiento] = useState("");
    const [declaracion, setDeclaracion] = useState("");

    const getFATCA = async () => {
        try {
            const resp: AxiosResponse<DeclaracionesResponse, any> = await getDeclaraciones(idFicha);
            let res = resp?.data?.body?.declaracion?.FATCA;
    
            return res;
        } catch (error) {
            console.log(error);
        }
    };

    const datos = async (idFicha : string) => {
        try {
            const datosFicha : AxiosResponse<GetDatosResponse, any> = await getDatos(idFicha);
            const nombreFicha : AxiosResponse<DatosPersonales, any>= await getNombre(idFicha);

            let res = datosFicha.data.body.datos;
            let nombre = nombreFicha.data.body.name + " " + nombreFicha.data.body.last_name;

            setNombre(nombre);
            setTipoIdFiscal(res?.TipoIdFiscal ? res.TipoIdFiscal : "");
            setIdFiscal(res?.NumeroIdFiscal ? res.NumeroIdFiscal : "");
            setFechaDeNacimiento(
                res?.FechaNacimiento
                    ? `${res.FechaNacimiento.slice(
                          8,
                          10
                      )}-${res.FechaNacimiento.slice(
                          5,
                          7
                      )}-${res.FechaNacimiento.slice(0, 4)}`
                    : ""
            );

            const FATCA = await getFATCA();

            let pep = "";
            let sujetoobligado = "No";
            let baselaft = "0";

            if (res?.TipoIdFiscal === "CUIT/CUIL") {
                const resp = await checkAprobar(res?.NumeroIdFiscal);
                pep = resp.data?.body.pep;
                sujetoobligado = resp.data?.body.sujetoobligado;
                baselaft = resp.data?.body.baselaft;
            }
            if (
                pep === "Si" ||
                sujetoobligado === "Si" ||
                res && Object.keys(res).length < 5 ||
                FATCA === "Si" ||
                baselaft !== "0"
            ) {
                let warn = [];

                baselaft !== "0" && warn.push(`TERRORISTA`);

                pep === "Si" && warn.push(`PEP`);

                sujetoobligado === "Si" && warn.push(`SUJETO OBLIGADO`);

                FATCA === "Si" && warn.push(`PERSONA ESTADOUNIDENSE`);

                let stringWarn : string = "";
                warn?.forEach((e, i) => stringWarn ? stringWarn = stringWarn + "/" + e : stringWarn = e)

                setWarning(stringWarn);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const archivosPrevios = async (idFicha : string) => {
        try {
            const resp: AxiosResponse<GetArchivos, any> = await getArchivos(idFicha);

            let res = resp.data.body.fileUserNew;

            const cuitRes = res.filter((e) => e.order === 1);
            setCuitUrl(cuitRes[0]?.url_temporal);
            let idCuit = cuitRes[0]?._id;
            idCuit && setCuitId(idCuit);
            let cuit = cuitRes[0]?.url_temporal;
            cuit && setIsCuitPicked(true);
            cuit && setShow(true);
    
            const cuilRes = res.filter((e) => e.order === 2);
            setCuilUrl(cuilRes[0]?.url_temporal);
            let idCuil = cuilRes[0]?._id;
            idCuil && setCuilId(idCuil);
            let cuil = cuilRes[0]?.url_temporal;
            cuil && setIsCuilPicked(true);
            cuil && setShow(true);

            const soRes = res.filter((e) => e.order === 3);
            setSoUrl(soRes[0]?.url_temporal);
            let idSo = soRes[0]?._id;
            idSo && setSoId(idSo);
            let so = soRes[0]?.url_temporal;
            so && setIsSoPicked(true);
            so && setShow(true);

            const ltRes = res.filter((e) => e.order === 4);
            setLtUrl(ltRes[0]?.url_temporal);
            let idLt = ltRes[0]?._id;
            idLt && setLtId(idLt);
            let lt = ltRes[0]?.url_temporal;
            lt && setIsLtPicked(true);
            lt && setShow(true);

            const rolRes = res.filter((e) => e.order === 5);
            setRolUrl(rolRes[0]?.url_temporal);
            let idRol = rolRes[0]?._id;
            idRol && setRolId(idRol);
            let rol = rolRes[0]?.url_temporal;
            rol && setIsRolPicked(true);
            rol && setShow(true);

        } catch (error) {
            console.log(error);
        }
    };

    return {
        handleCuit,
        handleCuil,
        handleSo,
        handleLt,
        handleRol,
        handleChange,
        handleSubmit,
        handleClick,
        show,
        cuitUrl,
        cuilUrl,
        soUrl,
        ltUrl,
        rolUrl,
        isCuitPicked,
        isCuilPicked,
        isSoPicked,
        isLtPicked,
        isRolPicked,
        tipoIdFiscal,
        idFiscal,
        nombre,
        fechaDeNacimiento,
        warning,
    };
};
