import { TableColumn } from 'react-data-table-component';
import { RowAprobado } from '../../interfaces/interfacesRows/rows';

const columnsAprobados: TableColumn<RowAprobado>[] = [
    {
        selector: (row: RowAprobado) => row.fecha,
        name: "Fecha",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.hora,
        name: "Hora",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.nombre,
        name: "Nombre y Apellido",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.ficha,
        name: "Ficha",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.manager,
        name: "Manager",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.email,
        name: "Email / CUIT",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.estado,
        name: "Estado",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.cuenta,
        name: "N° de cuenta",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
    {
        selector: (row: RowAprobado) => row.puntos,
        name: "",
        center: true,
        style: {
            color: '#004B6F',
        },
        conditionalCellStyles: [
            {
                when: (row: RowAprobado) => row.juridica,
                style: {
                    backgroundColor: '#A6C7F8',
                },
            },
        ],
    },
];

export {
    columnsAprobados,
};
