import React from "react";
import { Button } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useDividendos } from "../../hooks/useDividendos";
import { Box, Form, Input, Label, LogoAdjuntar, MiniBox, SeccionAdjuntos, SeccionButtons, } from "./elements";
import { useCBUs } from "../../hooks/useCBUs";

export const CBU = () => {
	const {
		handleSubmit,
		archive,
		setArchive,
	} = useCBUs();

	const { width, height } = useWindowDimensions();

	return (
		<Form
			style={{
				width: width * 0.84,
                height: height * 0.15,
			}}
			onSubmit={async (event) => {
				event.preventDefault(); // Evitar que el formulario se envíe automáticamente
				await handleSubmit(); // Esperar a que se complete la función handleSubmit
			}}
		>
			<Box>
				<>
					<SeccionAdjuntos>
								<MiniBox>
									{archive ? (
										<>
											<Label
												htmlFor='extractos-valores'
											>
												<LogoAdjuntar
													src={require(`../../images/documentos/adjuntar-dorado.png`)}
													alt="logo"
												/>
												EXTRACTO VALORES (CSV)
											</Label>
											<Input
												type="file"
												accept=".csv"
												id='extractos-valores'
												onChange={(e) => {
													if (e.target.files && e.target.files[0]) {
														setArchive(e.target.files[0]);
													}
												}}
											/>
										</>
									) : (
										<>
											<Label
												htmlFor='extractos-valores'
											>
												<LogoAdjuntar
													src={require("../../images/documentos/adjuntar.png")}
													alt="logo"
												/>
												EXTRACTO VALORES (CSV)
											</Label>
											<Input
												type="file"
												accept=".csv"
												id='extractos-valores'
												onChange={(e) => {
													if (e.target.files && e.target.files[0]) {
														setArchive(e.target.files[0]);
													}
												}}
											/>
										</>
									)}
								</MiniBox>
								<SeccionButtons>
						<Button
							style={{
								marginRight: "16px",
								backgroundColor: "#004B6F",
							}}
							type="submit"
							variant="contained"
							size="large"
						>
							PROCESAR
						</Button>
					</SeccionButtons>
					</SeccionAdjuntos>
				</>
			</Box>
		</Form>
	);
};
