import { useState } from "react";
import { getDividendos, cambiarEstadoRenta } from "../api/dividendos";
import { Checkbox } from "@material-ui/core";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { Dividendo, GetDividendosResponse } from "../interfaces/interfaceDividendos/getDividendosInterface";

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

interface DividendoRow extends Dividendo  {
    check: JSX.Element;
}

export const useEstadoRentasTable = ({userRol, setLoading}: Props) => {
	const [allRentas, setAllRentas] = useState<DividendoRow[]>([]);
	const [rentasFilt, setRentasFilt] = useState<DividendoRow[]>([]);

	const cambiarEstado = async (id: string, check: boolean) => {
        const resp = await cambiarEstadoRenta(id, !check);
        if (resp.status === 200) {
            await getAllRenta();
        }
    };

    const getAllRenta = async () => {
        let rentaData= [];
        try {
            if (
                userRol === roles.administrador ||
                userRol === roles.tesoreria
            ) {
                const res: AxiosResponse<GetDividendosResponse> = await getDividendos();

                if (res?.status === 200) {
                    rentaData = res?.data?.body;
                    let dataFiltrada: DividendoRow[] = [] as DividendoRow[];
                    // Agarra el primer elemento de Dividendos porque los demás tienen los mismos valores que se usan para mostrar en las tablas
                    rentaData.forEach((element) => {
                        element.dividendos[0].check = (
                            <Checkbox
                                inputProps={{
                                    "aria-label": "controlled",
                                }}
                                onChange={async () =>
                                    await cambiarEstado(
                                        element._id,
                                        element.check
                                    )
                                }
                                checked={element.check}
                                style={{
                                    color: "#004B6F",
                                    margin: 0,
                                }}
                                classes={{
                                    checked: "Mui-checked",
                                }}
                            />
                        );

                        dataFiltrada.push(element.dividendos[0].check && element.dividendos[0]);
                    });

                    setAllRentas(dataFiltrada);
                    setRentasFilt(dataFiltrada);
                    setLoading(false);
                }
            }
            !(rentaData?.length > 0) && setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

	// Funcion de filtro

	const filtRentas = (name: string) => {
		name = name.toLowerCase();

		let filtered = allRentas.filter((e) =>
			e.nombreEspecie.toLowerCase().includes(name)
		);

		setRentasFilt(filtered);

		filtered.length === 0 && setLoading(false);
	};

	return {
		rentasFilt,
		getAllRenta,
		filtRentas,
	};
};
