import { useContext, useEffect, useState } from "react";
import { DolaresContext } from "../context/dolaresContext/dolaresContext";
import { getDolares } from "../api/dolares";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { Dolar, GetDolaresResponse } from "../interfaces/interfaceDolares/getDolaresInterface";

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export const useDolaresTableHook = ({userRol, setLoading}: Props) => {
	const {dolares, actualizarDolares} = useContext(DolaresContext);
	const [dolaresFilt, setDolaresFilt] = useState<Dolar[]>([]);

	useEffect(() => {
		if(userRol === roles.administrador || userRol === roles.tesoreria){
			if (dolares.length !== 0) {
				setDolaresFilt(dolares);
			} else {
				getDolaresApi();
			}
		}
		dolares.length === 0 && setLoading(false);
	}, []);

	const getDolaresApi = async () => {
		try {
			const res: AxiosResponse<GetDolaresResponse> = await getDolares();
			if (res?.status === 200) {
				let dolar = res?.data?.body[0].dolares;
				actualizarDolares(dolar);
				setDolaresFilt(dolar);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const filtDolares = (name: string) => {
		if (name.length === 0) {
			setDolaresFilt(dolares);
		} else {
			let filtered = dolares.filter((e: Dolar) =>
				e["'Cliente Nombre del Cliente'"].toString().startsWith(name)
			);

			setDolaresFilt(filtered);
			filtered.length === 0 && setLoading(false);
		}
	};

	return {
		dolares,
		dolaresFilt,
		filtDolares,
	};
};
