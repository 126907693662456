import styled from "styled-components";

export const Label = styled.label`
	color: #00386E;
    font-size: 0.9em;
    margin-bottom: 0.3em;
`;

export const InputsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    margin: 2em 0 3em 0;
    padding: 0 0.5em 0 1.5em;

    @media screen and (min-width: 1600px) {
        margin: 2em 0 3em 0;
        padding: 0 0.5em 0 1.5em;
    }
`;