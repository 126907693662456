import React, { useEffect, useState, useContext } from "react";
import { getManagerById } from "../../../api/pendientes";
import { UserContext } from "../../../context/userContext/UserContexts";
import { Manager } from "../ManagerSelect/ManagerSelect"

// Muestra el nombre del manager en las screens Informadas, Aprobadas, Rechazadas y Subsanadas


interface Props {
	manager_id: string,
}

export const ManagerText = ({ manager_id } : Props) => {
	const [nombre, setNombre] = useState("");

	const { managers } = useContext(UserContext);
	const managersList: Manager[] = managers;

	useEffect(() => {
		let id = manager_id.toString();
		if (id.includes("Operador") || id.includes("ASIGNAR")) {
			setNombre(id);
		} else {
			const managerFound = managersList.find(
				(element : Manager) => element._id === id
			);
			if (managerFound !== null && managerFound !== undefined) {
				let completeName =
					managerFound.Codigo +
					" - " +
					capitalizarPalabras(managerFound.Nombre);
				setNombre(completeName);
			} else {
				setNombre("No hallado");
			}
		}
	}, [managersList]);

	function capitalizarPalabras(cadena : string) {
		// Dividir la cadena en palabras
		let palabras = cadena.split(" ");

		// Iterar sobre cada palabra
		for (let i = 0; i < palabras.length; i++) {
			// Convertir la primera letra a mayúscula y el resto a minúscula
			palabras[i] =
				palabras[i].charAt(0).toUpperCase() +
				palabras[i].slice(1).toLowerCase();
		}

		// Unir las palabras en una cadena nuevamente
		let resultado = palabras.join(" ");

		return resultado;
	}

	return (
		<div>
			<span>{nombre}</span>
		</div>
	);
};
