import { TableColumn } from 'react-data-table-component';
import { RowMovimientosHistorial } from '../../interfaces/interfacesRows/rows';

const columnsHistorial: TableColumn<RowMovimientosHistorial>[] = [
    {
        selector: (row: RowMovimientosHistorial) => row.fecha,
        name: "Fecha",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowMovimientosHistorial) => row.hora,
        name: "Hora",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowMovimientosHistorial) => row.usuario,
        name: "Usuario",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowMovimientosHistorial) => row.accion,
        name: "Acción",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowMovimientosHistorial) => row.cliente,
        name: "Cliente",
        center: true,
        style: {
            color: '#004B6F',
        },
    }
];

export {
    columnsHistorial,
};
