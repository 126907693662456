import { useContext, useEffect, useState } from "react";
import { DividendosContext } from "../context/dividendosContext/dividendosContext";
import { getDividendos } from "../api/dividendos";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { Dividendo, GetDividendosResponse } from "../interfaces/interfaceDividendos/getDividendosInterface";

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export const useDividendosTableHook = ({userRol, setLoading}: Props) => {
	const { dividendos, actualizarDividendos } = useContext(DividendosContext);
	const [dividendosFilt, setDividendosFilt] = useState<Dividendo[]>([]);

	useEffect(() => {
		if(userRol === roles.administrador || userRol === roles.tesoreria){
			if (dividendos.length !== 0) {
				setDividendosFilt(dividendos);
			} else {
				getDividendosApi();
			}
		}
		dividendos.length === 0 && setLoading(false);
	}, []);

	const getDividendosApi = async () => {
		try {
			const res: AxiosResponse<GetDividendosResponse> = await getDividendos();
			if (res?.status === 200) {
				let leng = res?.data?.body.length;
				// console.log(res.data.body)
				let ultDividendos = res?.data?.body[leng-1].dividendos;
				actualizarDividendos(ultDividendos);
				setDividendosFilt(ultDividendos);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const filtDividendos = (name: string) => {
		if (name.length === 0) {
			setDividendosFilt(dividendos);
		} else {
			let filtered = dividendos.filter((e) =>
				e["'Comitente'"].toString().startsWith(name)
			);

			setDividendosFilt(filtered);
			filtered.length === 0 && setLoading(false);
		}
	};

	return {
		dividendos,
		dividendosFilt,
		filtDividendos,
	};
};

const styles = {
	button: {
		backgroundColor: "transparent",
		border: "none",
		cursor: "pointer",
		color: "#004B6F",
	},
};
