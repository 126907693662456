import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 3em;
	height: 93%;
`;

export const ContenedorTextoBusqueda = styled.div`
	margin-left: 32px;
	margin-right: 32px;
	display: flex;
	justify-content: space-between;
`;

export const TituloSeccion = styled.h2`
	color: #004b6fbf;
	font-size: 24px;
	font-family: "Montserrat";
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const ContenedorBlanco = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	height: 95%;
	margin-right: 32px;
	margin-left: 32px;
	margin-bottom: 32px;
	overflow-y: scroll;
`;

export const ContenedorElementos = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 24px;
	margin-left: 40px;
`;

export const Titulo = styled.h3`
	color: #004b6fbf;
	font-size: 24px;
	font-family: "Montserrat";
	margin-top: 32px;
	margin-bottom: 16px;
`;

export const MotivoContainer = styled.div`
	margin-bottom: 8px;
	margin-top: 16px;
`;

export const BotonesContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 16px;
	width: 25%;
	margin: 0 auto;
	margin-bottom: 16px;
`;

