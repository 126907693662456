import apiInstance from "../utils/Axios.js";
import { ConfirmacionTitularResponse } from "../interfaces/interfacesCotitulares/confirmacionTitularInterface.js";
import { GetAllTitularesResponse } from "../interfaces/interfacesCotitulares/getAllTitularesInterface.js";

// Circuito anterior
export async function confirmacionTitular(idClient: string) {
	return await apiInstance.put<ConfirmacionTitularResponse>(`/confirmaciontitular`, {idClient});
}

// Circuito nuevo

export async function getAllTitulares(userId: string) {
	return await apiInstance.put<GetAllTitularesResponse>(`/cotitular`, {userId});
}

export async function getUrlCotitular(email: string, idTitular: string) {
	return await apiInstance.post<any>(`/cotitular/urlcotitular`, {email, idTitular});
}
// no pude realizar la peticion
// TypeError: Cannot read properties of null (reading 'email_cotitular'), linea 81 en controller

export async function sendEmailCotitular(email: string, idTitular: string) {
	return await apiInstance.post<any>(`/cotitular/sendemail`, {email, idTitular});
}

export async function updateEmailCotitular(userId: string, emailAntiguo: string, nuevoEmail: string) {
	return await apiInstance.put<any>(`/cotitular/email`, {userId, emailAntiguo, nuevoEmail});
}


