import React from "react";
import DataTable from "react-data-table-component";
import { Result } from "../../hooks/useReinversionRenta";
import { Container, TableContainer, TextAccount } from "./elements";
import columnsReinversionTable from "../../helper/Colums/columsReinversionTable";

interface Props {
    results: Result[], 
    resultAccountNumber: string,
}

const paginationOpciones = {
	rowsPerPageText: "Filas por página",
	rangeSeparatorText: "de",
	selectAllRowsItem: true,
	selectAllRowsItemText: "Todos",
};

export const ReinversionTable = ({ results, resultAccountNumber }: Props) => {

    return (
        <Container>
            <TextAccount>Cuenta {resultAccountNumber}</TextAccount>
            <TableContainer>
                <DataTable
                    columns={columnsReinversionTable}
                    data={results}
                    pagination
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
                    paginationComponentOptions={paginationOpciones}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    // minHeight="600px"
                    customStyles={customStyles}
                    responsive
                    keyField="id"
                />
            </TableContainer>
        </Container>
    );
};


const customStyles = {
    tableWrapper: {
        style: {
            display: "table",
            minHeight: "50%",
            maxHeight: "50%",
            backgroundColor: "white",
            color: "#004B6F",
            padding: "0 1.2em",
        },
    },

    header: {
        style: {
            minHeight: "56px",
        },
    },
    rows: {
        style: {
            minHeight: "58px", // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",

            backgroundColor: "rgba(142, 178, 221, 0.5)",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
        },
    },
};
