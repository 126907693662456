import * as React from "react";
import { aprobarFicha, checkAprobar } from "../api/aprobadas";
import { rechazarFicha } from "../api/rechazadas";
import { getArchivos } from "../api/documentos";
import { getDatos, getDeclaraciones } from "../api/datos";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { FileUserNew, GetArchivos } from "../interfaces/interfacesDocumentos/getDocumentosInterface";

// Permite aceptar o rechazar un ficha de acuerdo a sus validaciones

interface Props {
    handleClose: () => void,
    texto: string,
	id: string,
    phaseLength: number,
    rol: string,
}



export const useModalEstado = ({id, phaseLength, texto, handleClose, rol}: Props) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(rol === roles.compliance ? false : true);
    const [motivo, setMotivo] = React.useState<string>('');
    const [warning, setWarning] = React.useState<string[]>([]);
    const [documentos, setDocumentos] = React.useState<FileUserNew[] | []>([]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMotivo(event.target.value);
    };

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const archivosPrevios = async (idFicha: string) => {
        try {
            const resp: AxiosResponse<GetArchivos> = await getArchivos(idFicha);
    
            const res = resp.data.body.fileUserNew;
    
            return res;
    
        } catch (error) {
            console.log(error);
        }
    };
    
    const getCuit = async () => {
        try {
            const resp = await getDatos(id);
            if(resp?.data?.body?.datos?.TipoIdFiscal !== "CUIT/CUIL"){
                return "No es cuit/cuil";
            }
            const res = resp.data.body.datos.NumeroIdFiscal;
            return res;
        } catch (error) {
            console.log(error);
        }
    };
    
    const getFATCA = async () => {
        try {
            const resp = await getDeclaraciones(id);
            const res = resp.data.body.declaracion.FATCA;
    
            return res;
        } catch (error) {
            console.log(error);
        }
    };
    
    const handleOpen = () => {
        if (phaseLength < 6 && texto === "Aprobar") {
            handleClose();
        } else {
            setOpen(true);
        }
    };
    const handleCerrar = () => {
        setOpen(false);
        handleClose();
    };
    const submit = async () => {
        let resp;
        try {
            if (texto === "Aprobar") {

                // El primer if es para ver si ya se le mostro una advertencia y mientras esta no sea 
                // que no se puede aproabar (por terrorismo), si vuelven a tocar para aprobar
                // aprueba sin volver a hacer las verificaciones

                setLoading(true)
    
                if(warning.length > 0 && !warning.includes("El cliente es una persona terrorista") && !warning.includes("El cliente es una persona estadounidense")){
                        resp = await aprobarFicha(id);
                        setOpen(false);
                        handleClose();
                        window.location.reload();
                        
                } else {
                    
                    const cuit = await getCuit();
                    const FATCA = await getFATCA();

                    let pep = "";
                    let sujetoobligado = "No";
                    let baselaft = "0";
                    
                    if (cuit && cuit !== "No es cuit/cuil") {
                        const res = await checkAprobar(cuit);
                        pep = res.data?.body.pep;
                        sujetoobligado = res.data?.body.sujetoobligado;
                        baselaft = res.data?.body.baselaft;
                    } 
                    // baselaft devuelve la cantidad de alertas que tiene en bases publicas negativas, 
                    // tiene que ser 0 sino no se puede aprobar
                    if (baselaft !== "0") {
                        setWarning(["El cliente es una persona terrorista"]);
                    } else {
                        
                        const res: FileUserNew[] | undefined = await archivosPrevios(id);
                        res && setDocumentos(res);
                        
                        if (pep === "Si" || sujetoobligado === "Si" || (res && res.length < 5) || FATCA === "Si" ) {
    
                            let warn = []
                            
                            pep === "Si" &&
                            warn.push(`El cliente presenta inconsistencias en 'PEP'`);
                            
                            sujetoobligado === "Si" && 
                            warn.push(`El cliente presenta inconsistencias en 'Sujeto obligado'`);
    
                            FATCA === "Si" &&
                            warn.push(`El cliente es una persona estadounidense`);
                            
                            (res && res.length < 5) &&
                            warn.push(`La documentación esta incompleta`);
    
                            setWarning(warn);
                            setLoading(false);
                        } else {
                            resp = await aprobarFicha(id);
                            setOpen(false);
                            handleClose();
                            window.location.reload();
                        }
                    }
                }
                
            } else {
                resp = await rechazarFicha(id, checked, motivo);
                setOpen(false);
                handleClose();
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
        
    };

    return {
        handleOpen,
        open,
        handleCerrar,
        warning,
        submit,
        loading,
        handleChange,
        handleCheck,
        checked,
        documentos,
    }
}
