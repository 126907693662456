import { useState, useEffect, ChangeEvent, FormEvent } from "react"
import { autorizarClientes } from "../api/autorizados";
import { AxiosResponse } from "axios";
import { AutorizarClienteResponse } from "../interfaces/interfaceAutorizados/postAutorizarCliente";

// Hook que manipula el formulario para agregar un autorizado

export interface FormAgregarAutorizado {
    name: string, 
    lastName: string, 
    DNI: string, 
    accountNumber: string,
    email: string,
}

export interface Errors {
    incompletos?: string;
    request?: string;
}

export interface ValidationResult {
    [key: string]: string;
}

const validateForm = (form : FormAgregarAutorizado): ValidationResult =>{
    let errors : ValidationResult = {};

    if(!form.name || !form.lastName || !form.DNI || !form.accountNumber || !form.email){
        errors.incompletos = "Campos incompletos"
    }

    return errors;
}


export const useFormAgregarAutorizado = (initialForm: FormAgregarAutorizado) => {

    const [form, setForm] = useState<FormAgregarAutorizado>(initialForm);
    const [errors, setErrors] = useState<Errors>({});
    const [open, setOpen] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean | null>(null);

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }, [form, Object.keys(errors).length]);

    const handleChange = (e : ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value,
        });


        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }
    

    const handleSubmit = async(e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        let errorsVariableEstatica = validateForm(form);
        setErrors(validateForm(form));

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorsVariableEstatica).length === 0 
            ) {
                try {
                    const res : AxiosResponse<AutorizarClienteResponse> = await autorizarClientes(form);
                    if(res.status === 200){
                        setSuccess(true);
                        setOpen(true);
                        setForm(initialForm);
                    }
                } catch (error) {
                    setSuccess(false);
                    setOpen(true);
                    setForm(initialForm);
                    console.log(error)
                }
        } 
    }

    return {
        form,
        errors,
        handleChange,
        handleSubmit,
        open, 
        success,
        handleClose,
    }
}