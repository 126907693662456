import React, { ReactNode, createContext, useState } from "react";
import { BodyGetCBU } from "../../interfaces/interfaceCBU/getCBUInterface";
import { BodyProcesarCBU } from "../../interfaces/interfaceCBU/procesarCBUInterface";
import { CBUS } from "../../hooks/useCBUsTable";

interface CBUsContextProps {
	CBUs: CBUS[];
	actualizarCBUs: (newValue: CBUS[]) => void;
}

export const CBUsContext = createContext<CBUsContextProps>({} as CBUsContextProps);

interface CBUsProviderProps {
	children: ReactNode;
}

export const CBUsProvider = ({ children }: CBUsProviderProps) => {
	const [CBUs, setCBUs] = useState<CBUS[]>([]);

	const actualizarCBUs = (newValue: CBUS[]) => {
		newValue.sort((a, b) => {
			const aHasCuentas = Array.isArray(a.CuentasFilt) && a.CuentasFilt.length > 0;
			const bHasCuentas = Array.isArray(b.CuentasFilt) && b.CuentasFilt.length > 0;
			const aHasBeneficiario = a.Beneficiario !== " - ";
			const bHasBeneficiario = b.Beneficiario !== " - ";
	
			// Si 'a' tiene cuentas y 'b' no, 'a' va antes que 'b'
			if (aHasCuentas && !bHasCuentas) return -1;
			// Si 'b' tiene cuentas y 'a' no, 'b' va antes que 'a'
			if (!aHasCuentas && bHasCuentas) return 1;
	
			// Si ambos tienen cuentas o ninguno tiene cuentas, comparamos beneficiario
			if (aHasBeneficiario && !bHasBeneficiario) return -1;
			if (!aHasBeneficiario && bHasBeneficiario) return 1;
	
			// Si ambos tienen el mismo estado de 'Cuentas' y 'Beneficiario', no cambia su orden
			return 0;
		});
	};
    

	return (
		<CBUsContext.Provider
			value={{
				CBUs,
				actualizarCBUs,
			}}
		>
			{children}
		</CBUsContext.Provider>
	);
};
