import { TableColumn } from 'react-data-table-component';
import { RowRechazado } from '../../interfaces/interfacesRows/rows';

const columnsRechazados: TableColumn<RowRechazado>[] = [
    {
        selector: (row: RowRechazado) => row.fecha,
        name: "Fecha",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowRechazado) => row.hora,
        name: "Hora",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowRechazado) => row.nombre,
        name: "Nombre y Apellido",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowRechazado) => row.ficha,
        name: "Ficha",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowRechazado) => row.manager,
        name: "Manager",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowRechazado) => row.email,
        name: "Email",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowRechazado) => row.estado,
        name: "Estado",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowRechazado) => row.puntos,
        name: "",
        center: true,
        style: {
            color: '#004B6F',
        },
    }
];

export {
    columnsRechazados,
}
