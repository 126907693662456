import React from 'react'
import MenuItem from "@mui/material/MenuItem";
import ModalEliminarCriterio from '../../../ModalEliminarCriterio/ModalEliminarCriterio';

interface Props {
    id: string, 
    handleOpenEliminarCriterio: () => void, 
    openEliminarCriterio: boolean, 
    setOpenEliminarCriterio: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ItemsTesoreria = (props : Props) => {

    const { id, handleOpenEliminarCriterio, openEliminarCriterio, setOpenEliminarCriterio } = props;

    return (
        <div>
            <div>
                <MenuItem
                    onClick={handleOpenEliminarCriterio}
                    sx={{
                        color: "#004B6F",
                        justifyContent: "center",
                    }}
                >
                    Eliminar criterio
                </MenuItem>
                <ModalEliminarCriterio
                    open={openEliminarCriterio}
                    setOpen={setOpenEliminarCriterio}
                    id={id}
                />
            </div>
        </div>
    );
};
