import { useState, ReactNode  } from "react";
import { Time } from "../components/Table/Time/Time";
import { Avance } from "../components/Table/Avance/Avance";
import { Ficha } from "../components/Table/Ficha/Ficha";
import EstadoSelect from "../components/Table/EstadoSelect/EstadoSelect";
import FadeMenu from "../components/Table/TableButton/Menu";
import { getAllTitulares } from "../api/cotitulares";
import { EstadoButton } from "../components/Table/EstadoButton/EstadoButton";
import ModalMotivos from "../components/ModalMotivos/ModalMotivos";
import { ManagerText } from "../components/Table/ManagerText/ManagerText";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { GetAllTitularesResponse } from "../interfaces/interfacesCotitulares/getAllTitularesInterface";

//  Hook que manipula la data de las fichas que componen una cuenta pluripersonal

interface Cotitular {
    id: string;
    fecha: string;
    hora: string;
    nombre: string;
    icon: ReactNode;
    avance: ReactNode;
    ficha: ReactNode;
    manager: ReactNode;
    email: string;
    nCuenta: string;
    estado: ReactNode;
    puntos: ReactNode;
}

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
    titularId: string,
}

export default function useCotitularesHook({userRol, setLoading, titularId}: Props) {

    const [cotitulares, setCotitulares] = useState<Cotitular[]>([]);
    const [cotitularesFilt, setCotitularesFilt] = useState<Cotitular[]>([]);

    const handleClose = () => {
		setAnchorEl(null);
	};
	const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

	// Petición a la api

    const allCotitulares = async () => {
		let titularesData;
		try {
				const res : AxiosResponse<GetAllTitularesResponse> = await getAllTitulares(titularId);

                const emailCotitulares = res.data.body.emailCotitulares.flat();
                const emailClientes = res.data.body.datosclientes.map( e => e.email);
                let emailCotitularesFilt = [];

                for (let i = 0; i < emailCotitulares.length; i++) {
                    if (!emailClientes.includes(emailCotitulares[i].email)) {
                        emailCotitularesFilt.push(emailCotitulares[i]);
                    }
                }
                
				titularesData = [...res.data.body.datosclientes, ...emailCotitularesFilt];
				// titularesData = titularesData.reverse();

                titularesData = titularesData.map( (e) => {
                    return {
                        id: e._id,
                        fecha: e.creation ? `${e.creation.slice(0, 5)}` : '-',
                        hora: e.creation ? e.creation
                            .substring(e.creation.lastIndexOf(","))
                            .slice(1) : '-',
                        nombre: e.creation ? `${e.name} ${e.last_name}` : '-',
                        icon: e.creation ? <Time updatedAt={e.updatedAt} /> : '-',
                        avance: 
                        e.creation ? (
                            <Avance
                                phase={e.phase}
                                name={e.name}
                                status={e.status}
                            />
                        ) : '-',
                        ficha: e.creation ? <Ficha id={e._id} /> : '-',
                        manager: e.creation ? (
                            <ManagerText manager_id={e.manager} />
                        ) : '-',
                        email: e.email,
                        nCuenta: e.numCuenta ? e.numCuenta : "-",
                        estado: 
                        !e.creation ? '-' :
                                e.status_field === "RECHAZADO" ?
                                <EstadoButton texto='Rechazada' id={e._id}/>
                                :
                                e.status_field === "APROBADO" ?
                                <EstadoButton texto='Aprobada' id={e._id}/>
                                :
                                (e.status_field === "SUBSANADO" && userRol === roles.compliance) ?
                                <ModalMotivos
                                    id={e._id}
                                    handleClose={handleClose}
                                    subsanados={true}
                                />
                                :
                                (e.status_field === "INFORMADO" && userRol === roles.compliance) ? (
                                <ModalMotivos
                                    id={e._id}
                                    handleClose={handleClose}
                                    informados={true}
                                />
                            ) : 
                                <div>
                                    <EstadoSelect
                                        id={e._id}
                                        manager={e.manager}
                                        status={e.status_field}
                                        phaseLength={e.phase.length}
                                        rol={userRol}
                                        subsanado={e.subsanado}
                                    />
                                </div>
                            ,
                        puntos: (
                            <FadeMenu
                                id={e._id}
                                nombre={`${e.name} ${e.last_name}`}
                                telefono={e.phone}
                                cotitular={e.cotitular}
                                cuitVerificado={e.cuitVerificado}
                                refreshFunc={allCotitulares}
                                cotitularesView={true}
                                email={e.email}
                                fichaSinIniciar = {e.creation ? false : true}
                                idTitular = {titularId}
                            />
                        ),
                    }
                })

				setCotitulares(titularesData);
                setCotitularesFilt(titularesData)
				!(titularesData?.length > 0) && setLoading(false);
			
		} catch (error) {
			console.log(error);
		}
	};

    // Funcion de filtro

	const filtCotitulares = (name: string) => {
		name = name.toLowerCase();

		let filtered = cotitulares.filter(
			(e) =>
            e.fecha.toLowerCase().includes(name) ||
            e.hora.toLowerCase().includes(name) ||
            e.nombre.toLowerCase().includes(name) ||
            e.email.toLowerCase().includes(name)
		);

		setCotitularesFilt(filtered);
        filtered.length === 0 && setLoading(false);
	};

	return {
        allCotitulares,
		filtCotitulares,
        cotitularesFilt,
	};
}
