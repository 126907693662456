import React, { useContext } from "react";
import { Button } from "@mui/material";
import { Box, Container, ErrorsContainer, Form, FormContainer, Input, LabelForm, Option, OptionContainer, SeccionButtons, Select, Subtitulo, TextError } from "./elements";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useFormAgregarCritierio } from "../../../hooks/useFormAgregarCriterio";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context/userContext/UserContexts";

export const tiposDeCuentas = {
    todas: "Todas",
    especificas: "Específicas"
}

export const AgregarCriterio = () => {
    const { width } = useWindowDimensions();

    const { user } = useContext(UserContext);

    const location = useLocation();
    const { state } = location;

    const {
        id,
        editarCriterio,
        montoAnterior,
        porcTenenciaAnterior,
        tipoAnterior,
        cuentaAnterior,
    } = state || {};

    const initialForm = {
        tipo: tipoAnterior ? tipoAnterior : tiposDeCuentas.todas,
        cuentas: cuentaAnterior ? cuentaAnterior : "",
        monto: montoAnterior ? montoAnterior : "",
        porcTenencia: porcTenenciaAnterior ? porcTenenciaAnterior : "",
    };

    const { form, errors, handleChange, handleSubmit, handleCancelar, } = useFormAgregarCritierio(
        initialForm,
        editarCriterio,
        id,
    );

    return (
        <Container
            style={{
                width: width * 0.82,
                left: width * 0.165,
            }}
        >
            <Subtitulo>
                CONFIGURACIÓN DE LOS CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS
            </Subtitulo>
            <Box>
                <FormContainer>
                    <Form>
                        <OptionContainer>
                            <LabelForm>
                                Tipos de cuentas
                            </LabelForm>
                            <Select
                                name="tipo"
                                value={form.tipo}
                                onChange={(e) => handleChange(e)}
                            >
                                <Option>
                                    {form.tipo}
                                </Option>
                                <Option>
                                    {form.tipo === tiposDeCuentas.especificas ? tiposDeCuentas.todas : tiposDeCuentas.especificas}
                                </Option>
                            </Select>
                        </OptionContainer>
                        {form.tipo === tiposDeCuentas.especificas && (
                            <OptionContainer
                                style={{
                                    gridTemplateColumns: "34% 44% 22%",
                                }}
                            >
                                <LabelForm>
                                    Agregar cuentas
                                </LabelForm>
                                <Input
                                    type="text"
                                    name="cuentas"
                                    value={form.cuentas}
                                    onChange={(e) => handleChange(e)}
                                />
                            </OptionContainer>
                        )}

                        <OptionContainer>
                            <LabelForm>
                                La transferencia es inferior a
                            </LabelForm>
                            <Input
                                type="text"
                                name="monto"
                                value={form.monto}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>
                                El disponible a solicitar es hasta el
                            </LabelForm>
                            <Input
                                type="text"
                                name="porcTenencia"
                                value={form.porcTenencia}
                                onChange={(e) => handleChange(e)}
                            />
                            <LabelForm>
                                de la tenencia de activos
                            </LabelForm>
                        </OptionContainer>

                        {errors && (
                            <ErrorsContainer>
                                {errors.incompletos && (
                                    <TextError>
                                        {errors.incompletos}
                                    </TextError>
                                )}
                                {errors.cuentas && (
                                    <TextError>
                                        {errors.cuentas}
                                    </TextError>
                                )}
                                {errors.updateError && (
                                    <TextError>
                                        {errors.updateError}
                                    </TextError>
                                )}
                            </ErrorsContainer>
                        )}

                        <SeccionButtons>
                            <Button
                                style={{
                                    marginRight: "16px",
                                    backgroundColor: " rgba(0, 75, 111)",
                                    fontSize: "0.8em",
                                    fontWeight: "bold",
                                    width: "10em",
                                }}
                                onClick={handleSubmit}
                                variant="contained"
                                size="large"
                            >
                                GUARDAR
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#C9C9C9",
                                    fontSize: "0.8em",
                                    fontWeight: "bold",
                                    width: "10em",
                                }}
                                onClick={handleCancelar}
                                variant="contained"
                                size="large"
                            >
                                CANCELAR
                            </Button>
                        </SeccionButtons>
                    </Form>
                </FormContainer>
            </Box>
        </Container>
    );
};
