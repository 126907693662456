import { ChangeEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { CircularProgress } from "@mui/material";
import { updateEmailCotitular } from "../../api/cotitulares";
import { Input, InputContainer } from "./elements";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 460,
    maxHeight: 270,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const styleButtons = {
    width: "130px",
    height: "35px",
    fontWeight: "bold",
};

const styleContainerButtons = {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    marginBottom: "2.4em",
};

//Permite setear el cuit como valido o invalido y una vez seteado ver el dato

interface Props {
    handleClose: () => void, 
    id: string, 
    email: string, 
    open: boolean, 
    setOpen: (param:boolean) => void, 
    idTitular: string,
}

export default function ModalSubsanarMail({ handleClose, id, email, open, setOpen, idTitular } : Props) {

    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const [input, setInput] = useState('')

    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
		setInput(value);
    }

    const handleCerrar = () => {
        setOpen(false);
        handleClose();
    };

    const handleClick = async () => {
        try {
            setLoading(true)
            const res = await updateEmailCotitular(idTitular, email, input);
            if (res.status === 200) {
                setInput("");
                setLoading(false);
                setEmailSent(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={handleCerrar}
                        style={{
                            color: "#004B6F",
                            marginLeft: "16.5em",
                        }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                    {loading ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress
                                style={{
                                    color: "#004B6F",
                                    marginBottom: "6em",
                                    marginTop: "3em",
                                }}
                            />
                        </Box>
                    ) : emailSent ? (
                        <>
                            <Typography
                                id="keep-mounted-modal-title"
                                variant="h6"
                                component="h2"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: 1.2,
                                    width: 400,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    marginTop: "1.8em",
                                    marginBottom: "2em",
                                }}
                            >
                                SE ENVIÓ UN MAIL AL NUEVO CORREO ELECTRÓNICO
                            </Typography>
                            <div style={{...styleContainerButtons, marginBottom: "3em",
                                        marginTop: "2em",}}>
                                <Button
                                    style={{
                                        ...styleButtons,
                                        background: "#004B6F",
                                        marginRight: "16px",
                                    }}
                                    onClick={handleCerrar}
                                    variant="contained"
                                    size="small"
                                >
                                    ACEPTAR
                                </Button>
                                <Button
                                    style={{
                                        ...styleButtons,
                                        background: "#C9C9C9",
                                    }}
                                    onClick={handleCerrar}
                                    variant="contained"
                                    size="small"
                                >
                                    CANCELAR
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Typography
                                id="keep-mounted-modal-title"
                                variant="h6"
                                component="h2"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: 1.2,
                                    width: 400,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    marginTop: "0.5em",
                                    marginBottom: "1.5em",
                                }}
                            >
                                {`¿QUERES SUBSANAR EL CORREO DE ${email.toUpperCase()}?`}
                            </Typography>
                            <InputContainer>
                                <Input
                                    type="text"
                                    value={input}
                                    placeholder={
                                        "Escribí el correo electronico correcto"
                                    }
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <div style={styleContainerButtons}>
                                <Button
                                    style={{
                                        ...styleButtons,
                                        background: "#004B6F",
                                        marginRight: "16px",
                                    }}
                                    onClick={handleClick}
                                    variant="contained"
                                    size="small"
                                >
                                    ACEPTAR
                                </Button>
                                <Button
                                    style={{
                                        ...styleButtons,
                                        background: "#C9C9C9",
                                    }}
                                    onClick={handleCerrar}
                                    variant="contained"
                                    size="small"
                                >
                                    CANCELAR
                                </Button>
                            </div>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
