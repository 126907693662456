import { MouseEvent, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

// Menu de usuario

export default function UserMenu() {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                    color: "#004B6F",
                    textTransform: "capitalize",
                    fontSize: "1.1em",
                    height: 25,
                }}
            >
                <img
                    src={require("../../images/logo-usuario.png")}
                    alt="logo"
                />
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem
                    onClick={ () => navigate("/cambiar-contrasena")}
                    sx={{
                        color: "#004B6F",
                        justifyContent: "space-around",
                    }}
                >
                    <Icon
                        icon="tabler:lock-cog"
                        style={styles.icon}
                        fontSize={25}
                    />
                    <p style={styles.textIcon}>Cambiar contraseña</p>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        localStorage.setItem("token", "");
                        window.location.assign("/");
                    }}
                    sx={{
                        color: "#004B6F",
                    }}
                >
                    <Icon
                        icon="ci:log-out"
                        style={styles.icon}
                        fontSize={25}
                    />
                    <p style={styles.textIcon}>Cerrar sesión</p>
                </MenuItem>
            </Menu>
        </div>
    );
}

const styles = {
    icon: {
        alignItems: "center",
        marginRight: "8px",
    },
    textIcon: {
        fontWeight: "bold",
    }
}
