import React, { useContext } from "react";
import Table from "../../components/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";
import { columnsInformados, columnsInformadosCompliance } from "../../helper/Colums/columsInformados";
import { Container } from "./elements";

const subtitulo = "INFORMADOS"

export const Informados = () => {

    const { width } = useWindowDimensions();

    const { user } = useContext(UserContext);

    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <Table 
                subtitulo={subtitulo}
                columns={user.rol === roles.compliance ? columnsInformadosCompliance : columnsInformados}
            />
        </Container>
    );
}