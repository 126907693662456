import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import { Icon } from '@iconify/react';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getSettedNotifications } from "../../../api/notificaciones_app";
import { DateItem, ProgramadaItem, ProgramadasContainer, Sub, TimeItem, TitleItem } from "./elements";
import { Setted } from "../../../interfaces/interfaceNotificacionesApp/getSettedNotifications";

export const SideCalendar = () => {
	const { width, height } = useWindowDimensions();

	const [notificacionesApp, setNotificationesApp] = useState<Setted[]>([])

    useEffect(() => {
        getNotifications();
    }, [notificacionesApp?.length > 0])
    

	const hoy = moment().format();
	const ayer = moment().subtract(1, "days").format();

	const minDateRange = moment().subtract(6, "months").format();
	const maxDateRange = moment().add(6, "months").format();

	const dDate = require('date-and-time')

	const [date, setDate] = useState(new Date(hoy));

	const fechasNotificaciones = new Set(notificacionesApp?.map((e) => new Date(e.set[0].date.replaceAll("-", "/"))))

	const datesToAddContentTo = Array.from(fechasNotificaciones);;

	const TileContent = () => {
        return <div
		style={{
			height: "2px"
		}}>
			<Icon icon="akar-icons:circle-fill" style={{color: "#2B8EDE", height: "0.6em", marginBottom: "0.2em"}} />

		</div>
    };

	const tileContent = ({ date, view }: { date: Date; view: string }) => {
		// Add class to tiles in month view only
		if (view === "month") {
			// Check if a date React-Calendar wants to check is on the list of dates to add class to
			if (datesToAddContentTo.find(e => dDate.isSameDay(e, date))) {
				return <TileContent />;
			} else {
				return null
			}
		}
		return null;
	}

	const getNotifications = async() => {
        try {
            await getSettedNotifications().then(
                function (value) {
                    // Success!
                    setNotificationesApp(value.data.body.setted)
                    
                },
                function (error) {
                    console.log(error.response);
                }

            );
        } catch (error) {
            console.log(error)
        }
    }

	const render = () => {

		let notificaciones = notificacionesApp?.filter(e =>  e.set[0].date === moment(date).format().slice(0, 10))

		
		return (
            <ProgramadasContainer>
                <Sub>Programadas</Sub>
                <div style={{width: "100%"}}>
                    {notificaciones?.map((e) => (
                        <ProgramadaItem key={e._id} >
                            <TitleItem>{e.title.length > 35 ? `${e.title.slice(0, 35)}...` : e.title}</TitleItem>
							<div>
                            <DateItem>{e.set[0].date.replaceAll("-", "/")}</DateItem>
							<TimeItem>{e.set[0].time}</TimeItem>
							</div>
                        </ProgramadaItem>
                    ))}
                </div>
            </ProgramadasContainer>
        );
	}

	return (
		<>
			<div
				style={{
					display: "flex",
					alignSelf: "center",
					padding: "1.5em 1em 1em 2em",
				}}
			>
				<Calendar
					onChange={setDate}
					value={date}
					tileContent={tileContent}
					minDate={new Date(minDateRange)}
					maxDate={new Date(maxDateRange)}
				/>
			</div>

			<div style={{
				width: "90%",
				display: "flex"
				//backgroundColor: "aqua"
			}}>
				{render()}
			</div>
		</>
	);
};
