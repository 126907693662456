import { useState } from "react";
import { getInformadas } from "../api/informados";
import {
	getInformadosOperador,
} from "../api/operador";
import { Time } from "../components/Table/Time/Time";
import { Avance } from "../components/Table/Avance/Avance";
import { Ficha } from "../components/Table/Ficha/Ficha";
import { ManagerText } from "../components/Table/ManagerText/ManagerText";
import { CotitularesLink } from "../components/Table/CotitularesLink/CotitularesLink";
import ModalMotivos from "../components/ModalMotivos/ModalMotivos";
import EstadoSelect from "../components/Table/EstadoSelect/EstadoSelect";
import FadeMenu from "../components/Table/TableButton/Menu";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { InformadasResponse } from "../interfaces/interfaceInformados/getInformadasInterface";

// Hook que manipula la data de las fichas informadas

interface Informada {
	id: string;
	fecha: string;
	hora: string;
	nombre: string;
	icon: JSX.Element;
	avance: JSX.Element;
	ficha: JSX.Element;
	manager: JSX.Element;
	email: string;
	estado: JSX.Element;
	puntos: JSX.Element;
	usuarioViejo: boolean;
	cotitular: boolean;
}

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export default function useInformadasHook({userRol, setLoading}: Props) {

	const [informadas, setInformadas] = useState<Informada[]>([]);
	const [informadasFilt, setInformadasFilt] = useState<Informada[]>([]);

	const handleClose = () => {
		setAnchorEl(null);
	};
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	// Petición a la api

	const informadasFiles = async () => {
		let informadasData;
		try {
			if (userRol === roles.operador) {
				const res = await getInformadosOperador();

				informadasData = res.data.body;
				informadasData = informadasData.reverse();

				informadasData = informadasData.map((e : any) => ({
					id: e._id,
					fecha: e.creation.slice(0, 5),
					hora: e.creation.slice(12, 20),
					nombre: `${e.name} ${e.last_name}`,
					icon: <Time updatedAt={e.updatedAt} />,
					avance: <Avance phase={e.phase} name={e.name} status={e.status} />,
					ficha: <Ficha id={e._id} />,
					manager: <ManagerText manager_id={e.manager} />,
					email: e.email,
					estado:
						e.emailCotitulares.flat().length  !== 0 ?
						<div>
							<CotitularesLink id={e._id} nombre={`${e.name} ${e.last_name}`}  texto='Informado'/>
						</div>
						:
						userRol === roles.compliance ? (
							<ModalMotivos
								id={e._id}
								handleClose={handleClose}
								informados={true}
							/>
						) : (
							<>
								<div>
									<EstadoSelect
										id={e._id}
										manager={e.manager}
										status={e.status_field}
										phaseLength={e.phase.length}
										rol={userRol}
										subsanado={e.subsanado}
									/>
								</div>
							</>
						),
					puntos: (
						<FadeMenu
							id={e._id}
							nombre={`${e.name} ${e.last_name}`}
							telefono={e.phone}
							cotitular={e.cotitular}
						/>
					),
					usuarioViejo: e.newclient === "false" ? true : false,
					cotitular: e.cotitular,
				}));

				setInformadas(informadasData);
				setInformadasFilt(informadasData);
				!(informadasData?.length > 0) && setLoading(false);
			} else {
				const res : AxiosResponse<InformadasResponse>  = await getInformadas();

				informadasData = res.data.body.informadas;
				informadasData = informadasData.reverse();

				informadasData = informadasData.map((e) => ({
					id: e._id,
					fecha: e.creation.slice(0, 5),
					hora: e.creation.slice(12, 20),
					nombre: `${e.name} ${e.last_name}`,
					icon: <Time updatedAt={e.updatedAt} />,
					avance: <Avance phase={e.phase} name={e.name} status={e.status} />,
					ficha: <Ficha id={e._id} />,
					manager: <ManagerText manager_id={e.manager} />,
					email: e.email,
					estado:
					e.emailCotitulares.flat().length  !== 0  ?
						<div>
							<CotitularesLink id={e._id} nombre={`${e.name} ${e.last_name}`}  texto='Informado'/>
						</div>
						:
						userRol === roles.compliance ? (
							<ModalMotivos
								id={e._id}
								handleClose={handleClose}
								informados={true}
							/>
						) : (
							<>
								<div>
									<EstadoSelect
										id={e._id}
										manager={e.manager}
										status={e.status_field}
										phaseLength={e.phase.length}
										rol={userRol}
										subsanado={e.subsanado}
									/>
								</div>
							</>
						),
					puntos: (
						<FadeMenu
							id={e._id}
							nombre={`${e.name} ${e.last_name}`}
							telefono={e.phone}
							cotitular={e.cotitular}
						/>
					),
					usuarioViejo: e.newclient === "false" ? true : false,
					cotitular: e.cotitular,
				}));

				setInformadas(informadasData);
				setInformadasFilt(informadasData);
				!(informadasData?.length > 0) && setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

    // Funcion de filtro
	
	const filtInformadas = (name : string) => {
		name = name.toLowerCase();

		let filtered = informadas.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.hora.toLowerCase().includes(name) ||
                e.nombre.toLowerCase().includes(name) ||
                e.email.toLowerCase().includes(name)
        );

		setInformadasFilt(filtered);
		filtered.length === 0 && setLoading(false);
	};

	return {
        informadasFiles,
		informadasFilt,
        filtInformadas,
	};
}
