import { useState } from "react";
import Button from "@mui/material/Button";
import { getRechazadas } from "../api/rechazadas";
import {
	getRechazadosOperador,
} from "../api/operador";
import { Ficha } from "../components/Table/Ficha/Ficha";
import FadeMenu from "../components/Table/TableButton/Menu";
import { CotitularesLink } from "../components/Table/CotitularesLink/CotitularesLink";
import { ManagerText } from "../components/Table/ManagerText/ManagerText";
import { EstadoButton } from "../components/Table/EstadoButton/EstadoButton";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { RechazadasResponse } from "../interfaces/interfaceRechazadas/getRechazadasInterface";

// Hook que manipula la data de las fichas rechazadas

interface Rechazada {
    id: string;
    fecha: string;
    hora: string;
    nombre: string;
    ficha: JSX.Element;
    manager: JSX.Element;
    email: string;
    estado: JSX.Element;
    puntos: JSX.Element;
}

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export default function useRechazadasHook({userRol, setLoading}: Props) {

	const [rechazadas, setRechazadas] = useState<Rechazada[]>([]);
    const [rechazadasFilt, setRechazadasFilt] = useState<Rechazada[]>([]);

	// Petición a la api
	
    const rechazadasFiles = async () => {
		let rechazadasData;
		try {
			if (userRol === roles.operador) {
				const res = await getRechazadosOperador();

				rechazadasData = res.data.body;
				rechazadasData = rechazadasData.reverse();

                rechazadasData = rechazadasData.map((e : any) => ({
                    id: e._id,
                    fecha: e.creation.slice(0, 5),
			        hora: e.creation.slice(12, 20),
                    nombre: `${e.name} ${e.last_name}`,
			        ficha: <Ficha id={e._id} />,
                    manager: <ManagerText manager_id={e.manager} />,
                    email: e.email,
                    estado: (
                        e.emailCotitulares.flat().length  !== 0 ?
                        <div>
                            <CotitularesLink id={e._id} nombre={`${e.name} ${e.last_name}`} texto='Rechazada'/>
                        </div>
                        :
                        <EstadoButton texto='Rechazada' id={e._id}/>
                    ),
                    puntos: (
                        <FadeMenu
                            id={e._id}
                            nombre={`${e.name} ${e.last_name}`}
                            telefono={e.phone}
                            rechazada={true}
                            cotitular={e.cotitular}
                        />
                    ),
                }));

				setRechazadas(rechazadasData);
				setRechazadasFilt(rechazadasData);
				!(rechazadasData?.length > 0) && setLoading(false);
			} else {
				const res : AxiosResponse<RechazadasResponse> = await getRechazadas();

				rechazadasData = res.data.body.rechazados;
				rechazadasData = rechazadasData.reverse();

                rechazadasData = rechazadasData.map((e) => ({
                    id: e._id,
                    fecha: e.creation.slice(0, 5),
			        hora: e.creation.slice(12, 20),
                    nombre: `${e.name} ${e.last_name}`,
			        ficha: <Ficha id={e._id} />,
                    manager: <ManagerText manager_id={e.manager} />,
                    email: e.email,
                    estado: (
                        e.emailCotitulares.flat().length  !== 0 ?
                        <div>
                            <CotitularesLink id={e._id} nombre={`${e.name} ${e.last_name}`} texto='Rechazada'/>
                        </div>
                        :
                        <EstadoButton texto='Rechazada' id={e._id}/>
                    ),
                    puntos: (
                        <FadeMenu
                            id={e._id}
                            nombre={`${e.name} ${e.last_name}`}
                            telefono={e.phone}
                            rechazada={true}
                            cotitular={e.cotitular}
                        />
                    ),
                }));

				setRechazadas(rechazadasData);
				setRechazadasFilt(rechazadasData);
				!(rechazadasData?.length > 0) && setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

    // Funcion de filtro
	
	const filtRechazadas = (name: string) => {
		name = name.toLowerCase();

		let filtered = rechazadas.filter(
			(e) =>
            e.fecha.toLowerCase().includes(name) ||
            e.hora.toLowerCase().includes(name) ||
            e.nombre.toLowerCase().includes(name) ||
            e.email.toLowerCase().includes(name)
		);

		setRechazadasFilt(filtered);
        filtered.length === 0 && setLoading(false);
	};


	return {
        rechazadasFiles,
		rechazadasFilt,
        filtRechazadas,
	};
}
