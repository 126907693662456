import styled from "styled-components";

export const Container = styled.div`
    height: calc(100vh - 3em);
    position: absolute;
    top: 3em;
    display: flex;
    flex-direction: column;
`;

export const Subtitulo = styled.h4`
    font-size: 1.7em;
    margin: 0.2em 0;
    color: rgba(0, 75, 111, 0.75);
`;

export const Box = styled.div`
    background-color: white;
    margin-top: 0.3em;
    height: 88%;
    width: 100%;
    border-radius: 0.8em;
    margin-bottom: 0;
`;

export const FormContainer = styled.div`
    width: 96%;
    margin-left: 4%;
    margin-top: 1em;
	@media (min-width: 1600px) {
		margin-top: 3em;
	}
`;

export const Form = styled.form`
    margin-top: 3.5em;
	@media (min-width: 1600px) {
		margin-top: 2em;
	}
`;

export const LabelForm = styled.label`
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
`;

export const Option = styled.option`
    font-size: 1.2em;
`;

export const OptionContainer = styled.div`
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr;  */
	grid-template-columns: 34% 24% 42%; 
    grid-gap: 10px;
	width: 70%;
	margin-top: 1.5em;
	@media (min-width: 1600px) {
		width: 60%;
	}
`;

export const Input = styled.input`
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
	border-radius: 5px;
	padding: 0.8em;
    margin: 0 0.8em;
	color: #00386E;	
    border: none;
	@media (max-width: 992px) {
		font-size: 14px;
	}
	@media (min-width: 1600px) {
		font-size: 0.9em;
	}
`;

export const Select = styled.select`
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
	border-radius: 5px;
	padding: 0.8em;
    margin: 0 0.8em;
	color: #00386E;	
    border: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url("https://api.iconify.design/ep/arrow-down-bold.svg?color=#00386E");
	background-repeat: no-repeat, repeat;
	background-position: right 0.9em top 50%, 0 0;
	background-size: 0.85em auto, 100%;
	@media (max-width: 992px) {
		font-size: 14px;
	}
	@media (min-width: 1600px) {
		font-size: 0.9em;
	}
`;

export const ErrorsContainer = styled.div`
    margin-top: 2em;
`;

export const TextError = styled.p`
    margin-top: 0.1em;
    color: #E3342F;
    font-weight: bold;
`;

export const SeccionButtons = styled.div`
    display: flex;
    width: 80%;
    justify-content: center;
    margin-top: 4em;
`;
