import * as React from "react";
import { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Icon } from "@iconify/react";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";
import { Button, LogOutText, Logo } from "./elements";

export default function Appbar() {

    const { user } = useContext(UserContext);
	
	return (
        <AppBar
            elevation={0}
            position="static"
            style={{
                background: "white",
                boxShadow: "none",
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between", }}>
                <Logo
                    src={require("../../images/logo-rava.png")}
                    alt="logo"
                />
                {user && user.rol === roles.operadorDeCarteras && 
                    <Button
                        onClick={() => {
                            localStorage.setItem("token", "");
                            window.location.assign("/");
                        }}
                    >
                        <Icon
                            icon="ci:log-out"
                            style={styles.logOutIcon}
                            fontSize={25}
                        />
                        <LogOutText>
                            Cerrar sesión
                        </LogOutText>
                    </Button>
                }
            </Toolbar>
        </AppBar>
    );
}

const styles = {
    logOutIcon: {
        color: "#004b6f",
        alignItems: "center",
        marginRight: "8px",
    },
}
