import React, { ReactNode, createContext, useState } from "react";
import { Dolar } from "../../interfaces/interfaceDolares/getDolaresInterface";

interface DolaresContextProps {
	dolares: Dolar[];
	actualizarDolares: (newValue: Dolar[]) => void;
}

export const DolaresContext = createContext<DolaresContextProps>({} as DolaresContextProps);

interface DolaresProviderProps {
	children: ReactNode;
}

export const DolaresProvider = ({ children }: DolaresProviderProps) => {
	const [dolares, setDolares] = useState<Dolar[]>([]);

	const actualizarDolares = (newValue: Dolar[]) => {
		newValue.sort((a, b) => {
			// Si 'a.diferencias' es true y 'b.diferencias' es false,
			// colocamos 'a' antes que 'b'
			if (a.diferencias && !b.diferencias) {
				return -1;
			}
			// Si 'a.diferencias' es false y 'b.diferencias' es true,
			// colocamos 'b' antes que 'a'
			if (!a.diferencias && b.diferencias) {
				return 1;
			}
			// Si ambos tienen el mismo valor de 'diferencias', no cambia su orden
			return 0;
		});
		setDolares(newValue);
	};

	return (
		<DolaresContext.Provider
			value={{
				dolares,
				actualizarDolares,
			}}
		>
			{children}
		</DolaresContext.Provider>
	);
};
