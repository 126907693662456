import React, { useState } from "react";
import DataTable from "react-data-table-component";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useOrdenesCartera } from "../../hooks/useOrdenesCartera";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { Container, Subtitulo, SubtituloContainer, TableContainer, WhiteBox } from "./elements";
import { columnsOrdenesCartera } from "../../helper/Colums/columsOrdenesCartera";

const paginationOpciones = {
	rowsPerPageText: "Filas por página",
	rangeSeparatorText: "de",
	selectAllRowsItem: true,
	selectAllRowsItemText: "Todos",
};

export const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 18,
	},
	"& .css-1cjpqna-MuiPaper-root-MuiMenu-paper-MuiPopover-paper": {
		backgroundColor: "white",
	},
}));

export const OrdenesCartera = () => {
	const { width, height } = useWindowDimensions();

	const {
		ordenesFilt,
		openEstado,
		handleCloseEstado,
		handleOpenEstado,
		handleClickEstado,
		selectedIndex,
		arrMenuList,
		loading,
	} = useOrdenesCartera();

	const customStyles = {
		tableWrapper: {
			style: {
				display: "table",
				minHeight: height * 0.6,
				maxWidth: "100%",
				backgroundColor: "white",
				color: "#004B6F",
				padding: "0 1.2em",
			},
		},

		header: {
			style: {
				minHeight: "56px",
			},
		},
		rows: {
			style: {
				minHeight: "46px", // override the row height
			},
		},
		headCells: {
			style: {
				paddingLeft: "8px", // override the cell padding for head cells
				paddingRight: "8px",

				backgroundColor: "rgba(142, 178, 221, 0.5)",
			},
		},
		cells: {
			style: {
				paddingLeft: "8px", // override the cell padding for data cells
				paddingRight: "8px",
			},
		},
	};

	return (
		<div
			style={{
				width: width * 0.85,
				height: height * 0.92,
				left: width * 0.15,
				top: height * 0.062,
				position: "absolute",
			}}
		>
			<Container>
				<SubtituloContainer>
					<Subtitulo>ÓRDENES</Subtitulo>
					<div>
						<List component="nav" style={styles.menu}>
							<ListItem
								onClick={handleOpenEstado}
								style={styles.listItem}
							>
								<ListItemText
									primary={arrMenuList[selectedIndex]}
								/>
								<Icon
									icon="material-symbols:arrow-forward-ios-rounded"
									style={{
										width: "1em",
										height: "1em",
										marginRight: "0.8em",
									}}
								/>
							</ListItem>
						</List>
						<StyledMenu
							id="lock-menu"
							anchorEl={openEstado}
							keepMounted
							open={Boolean(openEstado)}
							onClose={handleCloseEstado}
						>
							{arrMenuList?.map((option, index) => (
								<MenuItem
									key={option}
									// disabled={index === selectedIndex}
									selected={index === selectedIndex}
									onClick={(event) =>
										handleClickEstado(event, index)
									}
									style={styles.menuItem}
								>
									{option}
								</MenuItem>
							))}
						</StyledMenu>
					</div>
				</SubtituloContainer>
				<WhiteBox>
					<TableContainer>
						<DataTable
							columns={columnsOrdenesCartera}
							data={ordenesFilt? ordenesFilt : []}
							pagination
							paginationPerPage={25}
							paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
							paginationComponentOptions={paginationOpciones}
							fixedHeader
							fixedHeaderScrollHeight="600px"
							// minHeight="600px"
							customStyles={customStyles}
							noDataComponent={
								loading ? (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<CircularProgress
											style={{ color: "#004B6F" }}
										/>
									</Box>
								) : (
									<>No hay órdenes por el momento</>
								)
							}
							responsive
							keyField="id"
						/>
					</TableContainer>
				</WhiteBox>
			</Container>
		</div>
	);
};

const styles = {
	menu: {
		height: "2.2em",
		borderRadius: "4px",
		padding: 0,
		margin: "0.2em 0 0 0",
	},
	listItem: {
		padding: "0.1em 0.5em 0.3em 0.5em",
		marginLeft: "0.1em",
		fontSize: "0.9em",
		cursor: "pointer",
	},
	menuItem: {
		width: "10.5em",
		fontSize: "0.9em",
		backgroundColor: "white", 
		color: "rgba(0, 75, 111, 0.75)",  
		borderBottom: "0.15em solid gray",
	},
}