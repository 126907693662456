import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 98%;
`;

export const TableContainer = styled.div`
    display: flex;
    height: 80%;
    width: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
    justify-content: center;
    padding: 2%;
`;

