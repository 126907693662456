import { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { getUrlCotitular, sendEmailCotitular } from "../api/cotitulares";
import { UserContext } from "../context/userContext/UserContexts";
import { Texts } from "../components/Table/TableButton/Menu";

/**
 * Hook que manipula el Menu
 * @param {Objetc} texts Opciones del valor text
 * @returns {Objetc} 
 */

interface Props {
    id: string,
    texts: Texts,
    email?: string,  
    idTitular?: string, //id del titular (para las pantallas de cotitulares de...) 
}


export const useMenuHook = ({id, texts, email, idTitular} : Props) => {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget as HTMLElement);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openDatos, setOpenDatos] = useState<boolean>(false);
    const handleOpenDatos = () => setOpenDatos(true);

    const [openDatosCotitular, setOpenDatosCotitular] = useState<boolean>(false);
    const handleOpenDatosCotitular = () => setOpenDatosCotitular(true);

    const [openSirena, setOpenSirena] = useState<boolean>(false);
    const handleOpenSirena = () => setOpenSirena(true);

    const [openCUIT, setOpenCUIT] = useState<boolean>(false);
    const handleOpenCUIT = () => setOpenCUIT(true);

    const [openSubsanar, setOpenSubsanar] = useState<boolean>(false);
    const handleOpenSubsanar = () => setOpenSubsanar(true);

    const [text, setText] = useState('');

    const [openAlerta, setOpenAlerta] = useState<boolean>(false);
    const handleOpenAlerta = (t: string) => {
        setText(t);
        setOpenAlerta(true);
        t === texts.datosCopiados ? copyToClipboard() : sendMail();
        setTimeout(() => {
            setOpenAlerta(false);
        }, 1000);
    };

    const [openEliminarCriterio, setOpenEliminarCriterio] = useState<boolean>(false);
    const handleOpenEliminarCriterio = () => setOpenEliminarCriterio(true);

    // copia los datos en el portaPapeles
    const copyToClipboard = async () => {
        try {
            if(email && idTitular){
                const res = await getUrlCotitular(email, idTitular);
                await navigator.clipboard.writeText(res.data.body);
            }
        } catch (error) {
            console.log(error)
        }
    };

    // Reenviar mail
    const sendMail = async () => {
        try {
            if(email && idTitular){
                await sendEmailCotitular(email, idTitular);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const { user } = useContext(UserContext);
    const rol = user.rol;

    const documentos = () => {
        let path = "/documentos/" + id;
        navigate(path);
    };

    
    return {
        open,
        handleClick,
        anchorEl,
        handleClose,
        rol,
        handleOpenDatos,
        handleOpenDatosCotitular,
        handleOpenSirena,
        handleOpenEliminarCriterio,
        openDatosCotitular,
        setOpenDatosCotitular,
        openDatos,
        setOpenDatos,
        openEliminarCriterio,
        setOpenEliminarCriterio,
        handleOpenAlerta,
        handleOpenSubsanar,
        openAlerta,
        text,
        openSubsanar,
        setOpenSubsanar,
        handleOpenCUIT,
        openCUIT,
        setOpenCUIT,
        openSirena,
        setOpenSirena,
        documentos,
    }
}