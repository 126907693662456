import { useState } from "react";
import Button from "@mui/material/Button";
import { getAprobadas } from "../api/aprobadas";
import {
	getAprobadosOperador,
} from "../api/operador";
import { Ficha } from "../components/Table/Ficha/Ficha";
import FadeMenu from "../components/Table/TableButton/Menu";
import { NumDeCuenta } from "../components/Table/NumDeCuenta/NumDeCuenta";
import { CotitularesLink } from "../components/Table/CotitularesLink/CotitularesLink";
import { ManagerText } from "../components/Table/ManagerText/ManagerText";
import { EstadoButton } from "../components/Table/EstadoButton/EstadoButton";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { AprobadasResponse } from "../interfaces/interfacesAprobadas/aprobadasInterface";

// Hook que manipula la data de las fichas aprobadas

interface Aprobada {
    id: string;
    fecha: string;
    hora: string;
    nombre: string;
    ficha: JSX.Element | string;
    manager: JSX.Element | string;
    email: string | undefined;
    estado: JSX.Element;
    cuenta: JSX.Element;
    puntos: JSX.Element | string;
    juridica: boolean;
    numCuenta: string;
}

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export default function useAprobadasHook({userRol, setLoading} : Props) {

	const [aprobadas, setAprobadas] = useState<Aprobada[]>([]);
	const [aprobadasFilt, setAprobadasFilt] = useState<Aprobada[]>([]);

	// Petición a la api

	const aprobadasFiles = async () => {
		let aprobadasData;
		try {
			if (userRol === roles.operador) {
				const res = await getAprobadosOperador();

				aprobadasData = res?.data?.body;
				aprobadasData = aprobadasData?.reverse();

                aprobadasData = aprobadasData?.map((e : any) => ({
                    id: e._id,
                    fecha: e.creation.slice(0, 5),
                    hora: e.creation.slice(12, 20),
                    nombre: e.razonSocial ? e.razonSocial : `${e.name} ${e.last_name}`,
                    ficha: e.razonSocial ? '' : <Ficha id={e._id}/>,
                    manager: e.razonSocial ? '-' : <ManagerText manager_id={e.manager} />,
                    email:  e.razonSocial ? e.cuit : e.email,
                    estado: (
                        e.emailCotitulares?.flat().length  !== 0  && !e.razonSocial ?
                        <div>
                            <CotitularesLink id={e._id} nombre={`${e.name} ${e.last_name}`} texto='Aprobada'/>
                        </div>
                        :
                        <EstadoButton texto='Aprobada' id={e._id}/>
                    ),
                    cuenta: (
                        <NumDeCuenta
                            id={e._id}
                            num={e.numCuenta ? e.numCuenta : ""}
                            usuarioViejo={e.newclient === "false" ? true : false}
                            cotitular={e.cotitular}
                            nombre={`${e.name} ${e.last_name}`}
                            razonSocial={e.razonSocial}
                        />
                    ),
                    puntos: (
                        e.razonSocial ?
                        '' :
                        <FadeMenu
                            id={e._id}
                            nombre={`${e.name} ${e.last_name}`}
                            telefono={e.phone}
                            cotitular={e.cotitular}
                        />
                    ),
                    juridica: e.razonSocial ? true : false,
                }));

				setAprobadas(aprobadasData);
				setAprobadasFilt(aprobadasData);
				!(aprobadasData?.length > 0) && setLoading(false);
			} else {
				const res : AxiosResponse<AprobadasResponse>  = await getAprobadas();

				aprobadasData = res?.data?.body?.all;
                aprobadasData = aprobadasData?.reverse();

                aprobadasData = aprobadasData?.map((e) => ({
                    id: e._id,
                    fecha: e.creation.slice(0, 5),
                    hora: e.creation.slice(12, 20),
                    nombre: e.razonSocial ? e.razonSocial : `${e.name} ${e.last_name}`,
                    ficha: e.razonSocial ? '' : <Ficha id={e._id}/>,
                    manager: e.razonSocial ? '-' : <ManagerText manager_id={e.manager ? e.manager : ""} />,
                    email:  e.razonSocial ? e.cuit?.toString() : e.email,
                    estado: (
                        e.emailCotitulares?.flat().length !== 0 && !e.razonSocial ?
                        <div>
                            <CotitularesLink id={e._id} nombre={`${e.name} ${e.last_name}`} texto='Aprobada'/>
                        </div>
                        :
                        <EstadoButton texto='Aprobada' id={e._id}/>
                    ),
                    cuenta: (
                        <NumDeCuenta
                            id={e._id}
                            num={e.numCuenta ? e.numCuenta : ""}
                            usuarioViejo={e.newclient === "false" ? true : false}
                            cotitular={e.cotitular ? e.cotitular : false}
                            nombre={`${e.name} ${e.last_name}`}
                            razonSocial={e.razonSocial}
                        />
                    ),
                    puntos: (
                        e.razonSocial ?
                        '' :
                        <FadeMenu
                            id={e._id}
                            nombre={`${e.name} ${e.last_name}`}
                            telefono={e.phone}
                            cotitular={e.cotitular ? e.cotitular : false}
                        />
                    ),
                    juridica: e.razonSocial ? true : false,
                    numCuenta: e.numCuenta ? e.numCuenta.toString() : "",
                }));

				setAprobadas(aprobadasData);
				setAprobadasFilt(aprobadasData);
				!(aprobadasData?.length > 0) && setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

    // Funcion de filtro

	const filtAprobadas = (name : string) => {
		name = name.toLowerCase();

		let filtered = aprobadas.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.hora.toLowerCase().includes(name) ||
                e.nombre.toLowerCase().includes(name) ||
                e.email?.toLowerCase().includes(name) ||
                e.numCuenta && e.numCuenta.toLowerCase().includes(name)
        );

		setAprobadasFilt(filtered);
        filtered.length === 0 && setLoading(false);
	};

	return {
        aprobadasFiles,
		aprobadasFilt,
        filtAprobadas,
	};
}
