
import { ReactNode, createContext, useState } from "react";
import { Conciliacion } from "../../interfaces/interfaceConciliaciones/getConciliacionesInterface";

interface ConciliacionesContextProps {
	conciliaciones: Conciliacion[];
	actualizarConciliaciones: (newValue: Conciliacion[]) => void;
}

export const ConciliacionesContext = createContext<ConciliacionesContextProps>({} as ConciliacionesContextProps);

interface ConciliacionesProviderProps {
	children: ReactNode;
}

export const ConciliacionesProvider = ({ children }: ConciliacionesProviderProps) => {
	const [conciliaciones, setConciliaciones] = useState<Conciliacion[]>([]);

	const actualizarConciliaciones = (newValue: Conciliacion[]) => {
		newValue.sort((a, b) => {
			// Si 'a.diferencias' es true y 'b.diferencias' es false,
			// colocamos 'a' antes que 'b'
			if (a.diferencias && !b.diferencias) {
				return -1;
			}
			// Si 'a.diferencias' es false y 'b.diferencias' es true,
			// colocamos 'b' antes que 'a'
			if (!a.diferencias && b.diferencias) {
				return 1;
			}
			// Si ambos tienen el mismo valor de 'diferencias', no cambia su orden
			return 0;
		});
		setConciliaciones(newValue);
	};

	return (
		<ConciliacionesContext.Provider
			value={{
				conciliaciones,
				actualizarConciliaciones,
			}}
		>
			{children}
		</ConciliacionesContext.Provider>
	);
};
