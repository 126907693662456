import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { Checkbox } from "@material-ui/core";
import ModalEstadoPedidoDolar from "../ModalEstadoPedidoDolar/ModalEstadoPedidoDolar";
import { UserContext } from "../../../context/userContext/UserContexts";
import { roles } from "../../../helper/roles";
import { Pedido } from "../../../hooks/usePedidosDolares";

interface Props {
    id: string,
    estado: string,
    origen?: string | undefined,
    actualizarPedidos: (newValue: Pedido) => void,
    deletePedido: (removeValue: Pedido) => void,
    subtitulo?: string, 
}

export const EstadoPedidoDolar = ({ id, estado, origen, actualizarPedidos, deletePedido, subtitulo }: Props) => {

    const { user } = useContext(UserContext);

    function returnColor() {
        let background;
        let color;

        if (estado === "PENDIENTE") {
            background = "#FBE6B9";
            color = "#C99000";
        } else if (estado === "APROBADA") {
            background = "#85cafba0";
            color = "#066db7";
        } else if (estado === "EN PROCESO") {
            background = "#ff94f69f";
            color = "#b706a8";
        } else {
            background = "#f66975bd";
            color = "#e30f36";
        }

        return { color, background };
    }

    const [checkEstado, setCheckEstado] = useState<boolean>(false);    

    const handleCheckEstado = (newValue: boolean) => {
        setCheckEstado(newValue);
        if (newValue) {
            actualizarPedidos({id: id, origen: origen});
        } else {
            deletePedido({id: id, origen: origen});
        }
    };

    return (
        <div style={{borderRadius: "5px",}}>
            <Button
                id="demo-customized-button"
                disabled={(user.rol !== roles.administrador || user.rol !== roles.tesoreria) && subtitulo !== "PEDIDOS DOLARES"}
                variant="contained"
                size="small"
                style={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    height: "28px",
                    width: "135px",
                    borderRadius: "5px",
                    background: returnColor().background,
                    color: returnColor().color,
                }}
                endIcon={
                    ((user.rol === roles.administrador || user.rol === roles.tesoreria) && subtitulo === "PEDIDOS DOLARES")&&
                    <Checkbox
                    inputProps={{
                        "aria-label": "controlled",
                    }}
                    onChange={() => handleCheckEstado(!checkEstado)}
                    checked={checkEstado}
                    style={{
                        color: "#004B6F",
                        margin: 0,
                        padding: '4px',
                    }}
                    classes={{
                        checked: "Mui-checked",
                    }}
                    />
                }
            >
                {estado}
            </Button>
        </div>
    )
}
