import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ModalAlert from "../../../ModalAlert/ModalAlert";
import ModalSubsanarMail from "../../../ModalSubsanarMail/ModalSubsanarMail";
import { Texts } from "../Menu";

interface Props {
    id: string,
    fichaSinIniciar: boolean, 
    handleClose: () => void,
    cotitularesView: boolean, 
    handleOpenAlerta: (param:string) => void,
    texts: Texts,
    handleOpenSubsanar: () => void,
    openAlerta: boolean,
    text: string,
    email: string,
    openSubsanar: boolean,
    setOpenSubsanar: React.Dispatch<React.SetStateAction<boolean>>,
    idTitular: string,
}

export const ItemsCotitulares = (props : Props) => {

    const {
        id,
        fichaSinIniciar,
        handleClose,
        cotitularesView,
        handleOpenAlerta,
        texts,
        handleOpenSubsanar,
        openAlerta,
        text,
        email,
        openSubsanar,
        setOpenSubsanar,
        idTitular,
    } = props;

    return (
        <div>
            {cotitularesView && fichaSinIniciar && (
                <div>
                    <MenuItem
                        onClick={() => handleOpenAlerta(texts.emailReenviado)}
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        Reenviar mail
                    </MenuItem>
                    <MenuItem
                        onClick={handleOpenSubsanar}
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        Subsanar mail
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleOpenAlerta(texts.datosCopiados)}
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        Link apertura
                    </MenuItem>
                    <ModalAlert open={openAlerta} text={text} />
                    <ModalSubsanarMail
                        handleClose={handleClose}
                        id={id}
                        email={email}
                        open={openSubsanar}
                        setOpen={setOpenSubsanar}
                        idTitular={idTitular}
                    />
                </div>
            )}
        </div>
    );
};
