import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { DatosContainer, RazonText, SeccionButtons } from "./elements";



const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 470,
	height: 300,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 3,
};
const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
};

// Modal de confirmación para agregar una Juridica

interface Props {
    handleClose: () => void, 
    formAgregar: FormAgregar, 
    handleAgregar: (param : HandleAgregarProps) => void,  
    handleSubmit: (param : FormAgregar) => void, 
}

interface HandleAgregarProps {
    handleClose: () => void, 
    handleOpen: () => void, 
}

interface FormAgregar {
    razonSocial: string, 
    cuit: string, 
    numCuenta: string,
}

export default function ModalJuridica({handleClose, formAgregar, handleAgregar, handleSubmit } : Props) {
	
    const { width, height } = useWindowDimensions();

    const [open, setOpen] = React.useState<boolean>(false);

	const handleOpen = () => setOpen(true);
	const handleCerrar = () => {
		setOpen(false);
		handleClose();
	};

    const handeClick = () => {
        handleAgregar({handleClose, handleOpen})
    }

    const agregarJuridica = () => {
        handleSubmit(formAgregar)
    }

	return (
        <div
            style={{
                display: "flex",
            }}
        >
            <Button
                style={{
                    marginRight: "16px",
                    backgroundColor: "#004B6F",
                    fontSize: "0.8em",
                    fontWeight: "bold"
                }}
                onClick={handeClick}
                variant="contained"
                size="large"
            >
                AGREGAR
            </Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 400,
                            margin: "0.5em 0 0 0",
                        }}
                    >
                        ¿QUERES AGREGAR ESTA PERSONA JURÍDICA?
                    </Typography>
                    <form>
                        <DatosContainer>
                            <RazonText>{formAgregar.razonSocial}</RazonText>
                            <RazonText>{formAgregar.cuit}</RazonText>
                            <RazonText>N° {formAgregar.numCuenta}</RazonText>
                        </DatosContainer>
                        <SeccionButtons>
                            <Button
                                style={{
                                    ...styleButtons,
                                    marginRight: "16px",
                                    backgroundColor: "#004B6F",
                                    fontSize: "0.8em",
                                }}
                                onClick={agregarJuridica}
                                variant="contained"
                                size="large"
                            >
                                AGREGAR
                            </Button>

                            <Button
                                style={{
                                    ...styleButtons,
                                    marginRight: "16px",
                                    backgroundColor: "#C9C9C9",
                                    fontSize: "0.8em",
                                }}
                                onClick={handleCerrar}
                                variant="contained"
                                size="large"
                            >
                                CANCELAR
                            </Button>
                        </SeccionButtons>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
