import { TableColumn } from 'react-data-table-component';
import { RowCriterio } from '../../interfaces/interfacesRows/rows';

const columnsCriterios: TableColumn<RowCriterio>[] = [
    {
        selector: (row: RowCriterio) => row.fecha,
        name: "Fecha",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCriterio) => row.hora,
        name: "Hora",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCriterio) => row.cuenta,
        name: "N° de cuenta",
        center: true,
        grow: 1.5,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCriterio) => row.monto,
        name: "Monto de transferencia mayor a",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCriterio) => row.porcTenencia,
        name: "% Tenencia de activos",
        center: true,
        grow: 1.5,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCriterio) => row.modificacion,
        name: "Modificación",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowCriterio) => row.puntos,
        name: "",
        center: true,
        style: {
            color: "#004B6F",
        },
    },
];

export {
    columnsCriterios,
}
