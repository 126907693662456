import { GetDatosResponse } from "../interfaces/interfacesDatos/getDatosInterface.js";
import { SirenaClientResponse } from "../interfaces/interfacesDatos/getDatosSirena.js";
import { DeclaracionesResponse } from "../interfaces/interfacesDatos/getDeclaracionesInterface.js";
import { DatosPersonales } from "../interfaces/interfacesDatos/getNombreInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getDatos (id : string) {
	return await apiInstance.get<GetDatosResponse>(`/datos/${id}`);
};

export async function getNombre (id : string) {
	return await apiInstance.get<DatosPersonales>(`/ficha/${id}`);
};

export async function getDatosSirena(phone : string) {
	return await apiInstance.post<SirenaClientResponse>("/sirena/client", {phone});
}

export async function getSmsCode(id : string) {
	return await apiInstance.get(`/codeSms/${id}`)
}

export async function getEmailCode(id : string) {
	return await apiInstance.get(`/codeemail/${id}`)
}

export async function getDeclaraciones (id : string) {
	return await apiInstance.get<DeclaracionesResponse>(`/declaraciones/${id}`);
};

export async function setCuitVerificado (verificacion : string, idClient: string) {
	return await apiInstance.put(`/setCuitVerificado`, { verificacion, idClient});
};
