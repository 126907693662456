import React, { useContext } from "react";
import { AsideAppbar } from "../AsideAppbar/AsideAppbar";
import Appbar from "../Appbar/Appbar";
import Header from "../Header/Header";
import { UserContext } from "../../context/userContext/UserContexts";
import { Container } from "./elements";

export const Navbar = () => {
    
    const { appBarAside } = useContext(UserContext);

    const render = () => {
    if(appBarAside ===  false || appBarAside === undefined){
        return(
            <Appbar />
        )
    } else {
        return (
            <Container>
                    <Header />
                    <AsideAppbar />
            </Container>
        )
    }
    }

    return (
        <>
            {render()}
        </>
    );
}

