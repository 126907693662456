import { useContext, useEffect, useState, ChangeEvent, MouseEvent, FormEvent } from "react";
import usePendientesHook from "./usePendientesHook";
import useAprobadasHook from "./useAprobadasHook";
import useInformadasHook from "./useInformadasHook";
import useRechazadasHook from "./useRechazadasHook";
import useSubsanadasHook from "./useSubsanadasHook";
import useMovimientosHook from "./useMovimientosHook";
import useCotitularesHook from "./useCotitularesHook";
import { useTranferenciasHook } from "./useTransferenciasHook";
import { useCriteriosHook } from "./useCriteriosHook";
import { useDividendosTableHook } from "./useDividendosTable";
import { useEstadoRentasTable } from "./useEstadoRentasTable";
import { useDolaresTableHook } from "./useDolaresTable";
import { UserContext } from "../context/userContext/UserContexts";
import useOrdenesExteriorHook from "./useOrdenesExteriorHook";
import usePedidosDolaresHook from "./usePedidosDolares";
import { useCBUsTableHook } from "./useCBUsTable";
import { useConciliacionesTableHook } from "./useConciliacionesTable";

// Hook que manipula la data que va a renderizar el componente NewTable

interface Props {
	subtitulo: string,
	titularId: string,
	filtroTenencia: string | undefined,
}

export default function useTableHook({ subtitulo, titularId, filtroTenencia }: Props) {
	const { user, getAllManagers } = useContext(UserContext);
	const userRol = user ? user.rol : "";

	const [loading, setLoading] = useState(true);

	const { pending, filtPendiente, pendienteFilt, filtPorEstadoCuit } =
		usePendientesHook({ userRol, setLoading });

	const { aprobadasFiles, aprobadasFilt, filtAprobadas } = useAprobadasHook({
		userRol,
		setLoading,
	});

	const { informadasFiles, informadasFilt, filtInformadas } =
		useInformadasHook({ userRol, setLoading });

	const { rechazadasFiles, rechazadasFilt, filtRechazadas } =
		useRechazadasHook({ userRol, setLoading });

	const { subsanadasFiles, subsanadasFilt, filtSubsanados } =
		useSubsanadasHook({ userRol, setLoading });

	const { allMovimientos, movimientosFilt, filtMovimientos } =
		useMovimientosHook({ userRol, setLoading });

	const { allCotitulares, filtCotitulares, cotitularesFilt } =
		useCotitularesHook({ userRol, setLoading, titularId });

	const {
		getAllTranferencias,
		transferenciasPendientes,
		transferenciasAprobadas,
		transferenciasAnuladas,
		filtTransferencia,
	} = useTranferenciasHook({ subtitulo, setLoading });

	const { getAllCriterios, criteriosFilt, filtCriterios } = useCriteriosHook({
		subtitulo,
		setLoading,
	});

	const { dolaresFilt, filtDolares } = useDolaresTableHook({ userRol, setLoading });

	const { dividendosFilt, filtDividendos } =
		useDividendosTableHook({ userRol, setLoading });

	const { conciliacionesFilt, filtconciliaciones } =
		useConciliacionesTableHook({ userRol, setLoading });

	const { filtRentas, getAllRenta, rentasFilt } =
		useEstadoRentasTable({ userRol, setLoading });

	const { getOrdenesExteriorData, ordenesExteriorFilt, filtOrdenesExterior } =
		useOrdenesExteriorHook({ setLoading });

	const { cbusFilt, filtCBUs } = useCBUsTableHook({ userRol, setLoading });


	//se llama a las funciones que hacen las peticiones al montarse el componente

	useEffect(() => {
		getAllManagers();
		switch (subtitulo) {
			case "PENDIENTES":
				pending();
				break;
			case "APROBADOS":
				aprobadasFiles();
				break;
			case "INFORMADOS":
				informadasFiles();
				break;
			case "RECHAZADOS":
				rechazadasFiles();
				break;
			case "HISTORIAL DE MOVIMIENTOS":
				allMovimientos();
				break;
			case "SUBSANADOS":
				subsanadasFiles();
				break;
			case "CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS":
				getAllCriterios();
				break;
			case "ESTADO DE RENTAS":
				getAllRenta();
				break;
			case "ÓRDENES A OPERAR":
				getOrdenesExteriorData();
				break;
			// case "DIVIDENDOS":
			// getAllCriterios();
			// break;
			default:
				break;
		}

		if (subtitulo.includes("COTITULARES")) {
			allCotitulares();
		}
		if (subtitulo.includes("TRANSFERENCIAS")) {
			getAllTranferencias();
		}
	}, []);

	const [name, setName] = useState("");

	// Renderización según el caso
	let data;
	let filtFunction: (name: string) => void;

	if (subtitulo === "PENDIENTES") {
		filtFunction = filtPendiente;
		data = pendienteFilt;
	}

	if (subtitulo === "INFORMADOS") {
		filtFunction = filtInformadas;
		data = informadasFilt;
	}

	if (subtitulo === "APROBADOS") {
		filtFunction = filtAprobadas;
		data = aprobadasFilt;
	}

	if (subtitulo === "RECHAZADOS") {
		filtFunction = filtRechazadas;
		data = rechazadasFilt;
	}

	if (subtitulo === "SUBSANADOS") {
		filtFunction = filtSubsanados;
		data = subsanadasFilt;
	}

	if (subtitulo === "HISTORIAL DE MOVIMIENTOS") {
		filtFunction = filtMovimientos;
		data = movimientosFilt;
	}

	if (subtitulo === "TRANSFERENCIAS PENDIENTES") {
		filtFunction = filtTransferencia;
		data = transferenciasPendientes;
	}

	if (subtitulo === "TRANSFERENCIAS APROBADAS") {
		filtFunction = filtTransferencia;
		data = transferenciasAprobadas;
	}

	if (subtitulo === "TRANSFERENCIAS ANULADAS") {
		filtFunction = filtTransferencia;
		data = transferenciasAnuladas;
	}

	if (subtitulo.includes("COTITULARES")) {
		filtFunction = filtCotitulares;
		data = cotitularesFilt;
	}

	if (subtitulo === "CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS") {
		filtFunction = filtCriterios;
		data = criteriosFilt;
	}

	if (subtitulo === "DIVIDENDOS") {
		filtFunction = filtDividendos;
		data = dividendosFilt;
	}

	if (subtitulo === "CONCILIACIONES") {
		filtFunction = filtconciliaciones;
		data = conciliacionesFilt;
	}

	if (subtitulo === "DOLARES") {
		filtFunction = filtDolares;
		data = dolaresFilt;
	}

	if (subtitulo === "ESTADO DE RENTAS") {
		filtFunction = filtRentas;
		data = rentasFilt;
	}

	if (subtitulo === "ÓRDENES A OPERAR") {
		filtFunction = filtOrdenesExterior;
		data = ordenesExteriorFilt;
	}

	if (subtitulo === "CBUs") {
		filtFunction = filtCBUs;
		data = cbusFilt;
	}

	const paginationOpciones = {
		rowsPerPageText: "Filas por página",
		rangeSeparatorText: "de",
		selectAllRowsItem: true,
		selectAllRowsItemText: "Todos",
	};

	function handleInput(e: ChangeEvent<HTMLInputElement>) {
		setName(e.target.value);
		filtFunction(e.target.value);
	}

	function handleSubmit(e: ChangeEvent<HTMLFormElement>) {
		e.preventDefault();

		setName(e.target.value);
		filtFunction(e.target.value);
	}

	//Filtro por estado de cuit

	const cuitOpciones = [
		"Todas",
		"Bloqueado",
		"Temporalmente Inhabilitado",
		"Inactivo",
		"Habilitado",
	];

	const [listaCuitOpciones, setListaCuitOpciones] = useState("Todas");

	const [openEstadoCuit, setOpenEstadoCuit] = useState<null | HTMLElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleOpenEstadoCuit = (event: MouseEvent<HTMLElement>) => {
		setOpenEstadoCuit(event.currentTarget);
	};

	const handleClickEstadoCuit = (event: MouseEvent<HTMLElement>, index: number) => {
		setSelectedIndex(index);
		setListaCuitOpciones(cuitOpciones[index]);
		filtPorEstadoCuit(cuitOpciones[index]);
		setOpenEstadoCuit(null);
	};

	const handleCloseEstadoCuit = () => {
		setOpenEstadoCuit(null);
	};

	return {
		handleInput,
		handleSubmit,
		data,
		paginationOpciones,
		loading,
		userRol,
		handleOpenEstadoCuit,
		cuitOpciones,
		openEstadoCuit,
		handleClickEstadoCuit,
		handleCloseEstadoCuit,
		selectedIndex,
		name,
	};
}
