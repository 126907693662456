
import { TableColumn } from 'react-data-table-component';
import { RowConciliacionesTable } from '../../interfaces/interfacesRows/rows';
import { formatCurrency } from '../formatCurrency';

const errorColor = "#FFBABA";

const columnsConciliacionesTable: TableColumn<RowConciliacionesTable>[] = [
    {
        selector: (row: RowConciliacionesTable) => row["fecha_Caja"],
        name: "Fecha",
        center: true,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowConciliacionesTable) => row["accountNumber_Gallo"] !== "" ? row["accountNumber_Gallo"] : row["cuenta_Caja"].toString(),
        name: "Comitente/s",
        center: true,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    // {
    //     selector: (row: RowConciliacionesTable) => row["cuitCapturado_Caja"],
    //     name: "Cuit captado",
    //     center: true,
    //     style: {
    //         color: "#004B6F",
    //     },
    //     conditionalCellStyles: [
    //         {
    //             when: (row: RowConciliacionesTable) => row["diferencias"] === true,
    //             style: {
    //                 backgroundColor: errorColor,
    //             },
    //         },
    //     ],
    // },
    // {
    //     selector: (row: RowConciliacionesTable) => row["comprobante_Gallo"],
    //     name: "Comprobante",
    //     center: true,
    //     grow: 2,
    //     style: {
    //         color: "#004B6F",
    //     },
    //     conditionalCellStyles: [
    //         {
    //             when: (row: RowConciliacionesTable) => row["diferencias"] === true,
    //             style: {
    //                 backgroundColor: errorColor,
    //             },
    //         },
    //     ],
    // },
    {
        selector: (row: RowConciliacionesTable) => row["importe_Caja"] === "" ? "" : formatCurrency(row["importe_Caja"]),
        name: "Importe en caja",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowConciliacionesTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },

    {
        selector: (row: RowConciliacionesTable) => row["importe_Gallo"] === "" ? "" : formatCurrency(row["importe_Gallo"]),
        name: "Importe en gallo",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
];

export {
    columnsConciliacionesTable
};
