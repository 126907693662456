import * as React from "react";
import { useState } from "react";
import { agregarNumCuenta, asignarNumCuenta } from "../../api/aprobadas";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ButtonsContainer, Input, InputContainer, WarningContainer, WarningText } from "./elements";


const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
    alingItems: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	height: 260,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 2,
};
const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
};

//Modal para ingresar y/o modificar el número de cuenta de cada ficha

interface Props {
    handleClose: () => void, 
    id: string, 
    num: string | number, 
    open: boolean, 
    setOpen: (param:boolean) => void, 
    nombre: string,
}

export default function ModalNumCuenta({handleClose, id, num, open, setOpen, nombre } : Props) {

    const [accountNumber, setAccountNumber] = useState<string>('');
    const [showWarning, setShowWarning] = useState<boolean>(false);
	
	const handleCerrar = () => {
        setShowWarning(false)
		setOpen(false);
		handleClose();
	};

    const handleClick = async () => {
        try {
            if (accountNumber !== "" && showWarning) {
                const res = await agregarNumCuenta(id, accountNumber);
                if (res.status === 200) {
                    window.location.reload();
                }
            } else if (accountNumber !== "" && !showWarning) {
                setShowWarning(true)
            }
        } catch (error) {
            console.log(error)
        }
    };

	return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 18,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 320,
                            margin: "0 auto",
                            marginTop: "0.5em",
                        }}
                    >
                        {`ASIGNAR UN NÚMERO DE CUENTA A ${nombre.toUpperCase()}`}
                    </Typography>
                    {
                        showWarning ?
                            <>
                                <WarningContainer>
                                    <WarningText>Considerá que el número de cuenta no puede modificarse y, si aceptás, se le enviará un mail automático al cliente con dicho número.</WarningText>
                                </WarningContainer>
                            </>
                            :
                            <>
                                <InputContainer>
                                    <Input
                                        onChange={(e) => setAccountNumber(e.target.value)}
                                    />
                                </InputContainer>
                            </>
                    }
                    <ButtonsContainer>
                        <Button
                            style={{
                                ...styleButtons,
                                background: "#004B6F",
                                marginRight: "16px",
                            }}
                            onClick={handleClick}
                            variant="contained"
                            size="small"
                        >
                            CONFIRMAR
                        </Button>
                        <Button
                            style={{
                                ...styleButtons,
                                background: "#C9C9C9",
                            }}
                            onClick={handleCerrar}
                            variant="contained"
                            size="small"
                        >
                            CANCELAR
                        </Button>
                    </ButtonsContainer>
                </Box>
            </Modal>
        </div>
    );
}
