import styled from "styled-components";

export const Text = styled.p`
    position: relative;
    display: flex;
    white-space: normal; // Permite que el texto se ajuste
    word-wrap: break-word;
`;

export const ContainerText = styled.div`
    position: absolute;
    background-color: transparent;
    color: transparent;
    padding: 0.5em;
    border-radius: 1em;
    top: 1em;
    display: flex;
    align-content: center;
    width: 18em;
    height: max-content;
    cursor: context-menu;
    &:hover {
        background-color: aqua;
        color: #004B6F;
    }
`;