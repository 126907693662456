import React, { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { CardInfoCuenta } from '../../components/TenenciaComponents/CardInfoCuenta/CardInfoCuenta';
import { CardInfoUser } from '../../components/TenenciaComponents/CardInfoUser/CardInfoUser';
import CardEspecieTenencia from '../../components/TenenciaComponents/CardEspeciesTenencia/CardEspecieTenencia';
import { useTenenciaHook } from "../../hooks/useTenenciaHook";
import { CircularProgress } from "@mui/material";
import { CardsInfoCuentaontainer, CardsInfoUserContainer, Container, ContainerError, ContainerEspecies, ContainerLoading, SearchButton, SearchContainer, SearchContainerSinInput, SecondContainer, Subtitulo, SubtituloContainer, TenenciaContainer, TextoError } from "./elements";
import { columnsTransferenciasDolares } from "../../helper/Colums/columsTransferenciasDolares";
import TableDolarRenta from "../../components/TableDolarRenta/TableDolarRenta";

export const Tenencia = () => {

    const { width, height } = useWindowDimensions();

    const {
        handleInput,
        handleSubmit,
        changeColor,
        loading,
        infoCuenta,
        nroCuentaCliente,
        handleKeyDown,
        error,
        actualOption,
        setActualOption,
        setTipoDolarImporte,
        transferirDolares,
        sortArray,
    } = useTenenciaHook();

    const [reloadTable, setReloadTable] = useState<boolean>(false);

    return (
        <Container
            style={{
                width: width * 0.85,
                height: height * 0.92,
                left: width * 0.15,
                top: height * 0.062,
            }}>

            <div>
                <SubtituloContainer>
                    <Subtitulo>
                        BUSCÁ EL NÚMERO DE CUENTA DEL CLIENTE/EMPRESA
                    </Subtitulo>
                </SubtituloContainer>
                <SearchContainerSinInput>
                    <SearchContainer
                        type="text"
                        placeholder="Buscar..."
                        onChange={(e) => handleInput(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <SearchButton
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                    >
                        <img
                            src={require("../../images/tabla/search-icon.png")}
                            alt="logo"
                            style={{ width: "1em" }}
                        />
                    </SearchButton>
                </SearchContainerSinInput>
            </div>
            {
                loading
                    ?
                    <ContainerLoading>
                        <CircularProgress style={{ color: "#004B6F" }} />
                    </ContainerLoading>
                    : infoCuenta
                        ?
                        <SecondContainer>
                            <CardsInfoUserContainer>
                                <CardInfoUser
                                    userName={infoCuenta.userName}
                                    nroCuenta={nroCuentaCliente}
                                />
                            </CardsInfoUserContainer>
                            <CardsInfoCuentaontainer>
                                <CardInfoCuenta
                                    tipoMercado='LOCAL'
                                    pesosEnCuenta={infoCuenta.cartera.actualWallet.pesos}
                                    dolaresMercadoLocal={infoCuenta.cartera.actualWallet.dolarBillete}
                                    variacion={infoCuenta.variacionMercadoLocal}
                                    img={require('../../images/banderas/bandera-argentina-cuenta.png')}
                                />
                                <CardInfoCuenta
                                    tipoMercado='EXTERIOR'
                                    dolaresEnCuenta={infoCuenta.cartera.actualWallet.dolarExterior}
                                    variacion={infoCuenta.variacionMercadoExterior}
                                    img={require('../../images/banderas/bandera-usa.png')}
                                />
                            </CardsInfoCuentaontainer>

                            <TableDolarRenta subtitulo={"PEDIDOS DOLARES TENENCIA"} columns={columnsTransferenciasDolares} filtroTenencia={nroCuentaCliente} reloadTable={reloadTable} setReloadTable={setReloadTable} />

                            <TenenciaContainer>
                                <ContainerEspecies>
                                    {
                                        sortArray(infoCuenta.tenenciaMercadoLocal).map((t, index) =>
                                            <CardEspecieTenencia
                                                key={t.nombreEspecie}
                                                especie={t.ticker}
                                                precio={t.precioActual}
                                                cantidad={t.cantidad}
                                                importe={t.importe}
                                                porcentaje={t.variacionDia}
                                                costo={t.costoCompra}
                                                variacionCosto={t.variacionCosto}
                                                tipoOpcion={actualOption}
                                                pintar={changeColor(index)}
                                                tipoMercado='LOCAL'
                                                nombreEspecie={t.nombreEspecie}
                                                setTipoDolarImporte={setTipoDolarImporte}
                                                transferirDolares={transferirDolares}
                                                dolarRenta={infoCuenta.pedidosDolarRenta}
                                                setReloadTable={setReloadTable}
                                            />
                                        )}
                                </ContainerEspecies>
                                <ContainerEspecies>
                                    {
                                        sortArray(infoCuenta.tenenciaMercadoExterior).map((t, index) =>
                                            <CardEspecieTenencia
                                                key={t.nombreEspecie}
                                                especie={t.ticker}
                                                precio={t.precioActual}
                                                cantidad={t.cantidad}
                                                importe={t.importe}
                                                porcentaje={t.variacionDia}
                                                costo={t.costoCompra}
                                                variacionCosto={t.variacionCosto}
                                                tipoOpcion={actualOption}
                                                pintar={changeColor(index)}
                                                tipoMercado='EXTERIOR'
                                                nombreEspecie={t.nombreEspecie}
                                                setReloadTable={setReloadTable}
                                            />
                                        )}
                                </ContainerEspecies>
                            </TenenciaContainer>
                        </SecondContainer>
                        : error.length
                            ? <ContainerError>
                                <TextoError>{error}</TextoError>
                            </ContainerError>
                            : null
            }
        </Container>
    )
}

