import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getDatosSirena } from "../../api/datos";
import { ConstructionOutlined } from "@mui/icons-material";
import { SirenaClientResponse, Body } from "../../interfaces/interfacesDatos/getDatosSirena";


const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 440,
	height: 240,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 4,
};

// Muestra si el cliente esta registrado o no en sirena


interface Props {
    handleClose: () => void,
    telefono: number,
    open: boolean,
    setOpen: (param:boolean) => void,
}


export default function ModalSirena({ handleClose, telefono, open, setOpen} : Props) {

    const [managerAsignado, setManagerAsignado] = useState<string>('');
    const [enSirena, setEnSirena] = useState<string>('');

    useEffect(() => {
        datosSirena();
    }, []);
    
	const handleCerrar = () => {
		setOpen(false);
		handleClose();
	};

    
	const datosSirena = async () => {
        let phone: string = telefono.toString()
		const resp = await getDatosSirena(phone);
        const res = resp.data.body.name;
        let enSirena = res.msg
        let manager
        res.text ? manager=res.text : manager='';
        setEnSirena(enSirena)
        setManagerAsignado(manager)
	}



    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleCerrar}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={handleCerrar}
                        style={{
                            color: "#004B6F",
                            // marginLeft: "15.8em",
                            marginLeft: enSirena !== "El usuario esta en sirena" ? "15.8em" : "16em",
                        }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 300,
                            margin: "0 auto",
                            marginTop: "0.5em",
                        }}
                    >
                        {enSirena === "El usuario esta en sirena" ? (
                            <CheckCircleIcon
                                style={{ color: "#4CC70A", fontSize: 50 }}
                            />
                        ) : (
                            <CancelIcon
                                style={{ color: "#FF0000", fontSize: 50 }}
                            />
                        )}
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 22,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 350,
                            margin: "0 auto",
                            marginTop: enSirena === "El usuario esta en sirena" ? "0.8em" : '1em',
                            marginBottom: enSirena === "El usuario esta en sirena" ? "2em" : '2.5em',
                        }}
                    >
                        {
                            enSirena === "El usuario esta en sirena" ?
                            `${enSirena.toLocaleUpperCase()}
                            ${managerAsignado.toLocaleUpperCase()}
                            `
                            : 
                            `EL USUARIO NO ESTA EN SIRENA`
                        }
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
