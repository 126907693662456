import React from "react";
import Table from "../../components/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useLocation, useParams } from "react-router-dom";
import { columnsCotitulares } from "../../helper/Colums/columsCotitulares";
import { Container } from "./elements";

export const Cotitulares = () => {

    const { width } = useWindowDimensions();

    const { id } = useParams();
    const location = useLocation();
    const cliente = location.state;
    const subtitulo = `COTITULARES DE ${cliente.toUpperCase()}`

    return (
        <Container style={{ width: width * 0.85, left: width * 0.15,  } }>
            <Table 
                subtitulo={subtitulo}
                columns= {columnsCotitulares}
                titularId={id}
            />
        </Container>
    );
}