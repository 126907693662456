import { useState, useEffect, ChangeEvent, FormEvent } from "react"
import { autorizarClientes } from "../api/autorizados";
import { AxiosResponse } from "axios";
import { AutorizarClienteResponse } from "../interfaces/interfaceAutorizados/postAutorizarCliente";
import { GetUsuarioAppResponse } from "../interfaces/interfaceUsuarioApp/getUsuarioAppInterface";
import { getUsuarioApp } from "../api/usuario_app";

// Hook que manipula el formulario para agregar un autorizado

export interface FormConsultarPin {
    DNI: string, 
    accountNumber: string,
}

export interface Errors {
    incompletos?: string;
    request?: string;
}

export interface ValidationResult {
    [key: string]: string;
}

const validateForm = (form : FormConsultarPin): ValidationResult =>{
    let errors : ValidationResult = {};

    if(!form.DNI || !form.accountNumber){
        errors.incompletos = "Campos incompletos"
    }

    return errors;
}


export const useFormConsultarPin = (initialForm: FormConsultarPin) => {

    const [form, setForm] = useState<FormConsultarPin>(initialForm);
    const [errors, setErrors] = useState<Errors>({});
    const [successText, setCuccessText] = useState<string>('');

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }, [form, Object.keys(errors).length]);

    const handleChange = (e : ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value,
        });

        successText !== '' && setCuccessText('');

        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }
    

    const handleSubmit = async(e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        successText !== '' && setCuccessText('');
        
        let errorsVariableEstatica = validateForm(form);
        setErrors(validateForm(form));

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorsVariableEstatica).length === 0 
            ) {
                try {
                    const res: AxiosResponse<GetUsuarioAppResponse> =
                        await getUsuarioApp(form);
                    if (res.status === 200) {
                        res.data.body.user === null
                            ? setCuccessText(
                                  `El cliente ${form.DNI}, cuenta ${form.accountNumber} NO generó PIN`
                              )
                            : setCuccessText(
                                  `El cliente ${form.DNI}, cuenta ${form.accountNumber} ya generó PIN`
                              );
                        setForm(initialForm);
                    }
                } catch (error) {
                    setErrors({ request: "Ocurrió un error" });
                    console.log(error);
                }
        } 
    }

    return {
        form,
        errors,
        handleChange,
        handleSubmit,
        successText,
    }
}