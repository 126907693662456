import { GetArchivos } from "../interfaces/interfacesDocumentos/getDocumentosInterface.js";
import apiInstance from "../utils/Axios.js";
import FormData from "form-data";

export async function adjuntarArchivo(archivos : any, position :  number, idFicha : string){
	let formData = new FormData();
	
		formData.append("attachments", archivos);
		formData.append("clientId", idFicha);
		formData.append("order", position);
	
	return await apiInstance.post("/upload", formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});
};

export async function getArchivos(id : string){
	return await apiInstance.get<GetArchivos>(`/attachments/${id}`);
};

export async function putArchivo(archivo : any, archivoId : string, idFicha : string, position : number){
	let formData = new FormData();

	formData.append("attachments", archivo);
	formData.append("clientId", idFicha);
	formData.append("order", position);
	return await apiInstance.put(`/upload/${archivoId}`, formData, {
		headers: { "Content-Type": "multipart/form-data" }
	});
};
