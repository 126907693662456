import React, { useContext } from "react";
import ModalNumCuenta from "../../ModalNumCuenta/ModalNumCuenta";
import { asignarNumCuenta } from "../../../api/aprobadas";
import { roles } from "../../../helper/roles";
import { UserContext } from "../../../context/userContext/UserContexts";
import { Boton, NumContainer } from "./elements";

//Se renderiza en todas las filas de la columna N° de cuenta en la screen aprobadas

interface Props {
    id: string,
    num: string | number,
    usuarioViejo: boolean,
    cotitular: boolean,
    nombre: string, 
    razonSocial?: string,
}

export const NumDeCuenta = ({id, num, usuarioViejo, cotitular, nombre, razonSocial} : Props) => {
    
    const [open, setOpen] = React.useState(false);

	const handleOpen = () => setOpen(true);
	
	const handleClose = () => {
		setOpen(false);
	};
    
    const { user } = useContext(UserContext);

    return (
        <div>
            {
					user.rol === roles.comercial || user.rol === roles.operador || usuarioViejo || cotitular || razonSocial || num ? 
                    (
                        <>
                            <NumContainer>
                                {num === "" ? 'Sin asignar' : num }
                            </NumContainer>
                        </>
                    )
                    :
                    (
                        <>
                            <Boton
                                onClick={handleOpen}
                            >
                                {num ? num : 'Asignar'}
                            </Boton>
                            <ModalNumCuenta id={id} handleClose={handleClose} num={num} open={open} setOpen={setOpen} nombre={nombre} />
                        </>
                    )
				}
        </div>
    )
}