import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    padding-left: 1em;
    padding-top: 1em;
`

export const Subtitulos = styled.h3`
    color: #00386E;
    font-size: 1.5em;
    margin: 0.1em 0 0 0;
`

export const LabelForm = styled.label`
	font-size: 1em;
	margin-bottom: 0.2em;
	margin-top: 0.3em;
    font-weight: bold;
    @media(min-width: 1600px){
        margin-bottom: 0.5em;
        margin-top: 0.5em;
    }
`

export const Input = styled.input` 
    display: none;
`

export const Label = styled.label` 
    display: flex;
    padding: 0.5em;
    background: #ecf5ff;
    box-shadow: inset 4px 4px 10px 0px rgba(177, 214, 255, 0.4), inset 4px 4px 14px rgba(177, 214, 255, 0.3);
    border-radius: 5px;
    border:  0.1px solid rgba(177, 214, 255, 0.3);
    cursor: pointer;
    color: #00386E;	
    font-weight: bolder;
    align-items: center;
    font-size: 0.8em;
    width: 20%;
    @media(min-width: 1600px){
        width: 20%;
    }
`

export const SeccionButtons = styled.div`
    margin-top: 2em;
    margin-bottom: 0;
    display: flex;
    @media(min-width: 1600px){
        margin-top: 4em;
    }
`

export const Error = styled.p`
	color: #E3342F;
	margin-top: 0.5em;
    margin-bottom: 0;
	font-size: 0.8em;
	font-weight: bold;
    @media(min-width: 1600px){
        font-size: 0.9em;
    }
`

export const FileText = styled.p`
    color: #00386E;
    font-weight: bold;
    font-size: 0.8em;
    margin-left: 1.8em;
    margin-top: 1em;
`
