import { PendientesResponse } from "../interfaces/interfacesPendientes/getPendientesInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getPendientes() {
	return await apiInstance.get<PendientesResponse>("/pendingFiles");
}

export async function asignarManager (id:string, manager: { manager: string; }) {
	return await apiInstance.put(`/asignar/${id}`, manager);
};

export async function getManagerById (id:string) {
	return await apiInstance.get(`/manager/${id}`);
};

// export async function getManagers () {
// 	return await apiInstance.get(`/manager/`);
// };
export async function getManagers () {
	return await apiInstance.get(`/v2/manager/`);
};





