import styled from "styled-components";

export const ElementosContainer = styled.div`
    display: flex;
	flex-direction: column;
`;

export const TitleForm = styled.span`
	margin-bottom: 12px;
	font-weight: bold;
`;

export const FormContainer = styled.div`
    display: flex;
	justify-content: space-between;
`;

export const FormSelectsContainer = styled.div`
    display: flex;
	flex-direction: column;
	width: 50%;
`;

export const TitleEtapa = styled.span`
	margin-bottom: 16px;
`;

export const TextSelecciona = styled.span`
	color: gray;
`;

export const TitleError = styled.span`
	margin-bottom: 16px;
	margin-top: 16px;
`;

export const TextContainer = styled.div`
    display: flex;
	flex-direction: column;
	width: 50%;
	margin-left: 16px;
`;

