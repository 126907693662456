import React from "react";
import { useEffect } from "react";

//Renderiza la differencia en horas de la última updatedAt y la hora actual

interface Props {
	updatedAt: string,
}

export const Time = ({ updatedAt } : Props) => {
	let fechaActual: Date;
	let updateAtInTimestap: Date;
	let difference: number;
	const [differenceInHours, setDifferenceInHours] = React.useState("");


	useEffect(() => {
        let diferenciaEnHoras;
		fechaActual = new Date();
		updateAtInTimestap = new Date(updatedAt);
		difference = fechaActual.getTime() - updateAtInTimestap.getTime();
        diferenciaEnHoras = Math.abs(difference / (1000 * 60 * 60)); // Calcula la diferencia en horas

		// Formatea el resultado como una cadena de texto
		const formattedDifference = diferenciaEnHoras.toFixed(2);
        setDifferenceInHours(formattedDifference);
	}, []);

	return <>{differenceInHours}</>;
};
