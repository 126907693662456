import styled from "styled-components";

export const Text = styled.p`
    position: absolute;
    background-color: transparent;
    color: transparent;
    padding: 0.5em;
    border-radius: 1em;
    bottom: 1.5em;
    cursor: context-menu;
    margin-bottom: 1em;
    &:hover {
        background-color: aliceblue;
        color: #004B6F;
    }
`;