import styled from "styled-components";

export const Container = styled.div`
	height: calc(100vh - 3em);
    position: absolute;
    top: 3em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Sub = styled.h3`
    font-size: 2em;
    color: rgba(0, 75, 111, 0.75);
`;

export const Box = styled.div`
	background-color: white;
    margin-top: 0.5em;
	height: 92%;
    width: 100%;
    border-radius: 0.8em;
    margin-bottom: 0;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    margin-left: 1.5em;
    width: 40%;
`

export const LabelForm = styled.label`
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
`;

export const Input = styled.input`
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
	border-radius: 5px;
	padding: 0.8em;
	color: #00386E;	
    border:  0.1px solid #EBEBED;
    outline: none;
	@media (max-width: 992px) {
		font-size: 14px;
	}
	@media (min-width: 1600px) {
		font-size: 0.9em;
        margin-bottom: 1.5em;
	}
`;

export const SeccionButton = styled.div`
    position: absolute;
    bottom: 4em;
    left: 40%;
    display: flex;
`;

export const TextError = styled.p`
    color: #E3342F;
	margin-top: 1.5em;
    margin-bottom: 0;
	font-size: 1em;
	font-weight: bold;
    /* @media(min-width: 1600px){
        font-size: 1em;
    } */
`;



