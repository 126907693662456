import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const EstadoContainer = styled.div`
    padding: 0.2em 0.5em;
    border-radius: 0.2em;
    text-align: center;
    margin: 0;
`;

export const EstadoText = styled.p`
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
`;

export const Button = styled.button`
	background: none;
    border: none;
    margin-top: 0.2em;
    text-align: center;
    cursor: pointer;
`;
