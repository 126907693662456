import { TableColumn } from 'react-data-table-component';
import { RowOperarFinal } from '../interfaces/interfacesRows/rows';

const columnsOperarFinal: TableColumn<RowOperarFinal>[] = [
    {
        selector: (row: RowOperarFinal) => row.comi,
        name: "CLIENTE",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOperarFinal) => row.oper,
        name: "OPERACIÓN",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOperarFinal) => row.espe,
        name: "ESPECIE",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOperarFinal) => row.cantidad,
        name: "CANTIDAD",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOperarFinal) => row.precio,
        name: "PRECIO",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        cell: (row: RowOperarFinal) => row.estado,
        name: "ESTADO",
        center: true,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOperarFinal) => row.tipo,
        name: "TIPO",
        center: true,
        grow: 2,
        style: {
            color: '#004B6F',
        },
    },
];

export default columnsOperarFinal;
