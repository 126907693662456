import { useState } from "react";
import Button from "@mui/material/Button";
import { getSubsanadas } from "../api/subsanados";
import { CotitularesLink } from "../components/Table/CotitularesLink/CotitularesLink";
import { Ficha } from "../components/Table/Ficha/Ficha";
import FadeMenu from "../components/Table/TableButton/Menu";
import { ManagerText } from "../components/Table/ManagerText/ManagerText";
import { EstadoButton } from "../components/Table/EstadoButton/EstadoButton";
import ModalMotivos from "../components/ModalMotivos/ModalMotivos";
import { AxiosResponse } from "axios";
import { SubsanadasResponse } from "../interfaces/interfaceSubsanadas/getSubsanadasInterface";

// Hook que manipula la data de las fichas subsanadas

interface Subsanada {
    id: string;
    fecha: string;
    hora: string;
    nombre: string;
    ficha: JSX.Element;
    manager: JSX.Element;
    email: string;
    estado: JSX.Element;
    puntos: JSX.Element;
}

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

export default function useSubsanadasHook({userRol, setLoading}: Props) {

    const [subsanadas, setSubsanadas] = useState<Subsanada[]>([]);
    const [subsanadasFilt, setSubsanadasFilt] = useState<Subsanada[]>([]);

    const handleClose = () => {
		setAnchorEl(null);
	};
	const [anchorEl, setAnchorEl] = useState(null);

	// Petición a la api
	
    const subsanadasFiles = async () => {
		let subsanadasData;
		try {
			const res : AxiosResponse<SubsanadasResponse> = await getSubsanadas();

			subsanadasData = res.data.body.subsanadas;
			subsanadasData = subsanadasData.reverse();
			subsanadasData = subsanadasData.map((e) => ({
                id: e._id,
                fecha: `${e.createdAt.slice(8, 10)}-${e.createdAt.slice(5, 7)}`,
                hora: e.createdAt.slice(11, 19),
                nombre: `${e.name} ${e.last_name}`,
                ficha: <Ficha id={e._id} />,
                manager: <ManagerText manager_id={e.manager} />,
                email: e.email,
                estado:
                    e.emailCotitulares.flat().length  !== 0  ? (
                        <div>
                            <CotitularesLink
                                id={e._id}
                                nombre={`${e.name} ${e.last_name}`}
                                texto="Subsanada"
                            />
                        </div>
                    ) : (
                        <ModalMotivos
                            id={e._id}
                            handleClose={handleClose}
                            subsanados={true}
                        />
                    ),
                puntos: (
                    <FadeMenu
                        id={e._id}
                        nombre={`${e.name} ${e.last_name}`}
                        telefono={e.phone}
                        cotitular={e.cotitular}
                    />
                ),
            }));

			setSubsanadas(subsanadasData);
			setSubsanadasFilt(subsanadasData);
			!(subsanadasData?.length > 0) && setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

    // Funcion de filtro
	
	const filtSubsanados = (name : string) => {
		name = name.toLowerCase();

		let filtered = subsanadas.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.hora.toLowerCase().includes(name) ||
                e.nombre.toLowerCase().includes(name) ||
                e.email.toLowerCase().includes(name)
        );

		setSubsanadasFilt(filtered);
        filtered.length === 0 && setLoading(false);
	};

	return {
        subsanadasFiles,
		subsanadasFilt,
        filtSubsanados,
	};
}
