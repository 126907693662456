import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import ModalJuridica from "../../components/ModalJuridica/ModalJuridica";
import { useFormJuridica } from "../../hooks/useFormJuridica";
import { ErrorsContainer, Form, FormContainer, Input, LabelForm, OptionContainer, OptionContainerMini, SeccionButtons, Sub, Subtitulo, TextError, TextForm, WhiteBox } from "./elements";
import useWindowDimensions from "../../hooks/useWindowDimensions";


export const AgregarJuridica = () => {

    const { width, height } = useWindowDimensions();

    const initialForm = {
        razonSocial: "",
        cuit: "",
        numCuenta: ""
    };

    const { form, errors, handleChange, handleCancelar, handleAgregar, handleSubmit} = useFormJuridica(
        initialForm,
    );

    const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	
    const handleClose = () => {
		setAnchorEl(null);
	};



    return (
        <div
            style={{
                left: width * 0.165,
                width: width * 0.82,
                height: "91%",
                position: "absolute",
                top: "3em",
                display: "flex",
                flexDirection: "column",
                marginTop: "1em",
                marginBottom: 0,
                padding: 0,
                overflow: "hidden",
            }}
        >
            <Subtitulo>PERSONAS JURÍDICAS</Subtitulo>
            <WhiteBox>
                <FormContainer>
                    <Sub>AGREGAR PERSONA JURÍDICA</Sub>
                    <Form>
                        <OptionContainer>
                            <LabelForm>Razón social</LabelForm>
                            <TextForm>
                                Escribir la razón social
                            </TextForm>

                            <Input
                                type="text"
                                name="razonSocial"
                                value={form.razonSocial}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>CUIT</LabelForm>
                            <TextForm>Escribir el CUIT</TextForm>

                            <Input
                                type="text"
                                name="cuit"
                                value={form.cuit}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainer>
                        <OptionContainerMini>
                            <LabelForm>
                                Número de cuenta
                            </LabelForm>
                            <TextForm>
                                Escribir el número de cuenta
                            </TextForm>
                            <Input
                                type="text"
                                name="numCuenta"
                                value={form.numCuenta}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainerMini>

                        {errors && (
                            <ErrorsContainer>
                                {errors.incompletos && (
                                    <TextError>
                                        {errors.incompletos}
                                    </TextError>
                                )}
                                {errors.razonSocial && (
                                    <TextError>
                                        {errors.razonSocial}
                                    </TextError>
                                )}
                                {errors.cuit && (
                                    <TextError>{errors.cuit}</TextError>
                                )}
                                {errors.numCuenta && (
                                    <TextError>
                                        {errors.numCuenta}
                                    </TextError>
                                )}
                            </ErrorsContainer>
                        )}

                        <SeccionButtons>
                            <ModalJuridica
                                handleClose={handleClose}
                                formAgregar={form}
                                handleAgregar={handleAgregar}
                                handleSubmit={handleSubmit}
                            />

                            <Button
                                style={{
                                    marginRight: "16px",
                                    backgroundColor: "#C9C9C9",
                                    fontSize: "0.8em",
                                    fontWeight: "bold",
                                }}
                                onClick={handleCancelar}
                                variant="contained"
                                size="large"
                            >
                                CANCELAR
                            </Button>
                        </SeccionButtons>
                    </Form>
                </FormContainer>
            </WhiteBox>
        </div>
    );
}