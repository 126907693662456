import { ReactNode, useContext, useEffect, useState } from "react";
import { roles } from "../helper/roles";
import { AxiosResponse } from "axios";
import { getCBUs } from "../api/cbu";
import { BodyGetCBU, GetCBUResponse } from "../interfaces/interfaceCBU/getCBUInterface";
import { CBUsContext } from "../context/cbusContext/cbusContext";
import { BodyProcesarCBU, Cuenta } from "../interfaces/interfaceCBU/procesarCBUInterface";
import { CuentasCBUs } from "../components/Table/CuentasCBUs/CuentasCBUs";

interface Props {
	userRol: string,
	setLoading: (param:boolean) => void,
}

const isBodyWithCuentas = (obj: any): obj is BodyGetCBU | BodyProcesarCBU => {
    return (obj as BodyGetCBU).Cuentas !== undefined || (obj as BodyProcesarCBU).Cuentas !== undefined;
};

export interface CBUS {
	_id: string,
	Fecha: string,
	Hora: string,
	Beneficiario: string,
	Banco: string,
	Importe: string,
	CuentasFilt: Cuenta[],
	Cuentas: ReactNode,
}

const formatCuentas = (cuentas: Cuenta[]) => {
    if (cuentas.length === 0) {
        return "-";
    }
    return cuentas.map(obj => obj.cuenta).join("-");
}

export const useCBUsTableHook = ({userRol, setLoading}: Props) => {
	const { CBUs, actualizarCBUs } = useContext(CBUsContext);
    const [cbusFilt, setcbusFilt] = useState<CBUS[]>([]);

	useEffect(() => {
		if(userRol === roles.administrador || userRol === roles.tesoreria){
			if (CBUs.length !== 0) {
				setcbusFilt(CBUs);
			} else {
				getCBUsApi();
			}
		}
		CBUs.length === 0 && setLoading(false);
	}, []);

	const getCBUsApi = async () => {
		try {
			const res: AxiosResponse<GetCBUResponse> = await getCBUs();
			if (res?.status === 200) {
				// const ultCBUs = res?.data?.body;
				let ultCBUs = res?.data?.body.map((e) => {
					return {
						_id: e._id,
						Fecha: e.Fecha,
						Hora: e.Hora,
						Beneficiario: e.Beneficiario,
						Banco: e.Banco,
						Importe: e.Importe,
						Cuentas: <CuentasCBUs cuentas= { formatCuentas(e.Cuentas) } />,
						CuentasFilt: e.Cuentas,
					}
				});
				actualizarCBUs(ultCBUs);
				setcbusFilt(ultCBUs);
			}
		} catch (error) {
			console.log(error);
		}
	};
    
	const filtCBUs = (name: string) => {
        if (name.length === 0) {
            setcbusFilt(CBUs);
        } else {
			// @ts-ignore: Ignore TypeScript error here
			const filtered = CBUs.filter((e: CBUS[]) =>
				isBodyWithCuentas(e) &&
				e.Cuentas.length > 0 &&
				e.Cuentas.some((cuentaObj) => cuentaObj.cuenta.toString().includes(name))
			);
            setcbusFilt(filtered as (CBUS[]));
            if (filtered.length === 0) {
                setLoading(false);
            }
        }
    };

	return {
		cbusFilt,
		filtCBUs,
	};
};

const styles = {
	button: {
		backgroundColor: "transparent",
		border: "none",
		cursor: "pointer",
		color: "#004B6F",
	},
};


