import React, {useState} from "react";
import Button from "@mui/material/Button";
import ModalMotivoRechazo from "../../ModalMotivoRechazo/ModalMotivoRechazo";

// Componente que muestra el estado en las  pantallas Rechazadas, Aprobadas y Subsanadas
// (no se puede modificar por eso desde acá el estado)

interface Props {
    texto: string,
    id: string,
}

const textoOpciones = {
    Rechazada: 'Rechazada',
    Aprobada: 'Aprobada',
    Subsanada: 'Subsanada',
}


export const EstadoButton = ({texto, id} : Props) => {

    const backgroundButton = texto === textoOpciones.Rechazada ? '#FFA3A3BF' : texto === textoOpciones.Aprobada ? '#C2DEFFBF' : '#FBC8E0';
    const colorButton = texto === textoOpciones.Rechazada ? '#E3342F' : texto === textoOpciones.Aprobada ? '#00386E' : '#FF3093';

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        if(texto === textoOpciones.Rechazada){
            //Abrir modal
            handleOpen()
        }
    }
    
    return (
        <div>
        <Button
            onClick={handleClick}
            variant="contained"
            size="small"
            style={{
                textTransform: "capitalize",
                fontSize: "12px",
                height: "25px",
				width: "90px",
                background: backgroundButton,
                color: colorButton,
                fontWeight: "300",
            }}
        >
            {texto}
        </Button>
        {
            texto === textoOpciones.Rechazada ?
            <ModalMotivoRechazo id={id} handleClose={handleClose} open={open}/>
            : null
        }
        </div>
    );
};
