import styled from "styled-components";

export const Select = styled.select`
    border-radius: 5px;
	border: none;
    padding: 0.8em;
    margin: 0 0.5em 1em 0.5em;
    background-image: url("https://api.iconify.design/ep/arrow-down-bold.svg");
    background-repeat: no-repeat, repeat;
	background-position: right 0.9em top 50%, 0 0;
	background-size: 0.85em auto, 100%;
    overflow: hidden;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
    box-shadow: 4px 4px 14px 0px rgba(235, 235, 237, 1) inset;
`;

export const TextContainer = styled.div`
    height: 0.7em;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 0 1em 0;
`;

export const Text = styled.p`
    font-size: 0.8em;
    font-weight: bold;
`;