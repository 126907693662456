
export const formatName = (name: string) => {
    // paso todo a minuscula;
    const newName = name.toLowerCase().split(' ')
    // convierto las primeras letras en mayuscculas.
    const finalName = newName.map( n => {
        if (n !== 'y/o'){
            const name = n.split('');
            name[0] = name[0]?.toUpperCase();
            return name?.join('');
        }
        return n
    });
    return finalName.join(' ');        
}