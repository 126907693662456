import { InformadasResponse } from "../interfaces/interfaceInformados/getInformadasInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getInformadas () {
	return await apiInstance.get<InformadasResponse>("/informadas");
};
export async function editarFichaInformada (id : string, motivo: any) {
	return await apiInstance.put("/informe/editar/" + id, motivo);
}
export async function informar (id: string) {
	return await apiInstance.put("/informar/" + id);
}
