import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { getMotivoRechazo } from "../../api/rechazadas";

const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 4,
};

//Mostrar los motivos y permitir editarlos según el rol

interface Props {
    id: string,
    handleClose: () => void,
    open: boolean,
}

export default function ModalMotivoRechazo({id, handleClose, open} : Props) {

    const [motivo, setMotivo] = useState("")

    useEffect(() => {
        if(open){
            getMotivo();
        }
    }, [open])

    const getMotivo = async () => {
        let res = await getMotivoRechazo(id);
        res?.data?.body?.motivo ? setMotivo(res?.data?.body?.motivo) : setMotivo("-");
    }
    

	return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                            lineHeight: 1.2,
                            width: 300,
                            margin: "0 auto",
                            marginBottom: "24px",
                        }}
                    >
                        MOTIVO
                    </Typography>
                    {
                        motivo ?
                        <p style={styles.texto}>{motivo}</p>
                        :
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress
                                style={{
                                    color: "#004B6F",
                                    marginBottom: "0.5em",
                                    marginTop: "0.5em",
                                }}
                            />
                        </Box>
                    }
                </Box>
            </Modal>
        </div>
    );
}

const styles = {
    texto: {
        fontSize: '1em',
        textAlign: 'center' as 'center'
    }
}
