import styled from "styled-components";


export const ButtonsContainer = styled.div`
    display: flex;
	justify-content: center;
	margin-top: 32px;
`;

export const Estados = styled.div`
    margin-top: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EstadoAprobado = styled.div`
    background: #c2deffbf;
	color: #00386e;
	width: 135px;
	height: 30px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const EstadoRechazado = styled.div`
    background: #ffa3a3bf;
	color: #e3342f;
	width: 135px;
	height: 30px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const ContainerCheck = styled.div`
    display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1em;
`;

export const InputMotivos = styled.textarea`
    background: #F5F5F5;
    border: none;
    padding: 0.5em;
    font-size: 0.9em;
    width: 100%;
    /* resize: none; */
    &:focus-visible {
        outline: none;
    }
`;

export const TextMotivos= styled.p`
    font-size: 0.9em;
	margin-bottom: 0.3em;
`;


export const s = {
    estadoPendiente: {
        background: "#fbe6b9bf",
        color: "#c99000",
        width: "135px",
        height: "30px",
        borderRadius: "5px",
        WebkitBorderRadius: "5px", // Para navegadores Webkit (Chrome, Safari)
        MozBorderRadius: "5px", // Para navegadores Mozilla (Firefox)
        MsBorderRadius: "5px", // Para navegadores Microsoft (Internet Explorer)
        OBorderRadius: "5px", // Para navegadores Opera
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center" as "center",
    },
    estadoPendienteSubsanado : {
        background: "#e3c0ffbf",
        color: "#8d03fa",
        width: "135px",
        height: "30px",
        borderRadius: "5px",
        WebkitBorderRadius: "5px", // Para navegadores Webkit (Chrome, Safari)
        MozBorderRadius: "5px", // Para navegadores Mozilla (Firefox)
        MsBorderRadius: "5px", // Para navegadores Microsoft (Internet Explorer)
        OBorderRadius: "5px", // Para navegadores Opera
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center" as "center",
    },
    estadoInformado: {
        background: "#C4FFC2",
        color: "#369F34",
        width: "135px",
        height: "30px",
        borderRadius: "5px",
        WebkitBorderRadius: "5px", // Para navegadores Webkit (Chrome, Safari)
        MozBorderRadius: "5px", // Para navegadores Mozilla (Firefox)
        MsBorderRadius: "5px", // Para navegadores Microsoft (Internet Explorer)
        OBorderRadius: "5px", // Para navegadores Opera
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center" as "center",
    }
}


