import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { asignarManager } from "../../../api/pendientes";
import { ButtonsContainer } from "./elements";


const style = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 450,
	height: 280,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 4,
};
const styleButtons = {
	width: "130px",
	height: "35px",
	fontWeight: "bold",
};

//Modal para asignar y/o reasignar un manager

interface Props {
	handleClose: () => void, 
	texto: string, 
	id: string, 
	setManagerName: (texto : string) => void, 
	managerName: string, 
	idManager: string,
}

export default function ManagerModal({handleClose, texto, id, setManagerName, managerName, idManager} : Props ) {
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => setOpen(true);
	const handleCerrar = () => {
		setOpen(false);
		handleClose();
	};

	const submit = async () => {
		try {

            const res = await asignarManager(id, {manager: idManager})
            
            setManagerName(texto)
		
		} catch (error) {
			console.log(error);
		}
		setOpen(false);
		handleClose();
	};

	return (
		<div>
			<Button
				onClick={handleOpen}
				variant="text"
				style={{
					color: "#004B6FBF",
					textTransform: "capitalize",
				}}
			>
				{texto}
			</Button>
			<Modal
				keepMounted
				open={open}
				onClose={handleCerrar}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="keep-mounted-modal-title"
						variant="h6"
						component="h2"
						style={{
							fontFamily: "Montserrat",
							fontSize: 20,
							fontWeight: "bold",
							textAlign: "center",
							lineHeight: 1.2,
							width: 300,
							margin: "0 auto",
						}}
					>
						¿QUERÉS ASIGNAR A {texto.toUpperCase()} COMO OPERADOR?
					</Typography>
					<ButtonsContainer>
						<Button
							style={{
								...styleButtons,
								background: "#004B6F",
								marginRight: "16px",
							}}
							onClick={submit}
							variant="contained"
							size="small"
						>
							ACEPTAR
						</Button>
						<Button
							style={{ ...styleButtons, background: "#C9C9C9" }}
							onClick={handleCerrar}
							variant="contained"
							size="small"
						>
							CANCELAR
						</Button>
					</ButtonsContainer>
				</Box>
			</Modal>
		</div>
	);
}
