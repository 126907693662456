import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3em;
`;

export const TextoTabla = styled.p`
    width:25%;
    text-align:right;
`;
export const TextoTablaWebVieja = styled.p`
    text-align:left;
`;