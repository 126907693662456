
import { TableColumn } from 'react-data-table-component';
import { RowDividendosTable } from '../../interfaces/interfacesRows/rows';
import { formatCurrency } from '../formatCurrency';

const errorColor = "#FFBABA";

const columnsDividendosTable: TableColumn<RowDividendosTable>[] = [
    {
        selector: (row: RowDividendosTable) => row["'Comitente'"],
        name: "Comitente",
        center: true,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) => row["ID de evento"],
        name: "ID",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) => row["Instrumento subyacente"],
        name: "Instrumento",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) => row["Total eligible balance"],
        name: "Tenencia Caja",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) => row["'Tenencia'"],
        name: "Tenencia Gallo",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) => formatCurrency(row["Importe neto"]),
        name: "Importe Caja",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) => formatCurrency(row["'Renta y Amortiz'"]),
        name: "Renta Gallo",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) =>
            row["Total eligible balance"] - row["'Tenencia'"],
        name: "Diferencia tenencia",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
    {
        selector: (row: RowDividendosTable) =>
            formatCurrency(row["Importe neto"] - row["'Renta y Amortiz'"]),
        name: "Diferencia renta",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
        conditionalCellStyles: [
            {
                when: (row: RowDividendosTable) => row["diferencias"] === true,
                style: {
                    backgroundColor: errorColor,
                },
            },
        ],
    },
];


export {
    columnsDividendosTable,
};
