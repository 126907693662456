import styled from "styled-components";

export const ProgramadasContainer = styled.div`
	width: 100%;
	display: flex;
	color: #333333;
	font-size: 0.8em;
	flex-direction: column;
	align-items: flex-start;
`;

export const Sub = styled.p`
	font-size: 1.3em;
	margin: 0;
`;

export const ProgramadaItem = styled.div`
    width: 100%;
	height: 4em;
	display: flex;
`;

export const TitleItem = styled.p`
	align-self: flex-start;
	font-weight: bold;
`;

export const DateItem = styled.p`
	position: absolute;
	right: 1.8em;
`;

export const TimeItem = styled.p`
	position: absolute;
	right: 1.8em;
	margin-top: 2.5em;
`;