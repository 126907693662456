import React from 'react'
import { Button } from "@mui/material";
import { Box, Container, Form, Input, LabelForm, SeccionButton, Sub, TextError, TextSucces } from './elements'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FormConsultarPin, useFormConsultarPin } from '../../hooks/useFormConsultarPin';

export const ConsultarPin = () => {

    const { width, height } = useWindowDimensions();

    const initialForm: FormConsultarPin = {
        DNI: "", 
        accountNumber: "",
    }

    const { form, errors, handleChange, handleSubmit, successText } = useFormConsultarPin(initialForm);


    return (
        <Container style={{
            left: width * 0.165,
            width: width * 0.82,
            height: height * 0.91,
        }}>
            <Sub>CONSULTAR PIN</Sub>
            <Box>
                <Form onSubmit={handleSubmit}>
                    <LabelForm>Número de cuenta</LabelForm>
                    <Input
                        type='text'
                        onChange={(e) => handleChange(e)}
                        name="accountNumber"
                        value={form.accountNumber}
                    />
                    <LabelForm>DNI</LabelForm>
                    <Input 
                        type='text'
                        onChange={(e) => handleChange(e)}
                        name="DNI"
                        value={form.DNI}
                    />
                    {errors.incompletos && <TextError>{errors.incompletos}</TextError>}
                    {errors.request && <TextError>{errors.request}</TextError>}
                    {successText && <TextSucces>{successText}</TextSucces>}
                    <SeccionButton>
                        <Button
                                style={{
                                    backgroundColor: "#004B6F",
                                    fontSize: "0.9em",
                                    fontWeight: "bold",
                                }}
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                CONFIRMAR
                            </Button>
                    </SeccionButton>
                </Form>
            </Box>
        </Container>
    )
}
