import styled from 'styled-components';

export const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1.2em;
`;

export const Input = styled.input`
    width: 85%;
    border: none;
    box-shadow: 4px 4px 14px 0px rgba(235, 235, 237, 1) inset;
    border-radius: 4px;
    background-color: white;
    padding: 0.6em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 1em;
    font-weight: lighter;

    &:focus {
        outline: none;
        border: none;
    }

    &::placeholder {
        font-weight: lighter;
    }
`;

