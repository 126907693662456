import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { Icon } from "@iconify/react";
import ModalEstado from "../../ModalEstado/ModalEstado";
import ModalMotivos from "../../ModalMotivos/ModalMotivos";
import { useNavigate } from "react-router-dom";
import { roles } from "../../../helper/roles";
import { Text } from "./elements";

// Muestra el estado actual de la ficha y los estados posibles para modificar 

interface Props {
	id: string,
	manager: string,
	status: string,
	phaseLength: number,
	rol: string,
	subsanado: boolean | string,
}

export default function EstadoSelect({id, manager, status, phaseLength, rol, subsanado } : Props) {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (status === "PENDIENTE" || status === "INFORMADO") {
			setAnchorEl(event.currentTarget);
		} else {
			console.log("no se puede cambiar el estado");
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const subsanar = () => {
		let path;
		if (rol === roles.administrador) {
			path = "/editar-subsanar";
		} else {
			path = "/subsanar/" + id;
		}
		navigate(path);
	};

	function returnColor() {
		let background;
		let color;
		let reference;

		if (manager === "ASIGNAR") {
			background = "#DCDCDCBF";
			color = "#878787";
			reference = "Sin manager"
		} else {
			if (subsanado === "true") {
				background = "#E3C0FFBF";
				color = "#8D03FA";
				reference = "Ficha subsanada por el cliente"
			} else {
				background = "#FBE6B9";
				color = "#C99000";
				reference = "Maneger asignado"
			}
		}
		return { color, background, reference };
	}
	return (
		<div>
			{status === "PENDIENTE" && (
				<Button
					id="demo-customized-button"
					aria-controls={open ? "fade-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					variant="contained"
					size="small"
					endIcon={
						(manager === "ASIGNAR" &&
							rol !== "administrador") ||
						rol === roles.operador  ? (
							<Icon
								style={{ fontSize: "10px", display: "none" }}
								icon="ep:arrow-down-bold"
							/>
						) : (
							<Icon
								style={{ fontSize: "10px" }}
								icon="ep:arrow-down-bold"
							/>
						)
					}
					style={{
						textTransform: "capitalize",
						fontSize: "12px",
						height: "25px",
						width: "90px",
						background: returnColor().background,
						color: returnColor().color,
					}}
					disabled={
						(manager === "ASIGNAR" &&
							rol !== "administrador") ||
						rol === roles.operador
					}
				>
					Pendiente
				</Button>
			)}
			<Text>{returnColor().reference}</Text>
			{status === "SUBSANADO" && (
				<ModalMotivos id={id} handleClose={handleClose} />
			)}
			{status === "INFORMADO" && (
				<Button
					id="demo-customized-button"
					aria-controls={open ? "fade-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					variant="contained"
					size="small"
					endIcon={
						manager === "ASIGNAR" &&
						rol !== "administrador" ? (
							<Icon
								style={{ fontSize: "10px", display: "none" }}
								icon="ep:arrow-down-bold"
							/>
						) : (
							<Icon
								style={{ fontSize: "10px" }}
								icon="ep:arrow-down-bold"
							/>
						)
					}
					style={{
						textTransform: "capitalize",
						fontSize: "12px",
						height: "25px",
						width: "90px",
						background: "#C4FFC2",
						color: "#369F34",
					}}
				>
					Informado
				</Button>
			)}

			<Menu
				id="fade-menu"
				MenuListProps={{
					"aria-labelledby": "fade-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{
					<MenuItem
						sx={{
							color: "#004B6F",
						}}
					>
						<ModalEstado
							handleClose={handleClose}
							texto="Rechazar"
							desde={
								status === "PENDIENTE"
									? "Pendiente"
									: "Informado"
							}
							id={id}
							phaseLength={phaseLength}
							subsanado={subsanado}
							rol={rol}
						/>
					</MenuItem>
				}

				{rol === roles.compliance && (
					<MenuItem onClick={handleClose}>
						<Button
							variant="text"
							style={{
								color:
									phaseLength < 6
										? 
										"#c0c0c0"
										: "#004B6FBF",
								textTransform: "capitalize",
							}}
							onClick={subsanar}
							disabled={
								phaseLength < 6
							}
						>
							Subsanar
						</Button>
					</MenuItem>
				)}

				{status === "PENDIENTE" && !(rol === roles.comercial) && (
					<MenuItem
						sx={{
							color: "#004B6F",
						}}
					>
						<ModalEstado
							handleClose={handleClose}
							texto="Aprobar"
							desde={
								status === "PENDIENTE"
									? "Pendiente"
									: "Informado"
							}
							id={id}
							phaseLength={phaseLength}
							subsanado={subsanado}
							rol={rol}
						/>
					</MenuItem>
				)}
				{status === "INFORMADO" && (
					<MenuItem
						sx={{
							color: "#004B6F",
						}}
					>
						<ModalMotivos
							id={id}
							handleClose={handleClose}
							_motivos={true}
						/>
					</MenuItem>
				)}
			</Menu>
		</div>
	);
}
