import React from "react";
import ReactDOM from 'react-dom/client';
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import App from "./App.js";

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 			<App />
// 		</Provider>
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

reportWebVitals();
