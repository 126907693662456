import { useState, useEffect, ChangeEvent } from "react"
import { useNavigate } from "react-router-dom";
import { tiposDeCuentas } from "../views/Transferencias/AgregarCriterio/AgregarCriterio";
import { postCriterios, putCriterios } from "../api/transferencias";

/**
 * Hook que manipula el formulario para agregar o modificar un criterio
 * @param {Objetc} initialForm Formulario inicial
 * @returns {Objetc} 
 */

interface Errors {
    incompletos?: string,
    cuentas?: string,
    updateError?: string,
}

interface InitialFormAgregarCriterio {
    tipo: string,
    cuentas: string,
    monto: string,
    porcTenencia: string,
}

const validateForm = ({ tipo, cuentas, monto, porcTenencia } : InitialFormAgregarCriterio) => {
    let errors: Errors = {};

    if(!tipo || !monto || !porcTenencia ){
        errors.incompletos = "Campos incompletos"
    } 
    if (tipo === tiposDeCuentas.especificas) {
        if(!cuentas){
            errors.incompletos = "Campos incompletos"
        } else {
            const isValidInput = /^[0-9-]*$/.test(cuentas.trim());
            if(!isValidInput){
                errors.cuentas = "El campo cuentas solo puede contener números y - como separador"
            }
        }
    }
    
    return errors;
}


export const useFormAgregarCritierio = (initialForm: InitialFormAgregarCriterio, editarCriterio: boolean, id: string) => {

    const navigate = useNavigate();

    const [form, setForm] = useState<InitialFormAgregarCriterio>(initialForm);

    const [errors, setErrors] = useState<Errors>({});

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form));
        }
    }, [form, (Object.keys(errors).length > 0)]);

    const handleChange = async(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        
        setForm({
            ...form,
            [name]: value,
        });

        // Limpiar el campo cuentas en caso de seleccionar "Todas"
        if(name === "tipo" && value === tiposDeCuentas.todas){
            setForm({
                ...form,
                [name]: value,
                cuentas: '',
            });
        }

        if (Object.keys(errors).length > 0) {
            setErrors( validateForm(form));
        }
    }

    const handleCancelar = () => {
        navigate("/transferencias-criterios");
    }

    const handleSubmit = async() => {
        let errorVariableEstatica = validateForm(form);
        setErrors( validateForm(form));

        const { tipo, cuentas, monto, porcTenencia } = form;

        if (
            Object.keys(errors).length === 0 &&
			Object.keys(errorVariableEstatica).length === 0 
            ) {
                try {
                    let res
                    if(editarCriterio){
                        res = await putCriterios( id, cuentas , monto, porcTenencia, tipo === tiposDeCuentas.todas ? "TODAS" : "ESPECIFICA" );
                    } else {
                        res = await postCriterios( cuentas , monto, porcTenencia, tipo === tiposDeCuentas.todas ? "TODAS" : "ESPECIFICA" );
                    }
                    
                    if(res.status === 200){
                        // console.log(res)
                        navigate("/transferencias-criterios");
                    } else {
                        setErrors({updateError : "Ocurrió un error, revisar los datos ingresados"})
                    }
                } catch (error) {
                    console.log(error)
                    setErrors({updateError : "Ocurrió un error, revisar los datos ingresados"})
                }
        } 
    }

    return {
        form,
        errors,
        handleChange,
        handleSubmit,
        handleCancelar,
    }
}