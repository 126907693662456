import { TableColumn } from 'react-data-table-component';
import { RowEstadoRentas } from '../../interfaces/interfacesRows/rows';

const columnsEstadoRentas: TableColumn<RowEstadoRentas>[] = [
    {
        selector: (row: RowEstadoRentas) => row["ID de evento"],
        name: "ID",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowEstadoRentas) => row["Fecha de pago"],
        name: "Fecha de pago",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowEstadoRentas) => row["Instrumento subyacente"],
        name: "Instrumento",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowEstadoRentas) => row["nombreEspecie"],
        name: "Ticker",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },

    {
        selector: (row: RowEstadoRentas) => row["Moneda"],
        name: "Moneda",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
    {
        selector: (row: RowEstadoRentas) => row["check"],
        name: "Procesado",
        center: true,
        grow: 2,
        style: {
            color: "#004B6F",
        },
    },
];

export {
    columnsEstadoRentas,
};
