import styled from "styled-components";

export const Container = styled.div`
	padding-left: 1em;
    padding-top: 1em;
    height: 100%;
    width: 100%;
`;

export const SubtituloContainer = styled.div`
	white-space: nowrap;
    margin-bottom: 1em;
`;

export const Subtitulo = styled.h2`
	font-size: 2em;
    color: rgba(0, 75, 111, 0.75);
`;

export const Box = styled.div`
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 99%;
    background-color: white;
    border-radius: 10px;
`;

