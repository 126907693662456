import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { ModalDatosCoTitulares } from "../../ModalDatos/ModalDatosCoTitulares";
import ModalDatos from "../../ModalDatos/ModalDatos";
import ModalEliminarCriterio from "../../../ModalEliminarCriterio/ModalEliminarCriterio";
import ModalAlert from "../../../ModalAlert/ModalAlert";
import ModalSubsanarMail from "../../../ModalSubsanarMail/ModalSubsanarMail";
import { ItemsDetalle } from "./itemsDetalles";
import { ItemsCotitulares } from "./ItemsCotitulares";
import { Texts } from "../Menu";

interface Props {
    id: string, 
    fichaSinIniciar: boolean, 
    cotitular: boolean, 
    handleOpenDatosCotitular: () => void,
    handleOpenDatos: () => void,
    handleOpenSirena: () => void,
    handleOpenEliminarCriterio: () => void,
    handleClose: () => void,
    telefono: number, 
    nombre: string, 
    rol: string,
    openDatosCotitular: boolean,
    setOpenDatosCotitular: React.Dispatch<React.SetStateAction<boolean>>,
    openDatos: boolean,
    setOpenDatos: React.Dispatch<React.SetStateAction<boolean>>,
    openSirena: boolean,
    setOpenSirena: React.Dispatch<React.SetStateAction<boolean>>,
    openEliminarCriterio: boolean,
    setOpenEliminarCriterio: React.Dispatch<React.SetStateAction<boolean>>,
    cotitularesView: boolean, 
    handleOpenAlerta: (param:string) => void,
    texts: Texts,
    handleOpenSubsanar: () => void,
    openAlerta: boolean,
    text: string,
    email: string, 
    openSubsanar: boolean,
    setOpenSubsanar: React.Dispatch<React.SetStateAction<boolean>>,
    idTitular: string,
    criterios: boolean,
}


export const ItemsAdministrador = (props : Props) => {
    const {
        id,
        fichaSinIniciar,
        cotitular,
        handleOpenDatosCotitular,
        handleOpenDatos,
        handleOpenSirena,
        handleOpenEliminarCriterio,
        handleClose,
        telefono,
        nombre,
        rol,
        openDatosCotitular,
        setOpenDatosCotitular,
        openDatos,
        openSirena,
        setOpenSirena,
        setOpenDatos,
        openEliminarCriterio,
        setOpenEliminarCriterio,
        cotitularesView,
        handleOpenAlerta,
        texts,
        handleOpenSubsanar,
        openAlerta,
        text,
        email,
        openSubsanar,
        setOpenSubsanar,
        idTitular,
        criterios,
    } = props;

    return (
        <div>
            {!fichaSinIniciar && !criterios && (
                <ItemsDetalle
                    id={id}
                    fichaSinIniciar={fichaSinIniciar}
                    cotitular={cotitular}
                    handleOpenDatosCotitular={handleOpenDatosCotitular}
                    handleOpenDatos={handleOpenDatos}
                    handleOpenSirena={handleOpenSirena}
                    handleClose={handleClose}
                    telefono={telefono}
                    nombre={nombre}
                    rol={rol}
                    openDatosCotitular={openDatosCotitular}
                    setOpenDatosCotitular={setOpenDatosCotitular}
                    openDatos={openDatos}
                    setOpenDatos={setOpenDatos}
                    openSirena={openSirena}
                    setOpenSirena={setOpenSirena}
                />
            )}
            {criterios && (
                <div>
                    <MenuItem
                        onClick={handleOpenEliminarCriterio}
                        sx={{
                            color: "#004B6F",
                            justifyContent: "center",
                        }}
                    >
                        Eliminar criterio
                    </MenuItem>
                    <ModalEliminarCriterio
                        open={openEliminarCriterio}
                        setOpen={setOpenEliminarCriterio}
                        id={id}
                    />
                </div>
            )}
            {cotitularesView && fichaSinIniciar && (
                <ItemsCotitulares
                    id={id}
                    fichaSinIniciar={fichaSinIniciar}
                    handleClose={handleClose}
                    cotitularesView={cotitularesView}
                    handleOpenAlerta={handleOpenAlerta}
                    texts={texts}
                    handleOpenSubsanar={handleOpenSubsanar}
                    openAlerta={openAlerta}
                    text={text}
                    email={email}
                    openSubsanar={openSubsanar}
                    setOpenSubsanar={setOpenSubsanar}
                    idTitular={idTitular}
                />
            )}
        </div>
    );
};
