import React from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Table from "../../../components/Table/Table";
import { Container } from "./elements";
import { columnsTransferencias } from "../../../helper/Colums/columsTransferencias";

const subtitulo = "TRANSFERENCIAS APROBADAS";

export const TransferenciasAprobadas = () => {

    const { width } = useWindowDimensions();

    return (
		<>
			<Container style={{ width: width * 0.85, left: width * 0.15,  } }>
				<Table subtitulo={subtitulo} columns={columnsTransferencias} />
			</Container>
		</>
	);
};
