import React, { useContext } from "react";
import { Button } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useFormCambiarContraseña } from "../../hooks/useFormCambiarContraseña";
import { Input, OptionContainer } from "../AgregarJuridica/elements";
import { UserContext } from "../../context/userContext/UserContexts";
import { Container, ErrorsContainer, Form, FormContainer, LabelForm, SeccionButtons, Subtitulo, TextError, WhiteBox } from "./elements";

interface ObjetcForm {
    mail: string, 
    contraseñaAnterior: string, 
    nuevaContraseña: string, 
    nuevaContraseñaCheck: string,
}

export const CambiarContraseña = () => {

    const { width } = useWindowDimensions();

    const { user } = useContext(UserContext);

    const initialForm : ObjetcForm = {
        mail: user.email,
        contraseñaAnterior: "",
        nuevaContraseña: "",
        nuevaContraseñaCheck: "",
    };

    const { form, errors, handleChange, handleSubmit } = useFormCambiarContraseña(
        initialForm,
    );


    return (
        <Container style={{ width: width * 0.82, position: "absolute", left: width * 0.165, }}>
            <Subtitulo>CAMBIAR CONTRASEÑA</Subtitulo>
            <WhiteBox>
                <FormContainer>
                    <Form>
                        <OptionContainer>
                            <LabelForm>Email</LabelForm>
                            <Input
                                type="text"
                                name="mail"
                                placeholder={form.mail}
                                disabled
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>Contraseña anterior</LabelForm>
                            <Input
                                type="password"
                                name="contraseñaAnterior"
                                value={form.contraseñaAnterior}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>Nueva contraseña</LabelForm>
                            <Input
                                type="password"
                                name="nuevaContraseña"
                                value={form.nuevaContraseña}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm>
                                Repetir nueva contraseña
                            </LabelForm>
                            <Input
                                type="password"
                                name="nuevaContraseñaCheck"
                                value={form.nuevaContraseñaCheck}
                                onChange={(e) => handleChange(e)}
                            />
                        </OptionContainer>

                        {errors && (
                            <ErrorsContainer>
                                {errors.incompletos && (
                                    <TextError>
                                        {errors.incompletos}
                                    </TextError>
                                )}
                                {errors.contraseña && (
                                    <TextError>
                                        {errors.contraseña}
                                    </TextError>
                                )}
                                {errors.updateError && (
                                    <TextError>
                                        {errors.updateError}
                                    </TextError>
                                )}
                            </ErrorsContainer>
                        )}

                        <SeccionButtons>
                            <Button
                                style={{
                                    marginRight: "16px",
                                    backgroundColor: " rgba(0, 75, 111)",
                                    fontSize: "0.8em",
                                    fontWeight: "bold",
                                }}
                                onClick={handleSubmit}
                                variant="contained"
                                size="large"
                            >
                                CONFIRMAR
                            </Button>
                        </SeccionButtons>
                    </Form>
                </FormContainer>
            </WhiteBox>
        </Container>
    );
};
