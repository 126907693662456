import React, { useEffect, useState } from 'react'
import { getTranferencias } from '../api/transferencias';
import { AxiosResponse } from 'axios';
import { GetTransferenciasResponse, Transferencia } from '../interfaces/interfaceTransferencias/getTransferenciasInterface';

interface Props {
	subtitulo: string,
	setLoading: (param:boolean) => void,
}

export const useTranferenciasHook = ({subtitulo, setLoading}: Props) => {

    const [allTranferencias, setAllTranferencias] = useState<Transferencia[]>([]);
    const [transferenciasPendientes, setTransferenciasPendientes] = useState<Transferencia[]>([]);
    const [transferenciasAnuladas, setTransferenciasAnuladas] = useState<Transferencia[]>([]);
    const [transferenciasAprobadas, setTransferenciasAprobadas] = useState<Transferencia[]>([]);
    
    const getAllTranferencias = async () => {
        let transferenciasData: Transferencia[] = [];
        try {
            const res: AxiosResponse<GetTransferenciasResponse> = await getTranferencias();
            if(res?.status === 200) {
                // console.log(res?.data?.body);
                transferenciasData = res?.data?.body;
                setAllTranferencias(res?.data?.body);
                setLoading(false);
                const pendientes = transferenciasData?.filter(
                    (e) => e.estado === "Pendiente Operador"
                );
                const aprobadas = transferenciasData?.filter(
                    (e) => e.estado === "Cumplida"
                );
                const anuladas = transferenciasData?.filter(
                    (e) => e.estado === "Anulada"
                );
                setTransferenciasPendientes(pendientes);
                setTransferenciasAprobadas(aprobadas);
                setTransferenciasAnuladas(anuladas);
                
            }

            !(transferenciasData?.length > 0) && setLoading(false);
        } catch (error) {
            console.log(error)
        }
    };

        // Funcion de filtro

	const filtTransferencia = (name: string) => {
        name = name.toLowerCase();

        let arr: Transferencia[] = [];

        if (subtitulo === "TRANSFERENCIAS PENDIENTES") {
            arr = allTranferencias?.filter(
                (e) => e.estado === "Pendiente Operador"
            );
        } else if (subtitulo === "TRANSFERENCIAS APROBADAS") {
            arr = allTranferencias?.filter(
                (e) => e.estado === "Cumplida"
            );
        } else if (subtitulo === "TRANSFERENCIAS ANULADAS") {
            arr = allTranferencias?.filter(
                (e) => e.estado === "Anulada"
            );
        }
        
        let filtered = arr.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.cliente.toLowerCase().includes(name) ||
                e.ticker.toLowerCase().includes(name) ||
                e.importe.toLowerCase().includes(name) ||
                e.numOrden.toLowerCase().includes(name) 
        );

        setTransferenciasPendientes(filtered);
        setTransferenciasAprobadas(filtered);
        setTransferenciasAnuladas(filtered);

		filtered.length === 0 && setLoading(false);
    };
    

    return {
        getAllTranferencias,
        allTranferencias,
        transferenciasPendientes,
        transferenciasAprobadas,
        transferenciasAnuladas,
        filtTransferencia,
    };
}
