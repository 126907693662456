import styled from "styled-components";

export const Box = styled.div`
	background-color: white;
    margin-top: 0.1em;
	height: 90%;
    width: 100%;
    border-radius: 0.8em;
    margin-bottom: 0;
`;


export const SeccionDatos = styled.div`
	background-color: #ecf5ff;
    font-weight: bold;
    height: 10%;
    width: 100%;
    padding-left: 3em;
    margin-top: 2.5em;
    display: flex;
    flex-direction: row;
`;

export const SeccionSubidos = styled.div`
    margin-left: 2em;
`;

export const SeccionAdjuntos = styled.div`
    position: relative;
    /* top: 4em;
    left: 3em; */
    display: flex;
    flex-wrap: wrap;
    /* width: 100%; */
    padding-top: 2em;
    margin-left: 3em;
`;

export const SeccionButtons = styled.div`
    position: absolute;
    bottom: 3em;
    left: 40%;
    @media screen and (min-width: 1680px) {
        position: absolute;
        bottom: 5em;
        left: 40%;
    }
`;

export const MiniBox = styled.div`
    display: flex;
    align-items: center;
    margin: 1em 2em;
    width: 25em;
`;

export const Input = styled.input`
    display: none;
`

export const Label = styled.label`
    display: flex;
    width: 16em;
    height: 5em;
    padding: 1.5em;
    margin-left: 1.5em;
    background: #ffffff;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6),
        inset 4px 4px 14px #ebebed;
    border-radius: 10px;
    border: solid rgba(183, 183, 183, 0.6) 1px;
    cursor: pointer;
    color: #757474;
    font-weight: bolder;
    align-items: center;
    font-size: 1em;
`;

export const Subtitulo = styled.h4`
    font-size: 1.5em;
    /* text-align: left; */
    margin-top: 0;
    margin-bottom: 0.5em;
`;

export const Sub = styled.h6`
    font-size: 1em;
    margin-top: 2em;
    margin-bottom: 0.5em;
`; 

export const ColumnaDatos = styled.div`
    margin-right: 1em;
`;

export const ColumnaError = styled.div`
    width: 50%;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: end;
`;

export const Datos = styled.p`
    padding-top: 0.5em;
    @media screen and (min-width: 1640px) {
    padding-top: 0.8em;
    }
`;

export const WarningBox = styled.div`
    background: rgba(255, 163, 163, 0.75);
	padding: 0.5em;
	border-radius: 0.5em;
	margin-top: 0.5em;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const WarningText = styled.p`
    padding: 0.1em;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #FF0000;
	font-weight: bold;
`;

export const Logo = styled.img`
    width: 1.3em;
    height: 1.3em;
`;

export const LogoAdjuntar = styled.img`
    width: 2em;
    height: 2em;
    margin-right: 0.8em;
`;

export const Icon = styled.img`
    width: 2.4em;
`;

export const Lista = styled.ul`
    list-style: none;
    font-weight: bold;
`;

export const Item = styled.li`
    display: flex;
    align-items: stretch;
    margin: 1em 0;
`;

export const LinkBox = styled.div`
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
    border-radius: 10px;
    width: 4em;
    height: 4em;
    border: solid rgba(183, 183, 183, 0.6) 1px;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export const ViewBox = styled.div`
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6), inset 4px 4px 14px #EBEBED;
    border-radius: 5px;
    width: 2.5em;
    height: 2.5em;
    border: solid rgba(183, 183, 183, 0.6) 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5em;
    color: rgba(0, 56, 110, 0.5);
    font-weight: bold;
    align-items: center;
    font-size: 1em;
`;






