import apiInstance from "../utils/Axios.js";


export async function getPendientesOperador() {
	return await apiInstance.get("/clientes/pendientes");
}

export async function getAprobadosOperador() {
	return await apiInstance.get("/clientes/aprobados");
}

export async function getInformadosOperador() {
	return await apiInstance.get("/clientes/informados");
}

export async function getSubsanadosOperador() {
	return await apiInstance.get("/clientes/subsanados");
}

export async function getRechazadosOperador() {
	return await apiInstance.get("/clientes/rechazados");
}
