import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { TableColumn } from 'react-data-table-component';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useTableHook from "../../hooks/useTableHook";
import { StyledMenu } from "../../views/OrdenesCartera/OrdenesCartera";
import { roles } from "../../helper/roles";
import { DownloadButton, CheckContainer, CheckText, CriterioContainer, DataBox, InputSearch, PlusIconContainer, PlusTexto, SearchButton, SearchContainer, Sub, SubContainer, RowContent, ButtonContainer } from "./elements";
import { baseURL } from "../../utils/Axios";
import { Checkbox } from "@material-ui/core";
import { useState } from "react";
import ModalEstadoPedidoDolar from "./ModalEstadoPedidoDolar/ModalEstadoPedidoDolar";

// Tabla que se renderiza en las diferentes screens

interface Props {
    subtitulo: string,
    titularId?: string,
    columns: TableColumn<any>[],
    filtroTenencia?: string
}

export default function Table({ subtitulo, columns, titularId, filtroTenencia }: Props) {
    const { width, height } = useWindowDimensions();
    const {
        handleInput,
        handleSubmit,
        data,
        paginationOpciones,
        loading,
        userRol,
        handleOpenEstadoCuit,
        cuitOpciones,
        openEstadoCuit,
        handleClickEstadoCuit,
        handleCloseEstadoCuit,
        selectedIndex,
        name,
    } = useTableHook({ subtitulo, titularId: titularId || '', filtroTenencia,});

    const customStyles = {
        tableWrapper: {
            style: {
                display: "table",
                minHeight: subtitulo === "PEDIDOS DOLARES TENENCIA" ? '6em' : height * 0.75,
                maxHeight: "33em",
                // minWidh: "30em",
                maxWidth: "100%",
                backgroundColor: "white",
                color: "#004B6F",
                // borderRadius: "20px"
            },
        },

        header: {
            style: {
                minHeight: "56px",
            },
        },
        rows: {
            style: {
                minHeight: "58px", // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",

                backgroundColor: "rgba(142, 178, 221, 0.5)",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                whiteSpace: 'normal', // Permite que el texto se envuelva
                //wordWrap: 'break-word', // Asegura que las palabras largas se envuelvan
            },
        },
    };

    let altura = subtitulo === "CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS" ?
        `${height * 0.65}px` : `${height * 0.75}px`;


    let widthContainer = width * 0.84;
    let heightContainer = height * 0.85;

    return (
        <div style={{ width: widthContainer, height: heightContainer, }}>
                <DataBox>
                    <SubContainer>
                        <Sub>{subtitulo}</Sub>
                    </SubContainer>
                    <div style={{ display: "flex" }}>
                        {userRol === roles.compliance && subtitulo === "APROBADOS" && (
                            <>
                                <Link to={"/agregar-juridica"} style={styles.links}>
                                    <PlusIconContainer>
                                        <Icon
                                            icon="ph:plus-bold"
                                            style={styles.plusIcon}
                                        />
                                        <PlusTexto>
                                            Agregar persona jurídica
                                        </PlusTexto>
                                    </PlusIconContainer>
                                </Link>
                            </>
                        )}

                        {userRol === roles.comercial && subtitulo === "PENDIENTES" && (
                            <>
                                <div style={{ marginRight: "1em" }}>
                                    <List component="nav" style={styles.menu}>
                                        <ListItem
                                            onClick={handleOpenEstadoCuit}
                                            style={styles.listItem}
                                        >
                                            <ListItemText
                                                primary={
                                                    cuitOpciones[selectedIndex]
                                                }
                                            />
                                            <Icon
                                                icon="material-symbols:arrow-forward-ios-rounded"
                                                style={{
                                                    width: "1em",
                                                    height: "1em",
                                                    marginLeft: "0.3em",
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                    <StyledMenu
                                        id="lock-menu"
                                        anchorEl={openEstadoCuit}
                                        keepMounted
                                        open={Boolean(openEstadoCuit)}
                                        onClose={handleCloseEstadoCuit}
                                    >
                                        {cuitOpciones?.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                // disabled={index === selectedIndex}
                                                selected={index === selectedIndex}
                                                onClick={(event) =>
                                                    handleClickEstadoCuit(
                                                        event,
                                                        index
                                                    )
                                                }
                                                style={styles.menuItem}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </StyledMenu>
                                </div>
                                <a
                                    href="https://seti.afip.gob.ar/padron-puc-constancia-internet/ConsultaConstanciaAction.do"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={styles.links}
                                >
                                    <PlusIconContainer>
                                        <Icon
                                            icon="mdi:check-bold"
                                            style={styles.plusIcon}
                                        />
                                        <PlusTexto>
                                            Verificar CUIT en AFIP
                                        </PlusTexto>
                                    </PlusIconContainer>
                                </a>
                            </>
                        )}

                        <SearchContainer>
                            <InputSearch
                                type="text"
                                placeholder="Buscar..."
                                onChange={(e) => handleInput(e)}
                                value={name}
                            />
                            <SearchButton
                                type="submit"
                            >
                                <img
                                    src={require("../../images/tabla/search-icon.png")}
                                    alt="logo"
                                    style={{ width: "1em" }}
                                />
                            </SearchButton>
                        </SearchContainer>
                    </div>
                </DataBox>

            {subtitulo === "CRITERIOS DE ACEPTACIÓN DE TRANSFERENCIAS" && (
                <CriterioContainer>
                    <Link to={"/transferencias/criterio"} style={{ ...styles.links, marginRight: "0.8em", }}>
                        <PlusIconContainer>
                            <Icon
                                icon="ph:plus-bold"
                                style={styles.plusIcon}
                            />
                            <PlusTexto>
                                Agregar nuevo criterio
                            </PlusTexto>
                        </PlusIconContainer>
                    </Link>
                </CriterioContainer>
            )}
                <DataTable
                    columns={columns}
                    data={data!}
                    pagination
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[15, 25, 30, 50, 100]}
                    paginationComponentOptions={paginationOpciones}
                    fixedHeader
                    fixedHeaderScrollHeight={altura}
                    // minHeight={altura}
                    customStyles={customStyles}
                    noDataComponent={
                        loading ? (
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <CircularProgress style={{ color: "#004B6F" }} />
                            </Box>
                        ) : (
                            <>No se encontró ningún elemento</>
                        )
                    }
                    responsive
                    keyField="id"
                />
        </div>
    );
}


const styles = {
    links: {
        textDecoration: "none",
        cursor: "pointer",
        marginRight: "2em",
        marginTop: "0.25em",
    },
    menu: {
        display: "flex",
        backgroundColor: "white",
        height: "2.2em",
        borderRadius: "14px",
        padding: 0,
        margin: 0,
    },
    listItem: {
        display: "flex",
        padding: "0.1em 0.5em 0.1em 0.5em",
        margin: 0,
        fontSize: "0.9em",
        cursor: "pointer",
    },
    menuItem: {
        fontSize: "0.9em",
        backgroundColor: "white",
        color: "rgba(0, 75, 111, 0.75)",
    },
    plusIcon: {
        width: "1.5em",
        height: "1.5em",
        marginRight: "0.3em",
        color: "#004B6F",
    },
    logo: {
        fontSize: "1.7em",
        color: "rgba(85, 86, 88, 0.75)",
        margin: "0.2em",
    },
}