import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
	justify-content: center;
	margin-top: 32px;
	margin-bottom: 16px;
`;

export const InputContainer = styled.div`
    display: flex;
	justify-content: center;
    align-items: center;
    margin-top: 0.5em;
`;

export const Input = styled.input`
    width: 50%;
    border: none;
    box-shadow: 4px 4px 14px 0px rgba(235, 235, 237, 1) inset;
    border-radius: 4px;
    background-color: white;
    padding: 0.6em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 1em;
    font-weight: lighter;

    &:focus {
        outline: none;
        border: none;
    }

    &::placeholder {
        font-weight: lighter;
    }
`;

export const WarningContainer = styled.div`
    display: flex;
	text-align: center;
    margin-top: 1em;
`;

export const WarningText = styled.p`
    font-size: 1em;
`;




