import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useState, useEffect, CSSProperties } from "react";
import { getEmailCode, getSmsCode } from "../../../api/datos";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Icon } from "@iconify/react";
import { confirmacionTitular } from "../../../api/cotitulares";
import { roles } from "../../../helper/roles";
import { ConfirmacionTitularResponse, Resultado, Body, Titular } from "../../../interfaces/interfacesCotitulares/confirmacionTitularInterface";
import { AxiosResponse } from "axios";

// Muestra datos del usuario cuando es cotitular

interface Props {
    id: string, 
    telefono: number, 
    nombre: string, 
    rol: string, 
    handleClose: () => void, 
    setOpen: (param: boolean) => void, 
    open: boolean,
}

export const ModalDatosCoTitulares = ({
	id,
	telefono,
	nombre,
    rol,
	handleClose,
	setOpen,
	open, 
} : Props ) => {
	const [smsCode, setSmsCode] = useState("");
	const [emailCode, setEmailCode] = useState("");
	const [titularesConformes, setTitularesConformes] = useState<string[] | []>([]);
	const [titularesNoConformes, setTitularesNoConformes] = useState<string[] | []>([]);

	const handleCerrar = () => {
		setOpen(false);
		handleClose();
	};
	useEffect(() => {
		if ( open && (rol === roles.administrador || rol === roles.comercial)) {
			getCodes();
			getConformidad();
		}
	}, [open]);

	const getCodes = async () => {
		const sms = await getSmsCode(id);
		const email = await getEmailCode(id);
		setEmailCode(email.data.body);
		if (sms.data.body === "No hay codigo") {
			setSmsCode(sms.data.body);
		} else {
			setSmsCode(sms.data.body.code);
		}
	};

	const getConformidad = async () => {
		try {
			const res: AxiosResponse<ConfirmacionTitularResponse> = await confirmacionTitular(id);
			// console.log(res.data.body.resultado)
			const result : Resultado =  res.data.body.resultado;
			if(result){
				let conformes = [];
				let inconformes = [];
				for (let i = 0; i < result.validate_titular.length; i++) {
					if(result.validate_titular[i].status){
						conformes.push(result.validate_titular[i].titular)
					} else {
						inconformes.push(result.validate_titular[i].titular)
					}
				}
				setTitularesConformes(conformes);
				setTitularesNoConformes(inconformes);
			}
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div>
			<Modal
				keepMounted
				open={open}
				onClose={handleCerrar}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
			>
				<div style={styles.container}>
					<div style={styles.containerModal}>
						<IconButton
							aria-label="delete"
							color="primary"
							size="small"
							onClick={handleCerrar}
							style={{
								color: "#004B6F",
								// alignSelf: "flex-end",
								position: "absolute",
								right: 0,
								top: 4,
							}}
						>
							<CloseOutlinedIcon />
						</IconButton>
						<div style={styles.containerDatos}>
							<div style={styles.flexColumn}>
								<div style={styles.flexColumn}>
									<Typography
										style={{
											fontFamily: "Montserrat",
											fontSize: 18,
											fontWeight: "bold",
											textAlign: "center",
											lineHeight: 1.2,
										}}
									>
										CONFORME
									</Typography>
									{titularesConformes.length > 0 ? titularesConformes.map((tit:string, index:number) => (
										<Typography
											key={index}
											style={{
												fontFamily: "Montserrat",
												fontSize: 14,
												textAlign: "center",
												lineHeight: 1.2,
											}}
										>
											{tit.toLocaleUpperCase()}
										</Typography>
									)) : <p style={{textAlign: 'center'}}>-</p>}
								</div>

								<div
									style={{
										...styles.flexColumn,
										marginTop: 16,
									}}
								>
									<Typography
										style={{
											fontFamily: "Montserrat",
											fontSize: 18,
											fontWeight: "bold",
											textAlign: "center",
											lineHeight: 1.2,
										}}
									>
										NO CONFORME
									</Typography>
									{titularesNoConformes.length > 0 ? titularesNoConformes.map((tit:string, index:number) => (
										<Typography
											key={index}
											style={{
												fontFamily: "Montserrat",
												fontSize: 14,
												textAlign: "center",
												lineHeight: 1.2,
											}}
										>
											{tit.toLocaleUpperCase()}
										</Typography>
									)) : <p style={{textAlign: 'center'}}>-</p>}
								</div>
							</div>
							<div
								style={{
									height: "160px",
									width: "1px",
									backgroundColor: "#949494",
									marginRight: 32,
									marginLeft: 32,
								}}
							/>

							<div style={styles.flexColumn}>
								<div style={styles.containerNombreTelefono}>
									<Typography
										style={{
											fontFamily: "Montserrat",
											fontSize: 18,
											fontWeight: "bold",
											textAlign: "center",
											lineHeight: 1.2,
										}}
									>
										{nombre.toUpperCase()}
									</Typography>
									<Typography
										style={{
											fontFamily: "Montserrat",
											fontSize: 18,
											fontWeight: "bold",
											textAlign: "center",
											lineHeight: 1.2,
										}}
									>
										{telefono}
									</Typography>
								</div>
								<div style={styles.containerIconoCodigo}>
									<Icon
										icon="mdi:email-outline"
										fontSize={"2.2em"}
									/>
									<div
										style={{
											...styles.flexColumn,
											marginTop: 4,
										}}
									>
										<Typography
											style={{
												fontFamily: "Montserrat",
												fontSize: 10,
												fontWeight: "bold",
												lineHeight: 1.2,
												marginLeft: 16,
											}}
										>
											CÓDIGO DE EMAIL:
										</Typography>
										<Typography
											style={{
												fontFamily: "Montserrat",
												fontSize: 18,
												fontWeight: "bold",
												lineHeight: 1.2,
												marginLeft: 16,
											}}
										>
											{emailCode}
										</Typography>
									</div>
								</div>

								{smsCode !== "No hay codigo" && (
									<div style={styles.containerIconoCodigo}>
										<Icon
											icon="mdi:cellphone"
											fontSize={"2.2em"}
										/>
										<div
											style={{
												...styles.flexColumn,
												marginTop: 4,
											}}
										>
											<Typography
												style={{
													fontFamily: "Montserrat",
													fontSize: 10,
													fontWeight: "bold",
													lineHeight: 1.2,
													marginLeft: 16,
												}}
											>
												CÓDIGO DE SMS:
											</Typography>
											<Typography
												id="keep-mounted-modal-title"
												variant="h6"
												component="h2"
												style={{
													fontFamily: "Montserrat",
													fontSize: 18,
													fontWeight: "bold",
													lineHeight: 1.2,
													marginLeft: 16,
												}}
											>
												{smsCode}
											</Typography>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const styles: Record<string, CSSProperties> = {
	container: {
		display: "flex",
		height: "100%",
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		// backgroundColor: "red",
	},
	containerModal: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		padding: 48,
		boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
		borderRadius: 8,
		position: "relative",
		// justifyContent: "center",
		// alignItems: "center",
	},
	flexColumn: {
		display: "flex",
		flexDirection: "column",
	},
	containerDatos: {
		display: "flex",
		flexDirection: "row",
		// padding: 28,
		// paddingRight: 48,
		// paddingLeft: 48,
		alignItems: "center",
		// backgroundColor:"red"
	},
	containerIconoCodigo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	containerNombreTelefono: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 16,
	},
};
